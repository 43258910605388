import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as AuthActions from '../auth/auth.actions';
// import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as SubscribersActions from 'src/app/@core/stores/subscribers/subscribers.actions';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as SubscriberActions from './subscribers.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import {
  USE_CUSTOM_BEARER_TOKEN,
  CUSTOM_BEARER_TOKEN,
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class SubscribersEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(SubscriberActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  // getAllSubscribers$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SubscriberActions.GetAllSubscribers),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([subscribersData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocAuthUrl}/Subscribers/getallsubscribers/${authState.user.UserId}/${subscribersData.payload.skip}/${subscribersData.payload.take}`
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             this.store.dispatch(
  //               SubscriberActions.IsLoading({ payload: true })
  //             );

  //             if (resData.succeeded === true) {
  //               return SubscriberActions.SaveAllSubscribers({
  //                 payload: resData.entity,
  //               });
  //             } else {
  //               const notification: Notification = {
  //                 state: 'success',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-success'
  //               );

  //               return { type: '[Subscribers] Failed To Get All Subscribers' };
  //             }
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Subscribers][CatchError] Failed To Get All Subscribers ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });

  getAllSubscribers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriberActions.GetAllSubscribers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Subscribers/getallsubscribers`,
            {
              subscriberId: 0,
              userId: authState.user.UserId,
              ...subscribersData.payload,
            }

            // {
            //   context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            // }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriberActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriberActions.SaveAllSubscribers({
                  payload: {
                    subscriber: resData.entity.subscriberlist,
                    totalCount: resData.entity.totalCount,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return SubscriberActions.SaveAllSubscribers({
                  payload: {
                    subscriber: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Get All Subscribers  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveSubscribers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriberActions.GetActiveSubscribers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Subscribers/getactivesubscribers`,
            {
              subscriberId: 0,
              userId: authState.user.UserId,
              ...subscribersData.payload,
            }

            // {
            //   context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            // }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriberActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriberActions.SaveActiveSubscribers({
                  payload: {
                    subscriber: resData.entity.subscriberlist,
                    totalCount: resData.entity.totalCount,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return SubscriberActions.SaveActiveSubscribers({
                  payload: {
                    subscriber: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Get Active Subscribers  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getInctiveSubscribers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriberActions.GetInactiveSubscribers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Subscribers/getinactivesubscribers`,
            {
              subscriberId: 0,
              userId: authState.user.UserId,
              ...subscribersData.payload,
            }

            // {
            //   context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            // }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriberActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriberActions.SaveInactiveSubscribers({
                  payload: {
                    subscriber: resData.entity.subscriberlist,
                    totalCount: resData.entity.totalCount,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return SubscriberActions.SaveInactiveSubscribers({
                  payload: {
                    subscriber: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Get Inactive Subscribers  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateSubscriber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriberActions.UpdateSubscriber),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Subscribers/update/${authState.user.UserId}`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...subscribersData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscribersActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    resData.message ||
                    resData.messages[0] ||
                    'Organization profile was updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  SubscriberActions.GetSubscriberById({
                    payload: { id: authState.user.SubscriberId },
                  })
                );

                return {
                  type: '[Subscribers] Update Subscriber Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Subscribers] Failed To Update Subscriber' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Update Subscriber ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createSubscribers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriberActions.createSubscribers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`,
            {
              subscription: {
                ...subscribersData.payload.subscription,
                subscriberId: authState.user.SubscriberId,
                subscriberName: '',
              },
              subscriber: {
                ...subscribersData.payload.subscriber,
                userId: authState.user.UserId,
              },
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Subscriber Created Successfuly',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return SubscriberActions.saveSubcribersResponse({
                  payload: {
                    subscriberResponse: resData.entity,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Subscribers] Failed To Create Subscribers' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Create Subscribers ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  editSubscribers$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriberActions.EditSubscribers),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscribersData, authState]) => {
          const params = new HttpParams().set(
            'isSubscriberNewlyCreated',
            subscribersData?.payload?.isSubscriberNewlyCreated ?? false
          );
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Subscribers/update/${
                subscribersData?.payload?.userId ?? authState.user.UserId
              }`,
              {
                ...subscribersData.payload,
                subscriberId:
                  subscribersData?.payload?.subscriberId ??
                  authState.user.SubscriberId,
                userId:
                  subscribersData?.payload?.userId ?? authState.user.UserId,
              },
              {
                context: new HttpContext().set(
                  USE_DEVELOPER_TOKEN,
                  subscribersData.payload.isSubscriberNewlyCreated
                    ? true
                    : false
                ),

                params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  SubscribersActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Subscriber Edited Successfuly',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriberActions.saveSubcribersResponse({
                      payload: {
                        subscriberResponse: resData.entity,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscribers][CatchError] Failed To Edit Subscribers ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  uploadSubscriberReceipt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriberActions.UploadSubscriberReceipt),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/uploadreceipt`,
            {
              ...subscribersData,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Receipt Uploaded Successfuly',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Subscribers] Receipt uploaded successfully',
                });

                return resData;
              } else {
                const notification: Notification = {
                  state: 'success',
                  message: 'Subscriber Created Successfuly',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Subscribers] Failed To Upload Subscriber Receipt',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Upload Subscriber Receipt ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriberById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriberActions.GetSubscriberById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriberData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Subscribers/getbyid/${subscriberData.payload.id}/${authState.user.UserId}`,
              {
                context: subscriberData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${subscriberData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  SubscribersActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscribers] Get Subscriber By Id Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscribers] Failed To Get Subscriber By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscribers][CatchError] Failed To Get Subscriber By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllPaymentChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriberActions.GetPaymentChannel),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `https://onyxdoc-subscription-poc.reventholdings.com/api/PaymentChannels/getactivepaymentchannels/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                return SubscriberActions.SaveAllPaymentChannel({
                  payload: resData.entity,
                });
              } else {
                return {
                  type: '[Subscribers] Failed To Get All Payment Channels',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Get All Payment Channels ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateSubscriber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriberActions.ActivateSubscriber),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Subscribers/activateSubscriber`,
            {
              userId: authState.user.UserId,
              id: subscribersData.payload.id,
              ...subscribersData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  SubscriberActions.IsLoading({ payload: false })
                );

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                if (subscribersData.instance === 'allSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetAllSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (subscribersData.instance === 'activeSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetActiveSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (subscribersData.instance === 'inactiveSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetInactiveSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                }

                return {
                  type: '[Subscribers] Subscriber was  Activated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscribers] Activation not Successful',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Activate Subscriber ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateSubscriber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriberActions.DeactivateSubscriber),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscribersData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Subscribers/inactivateSubscribeR`,
            {
              userId: authState.user.UserId,
              ...subscribersData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  SubscriberActions.IsLoading({ payload: false })
                );

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                if (subscribersData.instance === 'allSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetAllSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (subscribersData.instance === 'activeSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetActiveSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (subscribersData.instance === 'inactiveSubscribers') {
                  this.store.dispatch(
                    SubscriberActions.GetInactiveSubscribers({
                      payload: {
                        skip: 0,
                        take: 10,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                }

                return {
                  type: '[Subscribers] Subscriber was  Deactivated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscribers] Deactivation not Successful',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Deactivate Subscriber ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
