import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../services/notification.service';
import { Store } from '@ngrx/store';
import { HelperService } from '../../services/helper.service';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { environment } from 'src/environments/environment';

import * as FormBuilderActions from './form-builder.actions';
import * as fromApp from '../app/app.reducer';
import {
  catchError,
  map,
  mergeMap,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CUSTOM_BEARER_TOKEN,
  USE_ACCESS_TOKEN,
  USE_CUSTOM_BEARER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { convertToFormData } from '../../utils/helpers';
import { MatDialog } from '@angular/material/dialog';
import * as signalR from '@microsoft/signalr';
import { SignalrService } from '../../services/signalr.service';
import { TemplateFilter } from '../../enums';
// import { convertToFormData } from '../../utils/helpers';

@Injectable()
export class FormBuilderEffects {
  hubConnection: signalR.HubConnection;
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private notificationService: NotificationService,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    private dialog: MatDialog,
    signalRService: SignalrService
  ) {
    this.hubConnection = signalRService.hubConnection;
  }

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(FormBuilderActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  createForm$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.CreateForm),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/create`,
              {
                ...formData.payload,
              },
              {
                context: formData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${formData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Create Form Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Create Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Create Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateForm$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.UpdateForm),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/update`,
              {
                ...formData.payload,
              },
              {
                context: formData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${formData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[FormBuilder] Update Form Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Update Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Update Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  saveAndPublish$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.saveAndPublish),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/project/updatestatus`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...formData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: `Congratulations! Your form has been successfully published.`,
                  };
                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                  this.store.dispatch({
                    type: '[FormBuilder] Update Form Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Update Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Update Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  recipientSubmitForm$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.submitRecipientForm),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectinstance/createprojectinstance`,
              {
                ...formData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: `Congratulations! Your form has been successfully submitted.`,
                  };
                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                  this.store.dispatch({
                    type: '[FormBuilder] Form Submission Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Submit Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Submit Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  recipientGetFormResponse$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.recipientGetFormById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectinstance/projectbyid/${formData.payload.userId}/${formData.payload.id}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Get Recipient Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getFormById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetFormById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/projectbyid/${authState.user.SubscriberId}/${authState.user.UserId}/${formData.payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (!resData.succeeded) {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Get Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Get Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getProjectTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.GetProjectTemplates),
      withLatestFrom(this.store.select('auth')),
      switchMap(([tempData, authState]) => {
        const searchTerm = tempData.payload.searchTerm;
        const filter = tempData.payload.filter || TemplateFilter.All;
        return this.http
          .get<GenericResponse>(
            `${
              environment.onyxDocPhoenixFormBuilderUrl
            }/project/projecttemplates/${authState.user.SubscriberId}/${
              authState.user.UserId
            }/${tempData.payload.skip}/${
              tempData.payload.take
            }?filter=${filter}${searchTerm ? `&searchTerm=${searchTerm}` : ''}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  FormBuilderActions.SaveProjectTemplates({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[FormBuilder] Get Templates was successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  FormBuilderActions.SaveProjectTemplates({
                    payload: [],
                  })
                );

                return { type: '[FormBuilder] Failed To Get Templates' };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Get Templates ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createProjectCollaborators$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.CreateProjectCollaborators),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/project/createprojectcollaborator`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              creatorSubscriberId: authState.user.SubscriberId,
              ...formData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Collaborators Creation Was Successful',
                });

                return FormBuilderActions.GetInvitedCollaborators({
                  payload: {
                    projectguid: formData.payload.projectGuId,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Create Collaborators',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Create Collaborators ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createTemplateForm$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.CreateTemplateForm),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/createtemplate`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...formData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Create Template Form Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Create Template Form',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Create Template Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  cloneTemplate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.CloneTemplate),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/project/cloneprojecttemplate`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...formData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || 'Template cloned successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || 'Failed to clone template',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to clone template ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  editTemplateForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.EditTemplateForm),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/project/updatetemplate`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...formData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Edit Template Was Successful',
                });

                this.store.dispatch(
                  FormBuilderActions.GetProjectCategories({
                    payload: {
                      skip: 0,
                      take: 100,
                    },
                  })
                );

                return FormBuilderActions.GetProjectTemplates({
                  payload: {
                    skip: 0,
                    take: 100,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Edit Template',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Edit Template ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteTemplateForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.DeleteTemplateForm),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .delete<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/project/delete`,
            {
              body: {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...formData.payload,
              },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Delete Template Was Successful',
                });

                return FormBuilderActions.GetProjectTemplates({
                  payload: {
                    skip: 0,
                    take: 100,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Delete Template',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Delete Template ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getProjectCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.GetProjectCategories),
      withLatestFrom(this.store.select('auth')),
      switchMap(([catData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/projectcategory/projectcategories/${authState.user.SubscriberId}/${authState.user.UserId}/${catData.payload.skip}/${catData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.store.dispatch(
                  FormBuilderActions.SaveProjectCategories({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[FormBuilder] Get Categories was successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  FormBuilderActions.SaveProjectCategories({
                    payload: [],
                  })
                );

                return { type: '[FormBuilder] Failed To Get Categories' };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Get Categories ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getProjectCategoryById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetProjectCategoryById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          const searchTerm = formData.payload.searchTerm;
          const filter = formData.payload.filter || TemplateFilter.All;
          return this.http
            .get<GenericResponse>(
              `${
                environment.onyxDocPhoenixFormBuilderUrl
              }/projectcategory/projectcategorybyid/${
                authState.user.SubscriberId
              }/${authState.user.UserId}/${formData.payload.skip}/${
                formData.payload.take
              }/${formData.payload.id}?filter=${filter}${
                searchTerm ? `&searchTerm=${searchTerm}` : ''
              }`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: resData.message || resData.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );

                  this.store.dispatch({
                    type: '[FormBuilder] Get Category by Id Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Get Category By Id',
                  });
                }

                return resData.entity;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed To Get Category By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createTemplateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.CreateTemplateCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Projectcategory/create`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...formData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Create Category Was Successful',
                });

                return FormBuilderActions.GetProjectCategories({
                  payload: {
                    skip: 0,
                    take: 100,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Create Category',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Create Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  editTemplateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.EditTemplateCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/projectcategory/update`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...formData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Edit Category Was Successful',
                });

                return FormBuilderActions.GetProjectCategories({
                  payload: {
                    skip: 0,
                    take: 100,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Edit Category',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Edit Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteTemplateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.DeleteTemplateCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/projectcategory/delete`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...formData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Delete Category Was Successful',
                });

                return FormBuilderActions.GetProjectCategories({
                  payload: {
                    skip: 0,
                    take: 100,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[FormBuilder] Failed To Delete Category',
                });
              }

              return resData;
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Delete Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getInvitedCollaborators$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormBuilderActions.GetInvitedCollaborators),
      withLatestFrom(this.store.select('auth')),
      switchMap(([inviteData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/project/projectcollaborators/${authState.user.SubscriberId}/${authState.user.UserId}/${inviteData.payload.projectguid}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FormBuilderActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.store.dispatch(
                  FormBuilderActions.SaveInvitedCollaborators({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[FormBuilder] Get Invited Collaborators was successful',
                };
              } else {
                this.store.dispatch(
                  FormBuilderActions.SaveInvitedCollaborators({
                    payload: [],
                  })
                );

                return {
                  type: '[FormBuilder] Failed To Get Invited Collaborators',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder][CatchError] Failed To Get Invited Collaborators ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  uploadDocuments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.UploadDocuments),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/project/pdftoimageconversion`,
              convertToFormData({
                ...formData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              }),
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Document was successfully uploaded',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Upload Documents Using Iform File Was Successful ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to upload document. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Upload Documents Using Iform File',
                  });
                }

                return resData.entity;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents Using Iform File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createProjectPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.createProjectPage),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          // return of().pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendCreateProjectPageRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.projectGuId,
          //       objectName: 'Phoenix Project Page',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpage/create`,
              {
                ...formData.payload,
                objectGuId: formData.payload.guId,
                objectName: 'Phoenix Project Page',
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                // reportProgress: true,
                // observe: 'events',
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                // if (resData instanceof HttpResponse) {
                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page was successfully created',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Project Page Creation was Successful',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to create project page',
                  });
                }
                // }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to create project page ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateProjectPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.updateProjectPage),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([formData, authState]) => {
          // return of().pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendUpdateProjectPageRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.projectGuId,
          //       objectName: 'Phoenix Project Page',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpage/update`,
              {
                ...formData.payload,
                objectGuId: formData.payload.guId,
                objectName: 'Phoenix Project Page',
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page was successfully updated',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Page was successfully updated',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to update page',
                  });
                }
                // }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to update page ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteProjectPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.deleteProjectPage),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          // return of().pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendDeleteProjectPageRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.projectGuId,
          //       objectName: 'Phoenix Project Page',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpage/delete/${authState.user.SubscriberId}/${authState.user.UserId}/${formData.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                // reportProgress: true,
                // observe: 'events',
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page was successfully deleted',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Project page deleted successfully',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to delete project page',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to delete project page ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createProjectPageControl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.createProjectPageControl),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          // return of({}).pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendCreateProjectPageControlRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.guId,
          //       objectName: 'Phoenix Project Page Control',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpagecontrol/create`,
              {
                ...formData.payload,
                objectGuId: formData.payload.guId,
                objectName: 'Phoenix Project Page Control',
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                // reportProgress: true,
                // observe: 'events',
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                // if (resData instanceof HttpResponse) {
                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page Control was successfully created',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Page Control Successfully Created',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed To Create Page Control',
                  });
                }
                // }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Create Page Control ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateProjectPageControl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.updateProjectPageControl),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([formData, authState]) => {
          // return of().pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendUpdateProjectPageControlRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.projectGuId,
          //       objectName: 'Phoenix Project Page',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpagecontrol/update`,
              {
                ...formData.payload,
                objectGuId: formData.payload.guId,
                objectName: 'Phoenix Project Page Control',
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page control was successfully updated',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Page control was successfully updated',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to update page control',
                  });
                }
                // }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to update page control ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteProjectPageControl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.deleteProjectPageControl),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          // return of().pipe(
          //   tap(() => {
          //     this.hubConnection.invoke('SendDeleteProjectPageControlRequest', {
          //       ...formData.payload,
          //       objectGuId: formData.payload.projectGuId,
          //       objectName: 'Phoenix Project Page',
          //       ownerId: authState.user.UserId,
          //       actorEmail: authState.user.Email,
          //       subscriberId: authState.user.SubscriberId,
          //     });
          //   })
          // );
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectpagecontrol/delete/${authState.user.SubscriberId}/${authState.user.UserId}/${formData.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                // reportProgress: true,
                // observe: 'events',
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Page control was successfully deleted',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                  // this.store.dispatch({
                  //   type: '[FormBuilder] Page control was successfully deleted',
                  // });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to delete page control',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to delete page control ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  uploadFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.uploadFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectinstance/uploads`,
              formData.formData
              // {
              //   params: {
              //     ownerId: authState.user.UserId,
              //     actorEmail: authState.user.Email,
              //     subscriberId: authState.user.SubscriberId,
              //   },
              //   context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              // }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  FormBuilderActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'File uploaded successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] File uploaded successfully',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to upload file. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[FormBuilder] Failed to upload file',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed to upload file ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // Process
  CreateProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormBuilderActions.CreateProcess),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.flwmnFlowzUrl}/Process/create`,
            {
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
              ...userData.payload,
            },
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const process = resData?.entity;

                return FormBuilderActions.UpdateBlocks({
                  payload: {
                    blocks: userData.blocks,
                    processGuid: process?.guid,
                  },
                });
              } else {
                this.store.dispatch(
                  FormBuilderActions.IsCreatingProcess({ payload: false })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData?.message || resData?.messages?.[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[FormBuilder] Create Process Failed',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder] Create Process Failed`
              );
            })
          );
      })
    )
  );

  getProcessCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormBuilderActions.GetProcessCategories),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.flwmnFlowzUrl}/ProcessCategory/getallprocesscategories/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: {
                skip: userData.payload.skip,
                take: userData.payload.take,
              },
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FormBuilderActions.IsGettingProcessCategories({
                  payload: false,
                })
              );

              if (resData.succeeded) {
                return FormBuilderActions.SaveProcessCategories({
                  payload: resData?.entity,
                });
              } else {
                return {
                  type: '[FormBuilder] Get Unpaginated Categories Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[FormBuilder] Get Process Categories Failed`
              );
            })
          );
      }),
      share()
    )
  );

  updateBlocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormBuilderActions.UpdateBlocks),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        const updatedPayload = {
          ...formData.payload,
          blocks: formData.payload.blocks.map((block) => ({
            ...block,
            ownerId: authState.user.UserId,
            actorEmail: authState.user.Email,
            subscriberId: authState.user.SubscriberId,
          })),
        };
        return this.http
          .post(
            `${environment.flwmnFlowzUrl}/Block/update/list`,
            {
              ...updatedPayload,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
              processGuid: formData.payload.processGuid,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.dialog.closeAll();

              this.store.dispatch(
                FormBuilderActions.IsCreatingProcess({ payload: false })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                window.location.href = `${environment.flwmnFlowzFrontendUrl}/app/flowz/${formData.payload.processGuid}`;

                return {
                  type: '[Flowz Designer] Blocks Updated Successfully',
                  payload: false,
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData?.message || resData?.messages?.[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Flowz Designer] Blocks Update Failed',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Flowz Designer] Blocks Update Failed`
              );
            })
          );
      })
    )
  );

  getApiCollections$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetApiCollections),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAPIUrl}/ApiCollection/collections/${authState.user.SubscriberId}/${authState.user.UserId}`,
              {
                params: { ...formData.payload },
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'API collections gotten successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || 'Failed to get API collections',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to get API collections`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getEndpointsByCollectionId$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetEndpointsByCollectionId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAPIUrl}/Endpoints/${authState.user.SubscriberId}/${authState.user.UserId}/${formData.payload.id}`,
              {
                params: { ...formData.payload },
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Collection endpoints gotten successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message || 'Failed to get collection endpoints',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to get collection endpoints`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getEndpointDetails$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetEndpointDetails),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAPIUrl}/Endpoints/endpoint/${authState.user.SubscriberId}/${authState.user.UserId}/${formData.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Endpoint details gotten successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message || 'Failed to get endpoint details',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to get endpoint details`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  verifyControlContent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.VerifyControlContent),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectinstance/verifycontrolcontent`,
              { ...formData.payload },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Verification executed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message ||
                      'Failed to execute content verification',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to execute content verification`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getThemes$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.GetThemes),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/project/themes/${authState.user.SubscriberId}/${authState.user.UserId}`,
              {
                params: { ...formData.payload },
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Verification executed successfully',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || 'Failed to get themes',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to get themes`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  uploadTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FormBuilderActions.UploadTheme),
        withLatestFrom(this.store.select('auth')),
        switchMap(([formData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/project/uploadthemes`,
              formData.payload.ThemeImage,
              {
                params: {
                  ThemeName: formData.payload.ThemeName,
                  ThemeAccessLevel: formData.payload.ThemeAccessLevel,
                  ThemeType: formData.payload.ThemeType,
                  SubscriberId: authState.user.SubscriberId,
                  OwnerId: authState.user.UserId,
                  ActorEmail: authState.user.Email,
                  AccessToken: (() => {
                    return JSON.parse(localStorage.getItem('OnyxDoc') as string)
                      .accessToken;
                  })(),
                },
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: 'Verification executed successfully',
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || 'Failed upload theme',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[FormBuilder][CatchError] Failed to upload themes`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
