import {
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FlwmnSheetRef,
  SheetComponent,
  FLWMN_SHEET_DATA,
} from '../shared/sheet';

@Injectable({ providedIn: 'root' })
export class FlwmnSheet implements OnDestroy {
  /** All currently opened sheets. */
  private openSheetsMap: Map<ComponentType<any>, FlwmnSheetRef<any>> =
    new Map();

  private subscription = new Subscription();

  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * Opens a sheet containing the given component.
   * @param component Type of the component to load into the sheet.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened sheet.
   */
  open<T, D = any, R = any>(
    component: ComponentType<T>,
    config: {
      data?: D | null;
      disableClose?: boolean;
      panelClass?: string | string[];
      backdropClass?: string | string[];
      hasBackdrop?: boolean;
      position?: 'left' | 'right' | 'top' | 'bottom';
      width?: string;
      minWidth?: string;
      maxWidth?: string;
      height?: string;
      minHeight?: string;
      maxHeight?: string;
    } = {}
  ): FlwmnSheetRef<T, R> {
    // Check if a sheet of the same type is already open
    if (this.openSheetsMap.has(component)) {
      throw Error('This sheet is already open');
    }

    const positionStrategy = this.getPositionStrategy(config.position);
    const overlayConfig = this.getOverlayConfig(positionStrategy, config);
    const overlayRef = this.overlay.create(overlayConfig);
    const sheetRef = new FlwmnSheetRef<T, R>(overlayRef);

    const injector = this.createInjector(config.data, sheetRef);
    const componentPortal = new ComponentPortal(SheetComponent, null, injector);
    const sheetComponentRef = overlayRef.attach(componentPortal);

    sheetComponentRef.instance.loadComponent(component);
    sheetComponentRef.instance.slideInOutState = config.position;

    // Store the sheet reference in the map
    this.openSheetsMap.set(component, sheetRef);

    this.subscription.add(
      sheetRef.beforeClosed().subscribe(() => {
        sheetComponentRef.instance.slideInOutState = undefined;
      })
    );

    this.subscription.add(
      sheetComponentRef.instance.closeAnimationDone.subscribe(() => {
        // sheetRef.finalizeClose();
      })
    );

    // Reset the reference in the map when the sheet is closed
    this.subscription.add(
      sheetRef.afterClosed().subscribe(() => {
        this.openSheetsMap.delete(component);
      })
    );

    this.handleDisableClose(config, overlayRef, sheetRef);

    return sheetRef;
  }

  /**
   * Closes all of the currently-open sheets.
   */
  closeAll(): void {
    this.openSheetsMap.forEach((sheetRef) => sheetRef.close());
    this.openSheetsMap.clear();
  }

  private getPositionStrategy(
    position: string = 'right'
  ): GlobalPositionStrategy {
    switch (position) {
      case 'left':
        return this.overlay.position().global().left();
      case 'top':
        return this.overlay.position().global().top().centerHorizontally();
      case 'bottom':
        return this.overlay.position().global().bottom().centerHorizontally();
      case 'right':
      default:
        return this.overlay.position().global().right();
    }
  }

  private getOverlayConfig(
    positionStrategy: GlobalPositionStrategy,
    config: {
      panelClass?: string | string[];
      backdropClass?: string | string[];
      hasBackdrop?: boolean;
      position?: 'left' | 'right' | 'top' | 'bottom';
      width?: string;
      minWidth?: string;
      maxWidth?: string;
      height?: string;
      minHeight?: string;
      maxHeight?: string;
    }
  ): OverlayConfig {
    const width =
      config.width ||
      (config.position === 'top' || config.position === 'bottom'
        ? '100vw'
        : undefined);

    const height =
      config.height ||
      (config.position === 'left' || config.position === 'right'
        ? '100vh'
        : undefined);
    return {
      positionStrategy,
      panelClass: config.panelClass,
      backdropClass: config.backdropClass,
      hasBackdrop: config.hasBackdrop ?? !!config.backdropClass,
      width,
      minWidth: config.minWidth,
      maxWidth: config.maxWidth,
      height,
      minHeight: config.minHeight,
      maxHeight: config.maxHeight,
    };
  }

  private createInjector(data: any, sheetRef: FlwmnSheetRef<any>): Injector {
    return Injector.create({
      providers: [
        { provide: FLWMN_SHEET_DATA, useValue: data },
        { provide: FlwmnSheetRef, useValue: sheetRef },
      ],
      parent: this.injector,
    });
  }

  private handleDisableClose(
    config: any,
    overlayRef: OverlayRef,
    sheetRef: FlwmnSheetRef
  ) {
    // Ensure disableClose is a boolean
    config.disableClose =
      config.disableClose !== undefined ? config.disableClose : false;

    // Handle backdrop clicks and escape key press based on disableClose
    if (config.disableClose) {
      this.subscription.add(
        overlayRef.backdropClick().subscribe((event) => {
          event.stopPropagation();
        })
      );

      this.subscription.add(
        overlayRef.keydownEvents().subscribe((event) => {
          if (event.key === 'Escape') {
            event.stopPropagation();
          }
        })
      );
    } else {
      this.subscription.add(
        overlayRef.backdropClick().subscribe(() => sheetRef.close())
      );

      this.subscription.add(
        overlayRef.keydownEvents().subscribe((event) => {
          if (event.key === 'Escape') {
            sheetRef.close();
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
