import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/@core/services/helper.service';
import { ThemeService } from 'src/app/@core/services/theme.service';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';

@Component({
  selector: 'app-welcome-onboard',
  templateUrl: './welcome-onboard.component.html',
  styleUrls: ['./welcome-onboard.component.scss'],
})
export class WelcomeOnboardComponent implements OnInit {
  year!: number;
  isLoading!: Observable<boolean>;
  email: string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.year = new Date().getFullYear();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.email = this.route.snapshot.params['email'];
  }

  // goToLoginPage() {
  //   this.router.navigate(['login'], {
  //     queryParams: {
  //       isEmailVerified: 'true',
  //       email: this.email,
  //     },
  //   });
  // }

  goToIssue() {
    this.router.navigate(['register/issue'], {
      queryParams: {
        issueType: 'email verification error',
        email: this.email,
        returnUrl: this.router.url,
      },
    });
  }

  onSendVerificationEmail() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.sendVerificationEmail.bind(this)
    );
  }

  sendVerificationEmail() {
    this.store.dispatch(
      AuthActions.SendVerificationEmail({
        payload: {
          email: this.email!,
        },
      })
    );
  }
}
