import { createAction, props } from '@ngrx/store';
import { resourceReducerState } from './resource-configuration.reducer';

export const ResetStore = createAction('[Resource Configuration] Reset Store');

export const IsLoading = createAction(
  '[Resource Configuration] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetActiveResource = createAction(
  '[Resource Configuration] Get All Active Resource',
  props<{
    payload: {
      searchValue: string | null;
      skip: number;
      take: number;
      filterValue: string[];
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
    };
  }>()
);

export const SaveActiveResource = createAction(
  '[Resource Configuration] Save Active Resource',
  props<{
    payload: resourceReducerState['activeResource'];
  }>()
);

export const GetInactiveResource = createAction(
  '[Resource Configuration] Get All Inactive Resource',
  props<{
    payload: {
      skip: number;
      take: number;
      subscriberId?: 0;
      ownerId?: string | null;
      actorEmail?: string;
      // actorEmail?: string;
    };
  }>()
);

export const SaveInactiveResource = createAction(
  '[Resource Configuration] Save Inactive Resource',
  props<{
    payload: resourceReducerState['inactiveResource'];
  }>()
);

export const GetPendingResource = createAction(
  '[Resource Configuration] Get All Pending Resource',
  props<{
    payload: {
      searchValue: string | null;
      skip: number;
      take: number;
      filterValue: string[];
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
      // actorEmail?: string;
    };
  }>()
);

export const SavePendingResource = createAction(
  '[Resource Configuration] Save Pending Resource',
  props<{
    payload: resourceReducerState['pendingResource'];
  }>()
);

export const GetRejectedResource = createAction(
  '[Resource Configuration] Get All Rejected Resource',
  props<{
    payload: {
      searchValue: string | null;
      skip: number;
      take: number;
      filterValue: string[];
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
      // actorEmail?: string;
    };
  }>()
);

export const SaveRejectedResource = createAction(
  '[Resource Configuration] Save Rejected Resource',
  props<{
    payload: resourceReducerState['rejectedResource'];
  }>()
);

export const GetApprovedResource = createAction(
  '[Resource Configuration] Get All Approved Resource',
  props<{
    payload: {
      searchValue: string | null;
      skip: number;
      take: number;
      filterValue: string[];
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
      // actorEmail?: string;
    };
  }>()
);

export const SaveApprovedResource = createAction(
  '[Resource Configuration] Save Approved Resource',
  props<{
    payload: resourceReducerState['approvedResource'];
  }>()
);

export const GetResourceById = createAction(
  '[Resource Configuration] Get Resource',
  props<{
    payload: {
      // subscriberId?: number;
      // userId?: string;
      guId?: string;
    };
  }>()
);

export const SaveAllRequestById = createAction(
  '[Resource Configuration] Save All Request By Id',
  props<{
    payload: any;
  }>()
);

export const CreateResource = createAction(
  '[Resource Configuration] Create Resource',
  props<{
    payload: {
      name: string;
      resourceGuId?: string;
      resourceType: number;
      microServiceKey?: string;
      targetObject: string;
      targetField: string;
      unitOfMeasure: number;
      costBasis: number;
      currencyCode: string;
      amount: number;
      resourceUnitPriceItems: [
        {
          resourceUnitPriceItemGuId?: string;
          resourceGuId?: string;
          currencyCode: string;
          minimumUnit: number;
          maximumUnit: number;
          amount: number;
          guId?: string;
          subscriberId?: 0;
          ownerId?: string;
        }
      ];
      subscriberId?: 0;
      ownerId?: string;
    };
    instance: number;
  }>()
);

export const UpdateResource = createAction(
  '[Resource Configuration] Update Resource',
  props<{
    payload: {
      name: string;
      resourceGuId: string;
      resourceType: number;
      microServiceKey?: string;
      targetObject: string;
      targetField: string;
      unitOfMeasure: number;
      costBasis: number;
      currencyCode: string;
      amount: number;
      resourceUnitPriceItems: [
        {
          resourceUnitPriceItemGuId?: string;
          resourceGuId?: string;
          currencyCode: string;
          minimumUnit: number;
          maximumUnit: number;
          amount: number;
          guId: string;
          subscriberId?: 0;
          ownerId?: string;
        }
      ];
      subscriberId?: 0;
      ownerId?: string;
    };
  }>()
);

export const ActivateResource = createAction(
  '[Resource Configuration] Activate Resource ',
  props<{
    payload: {
      guId?: string;
    };
  }>()
);

export const DeactivateResource = createAction(
  '[Resource Configuration] Deactivate Resource ',
  props<{
    payload: {
      resourceGuId?: string;
    };
  }>()
);

export const AcceptResource = createAction(
  '[Resource Configuration] Accept Resource ',
  props<{
    payload: {
      resourceGuId?: string;
    };
  }>()
);

export const RejectResource = createAction(
  '[Resource Configuration] Reject Resource ',
  props<{
    payload: {
      resourceGuId?: string;
    };
  }>()
);

export const GetAllMicroService = createAction(
  '[Resource Configuration] Get All MicroService',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllMicroService = createAction(
  '[Resource Configuration] Save All MicroService',
  props<{
    payload: any[];
  }>()
);

export const GetAllTargetObject = createAction(
  '[Resource Configuration] Get All Target Object',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      productMicroServiceId: string;
    };
  }>()
);

export const SaveAllTargetObject = createAction(
  '[Resource Configuration]  Save All Target Object',
  props<{
    payload: any[];
  }>()
);

export const GetAllTargetObjectFields = createAction(
  '[Resource Configuration] Get All Target Object Fields',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      productMicroServiceId: string;
      objectName: string;
    };
  }>()
);

export const SaveAllTargetObjectFields = createAction(
  '[Resource Configuration]  Save All Target Object Fields',
  props<{
    payload: any[];
  }>()
);
// export const GetActiveResourcesCount = createAction(
//   '[Resource Configuration] Get Resource Count'
// );
