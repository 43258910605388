import { createReducer, on, Action } from '@ngrx/store';
import * as SubscribersActions from './subscribers.actions';

export interface State {
  isLoading: boolean;
  subscribers: { subscriber: any[]; totalCount: number } | null;
  activeSubscribers: { subscriber: any[]; totalCount: number } | null;
  inactiveSubscribers: { subscriber: any[]; totalCount: number } | null;
  subscriptionPlan: any;
  paymentChannel: any;
  subscriberResponse: any;
}

const initialState: State = {
  isLoading: false,
  subscribers: null,
  activeSubscribers: null,
  inactiveSubscribers: null,
  subscriptionPlan: null,
  paymentChannel: null,
  subscriberResponse: null,
};

const subscribersReducerInternal = createReducer(
  initialState,
  on(SubscribersActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(SubscribersActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(SubscribersActions.SaveAllSubscribers, (state, { payload }) => ({
    ...state,
    subscribers: payload,
  })),

  on(SubscribersActions.SaveActiveSubscribers, (state, { payload }) => ({
    ...state,
    activeSubscribers: payload,
  })),

  on(SubscribersActions.SaveInactiveSubscribers, (state, { payload }) => ({
    ...state,
    inactiveSubscribers: payload,
  })),

  on(SubscribersActions.SaveAllPaymentChannel, (state, { payload }) => ({
    ...state,
    paymentChannel: payload,
  })),
  on(SubscribersActions.saveSubcribersResponse, (state, { payload }) => ({
    ...state,
    subscriberResponse: payload,
  }))
);

export function subscribersReducer(state: State | undefined, action: Action) {
  return subscribersReducerInternal(state, action);
}
