// Audit Logs
export const ResetStore = createAction('[Audit Logs] Reset Store');

export const IsLoading = createAction(
  '[Audit Logs] Is Loading',
  props<{
    payload: boolean;
  }>()
);

import { createAction, props } from '@ngrx/store';

export const GetAPICallsLogByApplicationStatus = createAction(
  '[Audit Logs] Get API Calls Log By App Id',
  props<{
    payload: {
      applicationstatus: string;
    };
  }>()
);

export const GetAllAPICallsLog = createAction(
  '[Audit Logs] Get All API Calls Logs'
);

export const GetAPICallsLogBySubscriberId = createAction(
  '[Audit Logs] Get All API Calls Logs By Subscriber Id'
);

export const SaveAPICallsLog = createAction(
  '[Audit Logs] Save API Calls Log By Subscriber Id',
  props<{
    payload: any;
  }>()
);

export const SaveAPICallsLogById = createAction(
  '[Audit Logs] Save API Calls Log By Id',
  props<{
    payload: any;
  }>()
);
