import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-parameters',
  templateUrl: './request-parameters.component.html',
  styleUrls: ['./request-parameters.component.scss'],
})
export class RequestParametersComponent implements OnInit {
  rows: { key: string; value: string; description: string }[] = [
    { key: '', value: '', description: '' },
  ];

  constructor() {}

  ngOnInit(): void {
    console;
  }

  addRow() {
    this.rows.push({ key: '', value: '', description: '' });
  }

  deleteRow(index: number) {
    this.rows.splice(index, 1);
  }
}
