import { createReducer, on, Action } from '@ngrx/store';
import * as MyFormsActions from './my-forms.actions';

export interface State {
  isLoading: boolean;
  IsLoadingDeleteForm: boolean;
  IsLoadingRenameForm: boolean;
  myForms: any;
}

const initialState: State = {
  isLoading: false,
  IsLoadingDeleteForm: false,
  IsLoadingRenameForm: false,
  myForms: null,
};

const myFormsReducerInternal = createReducer(
  initialState,
  on(MyFormsActions.ResetStore, () => ({
    ...initialState,
  })),
  on(MyFormsActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(MyFormsActions.IsLoadingDeleteForm, (state, { payload }) => ({
    ...state,
    IsLoadingDeleteForm: payload,
  })),
  on(MyFormsActions.IsLoadingRenameForm, (state, { payload }) => ({
    ...state,
    IsLoadingRenameForm: payload,
  })),
  on(MyFormsActions.SaveAllForms, (state, { payload }) => ({
    ...state,
    myForms: payload,
  }))
);

export function myFormsReducer(state: State | undefined, action: Action) {
  return myFormsReducerInternal(state, action);
}
