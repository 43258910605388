import { createReducer, on, Action } from '@ngrx/store';
import * as ReleaseNoteActions from './release-note.actions';

export interface State {
  isLoading: boolean;
  allReleaseNotes: any;
  publishedReleaseNotes: any;
  savedReleaseNotes: any;
  SavedHiddenReleaseNotes: any;
  SingleReleaseNoteById: any;
  allPublishedWebsiteReleaseNotes: any;
}

const initialState: State = {
  isLoading: false,
  allReleaseNotes: null,
  publishedReleaseNotes: null,
  savedReleaseNotes: null,
  SavedHiddenReleaseNotes: null,
  SingleReleaseNoteById: null,
  allPublishedWebsiteReleaseNotes: null,
};

const releaseNoteReducerInternal = createReducer(
  initialState,
  on(ReleaseNoteActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(ReleaseNoteActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(ReleaseNoteActions.SaveAllReleaseNotes, (state, { payload }) => ({
    ...state,
    allReleaseNotes: payload,
  })),
  on(ReleaseNoteActions.SaveAllPublishedReleaseNotes, (state, { payload }) => ({
    ...state,
    publishedReleaseNotes: payload,
  })),

  on(ReleaseNoteActions.SaveAllDraftReleaseNotes, (state, { payload }) => ({
    ...state,
    savedReleaseNotes: payload,
  })),

  on(ReleaseNoteActions.SaveAllHiddenReleaseNotes, (state, { payload }) => ({
    ...state,
    SavedHiddenReleaseNotes: payload,
  })),

  on(ReleaseNoteActions.SaveSingleReleaseNoteById, (state, { payload }) => ({
    ...state,
    SingleReleaseNoteById: payload,
  })),

  on(ReleaseNoteActions.SaveAllWebsiteReleaseNote, (state, { payload }) => ({
    ...state,
    allPublishedWebsiteReleaseNotes: payload,
  }))
);

export const releaseNoteReducer = (
  state: State | undefined,
  action: Action
) => {
  return releaseNoteReducerInternal(state, action);
};
