<ng-container *ngIf="(isAuthLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="new-register">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>

        <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container>
          <div class="auth-login-form-container">
            <div class="hanging-borders-container">
              <span class="hanging-borders"></span>
              <span class="hanging-borders"></span>
            </div>

            <div class="welcome-message">
              <ng-container>
                <p class="flwmn-heading-4 flwmn-text-black fw-700 text-center">
                  Invite your team
                </p>

                <p
                  class="w-75 flwmn-para-3 flwmn-text-black fw-400 text-center"
                >
                  You have created a new account, invite your team to enjoy
                  seamless collaboration.
                </p>
              </ng-container>
            </div>

            <div class="auth-form-container">
              <form [formGroup]="inviteForm" (submit)="onSubmit()">
                <div class="invite-container">
                  <div class="input-style1 input-md auth-input-mb">
                    <label class="input-label"
                      >Specify users by email address</label
                    >

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input
                        #email
                        type="email"
                        inputmode="email"
                        matInput
                        formControlName="email"
                        placeholder="press enter to add email"
                        (input)="
                          inviteForm.patchValue({
                            email: email.value.replaceAll(' ', '')
                          })
                        "
                        (keydown.enter)="onEnterPress($event)"
                      />

                      <mat-error *ngIf="registrationFormControls.email.invalid">
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('email') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div
                    class="email-container"
                    formArrayName="invitedUsers"
                    *ngFor="
                      let invitedUser of invitedUserArray.controls;
                      let i = index
                    "
                  >
                    <div class="added-email" [formGroupName]="i">
                      <div class="initials">
                        <span class="initial">{{
                          invitedUser.value.email[0] +
                            invitedUser.value.email[1]
                        }}</span>
                        <span class="flwmn-para-1 flwmn-text-black">{{
                          invitedUser.value.email
                        }}</span>
                      </div>

                      <div class="role-selectors">
                        <div class="input-style1 auth-input-mb">
                          <label class="input-label">Role</label>

                          <mat-form-field
                            appearance="outline"
                            [style.width.%]="100"
                          >
                            <mat-select formControlName="role">
                              <ng-container
                                *ngIf="
                                  allRoles !== null && allRoles?.length !== 0;
                                  else noRolesByEmail
                                "
                              >
                                <ng-container *ngFor="let role of allRoles">
                                  <mat-option [value]="role.id">
                                    {{ role?.name }}
                                  </mat-option>
                                </ng-container>
                              </ng-container>

                              <ng-template #noRolesByEmail>
                                <ng-container *ngIf="allRoles === null">
                                  <mat-option disabled>
                                    Loading roles...</mat-option
                                  >
                                </ng-container>

                                <ng-container *ngIf="allRoles?.length === 0">
                                  <mat-option disabled>
                                    No role is available</mat-option
                                  >
                                </ng-container>
                              </ng-template>
                            </mat-select>

                            <mat-error
                              *ngIf="
                                invitedUserArray.controls[i].get('role')!.errors
                                  ?.required
                              "
                            >
                              <img
                                src="/assets/icons/error.svg"
                                alt=""
                                class="img-fluid error-icon"
                              />
                              Required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  matRipple
                  type="submit"
                  class="btn flwmn-btn-primary-outline flwmn-btn-md flwmn-btn-full login-btn rounded-pill mt-3"
                  [disabled]="
                    inviteForm.invalid || invitedUserArray.value.length === 0
                  "
                >
                  Send Invite
                </button>
              </form>
            </div>

            <div class="w-75 d-flex justify-content-center gap-3 mt-3">
              <button
                matRipple
                type="button"
                class="btn flwmn-btn-primary-dark-outline flwmn-btn-md flwmn-btn-full login-btn rounded-pill mt-3 flwmn-text-black"
                (click)="login()"
              >
                I’d do it later
              </button>

              <button
                matRipple
                type="button"
                class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full login-btn rounded-pill mt-3"
                (click)="login()"
              >
                Done
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>

    <!-- Carousel -->
    <div class="carouse-box">
      <app-auth-carousel></app-auth-carousel>
    </div>
  </div>
</section>
