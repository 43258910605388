import { createAction, props } from '@ngrx/store';
import { userRoleReducerState } from './user-role.reducer';

export const ResetStore = createAction('[User Role] Reset Store');

export const IsLoading = createAction(
  '[User Role] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllUserRoles = createAction(
  '[User Role] Get All User Roles',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveAllUserRoles = createAction(
  '[User Role] Save User Roles',
  props<{ payload: userRoleReducerState['roles'] }>()
);

export const GetActiveUserRoles = createAction(
  '[User Role] Get Active User Roles',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveActiveUserRoles = createAction(
  '[User Role] Save Active User Roles',
  props<{ payload: userRoleReducerState['activeRoles'] }>()
);

export const GetInactiveUserRoles = createAction(
  '[User Role] Get Inactive User Roles',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveInactiveUserRoles = createAction(
  '[User Role] Save Inactive User Roles',
  props<{ payload: userRoleReducerState['inactiveRoles'] }>()
);

export const GetRoleById = createAction(
  '[User Role] Get Role By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const CreateRoleAndPermissions = createAction(
  '[User Role] Create Role And Permissions',
  props<{
    payload: {
      name: string;
      roleAccessLevel: number;
      rolePermissions: {
        isChecked: boolean;
        featureId: number;
        featureName: string;
        parentFeatureId: number;
        parentFeatureName: string;
        permission: string;
        subscriptionPlan: string;
        subscriptionPlanId: number;
      }[];
      checkedCount: number;
    };
  }>()
);

export const EditRoleAndPermissions = createAction(
  '[User Role] Edit Role And Permissions',
  props<{
    payload: {
      roleId: number;
      name: string;
      roleAccessLevel: number;
      rolePermissions: {
        isChecked: boolean;
        featureId: number;
        featureName: string;
        parentFeatureId: number;
        parentFeatureName: string;
        permission: string;
        subscriptionPlan: string;
        subscriptionPlanId: number;
      }[];
      checkedCount: number;
    };
  }>()
);

export const GetPermissionsBySubscriptionPlanIdAndRoleAccessLevel =
  createAction(
    '[User Role] Get Permissions By Subscription Plan Id And Role Access Level',
    props<{
      payload: {
        roleAccessLevel: number;
        subscriptionPlanId: number;
        skip: number;
        take: number;
      };
    }>()
  );

export const SavePermissionsBySubscriptionPlanIdAndRoleAccessLevel =
  createAction(
    '[User Role] Save Permissions By Subscription Plan Id And Role Access Level',
    props<{
      payload: any;
    }>()
  );

export const ChangeRoleStatus = createAction(
  '[User Role] Change Role Status',
  props<{
    payload: {
      roleId: number;
    };
    instance: 'all' | 'active' | 'inactive';
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);
