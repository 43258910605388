import { createAction, props } from '@ngrx/store';
import { ProcessCategoryPayload } from '../../interfaces';

export const ResetStore = createAction('[Process Category] Reset Store');

export const IsLoading = createAction(
  '[Process Category] Is Loading',
  props<{ payload: boolean }>()
);

export const GetAllProcessCategory = createAction(
  '[Process Category] Get All Process Category',
  props<{ payload: { skip: number; take: number } }>()
);

export const SaveProcessCategory = createAction(
  '[Process Category] Save Process Category',
  props<{ payload: any }>()
);

export const GetProcessCategoryById = createAction(
  '[Process Category] Get Process Category By Id',
  props<{ payload: { id: string } }>()
);

export const SaveProcessCategoryById = createAction(
  '[Process Category] Save Process Category By Id',
  props<{ payload: any }>()
);

export const CreateProcessCategory = createAction(
  '[Process Category] Create Process Category',
  props<{ payload: ProcessCategoryPayload }>()
);

export const UpdateProcessCategory = createAction(
  '[Process Category] Update Process Category',
  props<{
    payload: {
      processCategories: [
        {
          guId: string;
          name: string;
          description: string;
          colorCode: string;
          includeSLA: boolean;
          isDefault: boolean;
          isDeleted: boolean;
        }
      ];
    };
  }>()
);

export const DeleteProcessCategory = createAction(
  '[Process Category] Delete Process Category ',
  props<{ payload: { guId: string } }>()
);
