import { createReducer, on, Action } from '@ngrx/store';
import * as CheckoutActions from './checkout.action';

export interface State {
  isLoading: boolean;
  subscriber: any | null;
}

const initialState: State = {
  isLoading: false,
  subscriber: null,
};

const checkoutReducerInternal = createReducer(
  initialState,
  on(CheckoutActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(CheckoutActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(CheckoutActions.SaveSubscriber, (state, { payload }) => ({
    ...state,
    subscriber: payload,
  }))
);

export function checkoutReducer(state: State | undefined, action: Action) {
  return checkoutReducerInternal(state, action);
}
