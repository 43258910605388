import {
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { saveAs } from 'file-saver';
import { DriveItemType } from '../enums/drive.enum';

// export const getBase64FromUrl = (url: string) => {
//   return fetch(url)
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         })
//     );
// };

// export const haveSameData = function (obj1: any, obj2: any) {
//   const obj1Length = Object.keys(obj1).length;
//   const obj2Length = Object.keys(obj2).length;

//   if (obj1Length === obj2Length) {
//     return Object.keys(obj1).every(
//       (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
//     );
//   }
//   return false;
// };

export const regexValidator = (
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | any => {
    if (!control.value) {
      return null;
    }

    const valid = regex.test(control.value);

    return valid ? null : error;
  };
};

//TODO To be deleted
export const ascendingSort = (data: any, sortValue: string) => {
  if (data) {
    data.sort((x: any, y: any) => {
      const a = x[sortValue].toUpperCase(),
        b = y[sortValue].toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }
  return data;
};

export const totalSubscriptionAmount = (
  planPrice: any,
  durationInMonthOrYear: any,
  numberOfUser: any
): any => {
  const duration = durationInMonthOrYear ? durationInMonthOrYear : 1;
  return duration * planPrice * numberOfUser;
};

export const durationInDateOfSubscription = (
  type: string,
  frequency: any,
  durationInMonthOrYear: any
): any => {
  const date = new Date();

  let monthPlusOne = date.getMonth() + 1;

  if (type === 'start') {
    return `${date.getDate()}/${monthPlusOne}/${date.getFullYear()}`;
  } else if (type === 'end') {
    if (frequency === 1 || frequency === 'monthly') {
      let monthIncrement = monthPlusOne + durationInMonthOrYear;

      //if month is greater than 12
      const yearIncrement =
        monthIncrement > 12 ? date.getFullYear() + 1 : date.getFullYear();

      monthIncrement =
        monthIncrement > 12 ? monthIncrement % 12 : monthIncrement;

      return `${date.getDate()}/${monthIncrement} /${yearIncrement}`;
    } else {
      const yearIncrement = date.getFullYear() + durationInMonthOrYear;

      return `${date.getDate()}/${monthPlusOne}/${yearIncrement}`;
    }
  } else {
    monthPlusOne += 1;
    return `${date.getDate()}/${monthPlusOne}/${date.getFullYear()}`;
  }
};

export const getParentFeatureNames = (subscriptionPlanFeatures: any[]) => {
  const parentFeatureNames: any[] = [];

  subscriptionPlanFeatures.forEach((subscriptionPlanFeature) => {
    if (
      subscriptionPlanFeature.status === 1 &&
      subscriptionPlanFeature?.parentFeatureId === 0
    ) {
      parentFeatureNames.push(subscriptionPlanFeature.parentFeatureName);
    }
  });

  const parentFeatures = [...new Set(parentFeatureNames)];

  return parentFeatures.sort((a: any, b: any) => {
    const el1 = a?.toLowerCase();
    const el2 = b?.toLowerCase();

    if (el1 < el2) {
      return -1;
    }

    if (el1 > el2) {
      return 1;
    }

    return 0;
  });
};

export const getActiveSubscriptionPlanForRegistration = (
  allSubscription: any
  // subscriberType: string
) => {
  const subscription = allSubscription?.filter((subscriptionPlan: any) => {
    return (
      !subscriptionPlan.name.toLowerCase().includes('revent') &&
      !subscriptionPlan.name.toLowerCase().includes('flowmono') &&
      subscriptionPlan?.isDefaultPlan === true
    );
  });

  // switch (subscriberType) {
  //   case 'individual':
  //     subscription = allSubscription?.filter((subscriptionPlan: any) => {
  //       return (
  //         subscriptionPlan?.subscriptionTypeDesc?.toLowerCase() ===
  //           subscriberType &&
  //         !subscriptionPlan.name.toLowerCase().includes('revent') &&
  //         !subscriptionPlan.name.toLowerCase().includes('flowmono')
  //       );
  //     });
  //     break;

  //   case 'corporate':
  //     subscription = allSubscription?.filter((subscriptionPlan: any) => {
  //       return (
  //         subscriptionPlan?.subscriptionTypeDesc?.toLowerCase() ===
  //           subscriberType &&
  //         !subscriptionPlan.name.toLowerCase().includes('revent') &&
  //         !subscriptionPlan.name.toLowerCase().includes('flowmono')
  //       );
  //     });
  //     break;

  //   default:
  //     return [];
  //     break;
  // }

  return subscription[0];
};

export const base64ToBlob = (
  b64Data: any,
  contentType: any = '',
  sliceSize: any = 512
) => {
  const byteCharacters = atob(b64Data);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export const sortAndMapTableData = (
  tableData: any[],
  sortingOrder: 'asc' | 'desc',
  propertyToSortBy: string,
  allDateProperties?: any[]
): any[] => {
  const modifiedData: any[] = [];

  tableData?.forEach((dataEl: any) => {
    let modifiedDataEl = {
      ...dataEl,
    };

    allDateProperties?.forEach((dateProp: any) => {
      modifiedDataEl = {
        ...modifiedDataEl,

        [dateProp]: new Date(
          dataEl[dateProp] ? dataEl[dateProp] + 'Z' : dataEl[dateProp]
        ).getTime(),

        // createdDate: new Date(dataEl.createdDate).getTime(),
        // lastModifiedDate: new Date(dataEl.lastModifiedDate).getTime(),
      };
    });

    modifiedData.push(modifiedDataEl);
  });

  const sortedData = modifiedData.slice().sort((a, b) => {
    const dateA = new Date(a[propertyToSortBy]).getTime();
    const dateB = new Date(b[propertyToSortBy]).getTime();

    let result: any;

    if (sortingOrder === 'asc') {
      result = dateA - dateB;
    } else if (sortingOrder === 'desc') {
      result = dateB - dateA;
    }

    return result;
  });

  return sortedData;
};

export const validateFileType = (files: FileList | File[], types: string[]) => {
  return Array.from(files).every((file: any) => {
    return types.includes(file.type);
  });
};

export const valueMissingInArrayValidator = (
  array: any[],
  error: ValidationErrors
): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | any => {
    if (!control.value) {
      return null;
    }

    let value: any;

    if (typeof control.value === 'string') {
      value = (control.value as string).toLowerCase();
    } else {
      value = control.value;
    }

    const valid = array.includes(value);

    return valid ? null : error;
  };
};

export const isControlDuplicateInFormArrayValidator = (
  formArray: FormArray,
  formControlName: string,
  error: ValidationErrors
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const array = formArray.controls.map((controlEl) => {
      return controlEl.value[formControlName];
    });

    const isControlADuplicate = array.some((el, index) => {
      if (el === control.value) {
        return array.indexOf(el) !== index;
      }
    });

    if (isControlADuplicate) {
      control.get(formControlName)?.markAsTouched();
    }

    return isControlADuplicate === true ? error : null;
  };
};

export const convertToFormData = (data: any) => {
  const formData = new FormData();

  if (data) {
    const keys = Object.keys(data);

    keys.forEach((key: string) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((el: any) => {
          formData.append(key, el);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
  }

  return formData;
};

const isEmptyString = (dateString: any) => {
  return /^\s*$/.test(dateString);
};

export const convertDateToDateTimeString = (dateString: any) => {
  if (!isEmptyString(dateString)) {
    const date = new Date(dateString);

    const options: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      date
    );

    // Extracting individual components for further customization
    const [month, day, year, time] = formattedDate.split(' ');

    // Customizing the output format
    const formattedString = `${month} ${day} ${year} ${time}`;

    return formattedString;
  } else return '';
};

export const exportToCSV = (data: any[], filename: string) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, filename);
};

const convertToCSV = (data: any[]): string => {
  const header = Object.keys(data[0])
    .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(',');
  const csv = data.map((row) => extractRowValues(row)).join('\n');
  return `${header}\n${csv}`;
};

const extractRowValues = (row: any): string => {
  const rowValues: any = [];
  Object.values(row).forEach((value: any) => {
    if (typeof value === 'object') {
      // Assuming you want to extract the 'name' property if it exists
      rowValues.push(value?.name ? value?.name : '');
    } else {
      rowValues.push(value);
    }
  });
  return rowValues.join(',');
};

export const toTitleCase = (str: string) => {
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
};

export const generateHoursInterval = (
  startHourInMinute: any,
  endHourInMinute: any,
  interval: any
) => {
  const times = [];

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break;

    const hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

    const mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

    times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);

    startHourInMinute = startHourInMinute + interval;
  }

  return times;
};

export const getControlName = (controlName: string): string => {
  const name = controlName
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase();

  return toTitleCase(name);
};

export const newGuid = (): string => {
  // Implementation of generating a new GUID
  // (You may replace this with your actual implementation)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateNewGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // tslint:disable-next-line
    const random = (Math.random() * 16) | 0;
    // v = c == 'x' ? random : (random & 0x3) | 0x8;
    return random.toString(16);
  });
};

export const getFileType = (file: File): DriveItemType => {
  const fileName = file.name.toLowerCase();
  const extension = fileName.split('.').pop();

  switch (extension) {
    case 'pdf':
      return DriveItemType.Pdf;
    case 'doc':
    case 'docx':
      return DriveItemType.Word;
    case 'ppt':
    case 'pptx':
      return DriveItemType.PowerPoint;
    case 'xls':
    case 'xlsx':
      return DriveItemType.Excel;
    case 'jpg':
    case 'jpeg':
    case 'png':
      return DriveItemType.Image;
    default:
      return DriveItemType.Folder;
  }
};

export const generateTimeZones = () => {
  const timezones = [];
  for (let i = 11; i > 0; i--) {
    timezones.push(`GMT-${i > 9 ? i : '0' + i}`);
  }

  for (let i = 0; i <= 12; i++) {
    timezones.push(`GMT+${i > 9 ? i : '0' + i}`);
  }

  return timezones;
};

export const getTimeInMinutes = (timeString: string) => {
  const hoursAndSeconds = timeString?.trim().split(' ');
  let hour;
  let minute;
  let timeInMinutes;

  //eslint-disable-next-line
  // console.log(hoursAndSeconds, hour, minute);

  // let timeInMinutes;

  if (timeString?.includes('h') && timeString?.includes('m')) {
    hour = +hoursAndSeconds[0].split('h')[0];
    minute = +hoursAndSeconds[1].split('m')[0];
    timeInMinutes = hour * 60 + minute;
  } else if (timeString?.includes('h')) {
    hour = +hoursAndSeconds[0].split('h')[0];
    timeInMinutes = hour * 60;
  } else if (timeString?.includes('m')) {
    minute = +hoursAndSeconds[0].split('m')[0];
    timeInMinutes = minute;
  }

  //eslint-disable-next-line
  // console.log(timeInMinutes);

  if (timeInMinutes) {
    return timeInMinutes;
  } else {
    return;
  }
};

export const convertTimeToSeconds = (time: string): number => {
  const splittedTime = time?.split(':');
  const seconds = splittedTime
    ? +splittedTime[0] * 60 * 60 + +splittedTime[1] * 60
    : 0;

  return seconds;
};

export const convertTimeToString = (time: number): string => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;

  let timeString = '';

  if (hours) {
    timeString = `${hours}h`;
  }

  if (minutes) {
    timeString += hours ? ` ${minutes}m` : `${minutes}m`;
  }

  return timeString;
};
