import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './@components/page-not-found/page-not-found.component';
import { UnauthorizedPageComponent } from './@components/unauthorized-page/unauthorized-page.component';
import { CheckoutSuccessComponent } from './@components/checkout-success/checkout-success.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'site',
  //   pathMatch: 'full',
  // },

  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },

  {
    path: 'unauthorized-page',
    component: UnauthorizedPageComponent,
  },

  {
    path: 'paymentredirect',
    component: CheckoutSuccessComponent,
  },

  {
    path: '',
    loadChildren: () =>
      import('src/app/@components/website/website.module').then(
        (m) => m.WebsiteModule
      ),
  },

  // {
  //   path: 'auth',
  //   loadChildren: () =>
  //     import('src/app/@auth/auth.module').then((m) => m.AuthModule),
  // },

  {
    path: 'app',
    data: { breadcrumb: 'Webapp' },
    loadChildren: () =>
      import('src/app/@components/webapp/webapp.module').then(
        (m) => m.WebappModule
      ),
  },

  // {
  //   path: 'editor',
  //   loadChildren: () =>
  //     import('src/app/@components/editor-old/editor.module').then(
  //       (m) => m.EditorModule
  //     ),
  // },

  {
    path: 'checkout',
    loadChildren: () =>
      import('src/app/@components/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
  },

  {
    path: 'transaction',
    loadChildren: () =>
      import('./@components/webapp/transaction/transaction.module').then(
        (m) => m.TransactionModule
      ),
  },

  {
    path: 'developer',
    loadChildren: () =>
      import('src/app/@components/developer/developer.module').then(
        (m) => m.DeveloperModule
      ),
  },

  {
    path: 'api',
    loadChildren: () =>
      import('src/app/@components/api/api.module').then((m) => m.ApiModule),
  },

  {
    path: 'external',
    loadChildren: () =>
      import('src/app/@components/external-sign/external-sign.module').then(
        (m) => m.ExternalSignModule
      ),
  },

  {
    path: 'forms/:subscriberId/:ownerId/:formId',
    loadChildren: () =>
      import('src/app/@components/external-form/external-form.module').then(
        (m) => m.ExternalFormModule
      ),
  },

  {
    path: 'verification',
    loadChildren: () =>
      import(
        'src/app/@components/external-verification/external-verification.module'
      ).then((m) => m.ExternalVerificationModule),
  },

  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 80],
  // onSameUrlNavigation: 'reload',
  // enableTracing: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
