<section
  id="flowmono-authenticator-app"
  *ngIf="!isEmailAuthenticator && !showAuthenticationError"
>
  <div class="flowmono-authenticator">
    <div class="d-flex flex-column gap-1">
      <h6 class="flwmn-heading-6 fw-700 mb-0">Login confirmation</h6>

      <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
        Someone tried to access your account now.
      </p>
    </div>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <p
    *ngIf="selectedMethod === 'authenticator'"
    class="flwmn-para-1 fw-500 flwmn-text-neutral text-center mb-0"
  >
    Enter the 6-digit code from your authenticator app to verify your login
    attempt.
  </p>

  <p
    *ngIf="selectedMethod === 'email'"
    class="flwmn-para-1 fw-500 flwmn-text-neutral text-center mb-0"
  >
    Enter the 6-digit code sent to {{ data.email }}
  </p>

  <div class="otp-container gap-1">
    <label class="input-label mb-3">Enter passcode</label>

    <form
      [formGroup]="otpForm"
      [ngClass]="{ 'disabled-form': otpForm.disabled }"
    >
      <input
        type="text"
        maxlength="1"
        formControlName="otp1"
        (paste)="handlePaste($event)"
        (keyup)="moveFocus($event, 'otp2', '')"
        [ngClass]="{
          error: otpForm.get('otp1')?.invalid && otpForm.get('otp1')?.touched
        }"
      />

      <input
        type="text"
        maxlength="1"
        formControlName="otp2"
        (keyup)="moveFocus($event, 'otp3', 'otp1')"
        [ngClass]="{
          error: otpForm.get('otp2')?.invalid && otpForm.get('otp2')?.touched
        }"
      />

      <input
        type="text"
        maxlength="1"
        formControlName="otp3"
        (keyup)="moveFocus($event, 'otp4', 'otp2')"
        [ngClass]="{
          error: otpForm.get('otp3')?.invalid && otpForm.get('otp3')?.touched
        }"
      />

      <input
        type="text"
        maxlength="1"
        formControlName="otp4"
        (keyup)="moveFocus($event, 'otp5', 'otp3')"
        [ngClass]="{
          error: otpForm.get('otp4')?.invalid && otpForm.get('otp4')?.touched
        }"
      />

      <input
        type="text"
        maxlength="1"
        formControlName="otp5"
        (keyup)="moveFocus($event, 'otp6', 'otp4')"
        [ngClass]="{
          error: otpForm.get('otp5')?.invalid && otpForm.get('otp5')?.touched
        }"
      />

      <input
        type="text"
        maxlength="1"
        formControlName="otp6"
        (keyup)="moveFocus($event, '', 'otp5')"
        [ngClass]="{
          error: otpForm.get('otp6')?.invalid && otpForm.get('otp6')?.touched
        }"
      />
    </form>
  </div>

  <div class="flwmn-para-1 fw-600 other-step">
    <p
      *ngIf="!isSingleMethodAvailable && selectedMethod === 'authenticator'"
      class="flwmn-para-1 fw-600"
    >
      Didn't get it?
    </p>

    <p
      *ngIf="!isSingleMethodAvailable && selectedMethod === 'email'"
      class="flwmn-para-1 fw-600 flwmn-pointer resend"
      (click)="sendToOtp()"
    >
      Resend Otp.
    </p>

    <span
      *ngIf="!isSingleMethodAvailable"
      (click)="onTryAnotherMethod()"
      class="flwmn-text-primary flwmn-pointer"
      >Try another method</span
    >
  </div>

  <div
    class="flwmn-para-1 fw-600 other-step"
    *ngIf="
      selectedMethod === 'authenticator' &&
      authenticatorExhausted &&
      isSingleMethodAvailable
    "
  >
    <p>
      Try again in {{ minutesAuthenticator }}:{{
        secondsAuthenticator | number : '2.0'
      }}
    </p>
  </div>

  <div
    class="flwmn-para-1 fw-600 other-step"
    *ngIf="
      selectedMethod === 'email' && emailExhausted && isSingleMethodAvailable
    "
  >
    <p>Try again in {{ minutesEmail }}:{{ secondsEmail | number : '2.0' }}</p>
  </div>

  <div
    *ngIf="isSingleMethodAvailable && selectedMethod === 'email'"
    class="flwmn-para-1 fw-600 flwmn-text-primary flwmn-pointer resend"
    (click)="sendToOtp()"
  >
    Resend OTP.
  </div>

  <div class="cta-container">
    <button
      matRipple
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button
      matRipple
      class="btn flwmn-btn-primary flwmn-btn-md"
      (click)="onSubmit()"
      [disabled]="otpForm.invalid"
    >
      Continue
    </button>
  </div>
</section>

<section
  id="email-authenticator-app"
  *ngIf="isEmailAuthenticator && !showAuthenticationError"
>
  <div class="email-authenticator">
    <div class="d-flex flex-column gap-1">
      <h6 class="flwmn-heading-6 fw-700 mb-0">2-Factor Authentication</h6>

      <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
        To help keep your account safe, we wants to make sure it’s really you
      </p>
    </div>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <div class="email-authenticator-container">
    <h6 class="flwmn-sub-heading mb-0">Choose verification method:</h6>

    <mat-radio-group
      aria-label="Select an option"
      color="primary"
      class="flwmn-radio-group"
      [(ngModel)]="selectedMethod"
      (change)="onSelectionChange($event)"
      [disabled]="emailExhausted && authenticatorExhausted"
    >
      <mat-radio-button
        class="flwmn-radio-btn"
        value="authenticator"
        [disabled]="authenticatorExhausted"
      >
        <div class="d-flex gap-2 align-items-center">
          <app-svg
            src="assets/icons/authenticator.svg"
            [ngClass]="{ 'disabled-icon': authenticatorExhausted }"
          ></app-svg>

          Get a verification code on your Flowmono authenticator

          <span *ngIf="authenticatorExhausted && countdownDuration > 0">
            <p class="flwmn-text-black mb-0">
              Try again in {{ minutesAuthenticator }}:{{
                secondsAuthenticator | number : '2.0'
              }}
            </p>
          </span>
        </div>
      </mat-radio-button>

      <mat-radio-button
        class="flwmn-radio-btn"
        value="email"
        [disabled]="emailExhausted"
      >
        <div class="d-flex gap-2 align-items-center">
          <app-svg
            src="assets/icons/mail.svg"
            [ngClass]="{ 'disabled-icon': emailExhausted }"
          ></app-svg>

          Get a verification code sent to your email

          <span *ngIf="emailExhausted && countdownDuration > 0">
            <p class="flwmn-text-black mb-0">
              Try again in {{ minutesEmail }}:{{
                secondsEmail | number : '2.0'
              }}
            </p>
          </span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="cta-container">
    <button
      matRipple
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button
      matRipple
      class="btn flwmn-btn-primary flwmn-btn-md"
      (click)="onSelect()"
    >
      Continue
    </button>
  </div>
</section>

<section
  id="authentication-error"
  *ngIf="!isEmailAuthenticator && showAuthenticationError"
>
  <div class="authentication-error-box">
    <div class="d-flex flex-column gap-3">
      <h6 class="flwmn-heading-6 fw-700 mb-0">
        Oh snap! Something went wrong...
      </h6>

      <p class="flwmn-para-1 fw-500 mb-0">
        We couldn't verify your account with the information provided. To keep
        your account secure, we'll need a bit more information. Please try again
        .
      </p>
    </div>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <div class="authentication-error-box-container">
    <h6 class="flwmn-sub-heading fw-600 mb-0">If possible, when signing in:</h6>

    <ul>
      <li>Provide the 6-digit code sent to your email or authenticator</li>
      <li>Use a device where you have signed in before</li>
      <li>Use a strong Wi-Fi network</li>
    </ul>
  </div>

  <div class="cta-container">
    <button
      matRipple
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button
      matRipple
      class="btn flwmn-btn-primary flwmn-btn-md"
      (click)="onTryAgain()"
    >
      Try again
    </button>
  </div>
</section>
