import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import * as DeveloperActions from './developer-dashboard.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class DeveloperDashboardEffects {
  defaultFilterPayload = {
    payload: {
      startDate: '',
      endDate: '',
      environmentVariableType: 0,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getResponseCallChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetResponseCallChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapiresponsecall/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingResponseCallChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveResponseCallChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveResponseCallChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Response Call Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingResponseCallChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError]  Failed To Get Response Call Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getRequestFailureChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetRequestFailureChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapirequestsfailure/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingRequestFailureChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveRequestFailureChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveRequestFailureChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Request Failure Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingRequestFailureChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError] Failed To Get Request Failure Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSuccessRateChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetSuccessRateChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapisuccessrate/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingSuccessRateChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveSuccessRateChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveSuccessRateChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Success Rate Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingSuccessRateChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError]  Failed To Get Success Rate Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSigningRequestChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetSigningRequestChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getsigningrequest/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingSigningRequestChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveSigningRequestChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveSigningRequestChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Signing Request Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingSigningRequestChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError]  Failed To Get Signing Request Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getTotalApiSuccessChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetTotalApiSuccessChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/gettotalapisuccess/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingTotalApiSuccessChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveTotalApiSuccessChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveTotalApiSuccessChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Total Api Success Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingTotalApiSuccessChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError]  Failed To Get Total Api Success Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getUsageGrowthChartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperActions.GetUsageGrowthChartData),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapiusagegrowth/${dashboardData.payload.startDate}/${dashboardData.payload.endDate}/${dashboardData.payload.environmentVariableType}/${authState.user.SubscriberId}/${dashboardData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingUsageGrowthChartData({
                  payload: false,
                })
              );
              if (resData.succeeded === true) {
                return DeveloperActions.SaveUsageGrowthChartData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DeveloperActions.SaveUsageGrowthChartData({
                    payload: null,
                  })
                );

                return {
                  type: '[Developer Dashboard] Failed To Get Usage Growth Chart Data',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DeveloperActions.IsLoadingUsageGrowthChartData({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                `[Developer Dashboard][CatchError]  Failed To Get Usage Growth Chart Data ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
