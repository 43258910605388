import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as fromApp from '../app/app.reducer';
import * as InboxActions from './inbox.actions';
import { GenericResponse, Notification } from '../../interfaces';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
declare let require: any;
const FileSaver = require('file-saver');

@Injectable()
export class InboxEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(InboxActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllInboxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.GetAllInboxes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([inboxData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocDocumentUrl}/Inboxes/getinboxesbyemail/${authState.user.SubscriberId}/${authState.user.Email}`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                this.store.dispatch(
                  InboxActions.SaveAllInboxes({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Inbox] Get All Inboxes Was Succesfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  InboxActions.SaveAllInboxes({
                    payload: [],
                  })
                );

                return {
                  type: '[Inbox] Failed To Get All Inboxes',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Inbox][CatchError] Failed To Get All Inboxes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getInboxById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InboxActions.GetInboxById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([inboxData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocDocumentUrl}/Inboxes/getinboxid/${inboxData.payload.id}/${inboxData.payload.documentId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  // this.store.dispatch(
                  //   InboxActions.SaveInboxById({
                  //     payload: resData.entity,
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Inbox] Get Inbox By Id Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Inbox] Failed To Get Inbox By Id',
                  });
                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Inbox][CatchError] Failed To Get Inbox By Id  ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  downloadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InboxActions.DownloadDocuments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([inboxData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/downloaddocuments`,
            {
              ...inboxData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(InboxActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(InboxActions.IsLoading({ payload: true }));

                resData?.entity?.documents.forEach((document: any) => {
                  FileSaver?.saveAs(
                    document.documentFile,
                    `Flowmono-${document.documentName}`
                  );

                  // if (
                  //   (document.documentName as string).endsWith(
                  //     document.documentExtension
                  //   )
                  // ) {
                  //   FileSaver?.saveAs(
                  //     document.documentFile,
                  //     `Flowmono-${document.documentName}`
                  //   );
                  // } else {
                  //   FileSaver?.saveAs(
                  //     document.documentFile,
                  //     `Flowmono-${document.documentName}.${document.documentExtension}`
                  //   );
                  // }
                });

                this.store.dispatch(InboxActions.IsLoading({ payload: false }));

                return {
                  type: '[Inbox] Download Documents Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Inbox] Failed To Download Documents',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Inbox][CatchError] Failed To Download Documents ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
