export const AuthenticationTypes = [
  { name: 'In App User', id: 1 },
  { name: 'Proxy User', id: 2 },
];

export const ApplicationStatus = {
  ReadyForProduction: 'Ready for production',
  InDevelopment: 'In Development',
  InProduction: 'In Production',
};

export const AuthenticationType = {
  InAppUser: 'In App',
  ProxyUser: 'Proxy User',
};
