import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Contact Category] Reset Store');

export const IsLoading = createAction(
  '[Contact Category] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateContactCategory = createAction(
  '[Contact Category] Create Contact Category',
  props<{
    payload: {
      categoryName: string;
    };
  }>()
);

export const UpdateContactCategory = createAction(
  '[Contact Category] Update Contact Category',
  props<{
    payload: {
      id: number;
      categoryName: string;
    };
  }>()
);

export const ActivateContactCategory = createAction(
  '[Contact Category] Activate Contact Category',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const DeactivateContactCategory = createAction(
  '[Contact Category] Deactivate Contact Category',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetContactCategory = createAction(
  '[Contact Category] Get Contact Category',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveContactCategory = createAction(
  '[Contact Category] Save Contact Category',
  props<{
    payload: any;
  }>()
);

export const GetContactCategoryById = createAction(
  '[Contact Category] Get Contact Category By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveContactCategoryById = createAction(
  '[Contact Category] Save Contact Category By Id',
  props<{
    payload: any;
  }>()
);
