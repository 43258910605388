import { createReducer, on, Action } from '@ngrx/store';
import * as ContactCategoryActions from './contact-category.actions';

export interface State {
  isLoading: boolean;
  contactCategories: any[] | null;
  contactCategoryById: any;
}

const initialState: State = {
  isLoading: false,
  contactCategories: null,
  contactCategoryById: null,
};

const contactCategoryReducerInternal = createReducer(
  initialState,
  on(ContactCategoryActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(ContactCategoryActions.SaveContactCategory, (state, { payload }) => ({
    ...state,
    contactCategories: payload,
  })),
  on(ContactCategoryActions.SaveContactCategoryById, (state, { payload }) => ({
    ...state,
    contactCategoryById: payload,
  }))
);

export function contactCategoryReducer(
  state: State | undefined,
  action: Action
) {
  return contactCategoryReducerInternal(state, action);
}
