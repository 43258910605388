import { createReducer, on, Action } from '@ngrx/store';
import * as FormbuilderDashboardActions from './fmbr-dashboard.actions';

export interface State {
  isLoading: boolean;
  isLoadingFormbuilderDonutChartData: any;
  isLoadingFormbuilderLineChartData: any;
  formbuilderDonutChartData: any;
  formbuilderLineChartData: any;
  dashboardMetric: any;
}

const initialState: State = {
  isLoading: false,
  isLoadingFormbuilderDonutChartData: null,
  isLoadingFormbuilderLineChartData: null,
  formbuilderDonutChartData: null,
  formbuilderLineChartData: null,
  dashboardMetric: null,
};

const formbuilderDashboardReducerInternal = createReducer(
  initialState,
  on(FormbuilderDashboardActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(FormbuilderDashboardActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(
    FormbuilderDashboardActions.SaveFormbuilderDonutChartData,
    (state, { payload }) => ({
      ...state,
      formbuilderDonutChartData: payload,
    })
  ),
  on(
    FormbuilderDashboardActions.SaveFormbuilderLineChartData,
    (state, { payload }) => ({
      ...state,
      formbuilderLineChartData: payload,
    })
  ),
  on(FormbuilderDashboardActions.SaveDashboardMetric, (state, { payload }) => ({
    ...state,
    dashboardMetric: payload,
  }))
);

export const formbuilderDashboardReducer = (
  state: State | undefined,
  action: Action
) => {
  return formbuilderDashboardReducerInternal(state, action);
};
