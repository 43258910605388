import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as ApplicationManagementActions from './application-management.action';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class ApplicationManagementEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(
      ApplicationManagementActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  // getAllExternalApplications$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ApplicationManagementActions.GetAllExternalApplications),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([applicationData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocAuthUrl}/ExternalApplication/getallexternalapplications/${authState.user.UserId}`
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               ApplicationManagementActions.IsLoading({ payload: false })
  //             );
  //             if (resData.succeeded === true) {
  //               return ApplicationManagementActions.SaveAllExternalApplications(
  //                 {
  //                   payload: resData.entity,
  //                 }
  //               );
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openSnackBar(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               this.store.dispatch(
  //                 ApplicationManagementActions.SaveAllExternalApplications({
  //                   payload: [],
  //                 })
  //               );

  //               return {
  //                 type: '[Application Management] Failed To Get All External Applications',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Application Management][CatchError]  Failed To Get All External Applications ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });

  getExternalApplicationById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationManagementActions.GetExternalApplicationById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([applicationData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ExternalApplication/getbyid/${applicationData.payload.id}/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  ApplicationManagementActions.IsFetching({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Application Management] Get External Application By Id Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Application Management] Failed To Get External Application By Id',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Application Management][CatchError] Failed To Get External Application By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  getExternalApplicationByAppId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationManagementActions.GetExternalApplicationByAppId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([applicationData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ExternalApplication/getbyid/${applicationData.payload.appId}/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  ApplicationManagementActions.IsFetching({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Application Management] Get External Application By App Id Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Application Management] Failed To Get External Application By App Id',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Application Management][CatchError] Failed To Get External Application By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  getActiveEncryptionKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.GetActiveEncryptionKey),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAlternateDocumentUrl}/ExternalApplication/getactiveencryptionkey/${applicationData.payload.appId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return ApplicationManagementActions.SaveActiveEncryptionKey({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  ApplicationManagementActions.SaveActiveEncryptionKey({
                    payload: null,
                  })
                );

                return {
                  type: '[Application Management] Failed To Get Active Encryption Key',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get Active Encryption Key ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getWebhookUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.GetWebhookUrl),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAlternateDocumentUrl}/WebHook/getwebhookurl/${applicationData?.payload?.appId}/${applicationData?.payload?.apiClientId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return ApplicationManagementActions.SaveWebhookUrl({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  ApplicationManagementActions.SaveWebhookUrl({
                    payload: null,
                  })
                );

                return {
                  type: '[Application Management] Failed To Get WebHook URL',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get WebHook URL ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getEncryptionKeyHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.GetEncryptionKeyHistory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAlternateDocumentUrl}/ExternalApplication/getencryptionkeyhistory/${applicationData.payload.appId}/${applicationData?.payload?.skip}/${applicationData?.payload?.take}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return ApplicationManagementActions.SaveEncryptionKeyHistory({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  ApplicationManagementActions.SaveEncryptionKeyHistory({
                    payload: [],
                  })
                );

                return {
                  type: '[Application Management] Failed To Get Active Encryption Key',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get Active Encryption Key ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createExternalApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.CreateExternalApplication),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/create`,
            {
              subscriberId: authState.user.SubscriberId,
              apiClientId: authState.user?.ApiClientId,
              userId: authState.user.UserId,
              ...applicationData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );

                this.dialog.closeAll();

                return {
                  type: '[Application Management] Create External Application Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Create External Application',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Create External Application ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createEncryptionKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.CreateEncryptionKey),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAlternateDocumentUrl}/ExternalApplication/createencryptionkey`,
            {
              apiClientId: authState.user?.ApiClientId,
              ...applicationData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  ApplicationManagementActions.GetEncryptionKeyHistory({
                    payload: {
                      ...this.defaultUserPaginationPayload.payload,
                      appId: applicationData.payload.appId,
                    },
                  })
                );

                this.store.dispatch(
                  ApplicationManagementActions.GetActiveEncryptionKey({
                    payload: { appId: applicationData.payload.appId },
                  })
                );

                return {
                  type: '[Application Management] Create Encryption Was Successful Key',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Create Encryption Key',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Create Encryption Key ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  configureWebhookUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.ConfigureWebhookUrl),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAlternateDocumentUrl}/WebHook/createwebhookurl`,
            {
              ...applicationData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message ?? 'Successful',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  ApplicationManagementActions.GetWebhookUrl({
                    payload: {
                      appId: applicationData.payload.appId,
                      apiClientId: applicationData.payload.apiClientId,
                    },
                  })
                );

                return {
                  type: '[Application Management] Configure WebHook Url Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Configure WebHook Url',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Configure WebHook Url ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateExternalApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.UpdateExternalApplication),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/update/${applicationData.payload.applicationId}`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...applicationData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );

                this.dialog.closeAll();

                return {
                  type: '[Application Management] Update External Application Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Update External Application',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Update External Application ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateExternalApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationManagementActions.ActivateExternalApplication),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/activate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ...applicationData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );

                return {
                  type: '[Application Management] Activate External Application Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Activate External Application',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Activate External Application ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateExternalApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationManagementActions.DeactivateExternalApplication),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/deactivate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ...applicationData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );

                return {
                  type: '[Application Management] Deactivate External Application Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Deactivate External Application',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Deactivate External Application ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  validateExternalApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationManagementActions.ValidateExternalApplication),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/validate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
              ...applicationData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );

                return {
                  type: '[Application Management] Deactivate External Application Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Deactivate External Application',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Deactivate External Application ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  pushAppToProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationManagementActions.PushAppToProduction),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/pushapptoproduction`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ...applicationData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ApplicationManagementActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  ApplicationManagementActions.GetAllExternalApplications()
                );
                this.dialog.closeAll();

                return {
                  type: '[Application Management] Pushing External Application To Production Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Application Management] Failed To Pushing External Application To Production',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError] Failed To Pushing External Application To Production ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getExternalApplicationsBySubscriberId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApplicationManagementActions.GetAllExternalApplications),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/getbysubscriberid/${authState.user?.ApiClientId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ApplicationManagementActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return ApplicationManagementActions.SaveAllExternalApplications(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  ApplicationManagementActions.SaveAllExternalApplications({
                    payload: [],
                  })
                );

                return {
                  type: '[Application Management] Failed To Get All External Applications By Subscriber Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get All External Applications By Subscriber Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
