import { Observable } from 'rxjs/internal/Observable';
import { createReducer, on, Action } from '@ngrx/store';
import * as DeveloperDashboardActions from './developer-dashboard.actions';
import { of } from 'rxjs';

export interface State {
  isLoadingResponseCallChartData: Observable<any>;
  isLoadingSuccessRateChartData: Observable<any>;
  isLoadingRequestFailureChartData: Observable<any>;
  isLoadingSigningRequestChartData: Observable<any>;
  isLoadingTotalApiSuccessChartData: Observable<any>;
  isLoadingUsageGrowthChartData: Observable<any>;
  responseCallChartData: any;
  requestFailureChartData: any;
  successRateChartData: any;
  signingRequestChartData: any;
  totalApisuccessChartData: any;
  apiUsageGrowthChartData: any;
}

const initialState: State = {
  isLoadingResponseCallChartData: of(false),
  isLoadingSuccessRateChartData: of(false),
  isLoadingRequestFailureChartData: of(false),
  isLoadingSigningRequestChartData: of(false),
  isLoadingTotalApiSuccessChartData: of(false),
  isLoadingUsageGrowthChartData: of(false),
  responseCallChartData: null,
  requestFailureChartData: null,
  successRateChartData: null,
  signingRequestChartData: null,
  totalApisuccessChartData: null,
  apiUsageGrowthChartData: null,
};

const developerDashboardReducerInternal = createReducer(
  initialState,
  on(DeveloperDashboardActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(
    DeveloperDashboardActions.IsLoadingResponseCallChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingResponseCallChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.IsLoadingSuccessRateChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingSuccessRateChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.IsLoadingRequestFailureChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingRequestFailureChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.IsLoadingSigningRequestChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingSigningRequestChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.IsLoadingTotalApiSuccessChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingTotalApiSuccessChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.IsLoadingUsageGrowthChartData,
    (state, { payload }) => ({
      ...state,
      isLoadingUsageGrowthChartData: of(payload),
    })
  ),
  on(
    DeveloperDashboardActions.SaveResponseCallChartData,
    (state, { payload }) => ({
      ...state,
      responseCallChartData: payload,
    })
  ),
  on(
    DeveloperDashboardActions.SaveRequestFailureChartData,
    (state, { payload }) => ({
      ...state,
      requestFailureChartData: payload,
    })
  ),
  on(
    DeveloperDashboardActions.SaveSuccessRateChartData,
    (state, { payload }) => ({
      ...state,
      successRateChartData: payload,
    })
  ),

  on(
    DeveloperDashboardActions.SaveSigningRequestChartData,
    (state, { payload }) => ({
      ...state,
      signingRequestChartData: payload,
    })
  ),

  on(
    DeveloperDashboardActions.SaveTotalApiSuccessChartData,
    (state, { payload }) => ({
      ...state,
      totalApisuccessChartData: payload,
    })
  ),

  on(
    DeveloperDashboardActions.SaveUsageGrowthChartData,
    (state, { payload }) => ({
      ...state,
      apiUsageGrowthChartData: payload,
    })
  )
);

export function developerDashboardReducer(
  state: State | undefined,
  action: Action
) {
  return developerDashboardReducerInternal(state, action);
}
