<div *ngIf="loadingRouteConfig" class="lazy-loading-module-loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div
  *ngIf="pwaUpdateService.appUpdateIsAvailable === true"
  matRipple
  class="pwa-update-banner"
  (click)="onClickPwaUpdateBanner()"
>
  <div matRipple class="pwa-update-banner-content">
    <div class="brand-logo-container">
      <img src="assets/logo/pwa/icon-512x512.png" alt="Flowmono" />
    </div>

    <p class="flwmn-para-1 fw-600 mb-0 pwa-update-banner-text">
      Flowmono has an update! Click to update to the latest version.
    </p>
  </div>
</div>

<router-outlet></router-outlet>
