import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import * as AuditActions from './audit.actions';

@Injectable()
export class AuditEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  //   Error handler
  // private handleError = (errorRes: any) => {
  //   this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //   return of({ type: '[Subscription Plan] Error' });
  // };

  getAuditByControllerName$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuditActions.GetAuditByControllerName),
        withLatestFrom(this.store.select('auth')),
        switchMap(([auditData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAudit}-${auditData.payload.apiSectionName}-poc.reventholdings.com/api/AuditTrails/getbycontroller/${authState.user.UserId}/${auditData.payload.controllerName}/${this.defaultUserPaginationPayload.payload.skip}/${this.defaultUserPaginationPayload.payload.take}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    AuditActions.SaveAuditByControllerName({
                      payload: resData.entity,
                    })
                  );

                  this.store.dispatch({
                    type: '[Audit] Get Audit By Controller Names Succesfully',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Audit] Get Audit By Controller Names Failed',
                  });
                  return resData.entity;
                }
              }),
              catchError((errRes) =>
                of({ type: '[Audit] Get Audit By Controller Error' })
              )
            );
        }),
        share()
      ),
    { dispatch: false }
  );
}
