import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective {
  @Input() dragAndDropInstance: 'normal' | 'page' = 'normal';

  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding('style.border-width') public borderWidth: any;
  @HostBinding('style.opacity') public opacity = '1';

  //Dragover listener, when something is dragged over our host element
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.dragAndDropInstance === 'normal') {
      this.borderWidth = '5px';
      this.opacity = '0.8';
    }

    if (this.dragAndDropInstance === 'page') {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'drag-and-drop-page-overlay'
      );
    }
  }

  //Dragleave listener, when something is dragged away from our host element
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.dragAndDropInstance === 'normal') {
      this.borderWidth = '1px';
      this.opacity = '1';
    }

    if (this.dragAndDropInstance === 'page') {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        'drag-and-drop-page-overlay'
      );
    }
  }

  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.dragAndDropInstance === 'normal') {
      this.borderWidth = '1px';
      this.opacity = '1';
    }

    if (this.dragAndDropInstance === 'page') {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        'drag-and-drop-page-overlay'
      );
    }

    const files = evt.dataTransfer.files;

    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}
}
