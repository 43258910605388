import { createReducer, on, Action } from '@ngrx/store';
import * as UserLicenseActions from './user-license.actions';

export interface State {
  isLoading: boolean;
}

const initialState: State = {
  isLoading: false,
};

const userLicenseReducerInternal = createReducer(
  initialState,
  on(UserLicenseActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(UserLicenseActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }))
);

export function userLicenseReducer(state: State | undefined, action: Action) {
  return userLicenseReducerInternal(state, action);
}
