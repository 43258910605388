import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Signing Request Audit] Reset Store');

export const IsLoading = createAction(
  '[Signing Request Audit] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateSigningRequestAudit = createAction(
  '[Signing Request Audit] Create Signing Request Audit',
  props<{
    payload: {
      name: string;
      addSLAToThisAudit: true;
    };
  }>()
);

export const UpdateSigningRequestAudit = createAction(
  '[Signing Request Audit] Update Signing Request Audit',
  props<{
    paylod: {
      id: number;
      name: string;
      addSLAToThisAudit: true;
    };
  }>()
);

export const GetAllSigningRequestAudit = createAction(
  '[Signing Request Audit]  Get All Signing Request Audit',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveSigningRequestAudit = createAction(
  '[Signing Request Audit] Save Signing Request Audit',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestAuditById = createAction(
  '[Signing Request Audit] Get Signing Request Audit By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveSigningRequestAuditById = createAction(
  '[Signing Request Audit] Save Signing Request Audit By Id',
  props<{
    payload: any;
  }>()
);
