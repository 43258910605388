import { createReducer, on, Action } from '@ngrx/store';
import * as InboxActions from './inbox.actions';

export interface State {
  isLoading: boolean;
  inboxes: any;
  inbox: any;
}

const initialState: State = {
  isLoading: false,
  inboxes: null,
  inbox: null,
};

const inboxReducerInternal = createReducer(
  initialState,
  on(InboxActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(InboxActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(InboxActions.SaveAllInboxes, (state, { payload }) => ({
    ...state,
    inboxes: payload,
  })),
  on(InboxActions.SaveInboxById, (state, { payload }) => ({
    ...state,
    inbox: payload,
  }))
);

export const inboxReducer = (state: State | undefined, action: Action) => {
  return inboxReducerInternal(state, action);
};
