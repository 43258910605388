<section class="flwmn-signature-container">
  <div
    [id]="id"
    [formGroup]="form"
    class="flwmn-signature-control no-select"
    [ngStyle]="{ cursor: actorMenu === true ? 'not-allowed' : '' }"
    (click)="actorMenu === false ? onHandleDoubleClick() : ''"
  >
    <!-- (click)="actorMenu === false ? onAddSignature() : ''" -->
    <ng-container *ngIf="currentSignature; else noSignature">
      <img [src]="currentSignature" alt="" class="signature" />
    </ng-container>

    <ng-template #noSignature>
      <p
        class="no-signature-text"
        [ngClass]="{
          'no-signature-external-text': controlMode === controlModeEnum.External
        }"
      >
        {{
          controlMode === controlModeEnum.Internal
            ? 'Signature Field'
            : 'Double click here to sign'
        }}
      </p>
    </ng-template>

    <ng-template #applyAllFeedback>
      <section>
        <p class="flwmn-para-2">
          Do you want to apply this signature to all your signature fields?
        </p>
        <div class="d-flex justify-content-between gap-2">
          <button
            matRipple
            (click)="onResponse(true)"
            class="btn flwmn-btn-primary flwmn-btn-md"
          >
            Yes
          </button>

          <button
            matRipple
            (click)="onResponse(false)"
            class="btn flwmn-btn-danger-outline flwmn-btn-md"
          >
            No
          </button>
        </div>
      </section>
    </ng-template>
  </div>

  <div
    matRipple
    class="flwmn-edit-signature"
    [class.opacity-0]="signType"
    *ngIf="currentSignature && isCurrentRecipient()"
  >
    <p class="signature-name mb-0" (click)="onEditSignatureClicked()">
      Change signature
    </p>
    <!-- <p
      class="signature-name mb-0"
      [class.opacity-0]="userData.fullName === undefined"
    >
      <span> {{ userData.fullName.split(' ')[0] }}</span>
    </p>

    <span>|</span>

    <span matRipple class="edit-icon" (click)="onEditSignatureClicked()">
      <app-svg src="assets/icons/edit.svg"></app-svg
    ></span> -->
  </div>
</section>

<canvas id="markedCanvas" class="d-none" width="1300" height="400"></canvas>
