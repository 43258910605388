<div id="auth-carousel-container">
  <div
    class="content-container"
    (mouseenter)="pauseCarousel()"
    (mouseleave)="startCarousel()"
  >
    <div class="product-name">
      <span class="flwmn-sub-heading flwmn-text-black fw-600">
        {{ items[currentIndex].productName }}
      </span>
    </div>

    <div class="product-caption flwmn-text-white flwmn-heading-3 fw-700">
      {{ items[currentIndex].caption }}
    </div>

    <div class="product-images">
      <div
        class="image-items"
        *ngFor="let product of items; let i = index"
        [ngStyle]="{
          transform: 'translateX(' + -100 * currentIndex + '%)',
          transition: disableTransition ? 'none' : 'transform 500ms ease-in-out'
        }"
      >
        <div
          class="img-container"
          [ngStyle]="{
            transform: currentIndex === i ? 'scale(1)' : 'scale(0.5)',
            transition: 'transform 300ms ease'
          }"
        >
          <img
            [src]="product.image"
            [alt]="items[0].productName"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <div class="product-description">
      <span class="flwmn-para-3 flwmn-text-white">
        {{ items[currentIndex].description }}
      </span>
      <!-- <span class="learn-more flwmn-sub-heading flwmn-text-white" role="button"
        >Learn more.</span
      > -->
    </div>

    <div class="auth-carousel-indicators">
      <span
        *ngFor="let item of items; let i = index"
        [class.active]="i === currentIndex"
        [ngStyle]="{
          transition: disableTransition ? 'none' : 'width 200ms ease-in-out'
        }"
        (click)="goToSlide(i)"
      ></span>
    </div>
  </div>
</div>
