import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionPlan } from '../interfaces/subscription.interface';

@Pipe({ name: 'plan' })
export class PlanPipe implements PipeTransform {
  transform(
    value: SubscriptionPlan[],
    selectedCurrency: 'string' | any,
    selectedFrequency: 'string' | any
  ): any {
    const plan = value.filter((plan) => {
      return plan.subscriptionPlanPricings.some((pricing) => {
        return (
          pricing?.currencyCode.toLowerCase() ===
            selectedCurrency?.toLowerCase() &&
          pricing?.pricingPlanTypeDesc.toLowerCase() ===
            selectedFrequency?.toLowerCase()
        );
      });
    });
    return plan;
  }
}
