import { createAction, props } from '@ngrx/store';

export const IsLoading = createAction(
  '[Organization] Is Loading',
  props<{
    payload: boolean;
  }>()
);

// Get Units
export const GetUnits = createAction(
  '[User Group] Get All Units For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveGetUnits = createAction(
  '[User Group] Get All Saved Units For User Group',
  props<{
    payload: any[];
  }>()
);

// Get Department
export const GetDepartment = createAction(
  '[User Group] Get All Departments For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveGetDepartment = createAction(
  '[User Group] Get All Saved Departments For User Group',
  props<{
    payload: any[];
  }>()
);

export const GetAllDepartmentsWithNumbersOfUsers = createAction(
  '[User Group] Get All Departments With Numbers of Users For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveAllDepartmentsWithNumbersOfUsers = createAction(
  '[User Group] Get All Saved Departments With Numbers of Users For User Group',
  props<{
    payload: any[];
  }>()
);

// Divisions
export const GetDivisions = createAction(
  '[User Group] Get All Divisions For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveDivisions = createAction(
  '[User Group] Get All Saved Divisions For User Group',
  props<{
    payload: any[];
  }>()
);

export const GetDivisionWithNumbersOfUsers = createAction(
  '[User Group] Get  Divisions With Numbers of Users For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveDivisionsWithNumbersOfUsers = createAction(
  '[User Group] Save All Divisions With Numbers of Users For User Group',
  props<{
    payload: any[];
  }>()
);

// Get Department By Division ID
export const GetDepartmentByDivisionId = createAction(
  '[User Group] Get All Department By Division Id For User Group',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      divisionId?: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveDepartmentByDivisionId = createAction(
  '[User Group] Get All Saved Departments By Division Id For User Group',
  props<{
    payload: any[];
  }>()
);

export const GetRolesWithNumbersOfUsers = createAction(
  '[User Group] Get All Divisions With Numbers of Users For User Group',

  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SaveRolesWithNumbersOfUsers = createAction(
  '[User Group] Save All Divisions With Numbers of Users For User Group',

  props<{
    payload: any[];
  }>()
);

export const GetUserGroupData = createAction(
  '[User Group] Save User Group',

  props<{
    payload: any[];
  }>()
);

export const SaveUserGroup = createAction(
  '[User Group] Save User Group Successfully',
  props<{
    payload: {
      name: string;
      description?: string;
      userGroupMembers: {
        profileType: number;
        memberProfileGuid: string;
        memberProfileName: string;
      }[];
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const UpdateUserGroup = createAction(
  '[User Group] Update User Group Successfully',
  props<{
    payload: {
      name: string;
      description?: string;
      userGroupMembers: {
        profileType: number;
        memberProfileGuid: string;
        memberProfileName: string;
      }[];
      subscriberId?: number;
      userId?: string;
      guid?: string;
    };
  }>()
);

// Save success
export const SaveUserGroupSuccess = createAction(
  '[User Group] Save User Group Success',
  props<{ payload: { response: any } }>()
);

export const GetCreatedUserGroup = createAction(
  '[User Group] Get Created User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveCreatedUserGroup = createAction(
  '[User Group] Save Created User Group',
  props<{
    payload: any[];
  }>()
);

export const createdUserGroupIsLoading = createAction(
  '[User Group Table] Is Loading For User Table Summary',
  props<{
    payload: boolean;
  }>()
);

export const activateUserGroup = createAction(
  '[User Group Table] Activate User Group',
  props<{
    payload: {
      guid?: string;
      subscriberId?: number;
      userId?: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const deactivateUserGroup = createAction(
  '[User Group Table] Deactivate User Group',
  props<{
    payload: {
      guid?: string;
      subscriberId?: number;
      userId?: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const getUserGroupById = createAction(
  '[User Group Table] Get User Group By Id',
  props<{
    payload: {
      id?: string;
      subscriberId?: number;
      userId?: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveGetUserGroupById = createAction(
  '[User Group Table] Save User Group By Id',
  props<{
    payload: [];
  }>()
);

export const PassUserGroupData = createAction(
  '[User Group Table] Share User Group Data',
  props<{
    payload: null;
  }>()
);
