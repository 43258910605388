import { Action, createReducer, on } from '@ngrx/store';
import * as TwoStepVerificationActions from './two-step-verification.actions';

export interface twoStepVerificationReducerState {
  isLoading: boolean;
  userEmail: any;
  otpUrl: any;
}

export const initialState: twoStepVerificationReducerState = {
  isLoading: false,
  userEmail: null,
  otpUrl: null,
};

const twoStepVerificationReducerInternal = createReducer(
  initialState,
  on(TwoStepVerificationActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(TwoStepVerificationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(TwoStepVerificationActions.SaveOtpUrl, (state, { payload }) => ({
    ...state,
    otpUrl: payload,
  })),

  on(TwoStepVerificationActions.SaveUserByEmail, (state, { payload }) => ({
    ...state,
    userEmail: payload,
  }))
);

export function twoStepVerificationReducer(
  state: twoStepVerificationReducerState | undefined,
  action: Action
) {
  return twoStepVerificationReducerInternal(state, action);
}
