import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom, share } from 'rxjs';
import { GenericResponse } from '../../interfaces';
import * as fromApp from '../app/app.reducer';
import * as ProcessCategoryActions from './process-category.actions';
import { Notification } from '../../interfaces/notification.interface';
import { HelperService } from '../../services/helper.service';
import { NotificationService } from '../../services/notification.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ProcessCategoryEffects {
  defaultPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(ProcessCategoryActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllProcessCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessCategoryActions.GetAllProcessCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessUrl}/ProcessCategory/getallprocesscategories/${authState.user.SubscriberId}/${authState.user.UserId}/${userData.payload.skip}/${userData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                return ProcessCategoryActions.SaveProcessCategory({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Category] Get Process Category Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Process Category] Get Process Category Failed`
              );
            })
          );
      }),
      share()
    )
  );

  getProcessCategoryById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessCategoryActions.GetProcessCategoryById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocProcessUrl}/ProcessCategory/getprocesscategory/${authState.user.SubscriberId}/${authState.user.UserId}/${userData.payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  return ProcessCategoryActions.SaveProcessCategoryById({
                    payload: resData.entity,
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Process Category] Is Loading',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Category] Get Process Category By Id Failed`
                );
              })
            );
        })
      ),
    { dispatch: false }
  );

  createProcessCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessCategoryActions.CreateProcessCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocProcessUrl}/ProcessCategory/create`,
            {
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
              ...userData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ProcessCategoryActions.GetAllProcessCategory(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Category] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Process Category] Create Process Category Failed`
              );
            })
          );
      })
    )
  );

  updateProcessCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessCategoryActions.UpdateProcessCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessUrl}/ProcessCategory/update`,
            {
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
              ...userData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ProcessCategoryActions.GetAllProcessCategory(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Category] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Process Category] Update Process Category Failed`
              );
            })
          );
      })
    )
  );

  deleteProcessCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessCategoryActions.DeleteProcessCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .delete<GenericResponse>(
            `${environment.onyxDocProcessUrl}/ProcessCategory/delete`,
            {
              body: {
                ...userData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return ProcessCategoryActions.GetAllProcessCategory(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Category] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Process Category] Delete Process Category Failed`
              );
            })
          );
      })
    )
  );
}
