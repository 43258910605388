import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Release Notes] Reset Store');

export const IsLoading = createAction(
  '[Release Notes] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetching = createAction(
  '[Release Notess] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

export const GetAllReleaseNotes = createAction(
  '[Release Notes] Get All Release Notes',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
      searchValue: '';
      filterValue: [];
    };
  }>()
);

export const GetAllPublishedNotes = createAction(
  '[Release Notes] Get All Published Release Notes',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
      searchValue: '';
      filterValue: [];
    };
  }>()
);

export const GetAllHiddenNotes = createAction(
  '[Release Notes] Get All hidden Release Notes',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
      searchValue: '';
      filterValue: [];
    };
  }>()
);

export const GetAllSavedNotes = createAction(
  '[Release Notes] Get All Saved Release Notes',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
      searchValue: '';
      filterValue: [];
    };
  }>()
);

export const GetAllPublisedWebsiteReleaseNotes = createAction(
  '[Release Notes] Get All Published Website Release Notes',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: null;
      filterValue: [];
    };
  }>()
);

export const CreateReleaseNote = createAction(
  '[Release Notes] Create Release Note',
  props<{
    payload: {
      subscriberId: number;
      userId: 'string';
      text: 'string';
      title: 'string';
      noteLink: 'string';
      image: 'string';
      releaseNoteType: number;
      releaseNoteCategory: number;
    };
  }>()
);

export const UpdateReleaseNote = createAction(
  '[Release Notes] Update Release Note',
  props<{
    payload: {
      releaseNoteId: number;
      text: string;
      title: string;
      noteLink: string;
      image: string;
      releaseNoteCategory: number;
    };
  }>()
);

export const DeleteReleaseNote = createAction(
  '[Release Notes] Delete Release Note',
  props<{
    payload: {
      releaseNoteId: number;
      type: string;
    };
  }>()
);

export const GetReleaseNoteById = createAction(
  '[Release Notes] Get Release Note By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const HideAndUnhideReleaseNote = createAction(
  '[Release Notes] Hide and Unhide Release Note',
  props<{
    payload: {
      subscriberId: number;
      userId: '';
      releaseNoteId: number;
      isHidden: boolean;
    };
  }>()
);

export const HideSavedReleaseNote = createAction(
  '[Release Notes] Hide saved Release Note',
  props<{
    payload: {
      subscriberId: number;
      userId: '';
      releaseNoteId: number;
      isHidden: boolean;
    };
  }>()
);

export const SaveAllReleaseNotes = createAction(
  '[Release Notes] Save Release Notes',
  props<{
    payload: any;
  }>()
);

export const SaveAllPublishedReleaseNotes = createAction(
  '[Release Notes] Save all Published Release Notes',
  props<{
    payload: any;
  }>()
);

export const SaveAllDraftReleaseNotes = createAction(
  '[Release Notes] Save all Drafted Release Notes',
  props<{
    payload: any;
  }>()
);

export const SaveSingleReleaseNoteById = createAction(
  '[Release Notes] Save Single Release Notes By Id',
  props<{
    payload: any;
  }>()
);

export const SaveAllHiddenReleaseNotes = createAction(
  '[Release Notes] Save all Hidden Release Notes',
  props<{
    payload: any;
  }>()
);

export const SaveAllWebsiteReleaseNote = createAction(
  '[Release Notes] Save all website Release Notes',
  props<{
    payload: any;
  }>()
);
