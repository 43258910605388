import { Injectable } from '@angular/core';

import * as SigningRequestCategoryActions from './signing-request-category.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../services/helper.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class SigningRequestCategoryEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      SigningRequestCategoryActions.IsLoading({ payload: false })
    );
    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllSigningRequestCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SigningRequestCategoryActions.GetAllSigningRequestCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestCategoryData, authState]) => {
        const payload = {
          subscriberId: authState.user.SubscriberId,
          userId: authState.user.UserId,
          skip: signingRequestCategoryData.payload.skip,
          take: signingRequestCategoryData.payload.take,
          searchValue: signingRequestCategoryData.payload.searchValue ?? null,
        };

        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Category/getall/`,
            payload
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SigningRequestCategoryActions.SaveSigningRequestCategory(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  SigningRequestCategoryActions.SaveSigningRequestCategory({
                    payload: [],
                  })
                );
                return {
                  type: '[Signing Request Category] Failed to Get All Signing Request Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request Category][CatchError] Failed To Get All Signing Request Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createSigningRequestCategory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestCategoryActions.CreateSigningRequestCategory),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestCategoryData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Category/createcategory`,
              {
                ...signingRequestCategoryData.payload,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestCategoryActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData?.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.dialog.closeAll();
                  this.store.dispatch(
                    SigningRequestCategoryActions.GetAllSigningRequestCategory({
                      payload: { skip: 0, take: 0 },
                    })
                  );

                  this.store.dispatch({
                    type: `[Signing Request Category] Signing Request Category Was Created Successfully`,
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: `[Signing Request Category] Failed To Create Signing Request Category`,
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request Category][CatchError] Failed To Create Signing Request Category ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateSigningRequestCategory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestCategoryActions.UpdateSigningRequestCategory),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestCategoryData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Category/updatecategory`,
              {
                ...signingRequestCategoryData.payload,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestCategoryActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData?.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.dialog.closeAll();
                  this.store.dispatch(
                    SigningRequestCategoryActions.GetAllSigningRequestCategory({
                      payload: { skip: 0, take: 0 },
                    })
                  );

                  this.store.dispatch({
                    type: '[Signing Request Category] Signing Request Category Was Updated Successfully',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request Category] Failed To Update Signing Request Category',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request Category][CatchError] Failed To Update Signing Request Category ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteSigningRequestCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SigningRequestCategoryActions.DeleteSigningRequestCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestCategoryData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Category/deactivatecategory`,
            {
              ...signingRequestCategoryData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  SigningRequestCategoryActions.GetAllSigningRequestCategory({
                    payload: {
                      skip: 0,
                      take: 0,
                    },
                  })
                );

                this.dialog.closeAll();
                return {
                  type: '[Signing Request Category] Signing Request Category Deactivated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Signing Request Category] Failed To Deactivate Signing Request Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request Category][CatchError] Failed To Deactivate Signing Request Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSigningRequestCategoryAuditList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SigningRequestCategoryActions.GetSigningRequestCategoryAuditList),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestCategoryData, authState]) => {
        const payload = {
          subscriberId: authState.user.SubscriberId,
          ownerId: authState.user.UserId,
          categoryId: signingRequestCategoryData.payload.categoryId,
          skip: signingRequestCategoryData.payload.skip,
          take: signingRequestCategoryData.payload.take,
          searchValue: signingRequestCategoryData.payload.searchValue ?? null,
          filterValue: signingRequestCategoryData.payload.filterValue ?? [],
        };
        const categoryUrl = `/AuditTrails/getsigningrequestauditbycategoryid/`;
        const allUrl = `/AuditTrails/getallsigningrequestauditbysubscriberid/`;
        const generalUrl = `/AuditTrails/getgenerallsigningrequestauditbysubscriberid/`;
        const url =
          signingRequestCategoryData.payload.categoryId !== null
            ? categoryUrl
            : signingRequestCategoryData.payload.defaultCategory === 'All'
            ? allUrl
            : generalUrl;

        if (signingRequestCategoryData.payload.categoryId === null)
          delete payload?.categoryId;

        return this.http
          .post<GenericResponse>(`${environment.onyxCore}${url}`, payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SigningRequestCategoryActions.SaveSigningRequestCategoryAuditList(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  SigningRequestCategoryActions.SaveSigningRequestCategoryAuditList(
                    {
                      payload: [],
                    }
                  )
                );
                return {
                  type: '[Signing Request Audit] Failed to Get All Signing Request Category Audit List',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request Category][CatchError] Failed To Get All Signing Request Category Audit List ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSigningRequestAuditListDetailById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SigningRequestCategoryActions.GetSigningRequestAuditListDetailById
      ),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestCategoryData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxCore}/AuditTrails/getsigningrequestauditbyid/${authState.user.UserId}/${authState.user.SubscriberId}/${signingRequestCategoryData.payload.id}`,
            {
              // params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SigningRequestCategoryActions.SaveSigningRequestAuditListDetailById(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  SigningRequestCategoryActions.SaveSigningRequestAuditListDetailById(
                    {
                      payload: [],
                    }
                  )
                );
                return {
                  type: '[Signing Request Audit] Failed to Get Signing Request Audit List By Id details',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request Category][CatchError] Failed to Get Signing Request Audit List By Id details ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
