export enum RecipientCategoryEnum {
  Signer = 1,
  Viewer = 2,
  Approver = 3,
  NoAction = 4,
}

export enum RecipientCategoryActionEnum {
  Sign = 1,
  View = 2,
  Approve = 3,
  NoAction = 4,
}

export enum PriorityEnum {
  NonUrgent = 0,
  Normal = 1,
  Urgent = 2,
}

export enum DocumentRecipientActionEnum {
  Sign = 1,
  Reject = 2,
  NoAction = 3,
  View = 4,
  Approve = 5,
  Comment = 6,
  Recalled = 7,
}
