import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as AuditLogActions from './audit-log.action';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class AuditLogsEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(AuditLogActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllAPICallsLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuditLogActions.GetAllAPICallsLog),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapicallslog`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                AuditLogActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return AuditLogActions.SaveAPICallsLog({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  AuditLogActions.SaveAPICallsLog({
                    payload: [],
                  })
                );

                return {
                  type: '[Audit Logs] Failed To Get All API Calls Log',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Audit Logs][CatchError]  Failed To Get All API Calls Log ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAPICallsLogByApplicationStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuditLogActions.GetAPICallsLogByApplicationStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([applicationData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ApiCallsLog/getallapicallslogbyapplicationstatus/${applicationData.payload.applicationstatus}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  AuditLogActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return AuditLogActions.SaveAPICallsLog({
                    payload: resData.entity,
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch(
                    AuditLogActions.SaveAPICallsLog({
                      payload: [],
                    })
                  );

                  return this.store.dispatch({
                    type: '[Audit Logs] Failed To Get API Calls Log By Id',
                  });
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Audit Logs][CatchError] Failed To Get API Calls Log By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  getAPICallsLogBySubscriberId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuditLogActions.GetAPICallsLogBySubscriberId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ApiCallsLog/getapicallslogbysubscriberid/${authState.user.Role.SubscriberId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                AuditLogActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return AuditLogActions.SaveAPICallsLog({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  AuditLogActions.SaveAPICallsLog({
                    payload: [],
                  })
                );

                return {
                  type: '[Audit Logs] Failed To Get All API Calls Log By Subscriber Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Audit Logs][CatchError]  Failed To Get All API Calls Log By Subscriber Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
