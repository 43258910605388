import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromGeneral from './general.reducer';

const getGeneralState = (state: fromApp.AppState) => state.general;

export const getGeneralIsLoading = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.isLoading
);

export const getNotification = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.notification
);

export const getIpAddressDetails = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.ipAddressDetails
);

// export const getCountryFromIpAddress = createSelector(
//   getGeneralState,
//   (state: fromGeneral.State) => state.countryFromIpAddress
// );

export const getAllCountries = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.allCountries
);

export const getAllStates = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.allStates
);

export const getAllCities = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.allCities
);

export const getAllCountryCodes = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.allCountryCodes
);

export const getIsSubscriptionExpirationBanner = createSelector(
  getGeneralState,
  (state: fromGeneral.State) => state.isSubscriptionExpirationBanner
);
