export enum ConfirmDeviceStatusEnum {
  Pending = 1,

  Accepted = 2,

  Rejected = 3,
}

export enum TwoFAVerificationType {
  EmailAddress = 1,

  AuthenticatorApp = 2,
}
