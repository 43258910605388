<section id="setup-users">
  <div class="avatar">
    <img src="/assets/icons/avatar-group.png" alt="Avatar group " />
  </div>

  <h3 class="flwmn-sub-heading fw-700 text-center">Set up your users</h3>

  <p class="flwmn-caption mb-0 text-center">
    You have a license for a maximum of 6 users. Select the users you want to
    have access to the plan (Professional).
  </p>

  <div class="search-container">
    <div class="input-style1 input-no-error w-100">
      <mat-form-field appearance="outline" [style.width.%]="100">
        <span matPrefix class="img-fluid">
          <app-svg src="/assets/icons/search-icon.svg"></app-svg>
        </span>

        <input
          matInput
          #searchQuery
          placeholder="Search"
          (input)="onFilterUsers($event)"
        />

        <mat-error *ngIf="filteredUsers?.length === 0">
          <img
            src="/assets/icons/error.svg"
            alt=""
            class="img-fluid error-icon"
          />
          No User(s) found
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="users-count">
    <p class="flwmn-caption fw-600">2 of 14 users</p>
    <p class="flwmn-caption fw-600">Users Limit: 6</p>
  </div>

  <div class="users">
    <div
      class="d-flex justify-content-between align-items-center"
      *ngFor="let user of filteredUsers; trackBy: trackById"
    >
      <app-user-initials [user]="user"></app-user-initials>
      <mat-checkbox
        color="primary"
        (change)="$event ? onSelectUser(user, $event) : null"
        [checked]="isUserSelected(user)"
        [indeterminate]="isUserSelected(user)"
        [aria-label]="'Select User'"
      >
      </mat-checkbox>
    </div>

    <mat-error *ngIf="filteredUsers?.length === 0">
      <img src="/assets/icons/error.svg" alt="" class="img-fluid error-icon" />
      No User(s) found
    </mat-error>
  </div>

  <div class="checkbox-container">
    <div class="toggle-container">
      <mat-checkbox color="primary"> </mat-checkbox>
    </div>

    <p class="flwmn-para-1 mb-0">Assign licenses to users automatically</p>
  </div>

  <div class="cta-container">
    <button
      matRipple
      type="button"
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      (click)="closeDialog()"
    >
      Skip for later
    </button>

    <button
      matRipple
      type="button"
      class="btn flwmn-btn-primary flwmn-btn-md"
      [disabled]="(isLoading | async) !== false"
    >
      Add Users
      <ng-container *ngIf="(isLoading | async) !== false">
        <app-button-loader></app-button-loader>
      </ng-container>
    </button>
  </div>
</section>
