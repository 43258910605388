import { createAction, props } from '@ngrx/store';
import { Condition } from '../../interfaces';

export const ResetStore = createAction('[Condition] Reset Store');

export const IsLoading = createAction(
  '[Condition] Is Loading',
  props<{ payload: boolean }>()
);
export const AllowModalClick = createAction(
  '[Condition] Is Loading',
  props<{ payload: boolean }>()
);

export const GetAllCondition = createAction(
  '[Condition] Get All Condition',
  props<{ payload: { skip: number; take: number } }>()
);

export const SaveConditions = createAction(
  '[Condition] Save Conditions',
  props<{ payload: any }>()
);

export const GetConditionById = createAction(
  '[Condition] Get Condition By Id',
  props<{ payload: { id: string } }>()
);

export const SaveConditionById = createAction(
  '[Condition] Save Condition By Id',
  props<{ payload: any }>()
);

export const CreateCondition = createAction(
  '[Condition] Create Condition',
  props<{ payload: Condition }>()
);

export const UpdateCondition = createAction(
  '[Condition] Update Condition',
  props<{ payload: Condition }>()
);

export const DeleteCondition = createAction(
  '[Condition] Delete Condition ',
  props<{ payload: { guId: string } }>()
);

export const ActivateCondition = createAction(
  '[Condition] Activate Condition ',
  props<{ payload: { guId: string } }>()
);

export const DeactivateCondition = createAction(
  '[Condition] Deactivate Condition ',
  props<{ payload: { guId: string } }>()
);

export const StringifyCondition = createAction(
  '[Condition] Stringify Condition',
  props<{ payload: { id: string; conditionIndex?: number } }>()
);

export const SaveStringifyCondition = createAction(
  '[Condition] Save Stringify Condition',
  props<{ payload: { id: string; queryString: string } }>()
);

export const ResetStringifyCondition = createAction(
  '[Condition] Reset Stringify Condition',
  props<{ payload: null }>()
);
export const ResetConditionById = createAction(
  '[Condition] Reset ConditionById',
  props<{ payload: null }>()
);
