import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromAuth from './auth.reducer';

const getAuthState = (state: fromApp.AppState) => state.auth;

export const getDeveloperTokenStatus = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.developerTokenExists
);

export const getAccessTokenStatus = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.accessTokenExists
);

export const getUser = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.user
);

export const getAuthIsLoading = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.isLoading
);

export const getAuthToVerifyDevice = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.toVerifyDevice
);

export const getUserBranding = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.branding
);

export const getUserPermissions = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.permissions
);

export const getLinkedInAuthUrl = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.linkedInUrl
);

export const getDocumentBranding = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.documentBrandingData
);

// export const getSystemSetting = createSelector(
//   getAuthState,
//   (state: fromAuth.State) => state.systemSettingData
// );

export const getRolePermissions = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.rolePermissions
);

export const getWaitlistProductType = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.waitlistProductType
);

export const getUserProfileDetails = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.userProfileDetails
);

export const getAuthConfirmDeviceStatus = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.verifyDeviceStatus
);

export const getDevicesById = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.devices
);

export const getUserTwoFADetails = createSelector(
  getAuthState,
  (state: fromAuth.authReducerState) => state.email
);
