import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SigningRequestAuditActions from './signing-request-audit.actions';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericResponse } from 'src/app/@core/interfaces/index';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
// import { NotificationService } from '../../services/notification.service';
// import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../services/helper.service';
// import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class SigningRequestAuditEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    // private notificationService: NotificationService,
    // private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      SigningRequestAuditActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllSigningRequestAudit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SigningRequestAuditActions.GetAllSigningRequestAudit),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestAuditData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Category/getall/${authState.user.SubscriberId}/${authState.user.UserId}/${signingRequestAuditData.payload.skip}/${signingRequestAuditData.payload.take}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestAuditActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SigningRequestAuditActions.SaveSigningRequestAudit({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  SigningRequestAuditActions.SaveSigningRequestAudit({
                    payload: [],
                  })
                );
                return {
                  type: '[Signing Request Audit] Failed to Get All Signing Request Audit',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request Audit][CatchError] Failed To Get All Signing Request Audit ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // getAllSigningRequestAudit$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SigningRequestAuditActions.GetAllSigningRequestAudit),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([auditData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Category/getall`,
  //           {
  //             userId: authState.user.UserId,
  //             subscriberId: authState.user.SubscriberId,
  //             ...auditData.payload,
  //           },
  //           {
  //             context: new HttpContext().set(USE_ACCESS_TOKEN, true),
  //           }
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               SigningRequestAuditActions.IsLoading({ payload: false })
  //             );

  //             if (resData.succeeded === true) {
  //               this.store.dispatch({
  //                 type: '[Signing Request Audit] Get All Signing Request Audit Was Successful',
  //               });
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message,
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               this.store.dispatch({
  //                 type: '[Signing Request Audit] Failed To Get All Signing Request Audit',
  //               });
  //             }
  //             return resData;
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Signing Request Audit][CatchError] Failed To Get All Signing Request Audit,${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });

  // getAllSigningRequestAudit$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SigningRequestAuditActions.GetAllSigningRequestAudit),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([auditData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Category/getall`,
  //           {
  //             userId: authState.user.UserId,
  //             subscriberId: authState.user.SubscriberId,
  //             ...auditData.payload,
  //           },
  //           {
  //             context: new HttpContext().set(USE_ACCESS_TOKEN, true),
  //           }
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               SigningRequestAuditActions.IsLoading({ payload: false })
  //             );

  //             if (resData.succeeded === true) {
  //               this.store.dispatch({
  //                 type: '[Signing Request Audit] Get All Signing Request Audit Was Successful',
  //               });
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message,
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               this.store.dispatch({
  //                 type: '[Signing Request Audit] Failed To Get All Signing Request Audit',
  //               });
  //             }
  //             return resData;
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Signing Request Audit][CatchError] Failed To Get All Signing Request Audit,${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });

  // createSigningRequestCategory$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SigningRequestAuditActions.CreateSigningRequestCategory),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([categoryData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Category/createcategory`,
  //           {
  //             ...categoryData.payload,
  //             userId: authState.user.UserId,
  //             subscriberId: authState.user.SubscriberId,
  //           }
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               SigningRequestAuditActions.IsLoading({ payload: false })
  //             );

  //             if (resData.succeeded === true) {
  //               const notification: Notification = {
  //                 state: 'success',
  //                 message: resData?.message,
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-success'
  //               );

  //               this.dialog.closeAll();

  //               return {
  //                 type: `[Signing Request Audit] Signing Request Category Was Created Successfully`,
  //               };
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               return {
  //                 type: `[Signing Request Audit] Failed To Create Signing Request Category`,
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Signing Request Audit][CatchError] Failed To Create Signing Request Category ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });
}
