import { createReducer, on, Action } from '@ngrx/store';
import * as ProductConfigurationActions from './product-configuration.actions';

export interface State {
  isLoading: boolean;
  isFetchingProductById: boolean;
  allProducts: {
    products: any[];
    totalCount: number;
  } | null;
  subscriberActiveProducts: any | null;
  activeProducts: {
    products: any[];
    totalCount: number;
  } | null;
  activeProductsForSelectField: {
    products: any[];
    totalCount: number;
  } | null;
  inactiveProducts: {
    products: any[];
    totalCount: number;
  } | null;
  productMicroService: any[];
  activeResources: any[];
  product: any;
}

const initialState: State = {
  isLoading: false,
  isFetchingProductById: false,
  allProducts: null,
  activeProducts: null,
  activeProductsForSelectField: null,
  inactiveProducts: null,
  subscriberActiveProducts: null,
  productMicroService: [],
  activeResources: [],
  product: null,
};

const productConfigurationReducerInternal = createReducer(
  initialState,
  on(ProductConfigurationActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(ProductConfigurationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(
    ProductConfigurationActions.IsFetchingProductById,
    (state, { payload }) => ({
      ...state,
      isFetchingProductById: payload,
    })
  ),

  on(ProductConfigurationActions.SaveProducts, (state, { payload }) => ({
    ...state,
    allProducts: payload,
  })),
  on(ProductConfigurationActions.SaveActiveProducts, (state, { payload }) => ({
    ...state,
    activeProducts: payload,
  })),
  on(
    ProductConfigurationActions.SaveActiveProductsForSelectField,
    (state, { payload }) => ({
      ...state,
      activeProductsForSelectField: payload,
    })
  ),
  on(
    ProductConfigurationActions.SaveInactiveProducts,
    (state, { payload }) => ({
      ...state,
      inactiveProducts: payload,
    })
  ),
  on(
    ProductConfigurationActions.SaveSubscriberActiveProducts,
    (state, { payload }) => ({
      ...state,
      subscriberActiveProducts: payload,
    })
  ),
  on(
    ProductConfigurationActions.SaveProductMicroService,
    (state, { payload }) => ({
      ...state,
      productMicroService: payload.microService,
    })
  ),
  on(ProductConfigurationActions.SaveActiveResources, (state, { payload }) => ({
    ...state,
    activeResources: payload.resources,
  })),
  on(ProductConfigurationActions.SaveProduct, (state, { payload }) => ({
    ...state,
    product: payload.product,
  }))
);

export function productConfigurationReducer(
  state: State | undefined,
  action: Action
) {
  return productConfigurationReducerInternal(state, action);
}
