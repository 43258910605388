export const SigningRequestStatuses = [
  'Draft',
  'InProgress',
  'Completed',
  'Recalled',
  'Rejected',
] as const; //Const Assertion

export const SigningRequestBuckets = [
  'All',
  'Draft',
  'AwaitingYourAction',
  'WaitingForOthers',
  'AwaitingRecipientAction',
  'Completed',
  'Sent',
  'Recalled',
  'Rejected',
  'Received',
] as const; //Const Assertion
