import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Dashboard] Reset Store');

export const IsLoading = createAction(
  '[Dashboard] Is Loading',
  props<{
    payload: boolean;
  }>()
);

//************************ Individual Dashboard**************************************//
export const SaveDocumentDonutChartData = createAction(
  '[Dashboard] Save Document Donut Chart Dashboard',
  props<{
    payload: any;
  }>()
);

export const GetDocumentDonutChart = createAction(
  '[Dashboard] Get Document Donut Dashboard',
  props<{
    payload: {
      selectType: number;
    };
  }>()
);

export const SaveDocumentLineChartData = createAction(
  '[Dashboard] Save Document Line Chart Dashboard',
  props<{
    payload: any;
  }>()
);

export const GetDocumentLineChart = createAction(
  '[Dashboard] Get Document Line Chart Dashboard',
  props<{
    payload: {
      year: number;
    };
  }>()
);

export const SaveActivities = createAction(
  '[Dashboard] Save Activities Dashboard',
  props<{
    payload: any;
  }>()
);

// export const SaveCoopDashboard = createAction(
//   '[CoopDashboard] Save Coop Dashboard Data',
//   props<{
//     payload: any;
//   }>()
// );

export const GetActivities = createAction(
  '[Dashboard] Get Activities Dashboard',
  props<{
    payload: {
      selectType: number;
    };
  }>()
);

export const GetRecentDocuments = createAction(
  '[Dashboard] Get Recent Dashboard'
);

export const SaveRecentDocuments = createAction(
  '[Dashboard] Save Recent Documents Data',
  props<{
    payload: any;
  }>()
);

export const CreateIndividualDashboard = createAction(
  '[Dashboard] Create Individual Dashboard Returns Dashboard Data',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const SaveIndividualDashboardData = createAction(
  '[Dashboard] Save Individual Dashboard Data',
  props<{
    payload: any;
  }>()
);

//************************ Individual Dashboard**************************************//

export const SaveCoopDashboard = createAction(
  '[CoopDashboard] Save Coop Dashboard Data',
  props<{
    payload: any;
  }>()
);

export const CreateCoopDashboard = createAction(
  '[CoopDashboard] Create Coop Dashboard Returns Coop Dashboard Data',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
      dashboardType: number;
    };
  }>()
);

export const CreateDashboard = createAction(
  '[Dashboard] Create Dashboard Returns Dashboard Data',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const SaveDashboard = createAction(
  '[Dashboard] Save Dashboard Data',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestsAwaitingUserAction = createAction(
  '[Dashboard] Get Signing Requests Awaiting User Action',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetIncomingSigningRequestsByUser = createAction(
  '[Dashboard] Get Incoming Signing Requests By User',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetSigningRequestsAwaitingRecipientAction = createAction(
  '[Dashboard] Get Signing Requests Awaiting Recipient Action',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const HideSigningRequest = createAction(
  '[Dashboard] Hide Signing Requests By User',
  props<{
    payload: {
      skip: number;
      take: number;
      id: number;
      type: string;
    };
  }>()
);

export const GetSigningRequestDetails = createAction(
  '[Dashboard] Get Signing Request Details',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveSigningRequestDetails = createAction(
  '[Dashboard] Save Signing Request Details',
  props<{
    payload: any;
  }>()
);

export const GetDashboardMetric = createAction(
  '[Dashboard] Get Dashboard Metric'
);

export const SaveDashboardMetric = createAction(
  '[Dashboard] Save Dashboard Metric',
  props<{
    payload: any;
  }>()
);
