export enum EditorModeEnum {
  Internal = 'internal',
  External = 'external',
  Developer = 'developer',
  PatchProcessConfigurationForm = 'patchProcessConfigurationForm', // This needs to be reviewed and standardized
}

export enum EditorSignTypeEnum {
  Self = 'self',
  Multiple = 'multiple',
}

export enum SignTypeEnum {
  SelfSign = 1,
  SignWithOthers = 2,
}

export enum EditorRightPaneComponentTypeEnum {
  None = 'None',
  EditorControlsToolbarComponent = 'EditorControlsToolbarComponent',
  EditorControlPropertiesComponent = 'EditorControlPropertiesComponent',
  EditorRejectDocumentComponent = 'EditorRejectDocumentComponent',
  EditorControlLocatorComponent = 'EditorControlLocatorComponent',
  EditorRecipientPaneComponent = 'EditorRecipientPaneComponent',
  EditorFlowmonoAssistantComponent = 'EditorFlowmonoAssistantComponent',
  EditorReassignDocumentComponent = 'EditorReassignDocumentComponent',
  EditorAcceptAndSignWithCommentComponent = 'EditorAcceptAndSignWithCommentComponent',
}
