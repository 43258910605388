import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  fromEvent,
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-flwmn-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit {
  searchForm = new FormControl('');
  searchResults: any[] = [];

  @ViewChild('searchQuery') searchQueryElement!: ElementRef;

  @Input() placeholder: string = 'search';
  @Input() margin: string = '0';

  @Output() searchAction: EventEmitter<any> = new EventEmitter<string>();
  @Output() searchQuery: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    console;
  }

  ngAfterViewInit() {
    fromEvent(this.searchQueryElement.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(async (event: any) => {
          this.handleSubmit();
        })
      )
      .subscribe();
  }

  optionSelected({ option: { value } }: any) {
    this.searchForm.patchValue(value, { emitEvent: false });
    this.handleSubmit();
  }

  getSearchQuery(
    searchQuery: string,
    event: KeyboardEvent | any,
    clear?: boolean
  ): void {
    clear
      ? ((this.searchQueryElement.nativeElement.value = ''),
        (this.searchForm = new FormControl('')),
        (this.searchResults = []))
      : null;
    this.searchQuery.emit(searchQuery);
    const key = event.key || event.keyCode;
    if (key == 'Enter' || key == 8 || searchQuery == '') {
      this.searchAction.emit(true);
    }
  }

  preventReload(event: any) {
    event.preventDefault();
  }

  public handleSubmit(): void {}
}
