import { createReducer, on, Action } from '@ngrx/store';
import * as ProcessCategoryActions from './process-category.actions';
import { Entity, ProcessCategory } from '../../interfaces';

export interface State {
  isLoading: boolean;
  processCategories: Entity<ProcessCategory[]> | null;
  processCategory: Entity<ProcessCategory> | null;
}

const initialState: State = {
  isLoading: false,
  processCategories: null,
  processCategory: null,
};

const processCategoryReducerInternal = createReducer(
  initialState,
  on(ProcessCategoryActions.ResetStore, () => ({
    ...initialState,
  })),
  on(ProcessCategoryActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(ProcessCategoryActions.SaveProcessCategory, (state, { payload }) => ({
    ...state,
    isLoading: false,
    processCategories: payload,
  })),
  on(ProcessCategoryActions.SaveProcessCategoryById, (state, { payload }) => ({
    ...state,
    isLoading: false,
    processCategory: payload,
  }))
);

export const processCategoryReducer = (
  state: State | undefined,
  action: Action
) => {
  return processCategoryReducerInternal(state, action);
};
