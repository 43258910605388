<div id="control-menu-bar">
  <ng-container *ngIf="showActors === true && allActorDetails !== null">
    <div
      matRipple
      class="actor-menu-toggler-container"
      [matMenuTriggerFor]="allActorsMenu"
    >
      <p class="actor-menu-toggler-text">
        <ng-container
          *ngIf="selectedActor && selectedActor !== null; else noSelectedActor"
        >
          {{ selectedActor.actorFirstName }} {{ selectedActor.actorLastName }}
        </ng-container>

        <ng-template #noSelectedActor>
          Assign {{ allActorDetails.actorListName | lowercase }}
        </ng-template>
      </p>
    </div>

    <mat-menu
      #allActorsMenu="matMenu"
      class="flwmn-menu all-control-actors-menu"
    >
      <div
        class="control-actors-menu-header"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        <h6 class="flwmn-heading-6 fw-700 mb-0">
          {{ allActorDetails.actorListName }}s
        </h6>
      </div>

      <div class="all-control-actors">
        <div class="all-control-actors-scrollable">
          <ng-container
            *ngIf="
              allActorDetails.actorsData &&
                allActorDetails.actorsData !== null &&
                allActorDetails.actorsData !== undefined &&
                allActorDetails.actorsData.length > 0;
              else noActors
            "
          >
            <ng-container *ngFor="let actorData of allActorDetails.actorsData">
              <div
                matRipple
                class="control-actor"
                (click)="onSelectActor(actorData)"
              >
                <div class="control-actor-avatar">
                  {{
                    actorData?.actorFirstName + ' ' + actorData?.actorLastName
                      | nameInitials
                  }}
                </div>

                <div class="control-actor-content">
                  <p class="flwmn-para-1 control-actor-name">
                    {{ actorData?.actorFirstName }}
                    {{ actorData?.actorLastName }}
                  </p>

                  <p class="flwmn-caption control-actor-email">
                    {{ actorData?.actorEmail }}
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #noActors>
            <ng-container
              *ngIf="
                allActorDetails.actorsData === null ||
                allActorDetails.actorsData === undefined
              "
            >
              <p class="flwmn-para-1 fw-600 mb-0 px-3 pb-2">
                Loading {{ allActorDetails.actorListName | lowercase }}s...
              </p>
            </ng-container>

            <ng-container *ngIf="allActorDetails.actorsData?.length === 0">
              <p class="flwmn-para-1 fw-600 mb-0 px-3 pb-2">
                There are no signers in your
                {{ allActorDetails.actorListName | lowercase }}s' list
              </p>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </mat-menu>
  </ng-container>

  <ng-container
    *ngIf="
      control?.type === allControlTypes.text ||
      control?.type === allControlTypes.date ||
      control?.type === allControlTypes.name ||
      control?.type === allControlTypes.email ||
      control?.type === allControlTypes.jobTitle
    "
  >
    <div class="text-size-container" matTooltip="Text Size">
      <input
        #textSize
        type="number"
        [matAutocomplete]="forTextSize"
        [value]="control.additionalStyles?.['font-size']"
        (input)="onSetTextSize(textSize.value)"
      />

      <mat-autocomplete
        class="flwmn-text-control-autocomplete"
        #forTextSize="matAutocomplete"
        (optionSelected)="onSetTextSize($event.option.value)"
      >
        <ng-container *ngIf="allTextSizes !== null">
          <mat-option *ngFor="let textSize of allTextSizes" [value]="textSize">
            {{ textSize }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </div>

    <div class="divider">&nbsp;</div>

    <div class="text-weight-container" matTooltip="Text Weight">
      <mat-select
        panelClass="flwmn-text-control-select"
        [value]="control.additionalStyles?.['font-weight']"
        (selectionChange)="onSetTextWeight($event)"
      >
        <ng-container *ngIf="allTextWeights !== null">
          <mat-option
            *ngFor="let textWeight of allTextWeights"
            [value]="textWeight?.value"
          >
            {{ textWeight?.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>

    <div class="divider">&nbsp;</div>

    <div
      matRipple
      class="text-align-container"
      matTooltip="Text Align"
      (click)="onSetTextAlign(textAlign)"
    >
      <ng-container *ngIf="textAlign === 'start'">
        <app-svg
          src="assets/icons/editor/control-menu-bar/text-align-start.svg"
        ></app-svg>
      </ng-container>

      <ng-container *ngIf="textAlign === 'center'">
        <app-svg
          src="assets/icons/editor/control-menu-bar/text-align-center.svg"
        ></app-svg>
      </ng-container>

      <ng-container *ngIf="textAlign === 'end'">
        <app-svg
          src="assets/icons/editor/control-menu-bar/text-align-end.svg"
        ></app-svg>
      </ng-container>
    </div>

    <div class="divider">&nbsp;</div>

    <div class="text-color-container" matTooltip="Text Color">
      <input
        #textColor
        type="color"
        [value]="control.additionalStyles?.['color']"
        (input)="onSetTextColor(textColor.value)"
      />
    </div>

    <div class="divider">&nbsp;</div>
  </ng-container>

  <ng-container *ngIf="currentSignature !== null">
    <div
      matRipple
      matTooltip="Manage Signature"
      class="cta-control-container"
      (click)="onEditSignatureClicked()"
    >
      <app-svg src="/assets/icons/edit.svg"></app-svg>
    </div>

    <div class="divider">&nbsp;</div>
  </ng-container>

  <ng-container *ngIf="!isInitialControl()">
    <div
      matRipple
      matTooltip="Duplicate"
      class="cta-control-container"
      (click)="onDuplicate()"
    >
      <app-svg
        src="/assets/icons/editor/control-menu-bar/duplicate.svg"
      ></app-svg>
    </div>
  </ng-container>

  <ng-container *ngIf="isInitialControl()">
    <div
      matRipple
      matTooltip="Duplicate"
      class="cta-control-container"
      matTooltipPosition="below"
      [matMenuTriggerFor]="duplicateMenuAction"
    >
      <app-svg
        src="/assets/icons/editor/control-menu-bar/duplicate.svg"
      ></app-svg>
    </div>

    <mat-menu
      #duplicateMenuAction="matMenu"
      class="flwmn-menu send-documents-to-recipients-menu"
    >
      <button mat-menu-item (click)="onDuplicate()">
        <div class="item-container">
          <!-- <div class="item-icon">
          <app-svg src="assets/icons/editor/reassign.svg"></app-svg>
        </div> -->

          <p class="item-text">Duplicate field</p>
        </div>
      </button>

      <button mat-menu-item (click)="onRepeatAllPages()">
        <div class="item-container">
          <!-- <div class="item-icon">
          <app-svg src="assets/icons/editor/reject.svg"></app-svg>
        </div> -->

          <p class="item-text">Repeat on all pages</p>
        </div>
      </button>
    </mat-menu>
  </ng-container>

  <div class="divider">&nbsp;</div>

  <div
    matRipple
    matTooltip="Remove"
    class="cta-control-container"
    (click)="onRemove()"
  >
    <app-svg src="/assets/icons/editor/control-menu-bar/remove.svg"></app-svg>
  </div>
</div>
