<div id="flwmn-search" [style.margin-bottom]="margin">
  <div>
    <form>
      <div class="input-style1 input-no-error">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <span class="icon">
            <img src="/assets/icons/search-normal.svg" alt="Search Icon" />
          </span>

          <input
            #searchQuery
            type="text"
            matInput
            [placeholder]="placeholder"
            (keyup)="getSearchQuery(searchQuery.value, $event)"
            (keydown.enter)="preventReload($event)"
          />

          <mat-autocomplete
            #auto="matAutocomplete"
            showPanel
            (optionSelected)="optionSelected($event)"
          >
          </mat-autocomplete>

          <span
            [hidden]="searchQuery.value === ''"
            class="_clear flwmn-pointer"
            (click)="getSearchQuery('', { keyCode: '' }, true)"
          >
            <img src="assets/icons/modal-close-icon.svg" alt="close" />
          </span>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
