import { ControlTypeEnum } from '../enums';

export const DefaultControlTypeStyles: any = {
  [ControlTypeEnum.text]: {
    'font-family': 'Helvetica, sans-serif',
    'font-size': '12px',
    'font-weight': '400',
    color: '#000000',
    'text-align': 'start',
    width: '187px',
    height: '24px',
    // width: '250px',
    // height: 'fit-content',
  },

  [ControlTypeEnum.signature]: {
    width: '132px',
    height: '48px',
    // width: '187px',
    // height: '36px',
  },

  [ControlTypeEnum.checkbox]: {
    width: '18px',
    height: '18px',
  },

  [ControlTypeEnum.date]: {
    'font-family': 'sans-serif',
    'font-size': '12px',
    'font-weight': '400',
    color: '#000000',
    'text-align': 'start',
    width: '120px',
    height: '24px',
  },

  [ControlTypeEnum.name]: {
    'font-family': 'Helvetica, sans-serif',
    'font-size': '12px',
    'font-weight': '400',
    color: '#000000',
    'text-align': 'start',
    width: '187px',
    height: '24px',
  },

  [ControlTypeEnum.email]: {
    'font-family': 'Helvetica, sans-serif',
    'font-size': '12px',
    'font-weight': '400',
    color: '#000000',
    'text-align': 'start',
    width: '187px',
    height: '24px',
  },

  [ControlTypeEnum.jobTitle]: {
    'font-family': 'Helvetica, sans-serif',
    'font-size': '12px',
    'font-weight': '400',
    color: '#000000',
    'text-align': 'start',
    width: '187px',
    height: '24px',
  },

  [ControlTypeEnum.initials]: {
    width: '132px',
    height: '48px',
  },
};
