import { createReducer, on, Action } from '@ngrx/store';
import * as SLAConfigActions from './sla-configuration.actions';
import { ConfigurationInterface } from '../../interfaces/sla-configuration.interface';

export interface SLAConfigurationState {
  creationStatus: boolean;
  configurations: ConfigurationInterface[];
  customConfigurations: { [key: string]: any }[];
  products: any[];
  microservices: any[];
  targetObjects: any[];
  customTargetObjects: { [key: string]: any }[];
  isLoading: boolean;
  currentConfiguration: ConfigurationInterface | null;
  currentCustomConfiguration: { [key: string]: any } | null;
}

const initialState: SLAConfigurationState = {
  creationStatus: false,
  configurations: [],
  customConfigurations: [],
  products: [],
  microservices: [],
  targetObjects: [],
  customTargetObjects: [],
  isLoading: false,
  currentConfiguration: null,
  currentCustomConfiguration: null,
};

const slaConfigurationReducerInternal = createReducer(
  initialState,
  on(SLAConfigActions.SaveAllConfigurations, (state, { payload }) => ({
    ...state,
    configurations: payload,
  })),
  on(SLAConfigActions.SaveAllCustomConfigurations, (state, { payload }) => ({
    ...state,
    customConfigurations: payload,
  })),
  on(SLAConfigActions.SaveAllProducts, (state, { payload }) => {
    return {
      ...state,
      products: [...payload],
    };
  }),
  on(SLAConfigActions.SaveMicroservices, (state, { payload }) => {
    return {
      ...state,
      microservices: [...payload],
    };
  }),
  on(SLAConfigActions.SaveTargetObjects, (state, { payload }) => {
    return {
      ...state,
      targetObjects: payload,
    };
  }),
  on(SLAConfigActions.SaveCustomTargetObjects, (state, { payload }) => {
    return {
      ...state,
      customTargetObjects: payload,
    };
  }),
  on(SLAConfigActions.ResetCustomTargetObjects, (state, { payload }) => {
    return {
      ...state,
      customTargetObjects: payload,
    };
  }),
  on(SLAConfigActions.CreationStatus, (state, { payload }) => {
    return {
      ...state,
      creationStatus: payload,
    };
  }),
  on(SLAConfigActions.IsLoading, (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    };
  }),
  on(SLAConfigActions.GetCurrentConfiguration, (state, { payload }) => ({
    ...state,
    currentConfiguration:
      state.configurations.find((config) => config.guId === payload) ?? null,
  })),
  on(SLAConfigActions.GetCurrentCustomConfiguration, (state, { payload }) => ({
    ...state,
    currentCustomConfiguration:
      state.customConfigurations.find((config) => {
        return config.guId === payload;
      }) ?? null,
  })),
  on(SLAConfigActions.ResetCurrentConfiguration, (state, { payload }) => ({
    ...state,
    currentConfiguration: payload,
  }))
);

export const slaConfigurationReducer = (
  state: SLAConfigurationState | undefined,
  action: Action
) => {
  return slaConfigurationReducerInternal(state, action);
};
