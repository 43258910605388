<div [id]="id" [formGroup]="form" class="flwmn-text-control">
  <div #inputWrapper class="flwmn-text-wrapper">
    <!-- <input
        type="text"
        placeholder="Type here"
        [formControlName]="component.name"
      /> -->

    <!-- <div
      #customTextArea
      class="custom-textarea"
      [attr.contenteditable]="actorMenu === false ? true : false"
      placeholder="Type here"
      (input)="actorMenu === false ? onInput($event) : ''"
      [ngStyle]="{ cursor: actorMenu === true ? 'not-allowed' : '' }"
    ></div> -->

    <textarea
      #textarea
      [placeholder]="getPlaceholder()"
      [ngClass]="{ 'no-text-external': editorMode === 'external' }"
      [readonly]="actorMenu === true ? true : false"
      [formControlName]="control.name"
      rows="1"
      (input)="onTextInput()"
    ></textarea>
    <!-- <textarea class="d-none" [formControlName]="control.name"></textarea> -->
  </div>
</div>
