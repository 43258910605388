import {
  SubscriptionFreeTrialPeriodFrequencyEnum,
  SubscriptionPlanTypeEnum,
} from '../enums';

export const STORAGE_SIZE_TYPE: any[] = [
  {
    name: 'MB',
    value: 1,
  },
  {
    name: 'GB',
    value: 2,
  },
  {
    name: 'TB',
    value: 3,
  },
];

export const CURRENCY = [
  { name: 'NGN', value: 1 },
  { name: 'USD', value: 2 },
];

export const SubscriptionFreeTrialPeriodFrequency = [
  {
    name: 'Days',
    value: SubscriptionFreeTrialPeriodFrequencyEnum.Days,
  },
  {
    name: 'Weeks',
    value: SubscriptionFreeTrialPeriodFrequencyEnum.Weeks,
  },
  {
    name: 'Months',
    value: SubscriptionFreeTrialPeriodFrequencyEnum.Months,
  },
  {
    name: 'Years',
    value: SubscriptionFreeTrialPeriodFrequencyEnum.Years,
  },
];

export const SubscriptionPlanType = [
  {
    name: 'Standard',
    value: SubscriptionPlanTypeEnum.Standard,
  },
  {
    name: 'Custom',
    value: SubscriptionPlanTypeEnum.Custom,
  },
];
