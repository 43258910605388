import { createAction, props } from '@ngrx/store';
import { PropNames } from 'src/app/@components/webapp/manage-conditions/manage-conditions.interface';

export const ResetStore = createAction('[Utilities] Reset Store');

export const IsLoading = createAction(
  '[Utilities] Is Loading',
  props<{ payload: boolean }>()
);

export const GetAllCurrencies = createAction('[Utilities] Get All Currencies');

export const SaveAllCurrencies = createAction(
  '[Utilities] Save All Currencies',
  props<{ payload: any[] }>()
);

export const GetTransactionRateTypes = createAction(
  '[Utilities] Get All Transaction Rate Types'
);

export const SaveTransactionRateTypes = createAction(
  '[Utilities] Save All Transaction Rate Types',
  props<{ payload: any[] }>()
);

export const GetSubscriptionTypes = createAction(
  '[Utilities] Get All Subscription Types'
);

export const SaveSubscriptionTypes = createAction(
  '[Utilities] Save All Subscription Types',
  props<{ payload: any[] }>()
);

export const GetSubscriptionFrequency = createAction(
  '[Utilities] Get All Subscription Frequency'
);

export const SaveSubscriptionFrequency = createAction(
  '[Utilities] Save All Subscription Frequency',
  props<{ payload: any[] }>()
);

export const GetStorageSizeTypes = createAction(
  '[Utilities] Get Storage Size Types'
);

export const SaveStorageSizeTypes = createAction(
  '[Utilities] Save Storage Size Types',
  props<{ payload: any[] }>()
);

// Control utilities
export const GetControlType = createAction('[Utilities] Get Control Type');

export const SaveControlType = createAction(
  '[Utilities] Save Control Type',
  props<{ payload: any[] }>()
);

export const GetBlockControl = createAction('[Utilities] Get Block Control');

export const SaveBlockControl = createAction(
  '[Utilities] Save Block Contorls',
  props<{ payload: any[] }>()
);

export const GetFieldControl = createAction('[Utilities] Get Field Contorl');

export const SaveFieldControl = createAction(
  '[Utilities] Save Field Contorls',
  props<{ payload: any[] }>()
);

export const GetControlPropertyType = createAction(
  '[Utilities] Get Control Property Type'
);

export const SaveControlPropertyType = createAction(
  '[Utilities] Save Control Property Type',
  props<{ payload: any[] }>()
);

export const GetControlPropertyValueType = createAction(
  '[Utilities] Get Control Property Value Type'
);

export const SaveControlPropertyValueType = createAction(
  '[Utilities] Save Control Property Value Type',
  props<{ payload: any[] }>()
);

export const GetParentControlProperty = createAction(
  '[Utilities] Get Parent Control Property'
);

export const SaveParentControlProperty = createAction(
  '[Utilities] Save Parent Control Property',
  props<{ payload: any[] }>()
);

// Default roles ultilities

export const GetDefaultRoles = createAction('[Utilities] Get Default Roles');

export const SaveDefaultRoles = createAction(
  '[Utilities] Save Default Roles',
  props<{ payload: any[] }>()
);

export const GetAccessLevels = createAction('[Utilities] Get Access Level');

export const SaveAccessLevels = createAction(
  '[Utilities] Save Access Levels',
  props<{ payload: any[] }>()
);

/*************************************Workflow*************************************/
export const GetWorkflowUserTypes = createAction(
  '[Utilities] Get Workflow User Types'
);

export const SaveWorkflowUserTypes = createAction(
  '[Utilities] Save Workflow User Types',
  props<{ payload: any[] }>()
);

export const GetWorkflowActions = createAction(
  '[Utilities] Get Workflow Actions'
);

export const SaveWorkflowActions = createAction(
  '[Utilities] Save Workflow Actions',
  props<{ payload: any[] }>()
);

export const GetBusinessObjects = createAction(
  '[Utilities] Get Business Objects by Target Product',
  props<{ payload: string }>()
);

export const SaveBusinessObjects = createAction(
  '[Utilities] Save Business Objects',
  props<{ payload: any[] }>()
);

export const GetBusinessObjectAttributes = createAction(
  '[Utilities] Get Business Object Attributes',
  props<{
    payload: { businessObject: string; baseUrl: string; conditionId?: string };
  }>()
);

export const SaveBusinessObjectAttributes = createAction(
  '[Utilities] Save Business Object Attributes',
  props<{ payload: any }>()
);
export const SetPropList = createAction(
  '[Utilities] Set PropList',
  props<{ payload: { [key: number]: PropNames } }>()
);
/**********************************************************************************/
