import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as APIRepositoryActions from './api-repository.action';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../services/notification.service';
import { HelperService } from '../../../services/helper.service';
import { FlwmnSheet } from 'src/app/@core/services/sheet.service';
import { SendRequestDialogComponent } from 'src/app/@components/webapp/flowz/flowz-designer/tasks/send-request/send-request-dialog/send-request-dialog.component';
// import { SendRequestDialogComponent } from 'src/app/@components/webapp/flowz/flowz-designer/tasks/send-request/send-request-dialog/send-request-dialog.component';

@Injectable()
export class APIRepositoryEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private sheet: FlwmnSheet
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(APIRepositoryActions.IsLoading({ payload: false }));
    this.store.dispatch(APIRepositoryActions.IsFetching({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAPICollections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.GetAPICollections),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/collections/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return APIRepositoryActions.SaveAPICollections({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  APIRepositoryActions.SaveAPICollections({
                    payload: null,
                  })
                );

                return {
                  type: '[API Repository] Failed To Get All External Applications',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError]  Failed To Get All External Applications ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAPICollection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(APIRepositoryActions.GetAPICollection),
        withLatestFrom(this.store.select('auth')),
        switchMap(([data, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAPIUrl}/ApiCollection/collection/${authState.user.SubscriberId}/${authState.user.UserId}/${data.payload.id}/${data.payload.objectGuid}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  APIRepositoryActions.IsFetching({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[API Repository] Get API Collection Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[API Repository] Failed To Get API Collection',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[API Repository][CatchError] Failed To Get API Collection ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  createAPICollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.CreateAPICollection),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/create`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(APIRepositoryActions.GetAPICollections());

                this.dialog.closeAll();

                return {
                  type: '[API Repository] Create API Collection Sucessfull',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Create API Collection',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Create API Collection ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateAPICollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.UpdateAPICollection),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/update`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              userId: authState.user.UserId,
              ...data.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(APIRepositoryActions.GetAPICollections());

                this.dialog.closeAll();

                return {
                  type: '[API Repository] Update API Collection Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Update API Collection',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Update API Collection ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateAPICollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.ActivateAPICollection),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/activate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.store.dispatch(
                //   APIRepositoryActions.GetAllExternalApplications()
                // );

                return {
                  type: '[API Repository] Activate API Collection Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Activate API Collection',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Activate API Collection ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateAPICollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.DeactivateAPICollection),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/deactivate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                // this.store.dispatch(
                //   APIRepositoryActions.GetAllExternalApplications()
                // );

                return {
                  type: '[API Repository] Deactivate API Collection Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Deactivate API Collection',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Deactivate API Collection ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteAPICollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.DeleteAPICollection),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/ApiCollection/delete`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(APIRepositoryActions.GetAPICollections());

                this.dialog.closeAll();

                return {
                  type: '[API Repository] Delete API Collection Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To API Collection',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To API Collection ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getEndpointsByCollectionId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.GetEndpointsByCollectionId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/${authState.user.SubscriberId}/${authState.user.UserId}/${data.payload.collectionId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                return APIRepositoryActions.SaveEndpointsByCollectionId({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  APIRepositoryActions.SaveEndpointsByCollectionId({
                    payload: null,
                  })
                );

                return {
                  type: '[API Repository] Failed To Get Endpoints',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError]  Failed To Get Endpoints ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.CreateEndpoint),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/create`,
            {
              ...data.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  APIRepositoryActions.GetEndpointsByCollectionId({
                    payload: {
                      collectionId: data.payload.endpointCollectionGuId!,
                    },
                  })
                );

                this.sheet.closeAll();

                this.sheet.open(SendRequestDialogComponent, {
                  data: data.payload.guid,
                  panelClass: ['flwmn-sheet'],
                  position: 'right',
                  width: '80%',
                });

                return {
                  type: '[API Repository]  Endpoint created successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Create Endpoint',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Create Endpoint ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(APIRepositoryActions.UpdateEndpoint),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/update`,
            {
              ...data.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  APIRepositoryActions.GetEndpointsByCollectionId({
                    payload: {
                      collectionId: data.payload.endpointCollectionGuId!,
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[API Repository] Update Endpoint Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Update Endpoint',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Update Endpoint ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.ActivateEndpoint),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/activate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.store.dispatch(
                //   APIRepositoryActions.GetAllExternalApplications()
                // );

                return {
                  type: '[API Repository] Activate Endpoint Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Activate Endpoint',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Activate Endpoint ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.DeactivateEndpoint),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/deactivate`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                // this.store.dispatch(
                //   APIRepositoryActions.GetAllExternalApplications()
                // );

                return {
                  type: '[API Repository] Deactivate Endpoint Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Deactivate Endpoint',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Deactivate Endpoint ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APIRepositoryActions.DeleteEndpoint),
      withLatestFrom(this.store.select('auth')),
      switchMap(([data, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAPIUrl}/Endpoints/delete`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...data.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                APIRepositoryActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  APIRepositoryActions.GetEndpointsByCollectionId({
                    payload: {
                      collectionId: data.payload.endpointCollectionGuId!,
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[API Repository] Delete Endpoint Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[API Repository] Failed To Endpoint',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[API Repository][CatchError] Failed To Endpoint ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
