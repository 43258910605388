import { createReducer, on, Action } from '@ngrx/store';
import * as ApplicationManagementActions from './application-management.action';

export interface State {
  isLoading: boolean;
  isFetching: boolean;
  allExternalApplications: any[] | null;
  externalApplicationsById: any;
  activeEncryptionKey: {
    appId: string;
    key: string;
    referenceNumber: string;
  } | null;
  encrytionKeyHistory: any | null;
  webhookUrl: any | null;
}

const initialState: State = {
  isLoading: false,
  isFetching: false,
  allExternalApplications: null,
  externalApplicationsById: null,
  activeEncryptionKey: null,
  encrytionKeyHistory: null,
  webhookUrl: null,
};

const applicationManagementReducerInternal = createReducer(
  initialState,
  on(ApplicationManagementActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(ApplicationManagementActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(ApplicationManagementActions.IsFetching, (state, { payload }) => ({
    ...state,
    isFetching: payload,
  })),
  on(
    ApplicationManagementActions.SaveAllExternalApplications,
    (state, { payload }) => ({
      ...state,
      allExternalApplications: payload,
    })
  ),

  on(
    ApplicationManagementActions.SaveExternalApplicationById,
    (state, { payload }) => ({
      ...state,
      externalApplicationsById: payload,
    })
  ),

  on(
    ApplicationManagementActions.SaveActiveEncryptionKey,
    (state, { payload }) => ({
      ...state,
      activeEncryptionKey: payload,
    })
  ),
  on(ApplicationManagementActions.SaveWebhookUrl, (state, { payload }) => ({
    ...state,
    webhookUrl: payload,
  })),
  on(
    ApplicationManagementActions.SaveEncryptionKeyHistory,
    (state, { payload }) => ({
      ...state,
      encrytionKeyHistory: payload,
    })
  )
);

export function applicationManagementReducer(
  state: State | undefined,
  action: Action
) {
  return applicationManagementReducerInternal(state, action);
}
