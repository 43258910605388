import { Action, createReducer, on } from '@ngrx/store';
import * as OrganizationActions from './organization.actions';

export interface organizationReducerState {
  isLoading: boolean;
  activeRoles: any[];
  collectionOfUsers: any[];

  isCreateLoading: boolean;
  // toggleLoader: boolean;
  // isTwoFactorEnabled: boolean;
  divisions: {
    divisions: any[];
    count: number;
  } | null;
  departments: {
    departments: any[];
    count: number;
  } | null;
  units: {
    units: any[];
    count: number;
  } | null;

  activeProducts: {
    [productId: string]: any;
  };
}

export const initialState: organizationReducerState = {
  isLoading: false,
  isCreateLoading: false,
  // toggleLoader: false,
  // isTwoFactorEnabled: false,
  divisions: null,
  departments: null,
  units: null,
  activeRoles: [],
  collectionOfUsers: [],
  activeProducts: {},
};

const organizationReducerInternal = createReducer(
  initialState,
  on(OrganizationActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(OrganizationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(OrganizationActions.IsCreateLoading, (state, { payload }) => ({
    ...state,
    isCreateLoading: payload,
  })),

  on(OrganizationActions.SaveAllDivisions, (state, { payload }) => ({
    ...state,
    divisions: payload,
  })),
  on(OrganizationActions.SaveAllDepartments, (state, { payload }) => ({
    ...state,
    departments: payload,
  })),
  on(OrganizationActions.SaveAllUnits, (state, { payload }) => ({
    ...state,
    units: payload,
  })),

  on(OrganizationActions.SaveActiveUserRoles, (state, { payload }) => ({
    ...state,
    activeRoles: [...payload],
  })),

  on(OrganizationActions.SavedUsersDetails, (state, { payload }) => ({
    ...state,
    collectionOfUsers: [...payload],
  })),

  on(
    OrganizationActions.SaveSubscriptionWithLicenseCountByProduct,
    (
      state,
      payload: {
        productGuId: string;
        productInfo: any;
      }
    ) => {
      const newActiveProducts = {
        ...state.activeProducts,
        [payload.productGuId]: payload.productInfo,
      };

      return {
        ...state,
        activeProducts: newActiveProducts,
      };
    }
  )
);

export function organizationReducer(
  state: organizationReducerState | undefined,
  action: Action
) {
  return organizationReducerInternal(state, action);
}

// Comments
