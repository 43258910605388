export interface DocumentItem {
  id: any;
  openState: boolean;
  loadingChildren: boolean;
  childItemsFetched: boolean;
  items: DocumentItem[];
  type: number;
  name: string;
  svgType: number;
  createdAt: Date;
  mofifiedAt: Date;
  rootFolder: string;
  status: number;
  rootFolderId?: number;
  parentFolderId?: number;
}

export interface ItemWithParent {
  item: DocumentItem;
  rootFolder?: string;
}

// key is itemId, value is itemWithParent
/**
 * {
     itemId : {
      parentId
      parentName
      item: {}
     }
 * }
 */

export type DocumentItemMap = {
  [itemId: string]: ItemWithParent;
};

export interface DocumentTreeBreadCrumb {
  id: string | null;
  name: string;
  rootFolder?: any;
  status?: any;
}

export interface FolderResponse {
  files: Folder[];
  filesCount: number;
}

export interface Folder {
  createdBy: string;
  createdByEmail: string;
  createdDate: string;
  description: string;
  deviceId: any;
  documents: Folder[];
  duplicateVersionId: number;
  fileShareDetails: any;
  folderPath: string;
  folderStatus: number;
  folderStatusDesc: string;
  folderType: number;
  id: number;
  isDuplicated: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  noOfDuplicate: number;
  parentFolderId: number;
  previousParentFolderId: number;
  recipients: any;
  rootFolderId: number;
  status: number;
  statusDesc: string;
  subscriberId: number;
  subscriberName: string;
}

export interface DocumentResponse {
  documents: Document[];
  documentsCount: number;
}

export interface Document {
  createdBy: string;
  createdDate: string;
  description: string;
  documentPages: any[];
  documentSigningUrl: string;
  documentStatus: number;
  documentStatusDesc: string;
  documentType: number;
  documentTypeDesc: string;
  email: string;
  expiryDate: string;
  extension: string;
  file: string;
  fileSharePermission: any;
  folderId: number;
  hash: string;
  isArchived: boolean;
  isDeleted: boolean;
  isDuplicated: boolean;
  isSigned: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  mimeType: string;
  name: string;
  nextActorAction: string;
  nextActorEmail: string;
  nextActorRank: number;
  recipients: any[];
  senderEmail: string;
  sharedDocumentUrl: string;
  signedDocument: string;
  status: number;
  statusDesc: string;
  userId: string;
  version: string;
}

export const AcceptedFileTypes = 'application/pdf,.doc,.docx,image/*';
