import { Pipe, PipeTransform } from '@angular/core';
import { DocumentSidebarSVG } from '../enums';

@Pipe({ name: 'documentSvg' })
export class DocumentSVGPipe implements PipeTransform {
  prefix: string = 'assets/icons/document/sidebar';

  transform(svgType: number, arg?: any): any {
    switch (true) {
      case svgType === DocumentSidebarSVG.DOCUMENT:
        return `${this.prefix}/document.svg`;

      case svgType === DocumentSidebarSVG.FOLDER:
        return `${this.prefix}/folder.svg`;

      case svgType === DocumentSidebarSVG.ARCHIVED:
        return `${this.prefix}/archived.svg`;

      case svgType === DocumentSidebarSVG.BIN:
        return `${this.prefix}/bin.svg`;

      case svgType === DocumentSidebarSVG.DRAFT:
        return `${this.prefix}/draft.svg`;

      case svgType === DocumentSidebarSVG.SENT:
        return `${this.prefix}/sent.svg`;

      case svgType === DocumentSidebarSVG.RECEIVED:
        return `${this.prefix}/received.svg`;

      case svgType === DocumentSidebarSVG.COMPLETED:
        return `${this.prefix}/completed.svg`;

      case svgType === DocumentSidebarSVG.EXPIRED:
        return `${this.prefix}/expired.svg`;

      case svgType === DocumentSidebarSVG.RECALLED:
        return `${this.prefix}/recalled.svg`;

      case svgType === DocumentSidebarSVG.INVALID:
        return `${this.prefix}/invalid.svg`;

      case svgType === DocumentSidebarSVG.SHARED:
        return `${this.prefix}/shared.svg`;

      case svgType === DocumentSidebarSVG.ORGANIZATION:
        return `${this.prefix}/organisation.svg`;

      case svgType === DocumentSidebarSVG.SECURE:
        return `${this.prefix}/lock.svg`;

      default:
        return `${this.prefix}/folder.svg`;
    }
  }
}
