import { createReducer, on, Action } from '@ngrx/store';
import * as PaymentActions from './payment.actions';

export interface State {
  isLoading: boolean;
  activePaymentChannels: any;
  paystackLink: any;
  flutterwaveLink: any;
  paymentHistory: any | null;
}

const initialState: State = {
  isLoading: false,
  activePaymentChannels: null,
  paystackLink: null,
  flutterwaveLink: null,
  paymentHistory: null,
};

const paymentReducerInternal = createReducer(
  initialState,
  on(PaymentActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(PaymentActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(PaymentActions.SaveActivePaymentChannels, (state, { payload }) => ({
    ...state,
    activePaymentChannels: payload,
  })),
  on(PaymentActions.SavePaystackLink, (state, { payload }) => ({
    ...state,
    paystackLink: payload,
  })),
  on(PaymentActions.SaveFlutterwaveLink, (state, { payload }) => ({
    ...state,
    flutterwaveLink: payload,
  })),
  on(PaymentActions.SavePaymentHistory, (state, { payload }) => ({
    ...state,
    paymentHistory: payload,
  }))
);

export function paymentReducer(state: State | undefined, action: Action) {
  return paymentReducerInternal(state, action);
}
