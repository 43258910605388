import { createReducer, on, Action } from '@ngrx/store';
import * as AuditActions from './audit.actions';

export interface State {
  auditControllerNames: any;
  auditByControllerName: any;
}

const initialState: State = {
  auditControllerNames: null,
  auditByControllerName: null,
};

const auditReducerInternal = createReducer(
  initialState,
  on(AuditActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(AuditActions.SaveAuditControllerName, (state, { payload }) => ({
    ...state,
    auditControllerNames: payload,
  })),
  on(AuditActions.SaveAuditByControllerName, (state, { payload }) => ({
    ...state,
    auditByControllerName: payload,
  }))
);

export const auditReducer = (state: State | undefined, action: Action) => {
  return auditReducerInternal(state, action);
};
