import { RoleAccessLevelEnum } from '../enums';

export const RoleAccessLevel = [
  {
    value: RoleAccessLevelEnum.SuperAdmin,
    name: 'Super Admin',
  },
  {
    value: RoleAccessLevelEnum.Admin,
    name: 'Admin',
  },
  {
    value: RoleAccessLevelEnum.PowerUser,
    name: 'Power User',
  },
  {
    value: RoleAccessLevelEnum.User,
    name: 'User',
  },
];

export const DefaultRoles = [
  {
    value: 0,
    name: 'Super Admin',
  },
  {
    value: 1,
    name: 'Admin',
  },
  {
    value: 2,
    name: 'Manager',
  },
  {
    value: 3,
    name: 'User',
  },
];
