import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpEventType,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
  distinctUntilChanged,
  tap,
  filter,
  mergeMap,
  concatMap,
} from 'rxjs/operators';
import * as DriveActions from './drive.actions';
import * as DocumentActions from 'src/app/@core/stores/document/document.actions';
import * as UserActions from '../user/user.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { convertToFormData } from '../../utils/helpers';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { of } from 'rxjs';
import { MyDriveGuid } from '../../constants/drive.constant';
import { DrivePreviewerService } from '../../services/drive-previewer.service';

@Injectable()
export class DriveEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private helperService: HelperService,
    private drivePreviewService: DrivePreviewerService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(DriveActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  private handleDriveReload(
    fileType: 'File' | 'Folder',
    guid: string,
    skip: number,
    take: number,
    currentModule: string
  ) {
    if (fileType === 'Folder') {
      switch (currentModule) {
        case 'my-drive':
          this.store.dispatch(
            DriveActions.GetFolders({
              payload: { skip: skip, take: take, parentGuid: guid },
            })
          );
          break;
        case 'recent-files':
          this.store.dispatch(
            DriveActions.GetRecentDriveFile({
              payload: { skip: skip, take: take },
            })
          );
          break;
        case 'recycle-bin':
          this.store.dispatch(
            DriveActions.GetRecycleFolders({
              payload: {
                skip: skip,
                take: take,
              },
            })
          );
          break;
        case 'shared-with-others':
          this.store.dispatch(
            DriveActions.GetSharedWithOthersFolders({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;

        case 'shared-with-me':
          this.store.dispatch(
            DriveActions.GetSharedWithMeFolders({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;

        case 'secured-folder':
          this.store.dispatch(
            DriveActions.GetSecuredFolders({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;

        default:
          this.store.dispatch(
            DriveActions.GetFolders({
              payload: { skip: skip, take: take, parentGuid: guid },
            })
          );
      }
    } else if (fileType === 'File') {
      switch (currentModule) {
        case 'my-drive':
          this.store.dispatch(
            DriveActions.GetDriveFile({
              payload: { skip: skip, take: take, folderGuid: guid },
            })
          );
          break;
        case 'recent-files':
          this.store.dispatch(
            DriveActions.GetRecentDriveFile({
              payload: { skip: skip, take: take },
            })
          );
          break;
        case 'recycle-bin':
          this.store.dispatch(
            DriveActions.GetRecycleFiles({
              payload: {
                skip: skip,
                take: take,
              },
            })
          );
          break;
        case 'shared-with-others':
          this.store.dispatch(
            DriveActions.GetSharedWithOthersFiles({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;
        case 'shared-with-me':
          this.store.dispatch(
            DriveActions.GetSharedWithMeFiles({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;

        case 'secured-folder':
          this.store.dispatch(
            DriveActions.GetSecuredDriveFile({
              payload: {
                Skip: skip,
                Take: take,
              },
            })
          );
          break;

        default:
          this.store.dispatch(
            DriveActions.GetDriveFile({
              payload: { skip: skip, take: take, folderGuid: guid },
            })
          );
      }
    }
  }

  getAllFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);

          if (driveData.payload.parentGuid) {
            params = params.set('ParentGuid', driveData.payload.parentGuid);
          }

          if (driveData.payload.search) {
            params = params.set('Search', driveData.payload.search);
          }

          if (driveData.payload.createdStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.createdStartDate
            );
          }

          if (driveData.payload.createdEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.createdEndDate
            );
          }

          if (driveData.payload.lastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.lastModifiedStartDate
            );
          }

          if (driveData.payload.lastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.lastModifiedEndDate
            );
          }

          return this.http
            .get<any>(`${environment.onyxDocDriveUrl}/Folders/allfolders`, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: params,
            })
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Folders Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Folders Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folders ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getRootFoldersTreeStructure$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetRootFoldersTreeStructure),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);
          params = params.set('ParentGuid', MyDriveGuid);

          return this.http
            .get<any>(`${environment.onyxDocDriveUrl}/Folders/allfolders`, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: params,
            })
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Root Folders Tree Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Root Folders Tree Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Root Drive Folders Tree ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getNodeFolderTreeStructure$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetNodeFoldersTreeStructure),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);
          params = params.set('ParentGuid', driveData.payload.parentGuId);

          return this.http
            .get<any>(`${environment.onyxDocDriveUrl}/Folders/allfolders`, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: params,
            })
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Folders Tree Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Folders Tree Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folders Tree ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllSecuredFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSecuredFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.FolderGuid) {
            params = params.set('FolderGuid', driveData.payload.FolderGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(`${environment.onyxDocDriveUrl}/Folders/securedfolders`, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: params,
            })
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Secured Folders Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Secured Folders Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Secured Folders ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveFolderByGuId$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFolderByGuId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/folder/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.guId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData.entity;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folder by Guid ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveFolderByGuIdForDrive$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFolderByGuIdForDrive),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/folder/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.guId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folder by Guid For Drive ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getFolderOnExternalAccessByGuId$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetFolderOnExternalAccessByGuId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/folder/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.guId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return resData.succeeded;
                }
              }),
              catchError((errorRes: any) => {
                this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folder by Guid ${errorRes.message}`
                );

                return of(false);
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getFoldersForModalSetups$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetFoldersForModalSetups),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);

          if (driveData.payload.parentGuid) {
            params = params.set('parentGuid', driveData.payload.parentGuid);
          }

          if (driveData.payload.search) {
            params = params.set('search', driveData.payload.search);
          }

          return this.http
            .get<any>(`${environment.onyxDocDriveUrl}/Folders/allfolders`, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              params: params,
            })
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folders on Modal ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getRecentFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetRecentFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/recentFolder/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Recent Folders ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getFoldersByStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetFolderByStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/foldersbystatus/${driveData.payload.status}/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folders By status ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getFoldersByFolderStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetFolderByFolderStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/foldersbystatus/${driveData.payload.folderStatus}/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folders ByFolder  status ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  createFolder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.CreateFolder),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDriveUrl}/Folders/create`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...documentData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                this.store.dispatch(DriveActions.GetDriveStorage());

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  if (documentData.triggerReload) {
                    this.handleDriveReload(
                      driveState.currentFilter,
                      driveState.currentlyOpenedFolderGuid,
                      0,
                      tempTake,
                      driveState.openedModule
                    );

                    this.dialog.closeAll();
                  } else {
                    this.store.dispatch(
                      DriveActions.GetFoldersForModalSetups({
                        payload: {
                          skip: 0,
                          take: 20,
                          parentGuid: documentData.parentId,
                        },
                      })
                    );
                  }

                  return {
                    type: '[Drive] Create Folder Was Successful',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Drive] Failed To Create Folder',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Create Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UpdateFolder),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/Folders/rename`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...documentData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.store.dispatch(
                //   DriveActions.ActionSuccess({
                //     payload: {
                //       action: documentData.payload.id,
                //       uniqueId: this.generateRandomNumbers(),
                //     },
                //   })
                // );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return {
                  type: '[Drive] Update Folder Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Update Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Update Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  /* Recycle Folder */
  activateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ActivateFolder),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/Folders/activate`,
            {
              guId: documentData.payload.guId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Document Was activated Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To activate Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To activate Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  moveFolder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.MoveFolder),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDriveUrl}/Folders/move`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                ...documentData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  /*  this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                ); */

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Document Was Moved Successfully',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Document Was Moved Successfully',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Move Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.DeleteFolder),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/Folders/delete`,
            {
              folderGuIds: documentData.payload.folderGuIds,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Folder Deleted successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                /*  this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                ); */

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Document Was Deleted Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Delete Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Delete Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  emptyRecycleBin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.EmptyRecycleBin),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/Folders/emptyrecyclebin`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Emptied recycle bin successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                /*  this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                ); */

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Emptied Recycle bin Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Empty Recycle Bin',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Empty Recycle Bin ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  duplicateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.DuplicateFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/duplicatefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );
                return {
                  type: '[Drive] Folder Was Duplicated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Duplicate Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Duplicate Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  duplicateDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.DuplicateDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/duplicatedocument`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Document Was Duplicated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Duplicate Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Duplicate Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  shareFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ShareFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/sharefolder`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Drive] Folder Was Shared Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Share Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Share Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  /* Drive User Activities */

  getUserActivities$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetUserActivity),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/UserActivity/listbysubscriber/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive User Activities ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getUserActivitiesByName$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetUserActivityListByName),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/UserActivity/listbyobjectname/${authState.user?.SubscriberId}/${authState.user?.UserId}/${driveData.payload.objectName}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by object Name Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by object Name  Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive User Activities by object Name ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getUserActivitiesByObjectId$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetUserActivityListByObjectId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/UserActivity/listbyobject/${driveData.payload.objectId}/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by object Id Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by object Id  Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive User Activities by object Id  ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getUserActivitiesByUserActivityType$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetUserActivityListByUserActivityType),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/UserActivity/listbyuseractivitytype/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.userActivityType}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by activity type Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive User Activities by activity type Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive User Activities by activity type ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  /* Drive Storage */

  getAllDriveStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetAllDriveStorage),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveStorage/allstorages/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Drive All Storage Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Drive All Storage Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive All Storage ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveStorage),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveStorage/storage/${authState.user.SubscriberId}/${authState.user.UserId}/`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsStorageLoading({ payload: true })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Drive Storage Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Drive Storage Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return null;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Storage ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  /* Drive Storage */

  shareDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ShareDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/sharedocument`,
            {
              shareDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Drive] Document Was Shared Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Share Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Share Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  moveFiles$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.MoveFiles),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Folders/movefiles`,
              {
                ...documentData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch(
                    DriveActions.ActionSuccess({
                      payload: {
                        action: documentData.payload.destinationFolderId,
                        uniqueId: this.generateRandomNumbers(),
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Files Were Moved Successfully',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Move Files',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Move Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  archiveFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ArchiveFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/archivefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Folder Was Archived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Archive Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Archive Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  archiveDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ArchiveDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/archivedocument`,
            {
              archiveDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Document Was Archived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Archive Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Archive Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unarchiveFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UnarchiveFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/unarchivefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Folder Was Unarchived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Unarchive Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Unarchive Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unarchiveDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UnarchiveDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/unarchivedocument`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Document Was Unarchived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Unarchive Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Unarchive Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getFolderContentByFolderId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.getFolderContentById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/getchildrenfiles/${documentData.payload.folderId}/${authState.user?.SubscriberId}/${authState.user?.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return DriveActions.SaveFolderContent({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DriveActions.SaveFolderContent({
                    payload: null,
                  })
                );

                return {
                  type: '[Drive] Failed To Get Folder Content By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Get Folder Content By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  uploadDocument$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UploadDocument),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          const encryptedDocumentData =
            this.helperService.AES_Encryption_Decryption(
              'encrypt',
              JSON.stringify({
                ...documentData.payload,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              })
            );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/uploaddocument`,
              {
                uploadDocumentRequest: encryptedDocumentData,
              },
              {
                reportProgress: true,
                observe: 'events',
              }
            )
            .pipe(
              map((event: any) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (event instanceof HttpResponse) {
                  if (event.body.succeeded) {
                    const notification: Notification = {
                      state: 'success',
                      message: 'Document was successfully uploaded',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    this.store.dispatch(
                      DriveActions.ActionSuccess({
                        payload: {
                          action: documentData.payload.name,
                          uniqueId: this.generateRandomNumbers(),
                        },
                      })
                    );

                    this.store.dispatch({
                      type: '[Drive] Upload Document Was Successful ',
                    });
                  } else {
                    const notification: Notification = {
                      state: 'error',
                      message: 'Failed To Upload Document. Please try again.',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );

                    this.store.dispatch({
                      type: '[Drive] Failed To Upload Document',
                    });
                  }
                }
                // if (resData.succeeded === true) {
                //   this.store.dispatch(
                //     DriveActions.ActionSuccess({ payload: 'resData.message' })
                //   );

                //   const notification: Notification = {
                //     state: 'success',
                //     message: 'Document was successfully uploaded',
                //   };

                //   this.notificationService.openSnackBar(
                //     notification,
                //     'flwmn-notification-success'
                //   );

                //   return {
                //     type: '[Drive] Upload Document Was Successful ',
                //   };
                // } else {
                //   this.store.dispatch(
                //     DriveActions.IsLoading({ payload: false })
                //   );

                //   const notification: Notification = {
                //     state: 'error',
                //     message: 'Failed To Upload Document. Please try again.',
                //   };

                //   this.notificationService.openSnackBar(
                //     notification,
                //     'flwmn-notification-error'
                //   );

                //   return {
                //     type: '[Drive] Failed To Upload Document',
                //   };
                // }

                return event;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Upload Document ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);

          if (driveData.payload.folderGuid) {
            params = params.set('FolderGuid', driveData.payload.folderGuid);
          }

          if (driveData.payload.search) {
            params = params.set('Search', driveData.payload.search);
          }

          if (driveData.payload.createdStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.createdStartDate
            );
          }

          if (driveData.payload.createdEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.createdEndDate
            );
          }

          if (driveData.payload.lastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.lastModifiedStartDate
            );
          }

          if (driveData.payload.lastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.lastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/alldrivefiles`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive Files Successful',
                  });

                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  this.store.dispatch({
                    type: '[Drive] fetch Drive Files Unsuccessful',
                  });

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSecuredDriveFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSecuredDriveFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.FolderGuid) {
            params = params.set('FolderGuid', driveData.payload.FolderGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/secureddrivefiles`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Secured Drive Files Successful',
                  });

                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] fetch Secured Drive Files Unsuccessful',
                  });

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Secured Drive Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveFileByFileGuId$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFileByFileGuId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/drivefile/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.fileGuId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] fetch Drive Files By GuId Successful',
                  });

                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  this.store.dispatch({
                    type: '[Drive] Failed To Get Drive Files By File Guid',
                  });

                  return resData.entity;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Files By File Guid${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveFileByDriveItemStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFileByDriveItemStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/drivefilesbydriveitemstatus/${driveData.payload.driveitemstatus}/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Files by drive Item status${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDriveFilesByStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveFileByStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/drivefilesbystatus/${driveData.payload.status}/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Files Status${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getRecentDriveFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetRecentDriveFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/recentfiles/${authState.user.SubscriberId}/${authState.user.UserId}/${driveData.payload.skip}/${driveData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Recent Drive Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  uploadDrives$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UploadDrives),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        concatMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/uploaddrives`,
              convertToFormData({
                ...documentData.payload,
                OwnerId: authState.user.UserId,
                ActorEmail: authState.user.Email,
                SubscriberId: authState.user.SubscriberId,
              }),
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                reportProgress: true,
                observe: 'events',
              }
            )
            .pipe(
              tap((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  const progress = Math.round(
                    (100 * event.loaded) / (event.total || 1)
                  ); // Avoid division by zero

                  this.store.dispatch(
                    DriveActions.UpdateFileUploadProgressItemProgress({
                      payload: {
                        id: documentData.metaData.id,
                        progress: progress,
                      },
                    })
                  );
                }
              }),
              filter((event) => event instanceof HttpResponse),
              map((event: any) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));
                this.store.dispatch(DriveActions.GetDriveStorage());

                if (event.body) {
                  if (event.body.succeeded === true) {
                    const tempTake = driveState.modulePagination.skip
                      ? driveState.modulePagination.skip +
                        driveState.modulePagination.take
                      : driveState.modulePagination.take;

                    this.handleDriveReload(
                      driveState.currentFilter,
                      driveState.currentlyOpenedFolderGuid,
                      0,
                      tempTake,
                      driveState.openedModule
                    );

                    this.store.dispatch({
                      type: '[Drive] Upload Drive File Was Successful',
                    });

                    this.store.dispatch(
                      DriveActions.UpdateFileUploadProgressItemUploadState({
                        payload: {
                          id: documentData.metaData.id,
                          progress: 100,
                          uploadState: 'Success',
                        },
                      })
                    );

                    return 'Success';
                  } else {
                    const notification: Notification = {
                      state: 'error',
                      message: event.body.message || event.body.messages[0],
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );

                    this.store.dispatch(
                      DriveActions.UpdateFileUploadProgressItemUploadState({
                        payload: {
                          id: documentData.metaData.id,
                          progress: 100,
                          uploadState: 'Failed',
                          message:
                            event.body.message ||
                            'Failed to upload, contact support.',
                        },
                      })
                    );

                    this.store.dispatch({
                      type: '[Drive] Failed To Upload Drive File',
                    });

                    return 'Failed';
                  }
                }
              }),
              catchError((errorRes: any) => {
                this.store.dispatch(
                  DriveActions.UpdateFileUploadProgressItemUploadState({
                    payload: {
                      id: documentData.metaData.id,
                      progress: 100,
                      uploadState: 'Failed',
                      message: 'Failed to upload file.',
                    },
                  })
                );

                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  moveDriveFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.MoveDriveFile),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/move`,
              {
                ...documentData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DriveActions.MovingDriveItem({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'File was Moved successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Moved Drive File Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Move Drive File. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed To Rename Drive File',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Move Documents File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  renameDriveFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.RenameDriveFile),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/rename`,
              {
                guId: documentData.payload.guId,
                name: documentData.payload.name,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'File was renamed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  if (driveState.currentFilter === 'File') {
                    this.store.dispatch(
                      DriveActions.GetDriveFile({
                        payload: {
                          skip: 0,
                          take: tempTake,
                          folderGuid: driveState.currentlyOpenedFolderGuid,
                        },
                      })
                    );
                  } else if (driveState.currentFilter === 'Folder') {
                    this.store.dispatch(
                      DriveActions.GetFolders({
                        payload: {
                          skip: 0,
                          take: tempTake,
                          parentGuid: driveState.currentlyOpenedFolderGuid,
                        },
                      })
                    );
                  }

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Renamed Drive File Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Rename Drive File. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed To Rename Drive File',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Renamed Documents File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  duplicateDriveFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.DuplicateDriveFile),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/DriveFile/duplicate`,
            {
              fileGuId: documentData.payload.fileGuId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                return {
                  type: '[Drive] File Was Duplicated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Duplicate DriveFile',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Duplicate Drive File ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteDriveFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.DeleteDriveFile),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/delete`,
              {
                filesGuIds: documentData.payload.filesGuIds,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'File was Deleted successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Deleted Drive File Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Delete Drive File. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed To Delete Drive File',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Delete Documents File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  recycleDriveFolder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.RecycleDriveFolders),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/Folders/recycle`,
              {
                folderGuIds: documentData.payload.folderGuIds,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Folder was recycled successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Folder Recycled Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      'Failed to Recycle Drive Folder. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed To Recycle Drive Folder',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Recycle Documents Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  recycleDriveFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.RecycleDriveFiles),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/recycle`,
              {
                filesGuIds: documentData.payload.filesGuIds,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'File was Recycled successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] File Recycled Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Recycle Drive File. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed To Recycle Drive File',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Recycle Documents File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  /* Recycle DriveFile */
  activateDriveFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ActivateDriveFile),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/DriveFile/activate`,
            {
              guId: documentData.payload.guId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'File Restored',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Document Was activated Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To activate Drive File',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To activate Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  restoreDriveFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.RestoreDriveFolder),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/Folders/restore`,
            {
              folderGuIds: documentData.payload.folderGuIds,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Folder Was Restored Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Restore Drive Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Restore Drive Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  restoreDriveFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.RestoreDriveFile),
      withLatestFrom(this.store.select('auth'), this.store.select('drive')),
      switchMap(([documentData, authState, driveState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDriveUrl}/DriveFile/restore`,
            {
              filesGuIds: documentData.payload.filesGuIds,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                const tempTake = driveState.modulePagination.skip
                  ? driveState.modulePagination.skip +
                    driveState.modulePagination.take
                  : driveState.modulePagination.take;

                this.handleDriveReload(
                  driveState.currentFilter,
                  driveState.currentlyOpenedFolderGuid,
                  0,
                  tempTake,
                  driveState.openedModule
                );

                this.dialog.closeAll();

                return { type: '[Drive] Document Was Restored Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Restore Drive File',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Restore File ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  uploadDocumentsUsingIformFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UploadDocumentsUsingIformFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAlternateDocumentUrl}/Documents/uploaddocumentsusingiformfile`,
              convertToFormData({
                ...documentData.payload,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              }),
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                reportProgress: true,
                observe: 'events',
              }
            )
            .pipe(
              map((event) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (event instanceof HttpResponse) {
                  if (event.body.succeeded) {
                    const notification: Notification = {
                      state: 'success',
                      message: 'Document was successfully uploaded',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    this.store.dispatch(
                      DriveActions.ActionSuccess({
                        payload: {
                          action: documentData.payload.destinationFolderId,
                          uniqueId: this.generateRandomNumbers(),
                        },
                      })
                    );

                    this.store.dispatch({
                      type: '[Drive] Upload Documents Using Iform File Was Successful ',
                    });
                  } else {
                    const notification: Notification = {
                      state: 'error',
                      message: 'Failed to upload document. Please try again.',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );

                    this.store.dispatch({
                      type: '[Drive] Failed To Upload Documents Using Iform File',
                    });
                  }
                }

                return event;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents Using Iform File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateDocumentName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UpdateDocumentName),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/updatedocumentname`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();
                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Update Document Name Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Update Document Name',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Update Document Name ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.DeleteDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/deletedocument`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Delete Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Delete Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Delete Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAuditParameter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetAuditParameter),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AuditTrails/getauditparameters/${authState.user.UserId}/${authState.user.SubscriberId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Drive] Audit paramter retrived successfully',
                  });

                  return resData.entity;
                } else {
                  // this.store.dispatch(
                  //   GeneralActions.SetNotification({
                  //     payload: {
                  //       isSuccess: false,
                  //       message: 'Failed to Change subscription',
                  //     },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Drive] Failed To Retrieve Audit Paramater',
                  });

                  return resData.entity;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Retrieve Audit Parameter ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.CreateFeedback),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/DocumentFeedback/createfeedback`,
            {
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return { type: '[Drive] Feedback Was Created Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Create Feedback',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Create Feedback ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getCommentOnDocumentById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.GetCommentOnDocumentById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Comments/getcommentsbydocumentid/${authState.user?.SubscriberId}/${authState.user?.UserId}/${documentData.payload.documentId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return DriveActions.SaveCommentOnDocument({
                  payload: resData.entity,
                });
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                this.store.dispatch(
                  DriveActions.SaveCommentOnDocument({
                    payload: null,
                  })
                );

                return {
                  type: '[Drive] Failed To Get Comment On Document By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Get Comment On Document By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // Address Book Actions

  createAddressBook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.CreateAddressBook),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/create`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DriveActions.IsLoading({
                  payload: false,
                })
              );

              if (resData.succeeded === true) {
                this.store.dispatch({
                  type: '[Drive] Address Book Creation Successful',
                });

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.GetAddressBookBySubscriberId()
                );

                this.router.navigate(['/app/contact']);

                return {
                  type: '[Drive] Create Address Book Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Create Address Book',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Create Address Book ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateAddressBook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UpdateAddressBook),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/update`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DriveActions.IsLoading({
                  payload: false,
                })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/contact']);

                this.store.dispatch(
                  DriveActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Drive] Update Address Book Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Update Address Book',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Update Address Book ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAddressBookBySubscriberId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DriveActions.GetAddressBookBySubscriberId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getaddressbooksbysubscriberid/${authState.user.SubscriberId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DriveActions.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    DriveActions.SaveAddressBookBySubscriberId({
                      payload: resData.entity.reverse(),
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Drive] Failed To Update Address Book',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Update Address Book ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getContactById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DriveActions.GetContactById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getcontactbyid/${authState.user.SubscriberId}/${authState.user.UserId}/${documentData.payload.id}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Contact By Id Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Failed To Get Contact By Id',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Address Book By Contact Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  updateAddressBooksStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriveActions.UpdateAddressBookStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/updateaddressbookstatus`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              id: documentData.payload.id,
              status: documentData.payload.status,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DriveActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Drive] Update Address Book Status Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Update Address Book Status',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Update Address Book Status ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllCompanyDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.GetAllCompanyDetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/getcompanydetails`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DriveActions.SaveAllCompanyDetails({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Get All Company Details',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Get All Company Details ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createDocumentPin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.CreateDocumentPin),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAuthUrl}/DocumentPin/createdocumentpin`,
              {
                subscriberId: authState.user.Role.SubscriberId,
                documentPin: documentData.payload.documentPin,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  if (documentData.payload.instance === 'normal') {
                    this.store.dispatch(
                      UserActions.IsLoading({ payload: true })
                    );

                    this.store.dispatch(
                      UserActions.GetUserById({
                        payload: {
                          id: authState.user.UserId,
                        },
                      })
                    );
                  }

                  this.store.dispatch({
                    type: '[Drive] Create Document Pin Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Create Document Pin',
                  });
                }

                return {
                  response: resData,
                  instance: documentData.payload.instance,
                  document: documentData?.additionalPayload?.document,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Create Document Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UpdateDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/DocumentPin/updatedocumentpin`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              currentDocumentPin: documentData.payload.currentDocumentPin,
              newDocumentPin: documentData.payload.newDocumentPin,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(UserActions.IsLoading({ payload: true }));

                this.store.dispatch(
                  UserActions.GetUserById({
                    payload: {
                      id: authState.user.UserId,
                    },
                  })
                );

                return {
                  type: '[Drive] Update Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Update Document Pin',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Update Document Pin ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  forgotDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ForgotDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/DocumentPin/forgotdocumentpin`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Drive] Forgot Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Forgot Document Pin Failed',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Forgot Pin FailedFailed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  resetDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.ResetDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/DocumentPin/resetdocumentpin`,
            {
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.router.navigate(['/app/account-settings/profile']);

                // this.store.dispatch(UserActions.IsLoading({ payload: true }));

                // this.store.dispatch(
                //   UserActions.GetUserById({
                //     payload: {
                //       id: authState.user.UserId,
                //     },
                //   })
                // );

                return {
                  type: '[Drive] Reset Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Reset Document Pin',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Reset Document Pin ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  verifyDocumentPin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.VerifyDocumentPin),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAuthUrl}/DocumentPin/verifydocumentpin`,
              {
                subscriberId: authState.user.SubscriberId,
                documentPin: documentData.payload.documentPin,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Verify Document Pin Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Verify Document Pin',
                  });
                }

                return resData;

                // return {
                //   response: resData,
                //   document: documentData.payload.document,
                //   nextAction: documentData.payload.nextAction,
                // };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Verify Document Pin ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  lockDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.LockDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/lockdocument`,
            {
              lockDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Drive] Lock Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Lock Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Lock Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unlockDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.UnlockDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/unlockdocument`,
            {
              unlockDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(DriveActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DriveActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Drive] Unlock Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Drive] Failed To Unlock Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Drive][CatchError] Failed To Unlock Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  generateRandomNumbers() {
    return Math.floor(Math.random());
  }

  // Document Viewer

  getMyDrive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriveActions.GetMyDrive),
      withLatestFrom(this.store.select('drive')),
      switchMap(([driveData, doc]) => {
        return this.http.get<GenericResponse>(`assets/json/my-drive.json`).pipe(
          map((resData) => {
            this.store.dispatch(
              DriveActions.IsLoading({
                payload: false,
              })
            );

            if (resData.succeeded === true) {
              this.store.dispatch(
                DriveActions.SaveDriveItems({
                  payload: resData.entity,
                })
              );

              return {
                type: '[Drive] My Drive fetched successfully',
              };
            } else {
              const notification: Notification = {
                state: 'error',
                message: resData.message || resData.messages[0],
              };

              this.notificationService.openNotification(
                notification,
                'flwmn-notification-error'
              );

              this.store.dispatch(
                DriveActions.SaveDriveItems({
                  payload: [],
                })
              );

              return {
                type: '[Drive] Failed To Get My Drive ',
              };
            }
          }),
          catchError((errorRes: any) => {
            return this.handleCatchError(
              errorRes,
              `[Drive][CatchError]  Failed To Get My Drive  ${errorRes.message}`
            );
          })
        );
      })
    );
  });

  getDriveItems$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveItems),
        withLatestFrom(this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<GenericResponse>(`assets/json/drive-item.json`)
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsFetching({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DriveActions.SaveDriveItems({
                      payload: resData.entity,
                    })
                  );

                  this.store.dispatch(
                    DriveActions.SaveDoubleClickCurrentItem({
                      payload: driveData.payload.itemId,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Get Drive Item Was Successful',
                  });
                } else {
                  this.store.dispatch(
                    DriveActions.SaveDriveItems({
                      payload: [],
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Get Drive Item',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] To Get Drive Item ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getActivities$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveActivities),
        withLatestFrom(this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<GenericResponse>(`assets/json/activities.json`)
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DriveActions.SaveDriveActivities({
                      payload: resData.entity,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Get Drive Activities Was Successful',
                  });
                } else {
                  this.store.dispatch(
                    DriveActions.SaveDriveActivities({
                      payload: [],
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Get Drive Activities',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] To Get Drive Item ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveDetails),
        withLatestFrom(this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .get<GenericResponse>(`assets/json/my-drive.json`)
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                if (resData.succeeded === true) {
                  const item = resData.entity.find(
                    (driveItem: any) =>
                      driveItem.id === driveData.payload.itemId
                  );

                  this.store.dispatch(
                    DriveActions.SaveDriveDetails({
                      payload: item,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Get Drive Details Was Successful',
                  });
                } else {
                  this.store.dispatch(
                    DriveActions.SaveDriveDetails({
                      payload: null,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Get Drive Details',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] To Get Drive Item ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  /*  createFolderAsync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DriveActions.CreateFolder),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Folders/createfolder`,
              {
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
                ...documentData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Create Folder Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Create Folder',
                  });

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Create Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  ).toPromise(); */

  /* Shared With Section */
  shareDriveFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.ShareDriveFolder),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharefolder`,
              {
                ...driveData.payload,
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.SharingLoading({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Drive] Shared Drive Folder Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Shared Drive Folder Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Shared Drive Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSharedWithMeFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSharedWithMeFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.ParentGuid) {
            params = params.set('ParentGuid', driveData.payload.ParentGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharedwithme/folders`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Me Folder  Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Me Folder Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To  Get Shared with me Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSharedWithOthersFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSharedWithOthersFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.ParentGuid) {
            params = params.set('ParentGuid', driveData.payload.ParentGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharedwithothers/folders`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Others Folder  Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Others Folder Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Shared with Others Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  shareDriveFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.ShareDriveFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharefile`,
              {
                ...driveData.payload,
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.SharingLoading({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Drive] Shared Drive File Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Shared Drive File Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Shared Drive File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSharedWithMeFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSharedWithMeFiles),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.FolderGuid) {
            params = params.set('FolderGuid', driveData.payload.FolderGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharedwithme/files`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Me Files  Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Me Files Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To  Get Shared with me Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSharedWithOthersFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetSharedWithOthersFiles),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);

          if (driveData.payload.ParentGuid) {
            params = params.set('ParentGuid', driveData.payload.ParentGuid);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          if (driveData.payload.CreatedStartDate) {
            params = params.set(
              'CreatedStartDate',
              driveData.payload.CreatedStartDate
            );
          }

          if (driveData.payload.CreatedEndDate) {
            params = params.set(
              'CreatedEndDate',
              driveData.payload.CreatedEndDate
            );
          }

          if (driveData.payload.LastModifiedStartDate) {
            params = params.set(
              'LastModifiedStartDate',
              driveData.payload.LastModifiedStartDate
            );
          }

          if (driveData.payload.LastModifiedEndDate) {
            params = params.set(
              'LastModifiedEndDate',
              driveData.payload.LastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/sharedwithothers/files`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Others Files Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Get Shared with Others Files Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Shared with Others Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  removeCollaborator$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.RemoveCollaborator),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/removecollaborator`,
              {
                ...driveData.payload,
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Remove Drive Folder/File Collaborator Successful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Remove Drive Folder/File Collaborator Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Remove Drive Folder/File Collaborator ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  removeSelfFromSharedWithMeDriveItem$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.RemoveSelfFromSharedWithMeDriveItem),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/SharedItem/removeself`,
              {
                ...driveData.payload,
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Remove self from shared Items collaborators Successful',
                  });

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] Remove self from shared Items collaborators Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Remove self from shared Items collaborators ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  recycleDriveFolders$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetRecycleFolders),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);

          if (driveData.payload.search) {
            params = params.set('search', driveData.payload.search);
          }

          if (driveData.payload.createdStartDate) {
            params = params.set(
              'createdStartDate',
              driveData.payload.createdStartDate
            );
          }

          if (driveData.payload.createdEndDate) {
            params = params.set(
              'createdEndDate',
              driveData.payload.createdEndDate
            );
          }

          if (driveData.payload.lastModifiedStartDate) {
            params = params.set(
              'lastModifiedStartDate',
              driveData.payload.lastModifiedStartDate
            );
          }

          if (driveData.payload.lastModifiedEndDate) {
            params = params.set(
              'lastModifiedEndDate',
              driveData.payload.lastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/Folders/recycledfolders`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Fetched Recycle folders Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Recycle Folders Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getRecycledDriveFiles$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetRecycleFiles),
        withLatestFrom(this.store.select('auth')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.skip);
          params = params.set('Take', driveData.payload.take);

          if (driveData.payload.search) {
            params = params.set('search', driveData.payload.search);
          }

          if (driveData.payload.createdStartDate) {
            params = params.set(
              'createdStartDate',
              driveData.payload.createdStartDate
            );
          }

          if (driveData.payload.createdEndDate) {
            params = params.set(
              'createdEndDate',
              driveData.payload.createdEndDate
            );
          }

          if (driveData.payload.lastModifiedStartDate) {
            params = params.set(
              'lastModifiedStartDate',
              driveData.payload.lastModifiedStartDate
            );
          }

          if (driveData.payload.lastModifiedEndDate) {
            params = params.set(
              'lastModifiedEndDate',
              driveData.payload.lastModifiedEndDate
            );
          }

          return this.http
            .get<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/recycleddrivefiles`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                params: params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DriveActions.IsLoadingInformation({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsScrollFetching({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsMoveModalLoadingData({ payload: false })
                );

                this.store.dispatch(
                  DriveActions.IsDataLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Drive] Fetched Recycled File Successful',
                  });
                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Drive] fetch Recycled File Unsuccessful',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  return [];
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Get Drive Files ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  secureFolder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.SecureFolder),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/Folders/secure`,
              {
                folderGuIds: documentData.payload.folderGuIds,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Folder Secured Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Secure Folder',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Secure Folder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  secureFiles$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.SecureFiles),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocDriveUrl}/DriveFile/secure`,
              {
                filesGuIds: documentData.payload.filesGuIds,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  const tempTake = driveState.modulePagination.skip
                    ? driveState.modulePagination.skip +
                      driveState.modulePagination.take
                    : driveState.modulePagination.take;

                  this.handleDriveReload(
                    driveState.currentFilter,
                    driveState.currentlyOpenedFolderGuid,
                    0,
                    tempTake,
                    driveState.openedModule
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] File Secured Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Secure File',
                  });
                }

                return true;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Secure File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSecureOtp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetSecureOtp),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/generateotp`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Generated Otp Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Generated Otp',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Generated Otp ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createSecurePin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.CreateSecurityPin),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/createsecuritypin`,
              {
                securityCode: documentData.payload.securityCode,
                securityType: documentData.payload.securityType,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.dialog.closeAll();

                  // Refresh auth Profile
                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Create Security Pin Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Create Security Pin',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Create Security Pin ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  forgotSecureFolderPass$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.ForgotSecureFolderPass),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/forgotsecuritypin`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                email: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Forgot Pass Request Successfull ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Forgot Pass Request Failed',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Forgot Pass Request Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  verifySecurePin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.VerifyOtp),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/verifysecuritypin`,
              {
                securityCode: documentData.payload.securityCode,
                securityType: documentData.payload.securityType,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Verified Pin Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Verify Pin',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Verify Pin ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  UpdateSecurityType$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UpdateSecurityType),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/updatesecuritytype`,
              {
                securityType: documentData.payload.securityType,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Updated  Security type Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Update Security type',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Update Security type ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  ResetSecurityPin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.ResetSecurityPin),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/resetsecuritypin`,
              {
                newPin: documentData.payload.newPin,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Reset Security PIN Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Reset Security PIN',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Reset Security PIN ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  /* Password -> Secure Folder */
  updateSecurityPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UpdateSecurityPassword),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/updatesecuritypassword`,
              {
                newPassword: driveData.payload.newPassword,
                currentPassword: driveData.payload.currentPassword,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Updated Security Password Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Update Security Password',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Update Security Password ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  resetSecurityPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.ResetSecurityPassword),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/resetsecuritypassword`,
              {
                token: driveData.payload.token,
                newSecurityPassword: driveData.payload.newSecurityPassword,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                email: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Auth] Get User Profile Details By Id',
                  });

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Reset Security Password Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Reset Security Password',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Reset Security Password ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  forgotSecureFolderPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.ForgotSecureFolderPassword),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Pin/forgotsecuritypassword`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                email: authState.user.Email,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: { id: authState.user.UserId },
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Forgot Security Password Request Sent Successfully ',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Forgot Security Password Request Failed',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Forgot Security Password Request Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  /* Comments */
  addDriveFileComments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.AddDriveFileComments),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocCoreUrl}/Comment/create`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                ...documentData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: resData.message || resData.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );

                  // const tempTake = driveState.modulePagination.skip
                  //   ? driveState.modulePagination.skip +
                  //     driveState.modulePagination.take
                  //   : driveState.modulePagination.take;

                  // if (documentData.triggerReload) {
                  //   this.handleDriveReload(
                  //     driveState.currentFilter,
                  //     driveState.currentlyOpenedFolderGuid,
                  //     0,
                  //     tempTake,
                  //     driveState.openedModule
                  //   );
                  // }

                  this.drivePreviewService.getCommentFromEffects([
                    resData.entity,
                  ]);

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Create Drive Comment Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Create Drive Comment',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Create Comment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  replyDriveFileComments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.ReplyDriveFileComments),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocCoreUrl}/Comment/create`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                ...documentData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DriveActions.GetDriveCommentByIdSuccess({
                      replies: [resData.entity],
                      commentGuId: documentData.payload.parentGuid!,
                    })
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Reply Drive Comment Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch(
                    DriveActions.GetDriveCommentByIdSuccess({
                      replies: [],
                      commentGuId: documentData.payload.parentGuid!,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Reply Drive Comment',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Reply Comment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  UpdateDriveFileComments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.UpdateDriveFileComments),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocCoreUrl}/Comment/edit`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                ...documentData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Update Drive Comment Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Update To Create Drive Comment',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Update Comment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteDriveItemComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.DeleteDriveItemComment),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([documentData, authState, driveState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocCoreUrl}/Comment/delete`,
              {
                subscriberId: authState.user.SubscriberId,
                ownerId: authState.user.UserId,
                commentGuid: documentData.payload.commentGuid,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                this.store.dispatch(DriveActions.GetDriveStorage());

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.entity.parentGuid
                      ? resData.message
                      : 'Thread was deleted',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Delete Drive Comment Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed To Delete Drive Comment',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed To Delete Comment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveCommentsByObject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetDriveCommentsByObject),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);
          params = params.set('ObjectGuId', driveData.payload.ObjectGuId);
          params = params.set('ObjectName', 'Drive');

          if (driveData.payload.StartTime) {
            params = params.set('StartTime', driveData.payload.StartTime);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          return this.http
            .get<any>(`${environment.onyxDocCoreUrl}/Comment/getbyobject`, {
              params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.dialog.closeAll();

                  this.drivePreviewService.getCommentFromEffects(
                    resData.entity.comments
                  );

                  this.store.dispatch({
                    type: '[Drive] Get Drive Comments By Object Successfull',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Get Drive Comments By Object',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Get Drive Comments By Object ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveCommentsByObjectLists$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetDriveCommentsByObjectLists),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);
          params = params.set('ObjectGuId', driveData.payload.ObjectGuId);
          params = params.set('ObjectName', 'Drive');

          if (driveData.payload.StartTime) {
            params = params.set('StartTime', driveData.payload.StartTime);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          return this.http
            .get<any>(`${environment.onyxDocCoreUrl}/Comment/getbyobject`, {
              params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.dialog.closeAll();

                  // this.drivePreviewService.getCommentFromEffects(
                  //   resData.entity.comments
                  // );

                  this.store.dispatch({
                    type: '[Drive] Get Drive Comments By Object Successfull',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Get Drive Comments By Object',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Get Drive Comments By Object ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveCommentById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetDriveCommentById),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        mergeMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('GuId', driveData.payload.GuId);

          if (driveData.payload.StartTime) {
            params = params.set('StartTime', driveData.payload.StartTime);
          }

          return this.http
            .get<any>(`${environment.onyxDocCoreUrl}/Comment/getbyid`, {
              params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: resData.message || resData.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Get Drive Comments By Id Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch(
                    DriveActions.GetDriveCommentByIdSuccess({
                      replies: [],
                      commentGuId: driveData.payload.GuId,
                    })
                  );

                  this.store.dispatch({
                    type: '[Drive] Failed to Get Drive Comments By Id',
                  });
                }

                return resData.succeeded;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Get Drive Comments By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveCommentByUserId$ = createEffect(
    () => {
      return this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DriveActions.GetDriveCommentByUserId),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        switchMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);
          params = params.set('ObjectGuId', driveData.payload.ObjectGuId);
          params = params.set('ObjectName', 'Drive');

          if (driveData.payload.StartTime) {
            params = params.set('StartTime', driveData.payload.StartTime);
          }

          if (driveData.payload.Search) {
            params = params.set('Search', driveData.payload.Search);
          }

          return this.http
            .get<any>(`${environment.onyxDocCoreUrl}/Comment/getbyuserId`, {
              params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message: resData.message || resData.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );

                  // this.drivePreviewService.getCommentFromEffects(
                  //   resData.entity.comments
                  // );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Get Drive Comments By User Id Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Get Drive Comments By User Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Get Drive Comments By User Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDriveCommentRepliesByParentGuid$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DriveActions.GetDriveCommentRepliesByParentGuid),
        // Prevent same action from calling simultaneously at same time.
        distinctUntilChanged((prev, curr) => {
          return prev.payload.ParentGuid === curr.payload.ParentGuid;
        }),
        withLatestFrom(this.store.select('auth'), this.store.select('drive')),
        mergeMap(([driveData, authState]) => {
          let params = new HttpParams();

          params = params.set('SubscriberId', authState.user.SubscriberId);
          params = params.set('OwnerId', authState.user.UserId);
          params = params.set('ActorEmail', authState.user.Email);
          params = params.set('Skip', driveData.payload.Skip);
          params = params.set('Take', driveData.payload.Take);
          params = params.set('ParentGuid', driveData.payload.ParentGuid);
          params = params.set('ObjectName', 'Drive');

          if (driveData.payload.StartTime) {
            params = params.set('StartTime', driveData.payload.StartTime);
          }

          return this.http
            .get<any>(`${environment.onyxDocCoreUrl}/Comment/getreplies`, {
              params: params,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(DriveActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.store.dispatch(
                    DriveActions.GetDriveCommentByIdSuccess({
                      replies: resData.entity.comments,
                      commentGuId: driveData.payload.ParentGuid,
                    })
                  );

                  this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Get Drive Comments Replies Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.dialog.closeAll();

                  this.store.dispatch({
                    type: '[Drive] Failed to Get Drive comments Replies',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Drive][CatchError] Failed to Get Drive Comments Replies ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
