import { ThirdPartyTypeEnum } from '../enums/third-party-type.enum';

export interface SignupAndSubscribePayload {
  subscription: {
    subscriberId: number;
    productId: number;
    subscriptionPlanPricingId: number;
    subscriberName: string;
    subscriptionPlanId: number;
    initialSubscriptionPlanId: number;
    renewedSubscriptionPlanId: number;
    subscriptionType: number;
    currencyId: number;
    paymentChannelId: number;
    currencyCode: string;
    noOfMonthOrYears: number;
    subscriptionFrequency: number;
    paymentPeriod: number;
    numberOfUsers: number;
    amount: number;
    isFreeTrial: boolean;
    userId: string;
    autoRenew: boolean;
  };
  subscriber: {
    name: string;
    token?: string;
    subscriberType: number;
    phoneNumber: string;
    city: string;
    state: string;
    country: string;
    latitude: string;
    longitude: string;
    companyName: string;
    contactEmail: string;
    subscriberCode: string;
    staffSize: string;
    industry: string;
    location: string;
    referrer: number;
    subscriptionPurpose: string;
    address: string;
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    jobTitle: string;
    invitedRecipients: { [key: string]: number } | null;
    themeColor: string;
    profilePicture: string;
    rcNumber: string | null;
    companyDocument: string | null;
    thirdPartyType?: ThirdPartyTypeEnum;
    allowUsersOutsideDomainToJoinOrganization: boolean;
    deviceId?: string;
    ipAddress?: string;
    deviceName?: string;
    browser?: string;
    deviceLocation?: string;
    updateSubscriber?: boolean;
    deviceType: number;
  };
  subscriberId?: number;
  userId?: string;
}

export interface StartSignUpPayload {
  subscription: {
    subscriberId: number;
    productId: number;
    subscriptionPlanPricingId: number;
    subscriberName: string;
    subscriptionPlanId: number;
    initialSubscriptionPlanId: number;
    renewedSubscriptionPlanId: number;
    subscriptionType: number;
    currencyId: number;
    paymentChannelId: number;
    currencyCode: string;
    noOfMonthOrYears: number;
    subscriptionFrequency: number;
    paymentPeriod: number;
    numberOfUsers: number;
    amount: number;
    isFreeTrial: boolean;
    userId: string;
    autoRenew: boolean;
  };
  subscriber: {
    subscriberType: number;
    contactEmail: string;
    subscriberCode: string;
    email: string;
    referrer?: number;
    thirdPartyType: number | null;
    deviceId?: string;
    ipAddress: string;
    deviceName?: string;
    browser?: string;
    deviceLocation: string;
    deviceType: number;
    updateSubscriber: boolean;
    profilePicture?: string;
    latitude?: string;
    longitude?: string;
    allowUsersOutsideDomainToJoinOrganization?: boolean;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  };
}

export interface InvitedEmail {
  email: string;
  role: number;
}

export interface InvitedUsers {
  email: string;
  roleId: number;
}

export interface AdditionalInfoPayload {
  allowUsersOutsideDomainToJoinOrganization: boolean;
  token: string;
  phoneNumber: string;
  contactEmail: string;
  address: string;
  city: string;
  state: string;
  sessionId: string;
  deviceType: number;
  name: string;
  country: string;
  latitude: string;
  longitude: string;
  referrer?: number;
  profilePicture: string;
  thirdPartyType: number | null;
  staffSize: number;
  industry?: string;
  subscriptionPurpose?: string;
  subscriberType?: number;
  firstName: string;
  jobTitle?: string;
  lastName: string;
  password: string;
  email: string;
  companyName: string;
  deviceId: string;
  ipAddress?: string;
  deviceName: string;
  browser: string;
  deviceLocation?: string;
  invitedEmails: InvitedEmail[];
  themeColor: string;
  themeColorCode: string;
  subscriberId: number;
  userId: string;
  expiresIn?: number;
  accessToken?: string;
  userToken?: string;
  refreshToken?: string;
  featuresToAdd?: string[];
  productsWorkedWith?: string[];
  flowmonoUsageType?: '0' | '1';
  isUserInvited?: boolean;
}

export enum ReferralEnum {
  SearchEngines = 1,
  SocialMedia = 2,
  Recommendations = 3,
  BlogsAndArticles = 4,
  Others = 5,
  Friends = 6,
  Quora = 7,
  BillBoard = 8,
  SoftwareReview = 9,
}

export interface SignUpWithEmailPayload {
  deviceId: string;
  ipAddress?: string;
  deviceName: string;
  browser: string;
  deviceLocation?: string;
  deviceType: number;
  contactEmail: string;
  subscriberCode: string;
  email: string;
  state: string;
  thirdPartyType?: ThirdPartyTypeEnum;
  subscriberType: number;
  subscriberAccessLevel?: number;
  subscriptionPlanId: number;
}

export interface CreateOrUpdateSubscriptionPlanPayload {
  // id: number;
  // name: string;
  // subscriptionClass: number;
  // subscriptionType: number;
  // productId: number;
  // numberOfUsers: number;
  // allowFreemium: boolean;
  // allowGracePeriod: boolean;
  // signingRequests: number;
  // period: number;
  // numberOfTemplates: number;
  // storageSize: number;
  // storageSizeType: number;
  // allowMonthlyPricing: boolean;
  // allowYearlyPricing: boolean;
  // allowFreeSigning: boolean;
  // numberOfFreeSigning: number;
  // allowFreeTrial: boolean;
  // freeTrialPeriod: number;
  // freeTrialPeriodFrequency: number;
  // allowDiscount: boolean;
  // showSubscribeButton: boolean;
  // showContactUsButton: boolean;
  // discount: number;
  // isHighlighted: boolean;
  // subscriptionPlanPricings: SubscriptionPlanPricingDTO[];
  // subscriptionPlanFeatures: SubscriptionPlanFeatureDTO[];

  id: number;
  subscriptionPlanType: number;
  subscriptionPlanTypeDesc: string;
  isDefaultPlan: boolean;
  name: string;
  productId: number;
  numberOfUsers: number;
  allowFreemium: boolean;
  allowGracePeriod: boolean;
  signingRequests: number;
  period: number;
  numberOfTemplates: number;
  storageSize: number;
  storageSizeType: number;
  allowMonthlyPricing: boolean;
  allowYearlyPricing: boolean;
  allowFreeSigning: boolean;
  numberOfFreeSigning: number;
  allowFreeTrial: boolean;
  subscriptionClass: number;
  freeTrialPeriod: number;
  freeTrialPeriodFrequency: number;
  allowDiscount: boolean;
  showSubscribeButton: boolean;
  showContactUsButton: boolean;
  discount: number;
  isHighlighted: boolean;
  subscriptionPlanPricings: SubscriptionPlanPricingDTO[];
  subscriptionPlanFeatures: SubscriptionPlanFeatureDTO[];
}

export interface SubscriptionPlanPricingDTO {
  id: number;
  currencyCode: string;
  currencyId: number;
  pricingPlanType: number;
  amount: number;
  maxNumberOfApiCalls: number;
  minNumberOfApiCalls: number;
  status: number;
}

export interface SubscriptionPlanFeatureDTO {
  id: number;
  featureId: number;
  featureName: string;
  parentFeatureId: number;
  parentFeatureName: string;
  isChecked: boolean;
  status: number;
}
export interface PricingDTO {
  currency: string;
  amount: number;
}

export enum SubscriptionType {
  Individual = 1,
  Corporate = 2,
}
export interface SubscriptionPlan {
  allowDiscount: boolean;
  allowFreeSigning: boolean;
  allowFreeTrial: boolean;
  allowMonthlyPricing: boolean;
  allowYearlyPricing: boolean;
  allowGracePeriod: boolean;
  createdBy: string;
  createdDate: string;
  discount: number;
  freeTrialDays: number;
  freeTrialPeriod: number;
  freeTrialPeriodFrequency: number;
  id: number;
  isDefaultPlan: boolean;
  isHighlighted: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  numberOfFreeSigning: number;
  gracePeriod: number;
  numberOfTemplates: number;
  numberOfUsers: number;
  period: number;
  showContactUsButton: boolean;
  showSubscribeButton: boolean;
  status: number;
  statusDesc: string;
  storageSize: number;
  storageSizeType: number;
  storageSizeTypeDesc: string;
  subscriberId: number;
  subscriptionClass: number;
  subscriptionClassDesc: string;
  subscriptionPlanFeatures: SubscriptionPlanFeatureResponse[];
  subscriptionPlanPricings: SubscriptionPlanPricingResponse[];
  subscriptionPlanType: number;
  subscriptionPlanTypeDesc: string;
  subscriptionType: number;
  subscriptionTypeDesc: string;
  userId: string;
}

export interface SubscriptionPlanFeatureResponse {
  allowFreeTrial: boolean;
  createdBy: string;
  createdDate: string;
  featureId: number;
  featureName: string;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  parentFeatureId: number;
  parentFeatureName: string;
  requiresSubscription: boolean;
  status: number;
  statusDesc: string;
  subscriptionPlanId: number;
}

export interface SubscriptionPlanPricingResponse {
  amount: number;
  createdBy: string;
  createdDate: string;
  currencyCode: string;
  currencyId: number;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  numberOfApiCalls: number;
  pricingPlanType: number;
  pricingPlanTypeDesc: string;
  status: number;
  statusDesc: string;
  subscriberId: number;
  subscriberName: string;
  subscriptionPlanId: number;
  vatPercentage: number;
}

export interface SubscriptionPlanRequestDTO {
  name: string;
  subscriptionClass: number;
  subscriptionType: number;
  numberOfUsers: number;
  numberOfTemplates: number;
  storageSize: number;
  storageSizeType: number;
  allowMonthlyPricing: boolean;
  allowYearlyPricing: boolean;
  allowFreeSigning: boolean;
  numberOfFreeSigning: number;
  allowFreemium: boolean;
  allowGracePeriod: boolean;
  signingRequests: number;
  period: number;
  allowFreeTrial: boolean;
  freeTrialDays: number;
  isHighlighted: boolean;
  allowDiscount: boolean;
  showSubscribeButton: boolean;
  showContactUsButton: boolean;
  discount: number;
  subscriptionPlanPricings: SubscriptionPlanPricingDTO[];
  subscriptionPlanFeatures: SubscriptionPlanFeatureDTO[];
  subscriberId: number;
  userId: string;
}

export interface CreateOrUpdateSubscriptionDTO {
  systemSettingId: number;
  subscriptionPlanId: number;
  subscriptionPlanPricingId: number;
  initialSubscriptionPlanId: number;
  renewedSubscriptionPlanId: number;
  subscriptionType: number;
  // currencyId: number;
  productId: number;
  paymentChannelId: number;
  currencyCode: string;
  subscriptionFrequency: number;
  noOfMonthOrYears: number;
  paymentPeriod: number;
  numberOfUsers: number;
  amount: number;
  isFreeTrial: boolean;
  userLicenses: UserLicense[];
  subscriberId?: number;
  userId?: string;
  skip?: number;
  take?: number;
}

export interface ChangeSubscriptionDTO {
  id: number; // Id of the subscription you want to change
  systemSettingId: number;
  subscriptionPlanId: number;
  currencyId: number;
  currencyCode: string;
  subscriptionPlanPricingId: number;
  paymentChannelId: number;
  subscriptionFrequency: number;
  numberOfUsers: number;
  amount: number;
  numberOfMonthsOrYears: number;
  subscriberId: number;
  userId: string;
  skip: number;
  take: number;
}

export interface CreateOrUpdateUserLicenses {
  subscriptionId: number;
  userLicenses: UserLicense[];
  subscriberId: number;
  userId: string;
  skip: number;
  take: number;
}

export interface UserLicense {
  subscriptionId: number;
  licenseUserId: string;
  isDeleted: boolean;
  id: number;
  name: string;
  description: string;
  subscriberId: number;
  userId: string;
  skip: number;
  take: number;
}

export interface SubscriptionResponse {
  amount: number;
  autoRenew: boolean;
  createdBy: string;
  createdByEmail: string;
  createdDate: string;
  currencyCode: string;
  currencyId: number;
  endDate: string;
  freeTrialActivated: boolean;
  hasPendingActivationSubscription: boolean;
  id: number;
  initialSubscriptionPlanId: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  noOfApiCallsBought: number;
  noOfApiCallsRemaining: number;
  noOfApiCallsUsed: number;
  noOfMonthOrYears: number;
  numberOfUsers: number;
  paymentChannelDesc: string;
  paymentChannelId: number;
  paymentChannelReference: any;
  paymentChannelResponse: any;
  paymentChannelStatus: any;
  paymentStatus: number;
  paymentStatusDesc: string;
  product: any;
  productId: any;
  renewedSubscriptionPlanId: any;
  startDate: string;
  status: 2;
  statusDesc: string;
  subscriberId: number;
  subscriberName: string;
  subscriptionFrequency: number;
  subscriptionFrequencyDesc: string;
  subscriptionNo: string;
  subscriptionPlan: SubscriptionPlan;
  subscriptionPlanId: number;
  subscriptionPlanPricing: SubscriptionPlanPricingResponse;
  subscriptionPlanPricingId: number;
  subscriptionStatus: number;
  subscriptionStatusDesc: string;
  subscriptionType: number;
  subscriptionTypeDesc: string;
  transactionReference: any;
  userId: string;
  userLicenses: UserLicense[];
}

export const SubscriptionStatuses = [
  { name: 'All', id: 0 },
  { name: 'Free Trial', id: 1 },
  { name: 'ProcessingPayment', id: 2 },
  { name: 'Active', id: 3 },
  { name: 'Expired', id: 4 },
  { name: 'Cancelled', id: 5 },
  { name: 'PendingActivations', id: 6 },
];

export enum Currency {
  Naira = 'NGN',
  Dollar = 'USD',
}
