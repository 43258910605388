import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as UserLicenseActions from '../user-license/user-license.actions';
import * as SubscriptionActions from 'src/app/@core/stores/subscription/subscription.actions';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { SubscriptionStatusEnum } from '../../enums';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class UserLicenseEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(UserLicenseActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  createUserLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserLicenseActions.CreateUserLicenses),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        const userLicenses = susbscriptionData.payload.userLicenses;
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/UserLicenses/createuserlicenses`,
            {
              ...susbscriptionData.payload,
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
              userLicenses: [
                {
                  ...userLicenses[0],
                  subscriberId: authState.user.Role.SubscriberId,
                  userId: authState.user.UserId,
                },
              ],
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                UserLicenseActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                // this.store.dispatch(UserLicenseActions.GetAllUserLicenses());

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  SubscriptionActions.GetActiveSubscriptionsByProduct({
                    payload: {
                      productId: susbscriptionData?.payload?.activeProductId!,
                      subscriptionStatus: SubscriptionStatusEnum.Active,
                      skip: 0,
                      take: 100,
                    },
                  })
                );

                return {
                  type: '[User License] Create User License Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User License] Failed To Create User License',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User License][CatchError] Failed To Create User License ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  // updateUserLicense$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserLicenseActions.UpdateSubscripitonPlan),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([susbscriptionData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocSubscriptionUrl}/UserLicenses/update`,
  //           {
  //             subscriberId: authState.user.Role.SubscriberId,
  //             ...susbscriptionData.payload,
  //             userId: authState.user.UserId,
  //           }
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               UserLicenseActions.IsLoading({ payload: false })
  //             );

  //             if (resData.succeeded) {
  //               this.store.dispatch(
  //                 UserLicenseActions.IsLoading({ payload: true })
  //               );

  //               this.store.dispatch(
  //                 UserLicenseActions.GetAllUserLicenses()
  //               );

  //               this.router.navigate([
  //                 '/app/account-settings/subscription-plan-configuration',
  //               ]);
  //               return {
  //                 type: '[User License] Update User License Was Successful',
  //               };
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openSnackBar(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               return {
  //                 type: '[User License] Failed To Update User License',
  //               };
  //             }
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[User License][CatchError] Failed To Update User License ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   )
  // );

  deleteUserLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserLicenseActions.DeleteUserLicense),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/UserLicenses/deleteuserlicense`,
            {
              ...susbscriptionData.payload,
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                UserLicenseActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.store.dispatch(UserLicenseActions.GetAllUserLicenses());

                location.reload();

                // this.store.dispatch(
                //   SubscriptionActions.GetActiveSubscriptionsByProduct({
                //     payload: {
                //       productId: susbscriptionData?.payload?.activeProductId!,
                //       subscriptionStatus: SubscriptionStatusEnum.All,
                //       skip: 0,
                //       take: 0,
                //     },
                //   })
                // );

                return {
                  type: '[User License] Delete License Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User License] Failed To Delete License',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User License][CatchError] Failed To Delete License ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createBulkUserLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserLicenseActions.CreateBulkUserLicense),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocProductUrl}/UserLicenses/createbulkuserlicenses`,
            {
              ...subscriptionData.payload,
              ownerId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserLicenseActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                location.reload();

                return {
                  type: '[User License] Create  Bulk User License Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User License] Create  Bulk User License Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User License][CatchError] Failed To Create Bulk User License ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
