import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, Subscription, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaUpdateService implements OnDestroy {
  appUpdateIsAvailable: boolean = false;
  hasUserGivenUpdateConsent = new BehaviorSubject<boolean>(false);

  private subscription: Subscription = new Subscription();

  constructor() {}

  checkForAppUpdates(swUpdate: SwUpdate): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (swUpdate.isEnabled) {
        swUpdate.checkForUpdate();

        this.subscription.add(
          swUpdate.versionUpdates
            .pipe(
              filter(
                (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
              ),
              map((evt) => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion,
              }))
            )
            .subscribe(() => {
              if (!location.pathname.includes('/app/')) {
                this.updateApplication(swUpdate);
              } else {
                this.appUpdateIsAvailable = true;

                this.subscription.add(
                  this.hasUserGivenUpdateConsent.subscribe((response) => {
                    if (response === true) {
                      this.updateApplication(swUpdate);
                    }
                  })
                );
              }
            })
        );
      }

      resolve();
    });
  }

  updateApplication(swUpdate: SwUpdate) {
    swUpdate.activateUpdate().then(() => {
      location.reload();

      this.appUpdateIsAvailable = false;
      this.hasUserGivenUpdateConsent.next(false);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
