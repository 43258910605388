import { createReducer, on, Action } from '@ngrx/store';
import * as FeaturesActions from './feature.actions';

export interface State {
  isLoading: boolean;
  allFeatures: any[] | null;
}

const initialState: State = {
  isLoading: false,
  allFeatures: null,
};

const featuresReducerInternal = createReducer(
  initialState,
  on(FeaturesActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(FeaturesActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(FeaturesActions.SaveAllFeatures, (state, { payload }) => ({
    ...state,
    allFeatures: payload,
  }))
);

export function featuresReducer(state: State | undefined, action: Action) {
  return featuresReducerInternal(state, action);
}
