import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from 'src/app/@core/services/helper.service';
import { ThemeService } from 'src/app/@core/services/theme.service';

declare let IN: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  isLoading!: Observable<boolean>;
  emailControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.email,
  ]);
  year!: number;
  routerEvents: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    public dialog: MatDialog,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.year = new Date().getFullYear();
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.forgotPassword.bind(this)
    );
  }

  forgotPassword() {
    this.store.dispatch(
      AuthActions.ForgotPassword({
        payload: {
          email: this.emailControl.value,
        },
      })
    );
  }
}
