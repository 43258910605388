import { createAction, props } from '@ngrx/store';
import { CreateOrUpdateSubscriptionDTO } from '../../interfaces/subscription.interface';

export const ResetStore = createAction('[Subscription] Reset Store');

export const IsLoading = createAction(
  '[Subscription] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetching = createAction(
  '[Subscription] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

export const CreateSubscription = createAction(
  '[Subscription] Create Subscription',
  props<{
    payload: CreateOrUpdateSubscriptionDTO;
  }>()
);

export const UpdateSubscripiton = createAction(
  '[Subscription] Update Subscription',
  props<{
    payload: any;
  }>()
);

export const ChangeSubscription = createAction(
  '[Subscription] Change Subscription',
  props<{
    payload: {
      id: number;
      systemSettingId: number;
      subscriptionPlanId: number;
      currencyId: number;
      currencyCode: string;
      subscriptionPlanPricingId: number;
      paymentChannelId: number;
      subscriptionFrequency: number;
      numberOfUsers: number;
      amount: number;
      numberOfMonthsOrYears: number;
    };
  }>()
);

export const RenewSubscription = createAction(
  '[Subscription] Renew Subscription',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const CancelSubscription = createAction(
  '[Subscription] Cancel Subscription',
  props<{
    payload: {
      id: number;
      systemSettingId: number;
    };
  }>()
);

export const ActivateSubscription = createAction(
  '[Subscription] Activate A Subscription',
  props<{
    payload: {
      id: number;
      subscriptionPlanId: number;
    };
  }>()
);

export const DeactivateSubscription = createAction(
  '[Subscription] Deactivate A Subscription',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetSubscriptionById = createAction(
  '[Subscription] Get Subscription By ID',
  props<{
    payload: {
      id: number;
      isSubscriberNewlyCreated?: boolean;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveSubscriptionById = createAction(
  '[Subscription] Save Subscription By ID',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionEndDateById = createAction(
  '[Subscription] Get Subscription End Date By ID',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveSubscriptionEndDateById = createAction(
  '[Subscription] Save Subscription End Date By ID',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionsByPlan = createAction(
  '[Subscription] Get Subscription By Plan',
  props<{
    payload: {
      subscriptionplanid: number;
    };
  }>()
);

export const SaveSubscriptionsByPlan = createAction(
  '[Subscription] Save Subscription By Plan',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriptionsByPlan = createAction(
  '[Subscription] Get Active Subscription By Plan',
  props<{
    payload: {
      subscriptionplanid: number;
    };
  }>()
);

export const SaveActiveSubscriptionsByPlan = createAction(
  '[Subscription] Save Active Subscription By Plan',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptions = createAction(
  '[Subscription] Get Subscriptions'
);

export const SaveSubscriptions = createAction(
  '[Subscription] Save Subscriptions',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriptions = createAction(
  '[Subscription] Get Active Subscriptions'
);

export const SaveActiveSubscriptions = createAction(
  '[Subscription] Save Active Subscriptions',
  props<{
    payload: any;
  }>()
);

export const GetAllActiveSubscriptions = createAction(
  '[Subscription] Get All Active Subscriptions'
);

export const SaveAllActiveSubscriptions = createAction(
  '[Subscription] Save All Active Subscriptions',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionStatus = createAction(
  '[Subscription] Get Subscription Status'
);

export const SaveSubscriptionsStatus = createAction(
  '[Subscription] Save Subscriptions Status',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriptionsBySubscriberId = createAction(
  '[Subscription] Get Active Subscription By Subscriber Id'
);

export const SaveActiveSubscriptionsBySubscriberId = createAction(
  '[Subscription] Save Active Subscription By Subscriber Id',
  props<{
    payload: any;
  }>()
);

export const GetAllSubscriptions = createAction(
  '[Subscription] Get All Subscriptions'
);

export const SaveAllSubscriptions = createAction(
  '[Subscription] Save All Subscriptions',
  props<{
    payload: any;
  }>()
);

export const SaveSubscriptionsByProduct = createAction(
  '[Subscription] Save Subscriptions By Product',
  props<{
    payload: any;
  }>()
);

export const SaveActiveSubscriptionsByProduct = createAction(
  '[Subscription] Save Active Subscriptions By Product',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionsByProduct = createAction(
  '[Subscription] Get Subscription By Product',
  props<{
    payload: {
      productId: number;
    };
  }>()
);

export const GetActiveSubscriptionsByProduct = createAction(
  '[Subscription] Get Active Subscription By Product',
  props<{
    payload: {
      productId: number;
      subscriptionStatus: number;
      skip: number;
      take: number;
    };
  }>()
);

export const AddCard = createAction(
  '[Card] Add Card',
  props<{
    payload?: {
      currencyCode: number;
    };
  }>()
);

export const GetCards = createAction('[Card] Get Card');

export const SaveCard = createAction(
  '[Document] Save Card',
  props<{
    payload: any;
  }>()
);
