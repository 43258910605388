import { Action, createReducer, on } from '@ngrx/store';
import * as UtilitiesActions from './utilities.actions';
import { BusinessObjectAttribute } from '../../interfaces/business-object.interface';
import { PropNames } from 'src/app/@components/webapp/manage-conditions/manage-conditions.interface';

export interface State {
  isLoading: boolean;
  currencies: any;
  transactionRateTypes: any;
  subscriptionTypes: any;
  allStorageSizeTypes: any;
  controlTypes: any;
  blockControls: any;
  fieldControls: any;
  controlPropertyTypes: any;
  controlPropertyValueType: any;
  defaultRoles: any;
  accessLevels: any;
  workflowUserTypes: any;
  workflowActions: any;
  businessObjects: string[] | null;
  businessObjectAttributes: {
    index: number;
    data: BusinessObjectAttribute[];
  } | null;
  propertyList: { [key: number]: PropNames };
}

export const initialState: State = {
  isLoading: false,
  currencies: null,
  transactionRateTypes: null,
  subscriptionTypes: null,
  allStorageSizeTypes: null,
  controlTypes: null,
  blockControls: null,
  fieldControls: null,
  controlPropertyTypes: null,
  controlPropertyValueType: null,
  defaultRoles: null,
  accessLevels: null,
  workflowUserTypes: null,
  workflowActions: null,
  businessObjects: null,
  businessObjectAttributes: null,
  propertyList: {},
};

const utilitiesReducerInternal = createReducer(
  initialState,
  on(UtilitiesActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(UtilitiesActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(UtilitiesActions.SaveAllCurrencies, (state, { payload }) => ({
    ...state,
    currencies: payload,
  })),
  on(UtilitiesActions.SaveTransactionRateTypes, (state, { payload }) => ({
    ...state,
    transactionRateTypes: payload,
  })),
  on(UtilitiesActions.SaveSubscriptionTypes, (state, { payload }) => ({
    ...state,
    subscriptionTypes: payload,
  })),
  on(UtilitiesActions.SaveStorageSizeTypes, (state, { payload }) => ({
    ...state,
    allStorageSizeTypes: payload,
  })),
  on(UtilitiesActions.SaveControlType, (state, { payload }) => ({
    ...state,
    controlTypes: payload,
  })),
  on(UtilitiesActions.SaveBlockControl, (state, { payload }) => ({
    ...state,
    blockControls: payload,
  })),
  on(UtilitiesActions.SaveFieldControl, (state, { payload }) => ({
    ...state,
    fieldControls: payload,
  })),
  on(UtilitiesActions.SaveControlPropertyType, (state, { payload }) => ({
    ...state,
    controlPropertyTypes: payload,
  })),
  on(UtilitiesActions.SaveControlPropertyValueType, (state, { payload }) => ({
    ...state,
    controlPropertyValueType: payload,
  })),
  on(UtilitiesActions.SaveDefaultRoles, (state, { payload }) => ({
    ...state,
    defaultRoles: payload,
  })),
  on(UtilitiesActions.SaveAccessLevels, (state, { payload }) => ({
    ...state,
    accessLevels: payload,
  })),
  on(UtilitiesActions.SaveWorkflowUserTypes, (state, { payload }) => ({
    ...state,
    workflowUserTypes: payload,
  })),
  on(UtilitiesActions.SaveWorkflowActions, (state, { payload }) => ({
    ...state,
    workflowActions: payload,
  })),
  on(UtilitiesActions.SaveBusinessObjects, (state, { payload }) => ({
    ...state,
    businessObjects: payload,
  })),
  on(UtilitiesActions.SaveBusinessObjectAttributes, (state, { payload }) => {
    return {
      ...state,
      businessObjectAttributes: payload,
    };
  }),
  on(UtilitiesActions.SetPropList, (state, { payload }) => {
    return {
      ...state,
      propertyList: { ...payload },
    };
  })
);

export function utilitiesReducer(state: State | undefined, action: Action) {
  return utilitiesReducerInternal(state, action);
}
