import { createAction, props } from '@ngrx/store';
import { organizationReducerState } from './organization.reducer';
import { CreateUserGroupPayload, SubsidiaryInfo } from '../../interfaces';

export const ResetStore = createAction('[Organization] Reset Store');

export const IsLoading = createAction(
  '[Organization] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsCreateLoading = createAction(
  '[Organization] Is Create subsidiary  Loading',
  props<{
    payload: boolean;
  }>()
);

export const ToggleLoader = createAction(
  '[Organization] Toggle Loader',
  props<{
    payload: boolean;
  }>()
);

export const CreateDivision = createAction(
  '[Organization] Create Division',
  props<{
    payload: {
      divisionName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateDivision = createAction(
  '[Organization] Update Division',
  props<{
    payload: {
      divisionId: number;
      divisionName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateDivision = createAction(
  '[Organization] Activate Division',
  props<{
    payload: {
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateDivision = createAction(
  '[Organization] Deactivate Division',
  props<{
    payload: {
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetDivisionById = createAction(
  '[Organization] Get Division By Id',
  props<{
    payload: {
      divisionId: number;
    };
  }>()
);

export const GetAllDivisions = createAction(
  '[Organization] Get All Divisions',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllDivisions = createAction(
  '[Organization] Save All Divisions',
  props<{
    payload: organizationReducerState['divisions'];
  }>()
);

// Department
export const CreateDepartment = createAction(
  '[Organization] Create Department',
  props<{
    payload: {
      departmentName: string;
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateDepartment = createAction(
  '[Organization] Update Department',
  props<{
    payload: {
      departmentId: number;
      divisionId: number;
      departmentName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateDepartment = createAction(
  '[Organization] Activate Department',
  props<{
    payload: {
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateDepartment = createAction(
  '[Organization] Deactivate Department',
  props<{
    payload: {
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetDepartmentById = createAction(
  '[Organization] Get Department By Id',
  props<{
    payload: {
      departmentId: number;
    };
  }>()
);

export const GetAllDepartments = createAction(
  '[Organization] Get All Departments',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllDepartments = createAction(
  '[Organization] Save All Departments',
  props<{
    payload: organizationReducerState['departments'];
  }>()
);

export const CreateUnit = createAction(
  '[Organization] Create Unit',
  props<{
    payload: {
      unitName: string;
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateUnit = createAction(
  '[Organization] Update Unit',
  props<{
    payload: {
      unitId: number;
      unitName: string;
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateUnit = createAction(
  '[Organization] Activate Unit',
  props<{
    payload: {
      unitId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateUnit = createAction(
  '[Organization] Deactivate Unit',
  props<{
    payload: {
      unitId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetUnitById = createAction(
  '[Organization] Get Unit By Id',
  props<{
    payload: {
      unitId: number;
    };
  }>()
);

export const GetAllUnits = createAction(
  '[Organization] Get All Units',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllUnits = createAction(
  '[Organization] Save All Units',
  props<{
    payload: organizationReducerState['units'];
  }>()
);

export const CheckUpDomainName = createAction(
  '[Organization] Check Up Domain Name',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const UpdateSubscriberDomains = createAction(
  '[Organization] Update Subscriber Domains',
  props<{
    payload: {
      subscriberDomains: {
        id: number;
        domainName: string;
        isDeleted: boolean;
        subscriberId: number;
        userId: string;
      }[];
    };
  }>()
);

export const DeleteSubscriberDomain = createAction(
  '[Organization] Delete Subscriber Domain',
  props<{
    payload: {
      id: number;
    };
    additionalPayload: {
      index: number;
    };
  }>()
);

/* Get all Roles */

export const GetAllRoles = createAction(
  '[User Group] Get All Roles',
  props<{
    payload: {
      userId: number;
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllRoles = createAction(
  '[User Group] Save All Roles',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const GetActiveUserRoles = createAction(
  '[User Group] Get  User Roles For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveActiveUserRoles = createAction(
  '[User Group] Save Active User Roles for User Group',
  props<{ payload: [] }>()
);

/* Get User Details*/

export const GetUsersDetails = createAction(
  '[User Group] Get All Users For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
      searchValue?: string;
    };
  }>()
);

export const SavedUsersDetails = createAction(
  '[User Group] Get All Saved Users For User Group',
  props<{
    payload: any[];
  }>()
);

/* Get Units */
export const GetUnits = createAction(
  '[User Group] Get All Units For User Group',
  props<{
    payload: {
      skip: number;
      take: number;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveGetUnits = createAction(
  '[User Group] Get All Saved Units For User Group',
  props<{
    payload: any[];
  }>()
);

/* User Group */

export const CreateUserGroup = createAction(
  '[Create User Group] Create User Group',
  props<{
    payload: CreateUserGroupPayload;
  }>()
);

/* Subsidiaries */
export const CreateSubsidiaries = createAction(
  '[Organization] Create Subsidiaries',
  props<{
    payload: {
      subsidiary: SubsidiaryInfo;
    };
  }>()
);

export const UpdateSubsidiaries = createAction(
  '[Organization] Update Subsidiaries',
  props<{
    payload: {
      subsidiary: SubsidiaryInfo;
    };
  }>()
);

export const ActivateSubsidiary = createAction(
  '[Organization] Activate Subsidiary',
  props<{
    payload: {
      guid: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateSubsidiary = createAction(
  '[Organization] Deactivate Subsidiary',
  props<{
    payload: {
      guid: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeleteSubsidiaries = createAction(
  '[Organization] Delete Subsidiaries',
  props<{
    payload: {
      subsidiaryGuid: number;
    };
    paginationPayload?: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetSubsidiaries = createAction(
  '[Organization] Get Subsidiaries',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string;
      filterValue?: string[];
    };
  }>()
);

export const GetActiveSubsidiaries = createAction(
  '[Organization] Get Active Subsidiaries',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string;
      filterValue?: string[];
    };
  }>()
);

export const GetDeactivatedSubsidiaries = createAction(
  '[Organization] Get Deactivated Subsidiaries',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string;
      filterValue?: string[];
    };
  }>()
);

export const GetSubsidiaryByGuId = createAction(
  '[Organization] Get Subsidiary By GuId',
  props<{
    payload: {
      guId: number;
    };
  }>()
);

export const GetSubsidiariesDetails = createAction(
  '[Organization] Get Subsidiaries Details',
  props<{
    payload: {};
  }>()
);

export const GetSubsidiariesDomainByParentId = createAction(
  '[Organization] Get Subsidiaries Domain By Parent Id',
  props<{
    payload: {
      parentId: number;
    };
  }>()
);

export const GetSubscriptionWithLicenseCountByProduct = createAction(
  '[Organization] Get User Subscription With License Count By Product',
  props<{
    payload: {
      productName?: string;
      productguid: string;
    };
  }>()
);

export const SaveSubscriptionWithLicenseCountByProduct = createAction(
  '[Organization] Get User Subscription With License Count By Product',
  props<{
    productGuId: string;
    productInfo: any;
  }>()
);

export const GetActiveProducts = createAction(
  '[Organization] Get Active Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string;
      filterValue?: string[];
    };
  }>()
);

export const GetSubsidiariesList = createAction(
  '[Organization] Get Subsidiaries for Delete Component',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string;
    };
  }>()
);

export const DeactivateSubsidiaryUsers = createAction(
  '[Organization] Deactivate Subsidiary users',
  props<{
    payload: {
      subsidiaryGuid: string;
    };
  }>()
);

export const MoveSubsidiaryUsers = createAction(
  '[Organization] Move Subsidiary users',
  props<{
    payload: {
      oldSubsidiaryGuid: string;
      newSubsidiaryGuid: string;
    };
  }>()
);
