import { createAction, props } from '@ngrx/store';
import { DocumentType } from '../../enums';
import {
  DocumentItem,
  ItemWithParent,
} from '../../interfaces/document.interface';

export const ResetStore = createAction('[Document] Reset Store');

export const IsLoading = createAction(
  '[Document] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetching = createAction(
  '[Document] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

export const IsSidebarToggled = createAction(
  '[Document] Is Sidebar Toggled',
  props<{
    payload: boolean;
  }>()
);

export const SaveViewMode = createAction(
  '[Document] Save View Mode',
  props<{
    payload: 'table' | 'grid';
  }>()
);

export const GetAllFolders = createAction('[Document] Get All Folders');

export const GetAllFoldersJSON = createAction(
  '[Document] Get All Folders JSON'
);

export const SaveAllFolders = createAction(
  '[Document] Save All Folders',
  props<{
    payload: any[];
  }>()
);

export const SaveAllFoldersJSON = createAction(
  '[Document] Save All Folders JSON',
  props<{
    payload: any[];
  }>()
);

export const GetAllDocuments = createAction('[Document] Get All Documents');

export const SaveAllDocuments = createAction(
  '[Document] Save All Documents',
  props<{
    payload: any[];
  }>()
);

export const CreateFolder = createAction(
  '[Document] Create Document Folder',
  props<{
    payload: {
      rootFolderId: number;
      parentFolderId: number;
      name: string;
      description: string;
      folderType: number;
      createFolderRecipientRequests: {
        roleId: number | null;
        roleName: string | null;
        email: string | null;
        filePermission: number;
      }[];
    };
  }>()
);

export const UpdateFolder = createAction(
  '[Document] Update Folder',
  props<{
    payload: {
      id: number;
      name: string;
      editFolderRecipientRequests: {
        id: number;
        roleId: number | null;
        roleName: string | null;
        sharedWithName: string | null;
        email: string | null;
        filePermission: number;
      }[];
    };
  }>()
);

export const DuplicateFolder = createAction(
  '[Document] Duplicate Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const DuplicateDocument = createAction(
  '[Document] Duplicate Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const ShareFolder = createAction(
  '[Document] Share Folder',
  props<{
    payload: {
      folderId: number;
      fileLink: string;
      fileShareMessage: string;
      fileShareDetails: {
        roleId: number;
        roleName: string | null;
        sharedWithName: string;
        email: string;
        filePermission: number;
        folderId: number;
        documentId: number;
      }[];
    };
  }>()
);

export const ShareDocument = createAction(
  '[Document] Share Document',
  props<{
    payload: {
      documentId: number;
      fileLink: string;
      fileShareSubject: string | null;
      fileShareMessage: string;
      fileShareDetails: {
        roleId: number;
        roleName: string | null;
        sharedWithName: string;
        email: string;
        filePermission: number;
        folderId: number;
        documentId: number;
      }[];
    };
  }>()
);

export const MoveFiles = createAction(
  '[Document] Move Files',
  props<{
    payload: {
      folderIds: number[];
      documentIds: number[];
      destinationFolderId: number;
    };
  }>()
);

export const ArchiveFolder = createAction(
  '[Document] Archive Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const ArchiveDocument = createAction(
  '[Document] Archive Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const UnarchiveFolder = createAction(
  '[Document] Unarchive Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const UnarchiveDocument = createAction(
  '[Document] Unarchive Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const DeleteFolder = createAction(
  '[Document] Delete Folder',
  props<{
    payload: {
      folderId: number;
      moveToTrash: boolean;
    };
  }>()
);

export const getFolderContentById = createAction(
  '[Document] Get Folder Content By Id',
  props<{
    payload: {
      folderId: number;
      rootFolderId: number;
    };
  }>()
);

export const SaveFolderContent = createAction(
  '[Document] Save Folder Content',
  props<{
    payload: any[] | null;
  }>()
);

export const UploadDocument = createAction(
  '[Document] Upload Document',
  props<{
    payload: {
      mimeType: string;
      name: string;
      destinationFolderId: number;
      documentType: DocumentType;
      file: string;
      extension: string;
    };
  }>()
);

export const UploadDocumentsUsingIformFile = createAction(
  '[Document] Upload Documents Using Iform File',
  props<{
    payload: {
      signingRequestId: number;
      files: File[];
      destinationFolderId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const UpdateDocumentName = createAction(
  '[Document] Update Document Name',
  props<{
    payload: {
      documentId: number;
      name: string;
    };
  }>()
);

export const DeleteDocument = createAction(
  '[Document] Delete Document',
  props<{
    payload: {
      documentId: number;
      moveToTrash: boolean;
    };
  }>()
);

export const GetAuditParameter = createAction('[Document] Get Audit Parameter');

export const CreateFeedback = createAction(
  '[Document] Create Feedback',
  props<{
    payload: {
      documentId: number;
      userId: string;
      comment: string;
      rating: number;
      subscriberId: number;
    };
  }>()
);

export const GetCommentOnDocumentById = createAction(
  '[Document] Get Comment On Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const SaveCommentOnDocument = createAction(
  '[Document] Save Comment On Document',
  props<{
    payload: any;
  }>()
);

// Address book

export const CreateAddressBook = createAction(
  '[Document] Create Address Book ',
  props<{
    payload: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      phoneNumber: string;
      profilePicture: string;
      pictureExtension: string;
      mimeType: string;
      companyName: string;
      companyAddress: string;
      companyEmail: string;
      companyPhoneNumber: string;
      state: string;
      country: string;
      registrationNumber: string;
      postalCode: string;
      contactCategory: string;
      contactType: number;
      phoneCode: string;
      companyPhoneCode: string;
    };
  }>()
);

export const UpdateAddressBook = createAction(
  '[Document] Update Address Book ',
  props<{
    payload: {
      id: number;
      firstName: string;
      lastName: string;
      emailAddress: string;
      phoneNumber: string;
      profilePicture: string;
      pictureExtension: string;
      mimeType: string;
      companyName: string;
      companyAddress: string;
      companyEmail: string;
      companyPhoneNumber: string;
      state: string;
      country: string;
      registrationNumber: string;
      postalCode: string;
      contactCategory: string;
      contactType: number;
      phoneCode: string;
      companyPhoneCode: string;
    };
  }>()
);

export const GetAddressBookBySubscriberId = createAction(
  '[Document] Get Address Book By Subscriber Id'
);

export const SaveAddressBookBySubscriberId = createAction(
  '[Document] Save Address Book By Subscriber Id',
  props<{
    payload: any;
  }>()
);

export const GetContactById = createAction(
  '[Document] Get Contact By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const UpdateAddressBookStatus = createAction(
  '[Document] Update Address Book Status',
  props<{
    payload: {
      id: number;
      status: number;
    };
  }>()
);

export const GetAllCompanyDetails = createAction(
  '[Document] Get All Company Detail'
);

export const SaveAllCompanyDetails = createAction(
  '[Document] Ssve All Company Detail',
  props<{
    payload: any;
  }>()
);

export const CreateDocumentPin = createAction(
  '[Document] Create Document Pin',
  props<{
    payload: {
      documentPin: string;
      instance: 'normal' | 'lock document';
    };
    additionalPayload?: {
      document?: any;
    };
  }>()
);

export const UpdateDocumentPin = createAction(
  '[Document] Update Document Pin',
  props<{
    payload: {
      currentDocumentPin: string;
      newDocumentPin: string;
    };
  }>()
);

export const ForgotDocumentPin = createAction('[Document] Forgot Document Pin');

export const ResetDocumentPin = createAction(
  '[Document] Reset Document Pin',
  props<{
    payload: {
      token: string;
      email: string;
      newDocumentPin: string;
    };
  }>()
);

export const VerifyDocumentPin = createAction(
  '[Document] Verify Document Pin',
  props<{
    payload: {
      documentPin: string;
      // document: any;
      // nextAction: string;
    };
  }>()
);

export const LockDocument = createAction(
  '[Document] Lock Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const UnlockDocument = createAction(
  '[Document] Unlock Document',
  props<{
    payload: {
      documentId: number;
      documentPin: string;
    };
  }>()
);

export const GetAllUserAndSubscribersContact = createAction(
  '[Document] Get all Users and Subscriber Contact'
);

export const SaveAllUserAndSubscribersContact = createAction(
  '[Document] Save all Users and Subscriber Contact',
  props<{
    payload: any;
  }>()
);

export const ActionSuccess = createAction(
  '[Document] Action Success',
  props<{
    payload: { action: string | number; uniqueId: number };
  }>()
);

// Document Viewer
export const GetBaseFolders = createAction(
  '[Document] Get Base Folders',
  props<{
    payload?: number;
  }>()
);

export const GetItemsMap = createAction('[Document] Get Items Map');

export const GetDocumentItem = createAction(
  '[Document] Get Doc Item',
  props<{
    payload: string;
  }>()
);

export const GetDocumentStatuses = createAction(
  '[Document] Get Document Statuses',
  props<{
    payload: {
      documentItem: Partial<DocumentItem>;
      defaultStatus: number;
    };
  }>()
);

export const GetRootFoldersByUserId = createAction(
  '[Document] Get Root Folders ByUser Id',
  props<{
    payload: Partial<DocumentItem>;
  }>()
);

export const GetDocumentByStatus = createAction(
  '[Document] Get Document By Status',
  props<{
    payload: {
      documentItem: Partial<DocumentItem>;
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const GetFilesByParentFolderId = createAction(
  '[Document] Get Files By Parent Folder Id',
  props<{
    payload: {
      documentItem: Partial<DocumentItem>;
      skip?: number;
      take?: number;
    };
  }>()
);

export const GetDocumentStatusesView = createAction(
  '[Document] Get Document Statuses View',
  props<{
    payload: Partial<DocumentItem>;
    additionalPayload?: {
      searchValue?: string | null;
    };
  }>()
);

export const GetRootFoldersByUserIdView = createAction(
  '[Document] Get Root Folders ByUser Id View',
  props<{
    payload: Partial<DocumentItem>;
  }>()
);

export const GetDocumentByStatusView = createAction(
  '[Document] Get Document By Status View',
  props<{
    payload: {
      documentItem: Partial<DocumentItem>;
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const GetFilesByParentFolderIdView = createAction(
  '[Document] Get Files By Parent Folder Id View',
  props<{
    payload: {
      documentItem: Partial<DocumentItem>;
      skip?: number;
      take?: number;
    };
  }>()
);

export const GetRootFoldersByUserIdRaw = createAction(
  '[Document] Get Root Folders ByUser Id Raw'
);

export const GetFilesByParentFolderIdRaw = createAction(
  '[Document] Get Files By Parent Folder Id Raw',
  props<{
    payload: {
      id: any;
      skip?: number;
      take?: number;
    };
  }>()
);

export const SaveFolder = createAction(
  '[Document] Save Folder',
  props<{
    payload: any;
  }>()
);

export const SaveFolders = createAction(
  '[Document] Save Folders',
  props<{
    payload: any[];
  }>()
);

export const SaveRootFolders = createAction(
  '[Document] Save Root Folders',
  props<{
    payload: any[];
  }>()
);

export const SetDocumentItemMap = createAction(
  '[Document] Set Doc Item Map',
  props<{
    payload: {
      itemId: any;
      itemToMap: ItemWithParent;
    };
  }>()
);
export const SaveCurrentlyActiveIndex = createAction(
  '[Document] Save Currently Active Index',
  props<{
    payload: any;
  }>()
);

export const SaveCurrentItem = createAction(
  '[Document] Save CurrentlyItem',
  props<{
    payload: any;
  }>()
);

export const ToggleItemCaret = createAction(
  '[Document] Toggle Item Caret',
  props<{
    payload: {
      itemId: string;
    };
  }>()
);

export const ToggleItemLoader = createAction(
  '[Document] Toggle Item Loader',
  props<{
    payload: {
      itemId: string;
      isLoading: boolean;
    };
  }>()
);

export const SaveChildrenItemsInParent = createAction(
  '[Document] Save Children Items In Parent',
  props<{
    payload: {
      parentId: any;
      children: DocumentItem[];
    };
  }>()
);
