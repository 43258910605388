<div id="response">
  <p class="flwmn-caption fw-600 mb-4">Response</p>

  <div class="empty-response">
    <div class="loading" *ngIf="isLoading">
      <p class="flwmn-caption flwmn-text-neutral">Sending request...</p>
    </div>

    <ng-container *ngIf="!isLoading && !response">
      <app-svg
        class="flwmn-pointer"
        src="assets/icons/flowz/tasks/send-request/empty-response.svg"
      ></app-svg>

      <p class="flwmn-caption flwmn-text-neutral">
        Click send to get a response
      </p>
    </ng-container>
  </div>

  <div class="ok-response" *ngIf="!isLoading && response">
    <div class="header">
      <p class="flwmn-caption mb-0">Body</p>

      <p class="flwmn-caption mb-0 ms-auto">
        Status: <span class="flwmn-text-success">401 unauthorised</span>
      </p>

      <p class="flwmn-caption mb-0">
        Time: <span class="flwmn-text-success">1022ms</span>
      </p>

      <p class="flwmn-caption mb-0">
        Size: <span class="flwmn-text-success"> 6.94 KB</span>
      </p>
    </div>

    <div class="body">
      <pre>{{ response }}</pre>
    </div>
  </div>
</div>
