export enum DateFilterItemsEnum {
  All_TIME = 'All Time',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  THIS_YEAR = 'This year',
  LAST_YEAR = 'Last year',
  SET_A_RANGE = 'Set a range',
}

export enum DriveItemType {
  Folder = 1,
  Word = 2,
  PowerPoint = 3,
  Excel = 4,
  Image = 5,
  Pdf = 6,
  File = 7,
}

export enum ActivityType {
  Shared = 1,
  Document = 2,
  Folder = 3,
}

export enum ActionTypeEnum {
  IN_PROGRESS = 2,
  SUCCESSFUL = 1,
  FAILED = 3,
}

export enum UploadMethodEnum {
  Desktop = 1,
  Google = 2,
  OneDrive = 3,
  DropBox = 4,
}

export enum DriveViewType {
  TABLE = 'table',
  GRID = 'grid',
}

export enum SecurityTypeEnums {
  NotSet = 0,
  Pin = 1,
  Password = 2,
  OTP = 3,
}
