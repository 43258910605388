<section id="general-loader">
  <div class="logo-container">
    <img
      src="/assets/gifs/loaders/flowmono-icon-animation.gif"
      alt="Flowmono Logo"
    />
  </div>

  <p *ngIf="loaderText !== ''" class="loader-text flwmn-para-2 fw-400">
    {{ loaderText }}
  </p>
</section>
