import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
  concatMap,
} from 'rxjs';
import { ApiResponse, Condition, GenericResponse } from '../../interfaces';
import * as fromApp from '../app/app.reducer';
import * as ConditionActions from './condition.actions';
import { Notification } from '../../interfaces/notification.interface';
import { HelperService } from '../../services/helper.service';
import { NotificationService } from '../../services/notification.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ConditionBuilderEffects {
  defaultPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(ConditionActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.GetAllCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/${authState.user.SubscriberId}/${authState.user.UserId}/${userData.payload?.skip}/${userData.payload?.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                return ConditionActions.SaveConditions({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Get Condition Failed',
                  payload: null,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Get Condition Failed`
              );
            })
          );
      }),
      share()
    )
  );

  getConditionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.GetConditionById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([{ payload }, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/${authState.user.SubscriberId}/${authState.user.UserId}/${payload.id}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                return ConditionActions.SaveConditionById({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Get Condition By Id Failed`
              );
            })
          );
      })
    )
  );

  createCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.CreateCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post<ApiResponse<Condition[]>>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/create`,
            {
              ...userData.payload,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                if (resData.entity?.length) {
                  this.store.dispatch(
                    ConditionActions.StringifyCondition({
                      payload: { id: resData.entity[0].conditionGroupId! },
                    })
                  );
                }

                return ConditionActions.GetAllCondition(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Create Condition Failed`
              );
            })
          );
      })
    )
  );

  updateCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.UpdateCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/update`,
            {
              ...userData.payload,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                if (resData.entity?.length) {
                  this.store.dispatch(
                    ConditionActions.StringifyCondition({
                      payload: { id: resData.entity[0].conditionGroupId! },
                    })
                  );
                }

                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ConditionActions.GetAllCondition(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Update Condition Failed`
              );
            })
          );
      })
    )
  );

  deleteCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.DeleteCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .delete<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/delete`,
            {
              body: {
                ...userData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return ConditionActions.GetAllCondition(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Delete Condition Failed`
              );
            })
          );
      })
    )
  );

  activateCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.ActivateCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .delete<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/activate`,
            {
              body: {
                ...userData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return ConditionActions.GetAllCondition(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Activate Condition Failed`
              );
            })
          );
      })
    )
  );

  deactivateCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.DeactivateCondition),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .delete<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/deactivate`,
            {
              body: {
                ...userData.payload,
                ownerId: authState.user.UserId,
                actorEmail: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              },
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return ConditionActions.GetAllCondition(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Deactivate Condition Failed`
              );
            })
          );
      })
    )
  );

  stringifyCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionActions.StringifyCondition),
      withLatestFrom(this.store.select('auth')),
      concatMap(([{ payload }, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocConditionBuilderUrl}/conditions/querystring/${authState.user.SubscriberId}/${authState.user.UserId}/${payload.id}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                this.dialog.closeAll();
                if (payload.conditionIndex === 4) {
                  this.store.dispatch(
                    ConditionActions.AllowModalClick({ payload: true })
                  );
                }

                return ConditionActions.SaveStringifyCondition({
                  payload: { id: payload.id, queryString: resData.entity },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Condition] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Condition] Deactivate Condition Failed`
              );
            })
          );
      })
    )
  );
}
