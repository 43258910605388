import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, share } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';

import * as SLAConfiguration from './sla-configuration.actions';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from 'src/app/@core/services/helper.service';

@Injectable()
export class SLAConfigurationEffects {
  ownerId: string;
  subscriberId: string;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetAllProducts),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxDocProductUrl}/Product/activeproducts/`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    SLAConfiguration.SaveAllProducts({
                      payload: response.entity.product,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All Products Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Get All Products'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getMicroservices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetMicroservices),
        switchMap((data) => {
          return this.http
            .get(
              `${environment.onyxCore}/ProductMicroService/getbyproductobjectguid/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.productGuId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    SLAConfiguration.SaveMicroservices({
                      payload: response.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All Products Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Get All Products'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getTargetObjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetTargetObjects),
        switchMap((data) => {
          return this.http
            .get(
              `${environment.onyxCore}/ProductMicroService/domainobjects/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.serviceGuId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    SLAConfiguration.SaveTargetObjects({
                      payload: response.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All Target Objects Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Get All Target Objects'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getCustomTargetObjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetCustomTargetObjects),
        switchMap((data) => {
          const headers = {
            apiKey: 'X-API-KEY',
            apiKeyValue: 'Nickie-fresh-money',
          };

          return this.http
            .post(`${data.payload.targetObjectUrl}`, headers)
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    SLAConfiguration.SaveCustomTargetObjects({
                      payload: response.entity.SLA,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All Custom Target Objects Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Get All Custom Target Objects'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  createConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.CreateConfiguration),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxSLA}api/slabuilder/create`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.CreationStatus({
                      payload: true,
                    })
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration created successfully' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Create SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Create SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  createCustomConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.CreateCustomConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/externalproductslabuilder/create`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    SLAConfiguration.CreationStatus({
                      payload: true,
                    })
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration created successfully' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Create SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Create SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  updateConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.UpdateConfiguration),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxSLA}api/slabuilder/update`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  this.store.dispatch(
                    SLAConfiguration.CreationStatus({
                      payload: true,
                    })
                  );
                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration updated successfully' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Update SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Update SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  updateCustomConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.UpdateCustomConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/externalproductslabuilder/update`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  this.store.dispatch(
                    SLAConfiguration.CreationStatus({
                      payload: true,
                    })
                  );
                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration updated successfully' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Update SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Update SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  activateConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.ActivateConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/slabuilder/activate`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration activated successfully!' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Activate SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Activate SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  activateCustomConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.ActivateCustomConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/externalproductslabuilder/activate`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration activated successfully!' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Activate SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Activate SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.DeactivateConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/slabuilder/deactivate`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration deactivated successfully!' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Dectivate SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Deactivate SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateCustomConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.DeactivateCustomConfiguration),
        switchMap((data) => {
          return this.http
            .post(
              `${environment.onyxSLA}api/externalproductslabuilder/deactivate`,
              data.payload,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration deactivated successfully!' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  this.store.dispatch(
                    SLAConfiguration.GetAllConfigurations({
                      payload: {
                        ownerId: data.payload.ownerId,
                        subscriberId: data.payload.subscriberId,
                        skip: 0,
                        take: 100,
                      },
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Dectivate SLA Configuration Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Deactivate SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetAllConfigurations),
        switchMap((data) => {
          return this.http
            .get<any>(
              `${environment.onyxSLA}api/slabuilder/slabuilders/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response) => {
                if (response.succeeded) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    SLAConfiguration.SaveAllConfigurations({
                      payload: response.entity.result,
                    })
                  );
                } else {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({
                      payload: false,
                    })
                  );

                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All SLA Configurations Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                this.store.dispatch(
                  SLAConfiguration.IsLoading({
                    payload: false,
                  })
                );

                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Load all SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllCustomConfiguration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetAllCustomConfigurations),
        switchMap((data) => {
          return this.http
            .get<any>(
              `${environment.onyxSLA}api/externalproductslabuilder/externalproduct-slabuilders/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response) => {
                if (response.succeeded) {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    SLAConfiguration.SaveAllCustomConfigurations({
                      payload: response.entity.result,
                    })
                  );
                } else {
                  this.store.dispatch(
                    SLAConfiguration.IsLoading({
                      payload: false,
                    })
                  );

                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[SLA Configuration] Get All Custom SLA Configurations Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                this.store.dispatch(
                  SLAConfiguration.IsLoading({
                    payload: false,
                  })
                );

                return this.handleCatchError(
                  errorRes,
                  '[SLA Configuration][CatchError] Failed To Load all SLA Configuration'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getConfigurationById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SLAConfiguration.GetConfigurationById),
        switchMap((data) => {
          return this.http
            .get<any>(
              `${environment.onyxSLA}api/slabuilder/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response) => {
                if (response.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Configuration loaded successfully!' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return this.store.dispatch(
                    SLAConfiguration.SaveAllConfigurations({
                      payload: response.entity.result,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[SLA Configuration] Get SLA Configuration By Id Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[SLA Configuration][CatchError] Failed To fetch SLA Configuration with Id: ${data.payload.id}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );
}
