import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as DriveActions from 'src/app/@core/stores/drive/drive.actions';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  CommentDetails,
  DriveCoordinates,
} from '../interfaces/drive.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
// import * as DriveSelectors from 'src/app/@core/stores/drive/drive.selectors';

@Injectable({
  providedIn: 'root',
})
export class DrivePreviewerService {
  Comments = new BehaviorSubject<CommentDetails | null>(null);

  private commentSelectedSource = new Subject<string>();
  commentSelected$ = this.commentSelectedSource.asObservable();

  constructor(
    private store: Store<fromApp.AppState>,
    private sanitizer: DomSanitizer
  ) {}

  createComment(commentDetails: CommentDetails) {
    this.store.dispatch(
      DriveActions.AddDriveFileComments({
        payload: {
          text: commentDetails.text,
          xCoordinate: commentDetails.xCoordinate,
          yCoordinate: commentDetails.yCoordinate,
          pageNumber: commentDetails.pageNumber,
          objectGuId: commentDetails.objectGuId,
          objectName: 'Drive',
          objectFileName: commentDetails.objectFileName,
          width: commentDetails.width,
          height: commentDetails.height,
          startXCoordinate: commentDetails.startXCoordinate,
          startYCoordinate: commentDetails.startYCoordinate,
          tags: commentDetails.tags,
        },
      })
    );
  }

  getComment() {
    return this.Comments.getValue();
  }

  setComment(comment: CommentDetails | null) {
    this.Comments.next(comment);
  }

  getCommentFromEffects(comments: any) {
    if (comments) {
      comments.forEach((comment: any) => {
        // Check to make sure Replies are not sent in, incase of backend errors
        /* Replies Don't have coordinates */
        if (
          comment.xCoordinate &&
          comment.yCoordinate &&
          comment.startYCoordinate &&
          comment.startXCoordinate
        ) {
          this.Comments.next({
            textSafeHtml: this.sanitizeHtml(comment.text),
            text: comment.text,
            xCoordinate: comment.xCoordinate,
            yCoordinate: comment.yCoordinate,
            pageNumber: comment.pageNumber,
            startXCoordinate: comment.startXCoordinate,
            startYCoordinate: comment.startYCoordinate,
            width: comment.width,
            height: comment.height,
            objectGuId: comment.objectGuId,
            objectName: 'Drive',
            userName: comment.createdByName,
            createdDate: comment.createdDate,
            createdBy: comment.createdBy,
            guId: comment.guId,
          });
        }
      });
    }
  }

  selectComment(commentId: string) {
    this.commentSelectedSource.next(commentId);
  }

  pixelToNumber(value: string): number {
    return parseFloat(value.replaceAll('"', '').replaceAll('px', ''));
  }

  // get Client Coordinates Dimension
  /* Method can get both actualDimension And client Dimension if the arguments are flipped */
  generateActualClientCoordinates(
    commentDetails: CommentDetails,
    actualPageDimensions: DriveCoordinates,
    clientPageDimensions: DriveCoordinates
  ): CommentDetails {
    const height =
      (this.pixelToNumber(actualPageDimensions.height) *
        commentDetails.height!) /
      this.pixelToNumber(clientPageDimensions.height);

    const width =
      (this.pixelToNumber(actualPageDimensions.width) * commentDetails.width!) /
      this.pixelToNumber(clientPageDimensions.width);

    const xCoordinate =
      (this.pixelToNumber(actualPageDimensions.width) *
        commentDetails.xCoordinate!) /
      this.pixelToNumber(clientPageDimensions.width);

    const yCoordinate =
      (this.pixelToNumber(actualPageDimensions.height) *
        commentDetails.yCoordinate!) /
      this.pixelToNumber(clientPageDimensions.height);

    const startYCoordinate =
      (this.pixelToNumber(actualPageDimensions.height) *
        commentDetails.startYCoordinate!) /
      this.pixelToNumber(clientPageDimensions.height);

    const startXCoordinate =
      (this.pixelToNumber(actualPageDimensions.width) *
        commentDetails.startXCoordinate!) /
      this.pixelToNumber(clientPageDimensions.width);

    const actualDimensions: CommentDetails = {
      ...commentDetails,
      height: height,
      width: width,
      xCoordinate: xCoordinate,
      yCoordinate: yCoordinate,
      startXCoordinate: startXCoordinate,
      startYCoordinate: startYCoordinate,
    };

    return actualDimensions;
  }

  setMention(
    commentText: string,
    mentionList: {
      name: string;
      email: string;
      profilePicture: string;
      initials: string;
    }[]
  ) {
    // Replace &nbsp; with spaces to allow easier matching
    const initialInnerHtml = commentText.replace(/&nbsp;/g, ' ');

    // Build the regex from suggestions dynamically, escaping any special characters
    const mentionRegex = new RegExp(
      `@(${mentionList
        .map((suggestion) =>
          suggestion.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        )
        .join('|')})`, // Escape special characters
      'gi'
    );

    const emailRegex = /@[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/gi;
    const newEmailMatch = initialInnerHtml.replace(
      emailRegex,
      (match) => `<span>${match}</span>`
    );

    // Replace matched mentions with <span> wrapped mentions
    const newHtml = newEmailMatch.replace(
      mentionRegex,
      (match) => `<span>${match}</span>`
    );

    return newHtml;
  }

  getUserInitials(name: string) {
    const nameParts = name.trim().split(/\s+/);

    return {
      name: name,
      initials: nameParts[0][0] + nameParts[1][0],
    };
  }

  // Method to sanitize HTML (for the 'text' field only)
  private sanitizeHtml(unsafeHtml: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
  }
}
