<ng-container *ngIf="(isLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<!-- <section id="welcome-onboard" *ngIf="false">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <h5 class="flwmn-heading-5 fw-700 header-text">Welcome Onboard!</h5>

            <div class="welcome-onboard-image-container">
              <img
                src="/assets/images/auth/welcome-onboard.svg"
                alt="Welcome Onboard"
              />
            </div>

            <p class="flwmn-para-1 text-1">
              A verification email has been sent to
              <span class="email">{{ email }}.</span> Tap the link in your inbox
              to verify your account
            </p>

            <p class="flwmn-caption text-2">
              Didn't receive the email?
              <span class="resend" (click)="onSendVerificationEmail()">
                Click to resend
              </span>
            </p>

            <p class="flwmn-para-1 text-3">
              If you run into any issue?
              <a routerLink="/contact-us" class="contact-support">
                Contact Support
              </a>
            </p>
          </div>

          <div class="illustration-section">
            <img
              src="/assets/images/auth/auth-right-illustration-2.png"
              alt="Illustration Image"
              class="illustration"
            />
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section> -->

<section id="new-welcome">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container>
          <div class="content-section-verify">
            <div class="form-section">
              <div class="device-verification-image-container">
                <img
                  src="/assets/images/auth/welcome-onboard.svg"
                  alt="Device Verification"
                />
              </div>

              <h5 class="flwmn-heading-5 fw-600 header-text">
                Verify your email address!
              </h5>

              <p class="flwmn-para-1 subheader-text">
                We’ve sent you an email to
                <strong>“{{ email }}”</strong>with a link you will need to click
                to verify your email. You might need to check your spam folder
                incase you don’t see it.
              </p>

              <div class="mt-2 text-center">
                <p class="flwmn-para-1">Didn’t get the link?</p>

                <button
                  matRipple
                  type="button"
                  class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full login-btn rounded-pill"
                  (click)="onSendVerificationEmail()"
                >
                  Click to resend
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
