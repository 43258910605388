import { createReducer, on, Action } from '@ngrx/store';
import * as CalendarActions from './calendar.actions';
import { Calendar } from '../../interfaces/calendar.interface';

export interface State {
  isLoading: boolean;
  creationSuccessful: boolean;
  calendars: Calendar[];
  currentCalendar: Calendar;
  defaultCalendar: Calendar;
  formDefaultCalendar: Calendar;
  lastCreatedCalendar: Calendar;
  calendarByIds: Calendar[];
}

const initialState: State = {
  isLoading: false,
  creationSuccessful: false,
  calendars: [],
  currentCalendar: {} as Calendar,
  lastCreatedCalendar: {} as Calendar,
  defaultCalendar: {} as Calendar,
  calendarByIds: [],
  formDefaultCalendar: {
    subscriberId: '',
    ownerId: '',
    timeZone: '',
    name: '',
    holidays: [],
    calendarWorkdays: [
      {
        subscriberId: '',
        ownerId: '',
        dayOfWeek: 0,
        calendarWorkHours: [
          {
            subscriberId: '',
            ownerId: '',
            calendarWorkDayGuId: '',
            startTime: '',
            endTime: '',
          },
        ],
      },
    ],
  },
};

const calendarReducerInternal = createReducer(
  initialState,
  on(CalendarActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(CalendarActions.SaveAllCalendars, (state, { payload }) => ({
    ...state,
    calendars: payload,
  })),
  on(CalendarActions.SaveCalendarById, (state, { payload }) => {
    return {
      ...state,
      calendarByIds: [...state.calendarByIds, payload],
    };
  }),
  on(CalendarActions.SaveLastCreatedCalendar, (state, { payload }) => ({
    ...state,
    lastCreatedCalendar: payload,
  })),
  on(CalendarActions.CreateSuccessful, (state, { payload }) => ({
    ...state,
    creationSuccessful: payload,
  })),
  on(CalendarActions.SaveDefaultCalendar, (state, { payload }) => ({
    ...state,
    defaultCalendar: state.calendars.find(
      (calendar: Calendar) => calendar.guId === payload.guId
    ) as Calendar,
  })),
  on(CalendarActions.GetCurrentCalendar, (state, { payload }) => ({
    ...state,
    currentCalendar: state.calendars.find(
      (calendar: Calendar) => calendar.guId === payload
    ) as Calendar,
  })),
  on(CalendarActions.UpdateCalendar, (state, { payload }) => ({
    ...state,
    calendars: state.calendars.map((calendar) => {
      if (calendar.name === payload.name) {
        return {
          ...calendar,
          timeZone: payload.timeZone,
          calendarHolidays: payload.calendarHolidays,
          calendarWorkDays: payload.calendarWorkDays,
        };
      }

      return calendar;
    }),
    currentCallendar: {
      timeZone: payload.timeZone,
      calendarHolidays: payload.calendarHolidays,
      calendarWorkDays: payload.calendarWorkDays,
    },
  })),
  on(CalendarActions.MakeDefaultCalendar, (state, { payload }) => ({
    ...state,
    defaultCalendar: state.calendars.find(
      (calendar: Calendar) => calendar.name === payload
    ) as Calendar,
  })),
  on(CalendarActions.DeactivateCalendar, (state, { payload }) => ({
    ...state,
    calendars: state.calendars.filter(
      (calendar: Calendar) => calendar.guId !== payload.guId
    ),
  }))
);

export const calendarReducer = (state: State | undefined, action: Action) => {
  return calendarReducerInternal(state, action);
};
