import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as OrganizationActions from './organization.actions';
import { environment } from 'src/environments/environment';
import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import { FlwmnSheet } from '../../services/sheet.service';
import { RoleStatusEnum } from '../../enums';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private helperService: HelperService,
    private sheet: FlwmnSheet
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(OrganizationActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  createDivision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.CreateDivision),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Division/create`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: `Divison created successfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Organization] Create Division Was Successful',
                });

                this.dialog.closeAll();

                return OrganizationActions.GetAllDivisions({
                  payload: {
                    skip: organizationData.paginationPayload.skip,
                    take: organizationData.paginationPayload.take,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Create Division' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Create Division ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateDivision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.UpdateDivision),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Division/update/${organizationData.payload.divisionId}`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: `Divison updated successfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Organization] Update Division Was Successful',
                });

                this.dialog.closeAll();

                return OrganizationActions.GetAllDivisions({
                  payload: {
                    skip: organizationData.paginationPayload.skip,
                    take: organizationData.paginationPayload.take,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Update Division' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Update Division ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  activateDivision$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.ActivateDivision),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Division/activatedivisionstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Divison activated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Activate Division Was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllDivisions({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return { type: '[Organization] Failed To Activate Division' };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Activate Division ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateDivision$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeactivateDivision),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Division/deactivatedivisionstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Divison deactivated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Deactivate Divsion Was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllDivisions({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Organization] Failed To Deactivate Division',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Deactivate Division ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetAllDivisions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Division/getalldivision/${authState.user.SubscriberId}/${authState.user.UserId}/${organizationData.payload.skip}/${organizationData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return OrganizationActions.SaveAllDivisions({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return OrganizationActions.SaveAllDivisions({
                  payload: {
                    divisions: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Get All Divisions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.CreateDepartment),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Department/create`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Department created successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Organization] Create Department Was Successful',
                });

                this.dialog.closeAll();

                return OrganizationActions.GetAllDepartments({
                  payload: {
                    skip: organizationData.paginationPayload.skip,
                    take: organizationData.paginationPayload.take,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Create Department' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Create Department ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.UpdateDepartment),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Department/update/${organizationData.payload.departmentId}`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Department updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Organization] Update Department Was Successful',
                });

                this.dialog.closeAll();

                return OrganizationActions.GetAllDepartments({
                  payload: {
                    skip: organizationData.paginationPayload.skip,
                    take: organizationData.paginationPayload.take,
                  },
                });

                return resData;
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Update Department' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Update Department ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  activateDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.ActivateDepartment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Department/activatedepartmentstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Department activated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Activate Department was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllDepartments({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Organization] Failed To Activate Department',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Activate Department ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeactivateDepartment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Department/deactivatedepartmentstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Department deactivated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Deactivate Department Was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllDepartments({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Organization] Failed To Deactivate Department',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Deactivate Department ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetAllDepartments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Department/getalldepartment/${authState.user.SubscriberId}/${authState.user.UserId}/${organizationData.payload.skip}/${organizationData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return OrganizationActions.SaveAllDepartments({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return OrganizationActions.SaveAllDepartments({
                  payload: {
                    departments: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Get All Departments ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.CreateUnit),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Units/create`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Unit created successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  OrganizationActions.GetAllUnits({
                    payload: {
                      skip: organizationData.paginationPayload.skip,
                      take: organizationData.paginationPayload.take,
                    },
                  })
                );

                return {
                  type: '[Organization] Create Unit Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Create Unit' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Create Unit ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.UpdateUnit),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Units/update/${organizationData.payload.unitId}`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...organizationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Unit updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Organization] Update Unit Was Successful',
                });

                this.dialog.closeAll();

                return OrganizationActions.GetAllUnits({
                  payload: {
                    skip: organizationData.paginationPayload.skip,
                    take: organizationData.paginationPayload.take,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Organization] Failed To Update Unit' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Update Unit ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  activateUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.ActivateUnit),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Units/activateunitstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Unit activated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Activate Unit Was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllUnits({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return { type: '[Organization] Failed To Activate Unit' };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Activate Unit ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeactivateUnit),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Units/deactivateunitstatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Unit deactivated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Deactivate Unit Was Successful',
                  });

                  this.store.dispatch(
                    OrganizationActions.GetAllUnits({
                      payload: {
                        skip: organizationData.paginationPayload.skip,
                        take: organizationData.paginationPayload.take,
                      },
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return { type: '[Organization] Failed To Deactivate Unit' };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Deactivate Unit ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetAllUnits),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Units/getallunits/${authState.user.SubscriberId}/${authState.user.UserId}/${organizationData.payload.skip}/${organizationData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return OrganizationActions.SaveAllUnits({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return OrganizationActions.SaveAllUnits({
                  payload: {
                    units: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Organization][CatchError] Failed To Get All Units ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  checkUpDomainName$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrganizationActions.CheckUpDomainName),
        switchMap((organizationData) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Users/checkupdomainname`,
              organizationData.payload,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Check Up Domain Name Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Check Up Domain Name',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Check Up Domain Name ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateSubscriberDomains$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrganizationActions.UpdateSubscriberDomains),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/SubscriberDomains/updatesubscriberdomains`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                subscriberName: `${authState.user.FirstName} ${authState.user.LastName}`,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Update Subscriber Domains Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Update Subscriber Domains',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Update Subscriber Domains ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteSubscriberDomain$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrganizationActions.DeleteSubscriberDomain),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/SubscriberDomains/deletesubscriberdomain`,
              {
                requestSubscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Delete Subscriber Domain Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Delete Subscriber Domain',
                  });
                }

                return {
                  resData: resData,
                  index: organizationData?.additionalPayload?.index,
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Delete Subscriber Domain ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.CreateUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/create`,
            {
              ...organizationData.payload,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: `User Group created successfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User Group] User Group  Was Successful',
                });

                this.dialog.closeAll();

                return {
                  type: '[User Group] Create Unit Was Successful',
                };

                // return OrganizationActions.GetAllDivisions({
                //   payload: {
                //     skip: organizationData.paginationPayload.skip,
                //     take: organizationData.paginationPayload.take,
                //   },
                // });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User Group] Failed To User Group' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group][CatchError] Failed To User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetActiveUserRoles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getbysubscriberidandstatus/${authState?.user?.SubscriberId}/${RoleStatusEnum.Active}/${authState?.user?.UserId}/${organizationData.payload.skip}/${organizationData.payload.take}`,

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return OrganizationActions.SaveActiveUserRoles({
                  payload: resData.entity.roles,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Role] Get All Roles Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role For User Group][CatchError] Failed To Get Active User Roles ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetUsersDetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([organizationData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Users/getallusers/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${organizationData.payload.skip}/${organizationData.payload.take}`,
            {
              params: {
                searchValue: organizationData.payload.searchValue || '',
              },

              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                OrganizationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return OrganizationActions.SavedUsersDetails({
                  payload: resData.entity.users,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group ] Get All Users Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role For User Group][CatchError] Failed To Get Active Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  /** Subsidiaries */
  createSubsidiary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.CreateSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/create`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload.subsidiary,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsCreateLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.sheet.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Create Subsidiary Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Create Subsidiary',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                this.store.dispatch(
                  OrganizationActions.IsCreateLoading({ payload: false })
                );

                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Create Subsidiary ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  updateSubsidiary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.UpdateSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/update`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload.subsidiary,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsCreateLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.sheet.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Update Subsidiary Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Update Subsidiary',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Update Subsidiary ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSubsidiaries$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          let params = new HttpParams();
          params = params.set('skip', organizationData.payload.skip);
          params = params.set('take', organizationData.payload.take);
          params = params.set(
            'searchValue',
            organizationData.payload.searchValue
          );
          params = params.set('subscriberId', authState.user.SubscriberId);
          params = params.set('userId', authState.user.UserId);

          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/subsidiariesbysubscriberid`,
              {
                /* Body of the POST request */
              },
              {
                params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Subsidiaries Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subsidiaries',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Subsidiaries ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getActiveSubsidiaries$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetActiveSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          let params = new HttpParams();
          params = params.set('skip', organizationData.payload.skip);
          params = params.set('take', organizationData.payload.take);
          params = params.set(
            'searchValue',
            organizationData.payload.searchValue
          );
          params = params.set('subscriberId', authState.user.SubscriberId);
          params = params.set('userId', authState.user.UserId);

          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/activesubsidiaries`,
              {
                /* Body of the POST request */
              },
              {
                params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Subsidiaries Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subsidiaries',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Subsidiaries ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  GetDeactivatedSubsidiaries$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetDeactivatedSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          let params = new HttpParams();
          params = params.set('skip', organizationData.payload.skip);
          params = params.set('take', organizationData.payload.take);
          params = params.set(
            'searchValue',
            organizationData.payload.searchValue
          );
          params = params.set('subscriberId', authState.user.SubscriberId);
          params = params.set('userId', authState.user.UserId);

          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/deactivatedsubsidiaries`,
              {
                /* Body of the POST request */
              },
              {
                params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Inactive Subsidiaries  Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Inactive Subsidiaries',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Inactive Subsidiaries ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSubsidiariesList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetSubsidiariesList),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          let params = new HttpParams();
          params = params.set('skip', organizationData.payload.skip);
          params = params.set('take', organizationData.payload.take);
          params = params.set(
            'searchValue',
            organizationData.payload.searchValue
          );
          params = params.set('subscriberId', authState.user.SubscriberId);
          params = params.set('userId', authState.user.UserId);

          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/subsidiariesbysubscriberid`,
              {
                /* Body of the POST request */
              },
              {
                params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Subsidiaries For Delete Component Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subsidiaries For Delete Component',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Subsidiaries For Delete Component ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSubsidiaryByGuId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetSubsidiaryByGuId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/subsidiarybyguid/${organizationData.payload.guId}/${authState.user.UserId}`,

              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Subsidiaries By Id Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subsidiaries By Id',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Subsidiaries By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  activateSubsidiary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.ActivateSubsidiary),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/activatesubsidiary`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Activate Subsidiary Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Activate Subsidiary',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Activate Subsidiary ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateSubsidiary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeactivateSubsidiary),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/deactivatesubsidiary`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Deactivate Subsidiary Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Deactivate Subsidiary',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Deactivate Subsidiary ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deleteSubsidiaries$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeleteSubsidiaries),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/deletesubsidiary`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Organization] Delete Subsidiary Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Delete Subsidiary',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Delete Subsidiary ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSubsidiariesDomainByParentId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetSubsidiariesDomainByParentId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryDomain/getsubsidiarydomainbyparentid`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Subsidiaries Domain Name By Parent Id Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subsidiaries  Domain Name By Parent Id',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To  Get Subsidiaries  Domain Name By Parent Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getSubscriptionWithLicenseCountByProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetSubscriptionWithLicenseCountByProduct),
        distinctUntilChanged((prev, curr) => {
          return prev.payload.productguid === curr.payload.productguid;
        }),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([organizationData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocProductUrl}/Subscriptions/getnumberofremaininguserlicensesbyproduct/${authState.user.SubscriberId}/${authState.user.UserId}/${organizationData.payload.productguid}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    OrganizationActions.SaveSubscriptionWithLicenseCountByProduct(
                      {
                        productGuId: organizationData.payload.productguid,
                        productInfo: resData.entity,
                      }
                    )
                  );

                  this.store.dispatch({
                    type: '[Organization] Get Subscription With licenseCount By product',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To  Get Subscription With licenseCount By product',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Get Subscription With licenseCount By product ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getActiveProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.GetActiveProducts),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocProductUrl}/Product/activeproducts`,
              {
                ownerId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Get Active Products was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Get Active Products',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Get Active Products${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateSubsidiaryUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.DeactivateSubsidiaryUsers),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/deactivatesubsidiaryusers`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Deactivate Subsidiary users was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Deactivate Subsidiary users was',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Deactivate Subsidiary users was${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  moveSubsidiaryUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.MoveSubsidiaryUsers),
        withLatestFrom(this.store.select('auth')),
        switchMap(([organizationData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/SubsidiaryManagement/moveuserstoanothersubsidiary`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                ...organizationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  OrganizationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Organization] Move Subsidiary users was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Organization] Failed To Move Subsidiary users was',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Organization][CatchError] Failed To Move Subsidiary users was${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );
}
