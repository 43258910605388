import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import {
  // MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Notification } from '../interfaces/notification.interface';
import { NotificationComponent } from '../shared/notification/notification.component';
import { HotToastService, ToastPosition } from '@ngneat/hot-toast';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private subscription = new Subscription();

  constructor(
    // private snackBar: MatSnackBar,
    private toast: HotToastService
  ) {
    this.toast.defaultConfig = {
      ...this.toast.defaultConfig,
      reverseOrder: true,

      // stacking: 'depth',
    };
  }

  openNotification(
    message: Notification,
    notificationClass: string,
    doNotDismiss: boolean = false,
    position: ToastPosition = 'top-right',
    durationInSeconds?: number
  ) {
    if (durationInSeconds) {
      this.toast.show<Notification>(NotificationComponent, {
        data: message,
        duration: durationInSeconds * 1000,
        position: position,
        className: notificationClass,
      });
    } else {
      this.toast.show<Notification>(NotificationComponent, {
        data: message,
        duration: doNotDismiss === false ? 10000 : undefined,
        position: position,
        className: notificationClass,
      });
    }
  }

  openNotificationWithComponent<T>(
    componentType: ComponentType<T>,
    data?: T,
    notificationClass?: string,
    doNotDismiss: boolean = false
  ) {
    this.toast.show<T>(componentType, {
      data,
      duration: doNotDismiss === false ? 10000 : undefined,
      dismissible: false,
      position: 'top-right',
      className: notificationClass,
      id: 'upload-progress',
    });
  }

  openNotificationWithTemplate(
    template: TemplateRef<any>,
    notificationClass?: string,
    doNotDismiss: boolean = false
  ) {
    this.toast.show(template, {
      duration: doNotDismiss === false ? 10000 : undefined,
      dismissible: false,
      position: 'top-right',
      className: notificationClass,
      id: 'upload-progress',
      autoClose: false,
    });
  }

  closeAllNotifications() {
    this.toast.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
