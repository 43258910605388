import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Audit] Reset Store');

export const GetAuditControllerName = createAction(
  '[Audit] Get All Audit Controller Name',
  props<{
    payload: {
      controllerName: string;
      apiSectionName: string;
    };
  }>()
);

export const SaveAuditControllerName = createAction(
  '[Audit] Save All Audit By Controller Name',
  props<{
    payload: any;
  }>()
);

export const GetAuditByControllerName = createAction(
  '[Audit] Get All Audit By Controller Name',
  props<{
    payload: {
      controllerName: string;
      apiSectionName: string;
    };
  }>()
);

export const SaveAuditByControllerName = createAction(
  '[Audit] Save All Audit By Controller Name',
  props<{
    payload: any;
  }>()
);
