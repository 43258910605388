import { createReducer, on, Action } from '@ngrx/store';
import { Notification } from '../../interfaces/index';
import * as GeneralActions from './general.actions';

export interface State {
  isLoading: boolean;
  notification: Notification | null;
  ipAddressDetails: any;
  // countryFromIpAddress: string | null;
  allCountries: string[] | null;
  allStates:
    | {
        name: string;
        state_code: string;
      }[]
    | null;
  allCities: string[] | null;
  allCountryCodes: string[] | null;
  isSubscriptionExpirationBanner: boolean;
}

const initialState: State = {
  isLoading: false,
  notification: null,
  ipAddressDetails: null,
  // countryFromIpAddress: null,
  allCountries: null,
  allStates: null,
  allCities: null,
  allCountryCodes: null,
  isSubscriptionExpirationBanner: true,
};

const generalReducerInternal = createReducer(
  initialState,
  on(GeneralActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(GeneralActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(GeneralActions.SetNotification, (state, { payload }) => ({
    ...state,
    notification: payload,
  })),
  on(GeneralActions.ClearNotification, (state) => ({
    ...state,
    notification: null,
  })),
  on(GeneralActions.SaveIpAddressDetails, (state, { payload }) => ({
    ...state,
    ipAddressDetails: payload,
  })),
  // on(GeneralActions.SaveCountryFromIpAddress, (state, { payload }) => ({
  //   ...state,
  //   countryFromIpAddress: payload,
  // })),
  on(GeneralActions.SaveCountries, (state, { payload }) => ({
    ...state,
    allCountries: payload,
  })),
  on(GeneralActions.SaveStates, (state, { payload }) => ({
    ...state,
    allStates: payload,
  })),
  on(GeneralActions.SaveCities, (state, { payload }) => ({
    ...state,
    allCities: payload,
  })),
  on(GeneralActions.SaveCountryCodes, (state, { payload }) => ({
    ...state,
    allCountryCodes: payload,
  })),
  on(
    GeneralActions.IsSubscriptionExpirationNotification,
    (state, { payload }) => ({
      ...state,
      isSubscriptionExpirationBanner: payload,
    })
  )
);

export function generalReducer(state: State | undefined, action: Action) {
  return generalReducerInternal(state, action);
}
