import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
  // tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import * as DashboardActions from './dashboard.actions';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(DashboardActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getDocumentDonutChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GetDocumentDonutChart),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/getdocumentdonutchart/${authState.user.SubscriberId}/${dashboardData.payload.selectType}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DashboardActions.SaveDocumentDonutChartData({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  DashboardActions.SaveDocumentDonutChartData({
                    payload: [],
                  })
                );

                return {
                  type: '[Dashboard] Failed To Get Document Donut Chart Data Dashboard',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Get Document Donut Chart Data Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getDocumentLineChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GetDocumentLineChart),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/getsigningrequestlinechart/${authState.user.SubscriberId}/${dashboardData.payload.year}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DashboardActions.SaveDocumentLineChartData({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  DashboardActions.SaveDocumentLineChartData({
                    payload: [],
                  })
                );

                return {
                  type: `[Dashboard] Failed To Get Document Line Chart Data Dashboard`,
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Get Document Line Chart Data Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  // getActivities$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DashboardActions.GetActivities),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([dashboardData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Dashboard/getactivities/${authState.user.SubscriberId}/${dashboardData.payload.selectType}/${authState.user.UserId}/${authState.user.Email}`
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(
  //               DashboardActions.IsLoading({ payload: false })
  //             );
  //             if (resData.succeeded === true) {
  //               return DashboardActions.SaveActivities({
  //                 payload: resData.entity,
  //               });
  //             } else {
  //               return {
  //                 type: '[Dashboard] Failed To Get Activities',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Dashboard][CatchError] Failed To Get Activities ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   )
  // );

  getRecentDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GetRecentDocuments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/getrecentdocuments/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return DashboardActions.SaveRecentDocuments({
                  payload: resData.entity,
                });
              } else {
                return {
                  type: '[Dashboard] Failed To Get Recent Documents',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Get Recent Documents ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createIndividualDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.CreateIndividualDashboard),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/create`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return DashboardActions.SaveIndividualDashboardData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Dashboard] Failed To Create Individual Dashboard',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Create Individual Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createCoopDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.CreateCoopDashboard),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/create`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              dashboardType: 1,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return DashboardActions.SaveIndividualDashboardData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Dashboard] Failed To Create Coop Dashboard',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Create Coop Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.CreateDashboard),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/create`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DashboardActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DashboardActions.SaveDashboard({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Dashboard] Failed To Create Dashboard',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Create Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getSigningRequestsAwaitingUserAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.GetSigningRequestsAwaitingUserAction),
        withLatestFrom(this.store.select('auth')),
        switchMap(([dashboardData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Dashboard/getsigningrequestsawaitinguseraction/${authState.user.SubscriberId}/${authState.user.UserId}/${dashboardData.payload.skip}/${dashboardData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DashboardActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Dashboard] Get Signing Requests Awaiting User Action Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Dashboard] Failed To Get Signing Requests Awaiting User Action',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Dashboard][CatchError] Failed To Get Signing Requests Awaiting User Action ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getIncomingSigningRequestsByUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.GetIncomingSigningRequestsByUser),
        withLatestFrom(this.store.select('auth')),
        switchMap(([dashboardData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Dashboard/getincomingsigningrequestsbyuser/${authState.user.SubscriberId}/${authState.user.UserId}/${dashboardData.payload.skip}/${dashboardData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DashboardActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Dashboard] Get Incoming Signing Requests By User Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Dashboard] Failed To Get Incoming Signing Requests By User',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Dashboard][CatchError] Failed To Get Incoming Signing Requests By User ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSigningRequestsAwaitingRecipientAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.GetSigningRequestsAwaitingRecipientAction),
        withLatestFrom(this.store.select('auth')),
        switchMap(([dashboardData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Dashboard/getsigningrequestsawaitingrecipientaction/${authState.user.SubscriberId}/${authState.user.UserId}/${dashboardData.payload.skip}/${dashboardData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DashboardActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Dashboard] Get Signing Requests Awaiting Recipient Action Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Dashboard] Failed To Get Signing Requests Awaiting Recipient Action',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Dashboard][CatchError] Failed To Get Signing Requests Awaiting Recipient Action ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  hideRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.HideSigningRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        const url =
          dashboardData.payload.type == 'awaiting'
            ? '/Dashboard/hidesigningrequest'
            : '';
        return this.http
          .post<GenericResponse>(`${environment.onyxDocDocumentUrl}${url}`, {
            subscriberId: authState.user.SubscriberId,
            userId: authState.user.UserId,
            id: dashboardData.payload.id,
          })
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Signing Request hidden successfully!',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                return dashboardData.payload.type == 'awaiting'
                  ? DashboardActions.GetSigningRequestsAwaitingUserAction({
                      payload: {
                        skip: dashboardData.payload.skip,
                        take: dashboardData.payload.take,
                      },
                    })
                  : DashboardActions.GetSigningRequestsAwaitingRecipientAction({
                      payload: {
                        skip: dashboardData.payload.skip,
                        take: dashboardData.payload.take,
                      },
                    });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Dashboard] Failed To Hide Signing Request',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Hide Signing Request ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getSigningRequestDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GetSigningRequestDetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/getsigningrequestdetails/${authState.user.SubscriberId}/${authState.user.UserId}/${dashboardData.payload.id}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DashboardActions.SaveSigningRequestDetails({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return DashboardActions.SaveSigningRequestDetails({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Save Signing Request Details ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getDashboardMetric$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GetDashboardMetric),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Dashboard/getuserdashboardmetrics/${authState.user?.SubscriberId}/${authState.user?.UserId}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DashboardActions.SaveDashboardMetric({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return DashboardActions.SaveDashboardMetric({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Dashboard][CatchError] Failed To Get Dashboard Metric ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
