import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Process Workflow] Reset Store');

export const IsLoading = createAction(
  '[Process Workflow] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const UploadForms = createAction(
  '[Process Workflow] Upload Forms',
  props<{
    payload: {
      mimeType: string;
      file: string;
      extension: string;
      email: string;
      saveFormAsTemplate: boolean;
      isDeleted: boolean;
      useExistingFile: boolean;
      existingFile: {
        mimeType: string;
        blobUrl: string;
        extension: string;
        email: string;
        passCodeRequired: boolean;
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        name: string;
        description: string;
        userId: string;
      } | null;
      id: number;
      subscriberId: number;
      name: string;
      description: string;
      userId: string;
    }[];
  }>()
);

export const DeleteForm = createAction(
  '[Process Workflow] Delete Form',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetProcesses = createAction('[Process Workflow] Get Processes');

export const GetPublishedProcessesByGroupId = createAction(
  '[Process Workflow] Get Published Processes By Group Id',
  props<{
    payload: {
      processGroupId: number;
    };
  }>()
);

export const SaveProcesses = createAction(
  '[Process Workflow] Save Processes',
  props<{
    payload: any;
  }>()
);

export const SavePublishedProcessesByGroupId = createAction(
  '[Process Workflow] Save Published Processes By Group Id',
  props<{
    payload: any;
  }>()
);

export const CreateProcess = createAction(
  '[Process Workflow] Create Process',
  props<{
    payload: {
      name: string;
      description: string;
      processGroupId: number;
      processAccessLevel: number;
      saveProcessAsTemplate: boolean;
      isArchived: boolean;
      processType: number;
      allowInitiatorSpecifyWorkflowActors: boolean;
      processForms: {
        processId: number;
        formId: number;
        formUrl: string | null;
        isPublished: boolean;
        saveFormAsTemplate: boolean;
        isDeleted: boolean;
        useExistingFile: boolean;
        existingFile: {
          mimeType: string;
          blobUrl: string;
          extension: string;
          email: string;
          passCodeRequired: boolean;
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string | null;
          userId: string;
        } | null;
        formComponents: {
          workflowLevelId: number;
          processFormId: number;
          fieldControlType: number;
          pageNumber: string;
          coordinates: {
            formComponentId: number;
            position: string;
            transform: string;
            width: string;
            height: string;
            coordinateType: number;
            isDeleted: boolean;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[];
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        }[];
        id: number;
        subscriberId: number;
        name: string | null;
        description: string | null;
        userId: string;
      }[];
      processInitiators: {
        processInitiatorType: number;
        initiatorId: string;
        initiatorName: string;
        roleId: number;
        roleName: string;
        jobFunctionId: number;
        jobFunctionName: string;
        divisionName: string;
        departmentId: number;
        departmentName: string;
        unitId: number;
        unitName: string;
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        description: string;
        userId: string;
      }[];
      workflows: {
        objectName: string;
        objectId: number;
        rank: number;
        versionNumber: number;
        isPublished: boolean;
        enableProcessInitiatorRestriction: boolean;
        allowInternalActors: boolean;
        allowExternalActors: boolean;
        isTemplate: boolean;
        workflowPhases: {
          workflowUserCategory: number;
          workflowSequence: number;
          workflowProxies: {
            workflowProxyType: number;
            proxyUserId: string;
            proxyName: string;
            roleId: number;
            roleName: string;
            jobFunctionId: number;
            jobFunctionName: string;
            divisionName: string;
            departmentId: number;
            departmentName: string;
            unitId: number;
            unitName: string;
            isDeleted: boolean;
            id: number;
            subscriberId: number;
            description: string;
            userId: string;
          }[];
          workflowLevels: {
            workflowPhaseId: number;
            workflowUserType: number;
            workflowUserTypeDesc: string;
            workflowAction: number;
            workflowActionDesc: string;
            rank: number;
            isDeleted: boolean;
            actorId: string;
            actorName: string;
            roleId: number;
            roleName: string;
            jobFunctionId: number;
            jobFunctionName: string;
            divisionId: number;
            divisionName: string;
            departmentId: number;
            departmentName: string;
            unitId: number;
            unitName: string;
            contactId: number;
            contactName: string;
            contactCategoryId: number;
            contactCategoryName: string;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[];
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        }[];
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        name: string;
        description: string;
        userId: string;
      }[];
    };
  }>()
);

export const UpdateProcess = createAction(
  '[Process Workflow] Update Process',
  props<{
    payload: {
      id: number;
      name: string;
      description: string;
      processGroupId: number;
      saveProcessAsTemplate: boolean;
      processStatus: number;
      processAccessLevel: number;
      allowInitiatorSpecifyWorkflowActors: boolean;
      useExistingDocument: boolean;
      existingDocument: {
        mimeType: string;
        blobUrl: string;
        extension: string;
        email: string;
        passCodeRequired: boolean;
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        name: string;
        description: string;
        userId: string;
      } | null;
      processForms: {
        processId: number;
        formId: number;
        formUrl: string;
        isPublished: boolean;
        saveFormAsTemplate: boolean;
        isDeleted: boolean;
        useExistingDocument: boolean;
        existingForm: {
          mimeType: string;
          blobUrl: string;
          extension: string;
          email: string;
          passCodeRequired: boolean;
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        } | null;
        formComponents: {
          workflowLevelId: number;
          processFormId: number;
          fieldControlType: number;
          pageNumber: string;
          coordinates: {
            formComponentId: number;
            position: string;
            transform: string;
            width: string;
            height: string;
            coordinateType: number;
            isDeleted: boolean;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[];
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        }[];
        id: number;
        subscriberId: number;
        name: string;
        description: string;
        userId: string;
      }[];
      processInitiators: {
        processInitiatorType: number;
        initiatorId: string;
        initiatorName: string;
        roleId: number;
        roleName: string;
        jobFunctionId: number;
        jobFunctionName: string;
        divisionName: string;
        departmentId: number;
        departmentName: string;
        unitId: number;
        unitName: string;
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        description: string;
        userId: string;
      }[];
      workflows: {
        objectName: string;
        objectId: number;
        rank: number;
        versionNumber: number;
        isPublished: boolean;
        enableProcessInitiatorRestriction: boolean;
        allowInternalActors: boolean;
        allowExternalActors: boolean;
        isTemplate: boolean;
        workflowPhases: {
          workflowUserCategory: number;
          workflowSequence: number;
          workflowProxies: {
            workflowProxyType: number;
            proxyUserId: string;
            proxyName: string;
            roleId: number;
            roleName: string;
            jobFunctionId: number;
            jobFunctionName: string;
            divisionName: string;
            departmentId: number;
            departmentName: string;
            unitId: number;
            unitName: string;
            isDeleted: boolean;
            id: number;
            subscriberId: number;
            description: string;
            userId: string;
          }[];
          workflowLevels: {
            workflowPhaseId: number;
            workflowUserType: number;
            workflowUserTypeDesc: string;
            workflowAction: number;
            workflowActionDesc: string;
            rank: number;
            isDeleted: boolean;
            actorId: string;
            actorName: string;
            roleId: number;
            roleName: string;
            jobFunctionId: number;
            jobFunctionName: string;
            divisionId: number;
            divisionName: string;
            departmentId: number;
            departmentName: string;
            unitId: number;
            unitName: string;
            contactId: number;
            contactName: string;
            contactCategoryId: number;
            contactCategoryName: string;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[];
          isDeleted: boolean;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        }[];
        isDeleted: boolean;
        id: number;
        subscriberId: number;
        name: string;
        description: string;
        userId: string;
      }[];
    };
    optionalPayload: {
      instance: 'normal' | 'finalize process';
      publishProcessPayload?: any;
    };
    // payload: {
    //   id: number;
    //   name: string;
    //   description: string;
    //   processGroupId: number;
    //   saveProcessAsTemplate: boolean;
    //   processStatus: number;
    //   processAccessLevel: number;
    //   isTemplate: boolean;
    //   useExistingDocument: boolean;
    //   existingDocument: {
    //     mimeType: string;
    //     blobUrl: string;
    //     extension: string;
    //     email: string;
    //     passCodeRequired: boolean;
    //     isDeleted: boolean;
    //     id: number;
    //     subscriberId: number;
    //     name: string;
    //     description: string;
    //     userId: string;
    //   } | null;
    //   processFormId: number;
    //   formId: number;
    //   formUrl: string;
    //   saveFormAsTemplate: boolean;
    //   formComponents: {
    //     workflowLevelId: number;
    //     processFormId: number;
    //     fieldControlType: number;
    //     pageNumber: string;
    //     coordinates: {
    //       formComponentId: number;
    //       position: string;
    //       transform: string;
    //       width: string;
    //       height: string;
    //       coordinateType: number;
    //       isDeleted: boolean;
    //       id: number;
    //       subscriberId: number;
    //       name: string;
    //       description: string;
    //       userId: string;
    //     }[];
    //     isDeleted: boolean;
    //     id: number;
    //     subscriberId: number;
    //     name: string;
    //     description: string;
    //     userId: string;
    //   }[];
    //   processInitiators: {
    //     processInitiatorType: number;
    //     initiatorId: string;
    //     initiatorName: string;
    //     roleId: number;
    //     roleName: string;
    //     jobFunctionId: number;
    //     jobFunctionName: string;
    //     divisionName: string;
    //     departmentId: number;
    //     departmentName: string;
    //     unitId: number;
    //     unitName: string;
    //     isDeleted: boolean;
    //     id: number;
    //     subscriberId: number;
    //     description: string;
    //     userId: string;
    //   }[];
    //   processWorkflows: {
    //     rank: number;
    //     versionNumber: number;
    //     isPublished: boolean;
    //     enableProcessInitiatorRestriction: boolean;
    //     allowInternalActors: boolean;
    //     allowExternalActors: boolean;
    //     isTemplate: boolean;
    //     workflowPhases: {
    //       workflowUserCategory: number;
    //       workflowSequence: number;
    //       workflowProxies: {
    //         workflowProxyType: number;
    //         proxyUserId: string;
    //         proxyName: string;
    //         roleId: number;
    //         roleName: string;
    //         jobFunctionId: number;
    //         jobFunctionName: string;
    //         divisionName: string;
    //         departmentId: number;
    //         departmentName: string;
    //         unitId: number;
    //         unitName: string;
    //         isDeleted: boolean;
    //         id: number;
    //         subscriberId: number;
    //         description: string;
    //         userId: string;
    //       }[];
    //       workflowLevels: {
    //         workflowPhaseId: number;
    //         workflowUserType: number;
    //         workflowUserTypeDesc: string;
    //         workflowAction: number;
    //         workflowActionDesc: string;
    //         rank: number;
    //         actorId: string;
    //         actorName: string;
    //         roleId: number;
    //         roleName: string;
    //         jobFunctionId: number;
    //         jobFunctionName: string;
    //         divisionId: number;
    //         divisionName: string;
    //         departmentId: number;
    //         departmentName: string;
    //         unitId: number;
    //         unitName: string;
    //         isDeleted: boolean;
    //         id: number;
    //         subscriberId: number;
    //         name: string;
    //         description: string;
    //         userId: string;
    //       }[];
    //       isDeleted: boolean;
    //       id: number;
    //       subscriberId: number;
    //       name: string;
    //       description: string;
    //       userId: string;
    //     }[];
    //     isDeleted: boolean;
    //     id: number;
    //     subscriberId: number;
    //     name: string;
    //     description: string;
    //     userId: string;
    //   }[];
    //   isHighlighted: boolean;
    // };
  }>()
);

export const GetProcessById = createAction(
  '[Process Workflow] Get Process By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveProcessData = createAction(
  '[Process Workflow] Save Process Data',
  props<{
    payload: any;
  }>()
);

export const GetProcessFormById = createAction(
  '[Process Workflow] Get Process Form By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveProcessForms = createAction(
  '[Process Workflow] Save Process Forms',
  props<{
    payload: any[] | null;
  }>()
);

export const GetProcessGroups = createAction(
  '[Process Workflow] Get Process Groups',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveProcessGroups = createAction(
  '[Process Workflow] Save Process Groups',
  props<{
    payload: any;
  }>()
);

export const GetActiveProcessGroups = createAction(
  '[Process Workflow] Get Active Process Groups'
);

export const SaveActiveProcessGroups = createAction(
  '[Process Workflow] Save Active Process Groups',
  props<{
    payload: any;
  }>()
);

export const CreateProcessGroup = createAction(
  '[Process Workflow] Create Process Group',
  props<{
    payload: {
      name: string;
      description: string;
      isDefault: boolean;
    };
  }>()
);

export const UpdateProcessGroup = createAction(
  '[Process Workflow] Update Process Group',
  props<{
    payload: {
      id: number;
      name: string;
      description: string;
      isDefault: boolean;
    };
  }>()
);

export const ArchiveProcessGroup = createAction(
  '[Process Workflow] Archive Process Group',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const UnarchiveProcessGroup = createAction(
  '[Process Workflow] Unarchive Process Group',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const DeleteProcessInitiator = createAction(
  '[Process Workflow] Delete Process Initiator',
  props<{
    payload: {
      processId: number;
      id: number;
      processRequestInitator: any;
    };
  }>()
);

export const GetWorkflowLevelsByWorkflowId = createAction(
  '[Process Workflow] Get Workflow Levels By Workflow Id',
  props<{
    payload: {
      workflowId: number;
    };
  }>()
);

export const SaveWorkflowLevelsByProcessWorkflowId = createAction(
  '[Process Workflow] Save Workflow Levels By Process Workflow Id',
  props<{
    payload: any;
  }>()
);

export const PublishProcess = createAction(
  '[Process Workflow] Publish Process',
  props<{
    payload: {
      id: number;
      processFormIdToPublish: number;
      processWorkflowIdToPublish: number;
    };
  }>()
);

export const DeleteWorkflowPhase = createAction(
  '[Process Workflow] Delete Workflow Phase',
  props<{
    payload: {
      workflowId: number;
      id: number;
      workflowPhaseIndex: any;
    };
  }>()
);

export const DeleteWorkflowProxy = createAction(
  '[Process Workflow] Delete Workflow Proxy',
  props<{
    payload: {
      workflowPhaseId: number;
      id: number;
      workflowProxy: any;
    };
  }>()
);

export const DeleteWorkflowLevel = createAction(
  '[Process Workflow] Delete Workflow Level',
  props<{
    payload: {
      workflowPhaseId: number;
      id: number;
      workflowLevelIndex: any;
      userCategory: 'internal' | 'external';
    };
  }>()
);

export const DeleteFormComponent = createAction(
  '[Process Workflow] Delete Form Component',
  props<{
    payload: {
      processFormId: number;
      id: number;
      component: any;
    };
  }>()
);
