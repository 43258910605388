import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as GeneralActions from '../general/general.actions';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import {
  GetCitiesResponseData,
  GetCountriesResponseData,
  GetStatesResponseData,
  Notification,
} from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { USE_DEVELOPER_TOKEN } from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class GeneralEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getIpAddressDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.GetIpAddressDetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .get<{ ip: string }>(`https://api.ipify.org/?format=json`)
          .pipe(
            mergeMap(
              (resData) =>
                this.http.get(`https://api.iplocation.net/?ip=${resData?.ip}`)
              // this.http.get(`http://ip-api.com/json/${resData?.ip}`)
            )
          )
          .pipe(
            map((resData: any) => {
              if (resData?.response_code === '200') {
                // if (resData?.status === 'success') {
                return GeneralActions.SaveIpAddressDetails({
                  payload: resData,
                  // payload: { ...resData, ip: resData?.query },
                });
              } else {
                return GeneralActions.SaveIpAddressDetails({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Get Ip Address Details ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // getCountryFromIpAddress$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(GeneralActions.GetCountryFromIpAddress),
  //     withLatestFrom(this.store.select('general')),
  //     switchMap(([generalData, generalState]) => {
  //       return this.http
  //         .get(
  //           // `http://api.ipapi.com/${generalState.ipAddress}?access_key=${environment.api_ipapi_access_token}format=1`
  //           `https://ipgeolocation.abstractapi.com/v1/?api_key=${environment.abstractApiKey}`
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             let country;

  //             if (resData.city) {
  //               if (resData.country === 'Nigeria') {
  //                 country = 'Nigeria';
  //               } else {
  //                 country = 'Others';
  //               }
  //             } else {
  //               country = 'Nigeria';
  //             }

  //             return GeneralActions.SaveCountryFromIpAddress({
  //               payload: country,
  //             });
  //           }),
  //           catchError((errorRes: any) => {
  //             return of({
  //               type: '[General] Failed To Get Country From Ip Address',
  //             });
  //           })
  //         );
  //     })
  //   );
  // });

  getCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.GetCountries),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .get<GetCountriesResponseData>(
            // `https://restcountries.com/v2/all`
            // `https://countriesnow.space/api/v0.1/countries`
            `${environment.onyxDocAuthUrl}/Utility/getcountrydetails`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData !== null) {
                const allCountries: string[] = [];

                resData.entity.forEach((el: any) => {
                  allCountries.push(el.name);
                });
                return GeneralActions.SaveCountries({
                  payload: allCountries.sort(),
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return GeneralActions.SaveCountries({ payload: null });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Get Countries ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.GetStates),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<GetStatesResponseData>(
            // `https://restcountries.com/v2/capital/${generalData.payload}`
            `https://countriesnow.space/api/v0.1/countries/states`,
            {
              country: generalData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.error === false) {
                const allStates: {
                  name: string;
                  state_code: string;
                }[] = [];

                resData.data.states.forEach((state_el) => {
                  allStates.push(state_el);
                });
                return GeneralActions.SaveStates({ payload: allStates });
              } else {
                return GeneralActions.SaveCountries({ payload: null });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Get States ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.GetCities),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<GetCitiesResponseData>(
            `https://countriesnow.space/api/v0.1/countries/state/cities`,
            {
              country: generalData.payload.country,
              state: generalData.payload.state,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.error === false) {
                return GeneralActions.SaveCities({ payload: resData.data });
              } else {
                return GeneralActions.SaveCities({ payload: null });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Get Cities ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getCountryCodes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.GetCountryCodes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .get<any>(
            // `https://restcountries.com/v2/all`
            `${environment.onyxDocAuthUrl}/Utility/getextendedcountrydetails`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                const allCountryCodes: {
                  flag: string;
                  country: string;
                  code: string;
                }[] = [];

                resData.entity.forEach((el: any) => {
                  allCountryCodes.push({
                    code: `+${el.callingCode}`,
                    country: el.name,
                    flag: el.flag,
                  });
                });

                return GeneralActions.SaveCountryCodes({
                  payload: allCountryCodes,
                });
              } else {
                return GeneralActions.SaveCountryCodes({ payload: null });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Get Country Codes ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createCustomerEnquiry = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.CreateCustomerEnquiry),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/ContactUs/createcustomerenquiry`,
            generalData.payload,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                return {
                  type: '[General] Create Customer Enquiry Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return { type: '[General] Failed To Create Customer Enquiry' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Create Customer Enquiry ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createFlowzWaitlist = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.CreateFlowzJoinWaitlist),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/ContactUs/createcustomerenquiry`,
            generalData.payload,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                return {
                  type: "[General] You've been added to Flowmono Flowz early access succesfully",
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[General] Failed To Add you to Flowz Waitlist',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Create Flowz Waitlist ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createCustomerSupportRequest = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.CreateCustomerSupportRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/ContactUs/createcustomersupportrequest`,
            generalData.payload,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                return {
                  type: '[General] Create Customer Support Request Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[General] Failed To Create Customer Support Request',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Create Customer Support Request ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  CreateBecomeApartnerRequest = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.CreateBecomeApartnerRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<any>(
            `${environment.reventUtilityServiceUrl}/Patnership/createpartnershiprequest`,
            generalData.payload,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );
                this.dialog.closeAll();

                return {
                  type: '[General] Create Become A partner Request Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[General] Failed To Create Become A partner Request',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Create Become A partner Request ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
