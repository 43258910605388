import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Formbuilder Dashboard] Reset Store');

export const IsLoading = createAction(
  '[Formbuilder Dashboard] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingFormbuilderDonutChartData = createAction(
  '[Formbuilder Dashboard] Is Loading Formbuilder Donut Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const SaveFormbuilderDonutChartData = createAction(
  '[Formbuilder Dashboard] Save Formbuilder Donut Chart Dashboard',
  props<{
    payload: any;
  }>()
);

export const GetFormbuilderDonutChart = createAction(
  '[Formbuilder Dashboard] Get Formbuilder Donut Dashboard',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const IsLoadingFormbuilderLineChartData = createAction(
  '[Formbuilder Dashboard] Is Loading Formbuilder Line Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const SaveFormbuilderLineChartData = createAction(
  '[Dashboard] Save Formbuilder Line Chart Dashboard',
  props<{
    payload: any;
  }>()
);

export const GetFormbuilderLineChart = createAction(
  '[Formbuilder Dashboard] Get Formbuilder Line Chart Dashboard',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const GetDashboardMetric = createAction(
  '[Formbuilder Dashboard] Get Dashboard Metric'
);

export const SaveDashboardMetric = createAction(
  '[Formbuilder Dashboard] Save Dashboard Metric',
  props<{
    payload: any;
  }>()
);

export const GetDraftsAction = createAction(
  '[Formbuilder Dashboard] Get Dashboard Drafts',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetRecentProjectsAction = createAction(
  '[Formbuilder Dashboard] Get Dashboard Recent Projects',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);
