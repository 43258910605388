import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  transform(...names: string[]): string | null {
    if (names && names.length) {
      let initial = '';
      switch (true) {
        case names.length > 1:
          initial = `${names[0][0]}${names[1][0]}`;
          break;

        default:
          // eslint-disable-next-line no-case-declarations
          const namesArray = names[0]?.split(' ');

          if (namesArray) {
            if (namesArray?.length > 1) {
              if (namesArray[0][0] === undefined) {
                initial = `${namesArray[1][0]}`;
              } else if (namesArray[1][0] === undefined) {
                initial = `${namesArray[0][0]}`;
              } else {
                initial = `${namesArray[0][0]}${namesArray[1][0]}`;
              }
            } else {
              initial = namesArray[0]?.substring(0, 2);
            }
          }
          break;
      }
      names.length > 1
        ? `${names[0][0]}${names[1][0]}`
        : names[0]?.substring(0, 2);
      return initial.toUpperCase();
    }

    return null;
  }
}
