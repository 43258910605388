import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';

@Component({
  selector: 'app-issue', //TODO This component is no longer needed
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss'],
})
export class IssueComponent implements OnInit, OnDestroy {
  year!: number;
  routeQueryParamsSub: Subscription;
  issueType:
    | 'email verification error'
    | 'email does not exist'
    | 'email not verified'
    | undefined = undefined;
  email: string | undefined = undefined;
  isLoading!: Observable<boolean>;
  returnUrl: string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.listenToRouteQueryParams();
  }

  listenToRouteQueryParams() {
    this.routeQueryParamsSub = this.route.queryParams.subscribe(
      (params: Params) => {
        this.issueType = params['issueType'];
        this.email = params['email'];
        this.returnUrl = params['returnUrl'];

        if (
          this.issueType == undefined ||
          this.email === undefined ||
          this.returnUrl === undefined
        ) {
          this.router.navigate(['/']);
        }
      }
    );
  }

  goback() {
    // this.router.navigate([this.returnUrl]);
    this.router.navigateByUrl(this.returnUrl!);
  }

  goToLoginPage() {
    this.router.navigate(['login'], {
      queryParams: {
        isEmailVerified: 'true',
        email: this.email,
      },
    });
  }

  goToIssue() {
    this.router.navigate(['register/issue'], {
      queryParams: {
        issueType: 'email verification error',
        email: this.email,
        returnUrl: this.router.url,
      },
    });
  }

  onSendVerificationEmail() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.store.dispatch(
      AuthActions.SendVerificationEmail({
        payload: {
          email: this.email!,
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.routeQueryParamsSub) {
      this.routeQueryParamsSub.unsubscribe();
    }
  }
}
