export enum SubscriptionFrequency {
  monthly = 1,
  yearly = 2,
}

export enum SubscriptionFreeTrialPeriodFrequencyEnum {
  Days = 1,
  Weeks = 2,
  Months = 3,
  Years = 4,
}

export enum SubscriptionStatusEnum {
  All = 0,
  Free_Trial = 1,
  Processing_Payment = 2,
  Active = 3,
  Expired = 4,
  Cancelled = 5,
  PendingActivations = 6,
}

export enum SubscriptionStatusDescriptionEnum {
  Free_Trial = 'Free Trial',
  Processing_Payment = 'ProcessingPayment',
  Active = 'Active',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  PendingActivations = 'Pending',
}

export enum SubscriptionClassEnum {
  SigningRequest = 1,
  Users = 2,
}

export enum SubscriptionPlanTypeEnum {
  Standard = 1,
  Custom = 2,
}
