import { createReducer, on, Action } from '@ngrx/store';
import * as AuditLogActions from './audit-log.action';

export interface State {
  isLoading: boolean;
  apiCallsLog: any[] | null;
}

const initialState: State = {
  isLoading: false,
  apiCallsLog: null,
};

const auditLogReducerInternal = createReducer(
  initialState,
  on(AuditLogActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(AuditLogActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(AuditLogActions.SaveAPICallsLog, (state, { payload }) => ({
    ...state,
    apiCallsLog: payload,
  }))
);

export function auditLogReducer(state: State | undefined, action: Action) {
  return auditLogReducerInternal(state, action);
}
