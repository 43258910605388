<section class="flwmn-signature-container">
  <div
    [id]="id"
    [formGroup]="form"
    class="flwmn-signature-control no-select"
    [ngStyle]="{ cursor: actorMenu === true ? 'not-allowed' : '' }"
    (click)="actorMenu === false ? onHandleDoubleClick() : ''"
  >
    <!-- (click)="actorMenu === false ? onAddSignature() : ''" -->
    <ng-container *ngIf="currentInitials; else noInitials">
      <img [src]="currentInitials" alt="" class="signature" />
    </ng-container>

    <ng-template #noInitials>
      <p
        class="no-signature-text"
        [ngClass]="{
          'no-signature-external-text': controlMode === controlModeEnum.External
        }"
      >
        {{
          controlMode === controlModeEnum.Internal
            ? 'Initials Field'
            : 'Double click to add initials '
        }}
      </p>
    </ng-template>

    <ng-template #applyAllFeedback>
      <section>
        <p class="flwmn-para-2">
          Do you want to apply this initials to all your initials fields?
        </p>
        <div class="d-flex justify-content-between gap-2">
          <button
            matRipple
            (click)="onResponse(true)"
            class="btn flwmn-btn-primary flwmn-btn-md"
          >
            Yes
          </button>

          <button
            matRipple
            (click)="onResponse(false)"
            class="btn flwmn-btn-danger-outline flwmn-btn-md"
          >
            No
          </button>
        </div>
      </section>
    </ng-template>
  </div>

  <div
    matRipple
    class="flwmn-edit-signature"
    [class.opacity-0]="signType"
    *ngIf="currentInitials && isCurrentRecipient()"
  >
    <p class="signature-name mb-0" (click)="onEditInitialsClicked()">
      Change initials
    </p>
  </div>
</section>
