import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SheetComponent } from './sheet.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [SheetComponent],
  imports: [CommonModule, OverlayModule],
  exports: [SheetComponent],
})
export class SheetModule {}
