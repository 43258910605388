<ng-container *ngIf="(isLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<!-- <section id="forgot-password" *ngIf="false">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <div class="forgot-password-image-container">
              <img
                src="/assets/icons/auth/password.svg"
                alt="Forgot Password"
              />
            </div>

            <h5 class="flwmn-heading-5 fw-700 header-text">Forgot Password?</h5>

            <p class="flwmn-caption subheader-text">
              No worries, it happens to the best of us.
            </p>

            <div class="input-style1 input-md">
              <label class="input-label">Email Address</label>

              <mat-form-field appearance="outline" [style.width.%]="100">
                <input
                  #email
                  type="email"
                  inputmode="email"
                  matInput
                  [formControl]="emailControl"
                  (input)="
                    emailControl.patchValue(email.value.replaceAll(' ', ''))
                  "
                />
                <mat-error *ngIf="emailControl.hasError('required')">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  Please enter your email
                </mat-error>

                <mat-error *ngIf="emailControl.hasError('email')">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  Sorry, this is not a valid email
                </mat-error>
              </mat-form-field>
            </div>

            <button
              matRipple
              class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full reset-btn"
              (click)="onSubmit()"
              [disabled]="emailControl.invalid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section> -->

<section id="new-forgot">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container>
          <div class="content-section-verify">
            <div class="form-section">
              <div class="device-verification-image-container">
                <img
                  src="/assets/icons/auth/password.svg"
                  alt="Device Verification"
                />
              </div>

              <h5 class="flwmn-heading-5 fw-600 header-text">
                Forgot Password?
              </h5>

              <p class="flwmn-para-1 subheader-text">
                No worries, it happens to the best of us.
              </p>

              <div class="auth-form-container">
                <div class="input-style1 input-md auth-input-mb">
                  <label class="input-label">Email Address</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      #email
                      type="email"
                      inputmode="email"
                      matInput
                      [formControl]="emailControl"
                      (input)="
                        emailControl.patchValue(email.value.replaceAll(' ', ''))
                      "
                    />
                    <mat-error *ngIf="emailControl.hasError('required')">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      Please enter your email
                    </mat-error>

                    <mat-error *ngIf="emailControl.hasError('email')">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      Sorry, this is not a valid email
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <button
                matRipple
                class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full login-btn rounded-pill"
                (click)="onSubmit()"
                [disabled]="emailControl.invalid"
              >
                Submit
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
