import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/@core/services/helper.service';
import * as UserActions from 'src/app/@core/stores/user/user.actions';
import * as UserRoleActions from 'src/app/@core/stores/user-role/user-role.actions';
import * as userRoleSelectors from 'src/app/@core/stores/user-role/user-role.selectors';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import { Store } from '@ngrx/store';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { UserEffects } from 'src/app/@core/stores/user/user.effects';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';

@Component({
  selector: 'app-invite-team',
  templateUrl: './invite-team.component.html',
  styleUrls: ['./invite-team.component.scss'],
})
export class InviteTeamComponent implements OnInit {
  isAuthLoading!: Observable<boolean>;
  isFromDeveloperWebsite: boolean = false;
  year!: number;

  subscriberId!: string;
  userId!: string;

  inviteForm!: FormGroup;

  allRoles: any[] | null = null;

  private _subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private router: Router,
    private userEffects: UserEffects
  ) {
    this._subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.checkForParams();

    this.isAuthLoading = this.store.select(authSelectors.getAuthIsLoading);
    this.createRegistrationForm();

    this.year = new Date().getFullYear();
    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getAllRoles.bind(this)
    );

    this.trackInvites();
  }

  checkForParams() {
    this.route.queryParams.subscribe((params) => {
      const subscriberId = params['subscriberId'];
      const userId = params['userId'];

      if (subscriberId && userId) {
        this.userId = userId;
        this.subscriberId = subscriberId;
      }
    });
  }

  getAllRoles() {
    this.store.dispatch(
      UserRoleActions.GetAllUserRoles({
        payload: {
          skip: 0,
          take: 0,
          userId: this.userId,
          subscriberId: parseInt(this.subscriberId),
        },
      })
    );

    this._subscription.add(
      this.store
        .select(userRoleSelectors.getAllUserRoles)
        .subscribe((resData) => {
          if (resData) {
            this.allRoles = resData?.roles?.filter(
              (role: any) => role.status !== 2
            );
          }
        })
    );
  }

  createRegistrationForm() {
    this.inviteForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      invitedUsers: this.fb.array([]),
    });
  }

  get registrationFormControls() {
    return this.inviteForm.controls;
  }

  get invitedUserArray() {
    return this.inviteForm.get('invitedUsers') as FormArray;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('required')
    ) {
      return 'Please enter your email';
    } else if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('email')
    ) {
      return 'Sorry, this is not a valid email';
    }
  }

  createInvitedUser(user: any) {
    return this.fb.group({
      email: [user, [Validators.required, Validators.email]],
      role: [null, Validators.required],
    });
  }

  onEnterPress(event: any) {
    const value = event.target.value;
    if (!value) {
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      const notification: Notification = {
        state: 'error',
        message: 'Input a valid email.',
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-error',
        false,
        'top-right',
        2
      );

      return;
    }

    const recipient = value;

    if (this.invitedUserArray.value.length === 0) {
      this.invitedUserArray.push(this.createInvitedUser(recipient));
    } else {
      const userExist = !this.invitedUserArray.value.some((el: any) => {
        return el.email === recipient;
      });

      if (userExist) {
        this.invitedUserArray.push(this.createInvitedUser(recipient));
      }
    }

    const emailControl = this.inviteForm.get('email');

    emailControl?.reset();
    emailControl?.clearValidators();
    emailControl?.updateValueAndValidity();
  }

  submitInvite() {
    this.store.dispatch(UserActions.IsLoading({ payload: true }));

    const { invitedUsers } = this.inviteForm.value;

    const inviteesDictionary = invitedUsers.reduce(
      (previousValue: any, currentValue: any) => ({
        ...previousValue,
        [currentValue.email]: currentValue.role,
      }),
      {}
    );

    this.store.dispatch(
      UserActions.InviteMultipleUsers({
        payload: {
          roleId: invitedUsers[0].role,
          invitedRecipients: inviteesDictionary,
          userId: this.userId,
          subscriberId: parseInt(this.subscriberId),
        },
      })
    );
  }

  trackInvites() {
    this._subscription.add(
      this.userEffects.inviteMultiplesUsers$.subscribe((resData) => {
        if (resData.succeeded) {
          this.login();
        }
      })
    );
  }

  login() {
    this.router.navigate(['/login']);
  }

  onSubmit() {
    this.submitInvite();
  }
}
