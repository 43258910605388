import { Action, createReducer, on } from '@ngrx/store';
import * as userGroupAction from 'src/app/@core/stores/user-group/user-group.actions';

export interface userGroupReducerState {
  isLoading: boolean;
  units: any[];
  departments: any[];
  departmentsMembers: any[];
  divisions: any[];
  departmentsByDivisionId: any[];
  divisionWithNumbersOfUsers: any[];
  rolesWithNumbersOfUsers: any[];
  groupDetails: any[];
  error: any | null;
  createdUserGroup: any[];
  createdUserGroupIsLoading: boolean;
  userGroupById: any[];
  userGroupData: any;
}

export const initialState: userGroupReducerState = {
  isLoading: false,
  units: [],
  departments: [],
  divisions: [],
  departmentsMembers: [],
  departmentsByDivisionId: [],
  divisionWithNumbersOfUsers: [],
  rolesWithNumbersOfUsers: [],
  groupDetails: [],
  error: null,
  createdUserGroup: [],
  createdUserGroupIsLoading: false,
  userGroupById: [],
  userGroupData: null,
};

const userGroupReducerInternal = createReducer(
  initialState,
  on(userGroupAction.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(userGroupAction.SaveGetUnits, (state, { payload }) => ({
    ...state,
    units: [...payload],
  })),

  on(userGroupAction.SaveGetDepartment, (state, { payload }) => ({
    ...state,
    departments: [...payload],
  })),

  on(
    userGroupAction.SaveAllDepartmentsWithNumbersOfUsers,
    (state, { payload }) => ({
      ...state,
      departmentsMembers: [...payload],
    })
  ),

  on(userGroupAction.SaveDivisions, (state, { payload }) => ({
    ...state,
    divisions: [...payload],
  })),

  on(userGroupAction.GetDivisionWithNumbersOfUsers, (state, { payload }) => ({
    ...state,
    divisions: [payload],
  })),

  on(userGroupAction.SaveDivisionsWithNumbersOfUsers, (state, { payload }) => ({
    ...state,
    divisionWithNumbersOfUsers: [payload],
  })),

  on(userGroupAction.GetDepartmentByDivisionId, (state, { payload }) => ({
    ...state,
    departmentsByDivisionId: [payload],
  })),

  on(userGroupAction.SaveDepartmentByDivisionId, (state, { payload }) => ({
    ...state,
    departmentsByDivisionId: [...payload],
  })),

  on(userGroupAction.SaveRolesWithNumbersOfUsers, (state, { payload }) => {
    return {
      ...state,
      rolesWithNumbersOfUsers: [...payload],
    };
  }),

  on(userGroupAction.SaveUserGroup, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(userGroupAction.SaveUserGroupSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(userGroupAction.SaveCreatedUserGroup, (state, { payload }) => {
    return {
      ...state,
      createdUserGroup: [...payload],
    };
  }),

  on(userGroupAction.createdUserGroupIsLoading, (state, { payload }) => {
    return {
      ...state,
      createdUserGroupIsLoading: payload,
    };
  }),

  on(userGroupAction.SaveGetUserGroupById, (state, { payload }) => {
    return {
      ...state,
      userGroupById: [...payload],
    };
  }),
  on(userGroupAction.PassUserGroupData, (state, { payload }) => {
    return {
      ...state,
      userGroupData: payload,
    };
  })
);

export function userGroupReducer(
  state: userGroupReducerState | undefined,
  action: Action
) {
  return userGroupReducerInternal(state, action);
}
