import { createReducer, Action, on } from '@ngrx/store';

import * as ResourceConfigurationActions from './resource-configuration.actions';

export interface resourceReducerState {
  isLoading: boolean;
  microService: any[];
  targetObject: any[];
  targetObjectFields: any[];
  resourceData: any;
  activeResource: { resource: any[]; totalCount: number } | null;
  inactiveResource: { resource: any[]; totalCount: number } | null;
  pendingResource: { resource: any[]; totalCount: number } | null;
  rejectedResource: { resource: any[]; totalCount: number } | null;
  approvedResource: { resource: any[]; totalCount: number } | null;
}

const initialState: resourceReducerState = {
  isLoading: false,
  microService: [],
  targetObject: [],
  targetObjectFields: [],
  resourceData: null,
  activeResource: null,
  inactiveResource: null,
  pendingResource: null,
  rejectedResource: null,
  approvedResource: null,
};

const resourceConfigurationReducerInternal = createReducer(
  initialState,

  on(ResourceConfigurationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(ResourceConfigurationActions.SaveActiveResource, (state, { payload }) => ({
    ...state,
    activeResource: payload,
  })),

  on(
    ResourceConfigurationActions.SaveInactiveResource,
    (state, { payload }) => ({
      ...state,
      inactiveResource: payload,
    })
  ),
  on(
    ResourceConfigurationActions.SavePendingResource,
    (state, { payload }) => ({
      ...state,
      pendingResource: payload,
    })
  ),

  on(
    ResourceConfigurationActions.SaveRejectedResource,
    (state, { payload }) => ({
      ...state,
      rejectedResource: payload,
    })
  ),

  on(
    ResourceConfigurationActions.SaveApprovedResource,
    (state, { payload }) => ({
      ...state,
      approvedResource: payload,
    })
  ),

  on(
    ResourceConfigurationActions.SaveAllMicroService,
    (state, { payload }) => ({
      ...state,
      microService: payload,
    })
  ),

  on(
    ResourceConfigurationActions.SaveAllTargetObject,
    (state, { payload }) => ({
      ...state,
      targetObject: payload,
    })
  ),

  on(
    ResourceConfigurationActions.SaveAllTargetObjectFields,
    (state, { payload }) => ({
      ...state,
      targetObjectFields: payload,
    })
  ),

  on(ResourceConfigurationActions.SaveAllRequestById, (state, { payload }) => ({
    ...state,
    resourceData: payload,
  }))
);

export function resourceConfigurationReducer(
  state: resourceReducerState | undefined,
  action: Action
) {
  return resourceConfigurationReducerInternal(state, action);
}
