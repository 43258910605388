<div id="request-headers">
  <p class="flwmn-caption">Query Parameters</p>

  <table class="request-table">
    <thead>
      <tr>
        <th></th>
        <th>Key</th>
        <th>Value</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows; let i = index" class="table-row">
        <td>
          <mat-checkbox color="primary" [checked]="true"> </mat-checkbox>
        </td>
        <td>
          <div class="input-style1 input-no-error input-borderless">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input
                type="text"
                matInput
                placeholder="Key"
                [(ngModel)]="row.key"
              />
            </mat-form-field>
          </div>
        </td>
        <td>
          <div class="input-style1 input-no-error input-borderless">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input
                type="text"
                matInput
                placeholder="Value"
                [(ngModel)]="row.value"
              />
            </mat-form-field>
          </div>
        </td>
        <td>
          <div class="input-style1 input-no-error input-borderless">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input
                type="text"
                matInput
                placeholder="Description"
                [(ngModel)]="row.description"
              />
            </mat-form-field>
          </div>
        </td>

        <td class="delete-button-cell">
          <app-svg
            *ngIf="rows.length > 1"
            (click)="deleteRow(i)"
            matRipple
            class="flwmn-pointer"
            src="assets/icons/delete.svg"
          ></app-svg>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="action">
    <app-svg
      (click)="addRow()"
      matRipple
      class="flwmn-pointer"
      src="assets/icons/flowz/tasks/send-request/add.svg"
    ></app-svg>
  </div>
</div>
