<div id="request-body">
  <div class="left">
    <div class="input-style1 input-no-error input-fit-content input-no-error">
      <mat-radio-group
        class="flwmn-radio-group flwmn-radio-group-column"
        [(ngModel)]="bodyType"
      >
        <div
          class="flwmn-radio-btn"
          *ngFor="let type of ['none', 'form-data', 'raw']"
        >
          <mat-radio-button [value]="type">
            {{ type }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <div class="right">
    <!-- None -->
    <ng-container *ngIf="bodyType === 'none'">
      <div class="none">
        <p class="flwmn-caption mb-0">
          This request does not use any authorization
        </p>
      </div>
    </ng-container>

    <!-- Form Data -->
    <ng-container *ngIf="bodyType === 'form-data'">
      <div class="form-data row">
        <div class="col-12 col-md-6">
          <div class="input-style1 input-no-error">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input type="text" matInput placeholder="Key" />
            </mat-form-field>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div
            class="input-style1 input-fit-content input-no-error custom-input"
          >
            <span class="custom-icon">
              <app-svg
                src="assets/icons/flowz/tasks/text.svg"
                svgPathStroke="var(--flwmn-neutral-600)"
              ></app-svg>
            </span>

            <mat-form-field appearance="outline" [style.width.%]="100">
              <span style="margin-right: 1.3rem"></span>
              <input type="text" matInput placeholder="Value" />
            </mat-form-field>
          </div>
        </div></div
    ></ng-container>

    <!-- API Key -->
    <ng-container *ngIf="bodyType === 'raw'">
      <div class="input-style1 input-no-error">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <textarea matInput rows="20" placeholder="Enter Content"></textarea>
        </mat-form-field></div
    ></ng-container>
  </div>
</div>
