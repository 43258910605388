export enum RoleAccessLevelEnum {
  SuperAdmin = 1,
  Admin = 2,
  PowerUser = 3,
  User = 4,
}

export enum RoleStatusEnum {
  Active = 1,
  Inactive = 2,
}
