<div id="request-authorization">
  <div class="left">
    <div class="input-style1 input-no-error input-fit-content input-no-error">
      <label class="input-label">Auth type</label>

      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-select
          placeholder="Authorization Type"
          [(ngModel)]="authorizationType"
        >
          <mat-option
            *ngFor="let authorization of ['No Auth', 'Bearer Token', 'API Key']"
            [value]="authorization"
          >
            {{ authorization }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="right">
    <!-- No Auth -->
    <ng-container *ngIf="authorizationType === 'No Auth'">
      <div class="no-auth">
        <p class="flwmn-caption mb-0">
          This request does not use any authorization
        </p>
      </div>
    </ng-container>

    <!-- Bearer Token -->
    <ng-container *ngIf="authorizationType === 'API Key'">
      <div class="bearer row">
        <div class="col-12 col-md-6">
          <div class="input-style1 input-no-error">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input type="text" matInput placeholder="Key" />
            </mat-form-field>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="input-style1 input-no-error">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <input type="text" matInput placeholder="Value" />
            </mat-form-field>
          </div>
        </div></div
    ></ng-container>

    <!-- API Key -->
    <ng-container *ngIf="authorizationType === 'Bearer Token'">
      <div class="input-style1 input-no-error">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <textarea matInput rows="20" placeholder="Enter Content"></textarea>
        </mat-form-field></div
    ></ng-container>
  </div>
</div>
