import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface authReducerState {
  isLoading: boolean;
  toVerifyDevice: any;
  verifyDeviceStatus: any;
  developerTokenExists: boolean;
  accessTokenExists: boolean;
  user: any;
  permissions: string[] | null;
  error?: Error;
  linkedInUrl: any;
  documentBrandingData: any;
  // systemSettingData: any;
  branding: any;
  rolePermissions: any;
  waitlistProductType: any;
  userProfileDetails: any;
  devices: any[];
  errorMessage: string | null;
  email: any;
}

const initialState: authReducerState = {
  isLoading: false,
  toVerifyDevice: null,
  verifyDeviceStatus: null,
  developerTokenExists: false,
  accessTokenExists: false,
  user: null,
  permissions: null,
  linkedInUrl: null,
  error: undefined,
  documentBrandingData: null,
  // systemSettingData: null,
  branding: null,
  rolePermissions: null,
  waitlistProductType: null,
  userProfileDetails: null,
  devices: [],
  errorMessage: null,
  email: null,
};

const authReducerInternal = createReducer(
  initialState,
  on(AuthActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(AuthActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(AuthActions.ToVerifyDevice, (state, { payload }) => ({
    ...state,
    toVerifyDevice: payload,
  })),
  on(AuthActions.UpdateDeveloperTokenStatus, (state, { payload }) => ({
    ...state,
    developerTokenExists: payload.status,
  })),
  on(AuthActions.UpdateAccessTokenStatus, (state, { payload }) => ({
    ...state,
    accessTokenExists: payload.status,
  })),
  on(AuthActions.RefreshTokenSuccess, (state, { payload }) => ({
    ...state,
    user: payload.user,
    permissions: payload.permissionsArray,
    isLoading: false,
    branding: payload.branding,
  })),
  on(AuthActions.AuthenticateSuccess, (state, { payload }) => ({
    ...state,
    user: payload.user,
    permissions: payload.permissionsArray,
    isLoading: false,
    branding: payload.branding,
  })),
  on(AuthActions.Logout, (state) => ({
    ...state,
    isLoading: true,
  })),
  // on(AuthActions.ClearUser, (state) => ({
  //   ...state,
  //   user: null,
  //   permissions: null,
  //   isLoading: false,
  // })),
  on(AuthActions.LinkedInSignUp, (state) => ({
    ...state,
  })),
  on(AuthActions.SaveDocumentBranding, (state, { payload }) => ({
    ...state,
    documentBrandingData: payload,
  })),
  // on(AuthActions.SaveSystenSetting, (state, { payload }) => ({
  //   ...state,
  //   systemSettingData: payload,
  // })),
  on(AuthActions.SaveRolePermissions, (state, { payload }) => ({
    ...state,
    rolePermissions: payload,
  })),
  on(AuthActions.SaveWaitlistProductType, (state, { payload }) => ({
    ...state,
    waitlistProductType: payload,
  })),
  on(AuthActions.SaveUserProfileDetailsById, (state, { payload }) => ({
    ...state,
    userProfileDetails: payload,
  })),
  on(AuthActions.SaveConfirmDeviceStatus, (state, { payload }) => ({
    ...state,
    verifyDeviceStatus: payload,
  })),

  on(AuthActions.SaveDevicesById, (state, { payload }) => ({
    ...state,
    devices: payload,
  })),

  on(AuthActions.SaveUserTwoFADetails, (state, { payload }) => ({
    ...state,
    email: payload,
  }))
);

export function authReducer(
  state: authReducerState | undefined,
  action: Action
) {
  return authReducerInternal(state, action);
}
