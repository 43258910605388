import { createAction, props } from '@ngrx/store';
import { Calendar } from '../../interfaces/calendar.interface';

export const IsLoading = createAction(
  '[Calendar] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateCalendar = createAction(
  '[Calendar] Create Calendar',
  props<{
    payload: any;
  }>()
);

export const CreateSuccessful = createAction(
  '[Calendar] Creation Successful',
  props<{
    payload: boolean;
  }>()
);

export const ResetCurrentCalendar = createAction(
  '[Calendar] Reset Current Calendar',
  props<{
    payload: string;
  }>()
);

export const GetCurrentCalendar = createAction(
  '[Calendar] Edit Current Calendar',
  props<{
    payload: string;
  }>()
);

export const UpdateCalendar = createAction(
  '[Calendar] Update Calendar',
  props<{
    payload: any;
  }>()
);

export const GetDefaultCalendar = createAction(
  '[Calendar] Get Default Calendar',
  props<{
    payload: string;
  }>()
);

export const MakeDefaultCalendar = createAction(
  '[Calendar] Make Default Calendar',
  props<{
    payload: any;
  }>()
);

export const DeleteCalendar = createAction(
  '[Calendar] Delete Calendar',
  props<{
    payload: string;
  }>()
);

export const GetAllCalendars = createAction(
  '[Calendar] Get All Calendars',
  props<{
    payload: any;
  }>()
);

export const GetCalendarById = createAction(
  '[Calendar] Get Calendar By Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      id: string;
    };
  }>()
);
export const SaveCalendarById = createAction(
  '[Calendar] Save Calendar By Id',
  props<{
    payload: Calendar;
  }>()
);

export const SaveAllCalendars = createAction(
  '[Calendar] Save all Calendars',
  props<{
    payload: any;
  }>()
);

export const SaveLastCreatedCalendar = createAction(
  '[Calendar] Save Default Calendar',
  props<{
    payload: Calendar;
  }>()
);
export const SaveDefaultCalendar = createAction(
  '[Calendar] Save Default Calendars',
  props<{
    payload: Calendar;
  }>()
);

export const ActivateCalendar = createAction(
  '[Calendar] Activate Calendar',
  props<{
    payload: {
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
      guId: string;
    };
  }>()
);

export const DeactivateCalendar = createAction(
  '[Calendar] Deactivate Calendar',
  props<{
    payload: {
      subscriberId: number;
      ownerId: string;
      guId: string;
    };
  }>()
);
