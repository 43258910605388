<div id="view-mode" *ngIf="views.length > 0">
  <div
    *ngFor="let view of views"
    matRipple
    class="view-mode no-select"
    [ngClass]="{
      active: view?.name === activeView
    }"
    (click)="setActiveView(view.name)"
  >
    <app-svg *ngIf="view?.icon" [src]="view?.icon!"></app-svg>

    <p class="view-mode-text">{{ view?.name }}</p>
  </div>
</div>
