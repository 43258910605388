import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  manageAllPageTitlesAndDescription() {
    const route = location.pathname;

    if (route === '/') {
      this.setPageTitle(
        `Swiftly Manage and Sign Documents Online with Flowmono`
      );

      this.setPageDescription(
        `Take control of your work with Flowmono's powerful document management tools. Easily sign, approve, and send documents in seconds for a streamlined workflow.`
      );
    } else if (route.includes('/features')) {
      this.setPageTitle(
        `Automate, collaborate, manage documents – all in one platform`
      );

      this.setPageDescription(
        `Explore Flowmono's powerful features: process automation, eSigning, document management, workflow builder, and more.`
      );
    } else if (route.includes('/use-cases/banking-and-finance')) {
      this.setPageTitle(
        `Secure workflow process and collaboration for Banks and Fintechs`
      );

      this.setPageDescription(
        `Simplify finance tasks with Flowmono. Automate loan applications, secure document management, and enhance customer experience seamlessly.`
      );
    } else if (route.includes('/use-cases/insurance')) {
      this.setPageTitle(`Claims and policy management made easy`);

      this.setPageDescription(
        `Document management for modern insurance practices. Simplify claims processing, send quotes, and manage policies efficiently with Flowmono.`
      );
    } else if (route.includes('/use-cases/real-estate')) {
      this.setPageTitle(
        `Get signatures and close deals fast from anywhere, anytime.`
      );

      this.setPageDescription(
        `Transform your real estate process with Flowmono. Automate contracts, and e-sign documents, and collaborate effortlessly for a seamless client experience.`
      );
    } else if (route.includes('/use-cases/public-service')) {
      this.setPageTitle(
        `Serve efficiently and improve citizen-government interactions`
      );

      this.setPageDescription(
        `Enhance citizen services, automate paperwork, and improve operational efficiency.`
      );
    } else if (route.includes('/use-cases/healthcare')) {
      this.setPageTitle(
        `Streamlining healthcare workflows for improved patient care`
      );

      this.setPageDescription(
        `Optimize hospital operations, modernize patient intake, and secure patient records. Deliver better care, reduce errors, and empower healthcare professionals`
      );
    } else if (route.includes('/use-cases/legal')) {
      this.setPageTitle(
        `Simplify Client Contracts and Processes with Flowmono`
      );

      this.setPageDescription(
        `Automate contract lifecycle management, and e-sign documents, and collaborate securely with Flowmono. `
      );
    } else if (route.includes('/use-cases/sales-and-marketing')) {
      this.setPageTitle(
        `Sell Smarter, Not Harder. Boosts your sales & marketing processes from anywhere.`
      );

      this.setPageDescription(
        `Send proposals, contracts, and client onboarding with  Flowmono. Optimize your sales funnel, track opportunities, and close deals faster. `
      );
    } else if (route.includes('/use-cases/human-resource')) {
      this.setPageTitle(`Onboard and document HR management smoothly`);

      this.setPageDescription(
        `Simplify HR processes. Automate onboarding, eSign documents, and manage employee records with Flowmono. Save time, reduce errors, and empower your HR team.`
      );
    } else if (route.includes('/api')) {
      this.setPageTitle(
        `Integrate e-signatures and powerful workflow processes into your apps`
      );

      this.setPageDescription(
        `Extend your application's capabilities with Flowmono API. Automate workflows, manage documents and connect with other tools seamlessly.`
      );
    } else {
      this.setPageTitle(
        `Swiftly Manage and Sign Documents Online with Flowmono`
      );

      this.setPageDescription(
        `Take control of your work with Flowmono's powerful document management tools. Easily sign, approve, and send documents in seconds for a streamlined workflow.`
      );
    }
  }

  setPageTitle(title: string) {
    this.document.title = title;
  }

  setPageDescription(description: string) {
    const canonicalLink = document.querySelector(
      `meta[name="description"]`
    ) as HTMLElement;

    canonicalLink.setAttribute('content', description);
  }

  setCanonicalLinkURL() {
    const canonicalLink = document.querySelector(
      `[rel="canonical"]`
    ) as HTMLElement;

    canonicalLink.setAttribute('href', this.document.URL);
  }
}
