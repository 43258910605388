import { createReducer, on, Action } from '@ngrx/store';
import * as SigningRequestCategoryActions from './signing-request-category.actions';

export interface State {
  isLoading: boolean;
  categories: any[] | null;
  signingRequestCategoryById: any;
  categoryAuditList: any[] | null;
  auditByIdDetails: any | null;
}

const initialState: State = {
  isLoading: false,
  categories: null,
  signingRequestCategoryById: undefined,
  categoryAuditList: null,
  auditByIdDetails: null,
};

const signingRequestCategoryReducerInternal = createReducer(
  initialState,
  on(SigningRequestCategoryActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(SigningRequestCategoryActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  //   on(
  //     SigningRequestCategoryActions.SaveSigningRequestCategoryById,
  //     (state, { payload }) => ({
  //       ...state,
  //       signingRequestCategoryById: payload,
  //     })
  //   ),

  on(
    SigningRequestCategoryActions.SaveSigningRequestCategory,
    (state, { payload }) => ({
      ...state,
      categories: payload,
    })
  ),

  on(
    SigningRequestCategoryActions.SaveSigningRequestCategoryAuditList,
    (state, { payload }) => ({
      ...state,
      categoryAuditList: payload,
    })
  ),

  on(
    SigningRequestCategoryActions.SaveSigningRequestAuditListDetailById,
    (state, { payload }) => ({
      ...state,
      auditByIdDetails: payload,
    })
  )
);

export function signingRequestCategoryReducer(
  state: State | undefined,
  action: Action
) {
  return signingRequestCategoryReducerInternal(state, action);
}
