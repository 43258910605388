import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as CheckoutActions from './checkout.action';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as AuditLogActions from './checkout.action';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class CheckoutEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(AuditLogActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  signUpAndSubscribe$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CheckoutActions.SignUpAndSubscribe),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`,
              authData.payload
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  CheckoutActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Registration Was Successful',
                  });

                  this.store.dispatch(
                    CheckoutActions.SaveSubscriber({
                      payload: resData.entity,
                    })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Register',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Register ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
