import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-headers',
  templateUrl: './request-headers.component.html',
  styleUrls: ['./request-headers.component.scss'],
})
export class RequestHeadersComponent implements OnInit {
  rows: { key: string; value: string; description: string }[] = [
    { key: 'Host', value: '<calculatedwhen request is sent>', description: '' },
    { key: 'User agent', value: 'flowmonoRuntime/7.38', description: '' },
    { key: 'Accept', value: '*/*', description: '' },
    { key: 'Accept-Encoding', value: 'gzip, deflate, r', description: '' },
    { key: 'Connection', value: 'keep-alive', description: '' },
  ];

  constructor() {}

  ngOnInit(): void {
    console;
  }

  addRow() {
    this.rows.push({ key: '', value: '', description: '' });
  }

  deleteRow(index: number) {
    this.rows.splice(index, 1);
  }
}
