import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpContextToken,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from '../services/helper.service';

export const USE_CUSTOM_BEARER_TOKEN = new HttpContextToken(() => false);
export const CUSTOM_BEARER_TOKEN = new HttpContextToken(() => 'Bearer Custom');
export const USE_DEVELOPER_TOKEN = new HttpContextToken(() => false);
export const USE_ACCESS_TOKEN = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root',
})
export class AppInterceptorService implements HttpInterceptor {
  constructor(private helperService: HelperService) {
    this.helperService;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders({
      // 'Content-Security-Policy': '*',
      // 'X-Frame-Options': 'SAMEORIGIN',
      // 'X-Content-Type-Options': 'nosniff',
      // 'Referrer-Policy': 'strict-origin-when-cross-origin',
      // 'Permissions-Policy': 'geolocation=(), microphone=()',
      // 'user-agent': navigator.userAgent,
    });

    if (request.context.get(USE_CUSTOM_BEARER_TOKEN) === true) {
      headers = headers.set(
        'Authorization',
        request.context.get(CUSTOM_BEARER_TOKEN)
      );
      const modifiedRequest = request.clone({
        // headers: request.headers.set(
        //   'Authorization',
        //   request.context.get(CUSTOM_BEARER_TOKEN)
        // ),
        headers: headers,
      });

      return next.handle(modifiedRequest);
    } else if (request.context.get(USE_DEVELOPER_TOKEN) === true) {
      if (localStorage.getItem('OnyxDoc_auth') !== null) {
        const OnyxDocAuthData: {
          expiryDate: number;
          bearer_token: string;
        } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

        headers = headers.set(
          'Authorization',
          `Bearer ${OnyxDocAuthData.bearer_token}`
        );

        const modifiedRequest = request.clone({
          // headers: request.headers.set(
          //   'Authorization',
          //   `Bearer ${OnyxDocAuthData.bearer_token}`
          // ),

          headers: headers,
        });

        return next.handle(modifiedRequest);
      } else {
        return next.handle(request); //Tentative
      }
    } else if (request.context.get(USE_ACCESS_TOKEN) === true) {
      if (localStorage.getItem('OnyxDoc') !== null) {
        const OnyxDocData: {
          accessToken: string;
          userToken: string;
          exp: number;
        } = JSON.parse(localStorage.getItem('OnyxDoc')!);

        headers = headers.set(
          'Authorization',
          `Bearer ${OnyxDocData.accessToken}`
        );
        const modifiedRequest = request.clone({
          // headers: request.headers.set(
          //   'Authorization',
          //   `Bearer ${OnyxDocData.accessToken}`
          // ),

          headers: headers,
        });

        return next.handle(modifiedRequest);
      } else {
        return next.handle(request);
      }
    } else {
      /** //TODO
       * Due to the addition of httpContextToken
       * the following else block is outdated.
       * When all the endpoints on Flowmono are updated with
       * context metadata, the else block can be removed and
       * replaced with
       * else {
       * return next.handle(request);
       *  }
       * **/

      if (
        request.url === `${environment.onyxDocAuthUrl}/Authentication/login` ||
        request.url === `${environment.onyxDocAuthUrl}/Subscribers/signup` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Users/checkupdomainname` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Users/joinexistingorganization` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Authentication/forgotpassword` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Authentication/resetpassword` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Authentication/loginwiththirdparty` ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Users/emailconfirmation?Email=`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Authentication/linkedinsignup`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Authentication/loginwithlinkedin`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Authentication/linkedinauthorization`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Users/editinviteduser`
        ) ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getactivesubscriptionplans/`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Subscribers/checkemail`
        ) ||
        request.url.includes(
          `${environment.onyxDocDocumentUrl}/Recipients/getrecipientbyemail`
        ) ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getsubscriptionplanbyid/${environment.onyxDocAdminSubscriberId}`
        ) ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`
        ) ||
        request.url ===
          `${environment.onyxDocSubscriptionUrl}/PaymentChannels/getactivepaymentchannels/${environment.onyxDocAdminSubscriberId}/null` ||
        request.url ===
          `${environment.onyxDocSubscriptionUrl}/Paystack/createpaystackpayment` ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/Paystack/verifypaystackpayment`
        ) ||
        request.url ===
          `${environment.onyxDocSubscriptionUrl}/Flutterwave/initializeflutterwavepayment` ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/Flutterwave/verifyflutterwavepayment`
        ) ||
        request.url.includes(
          `${environment.onyxDocSubscriptionUrl}/Stripe/getpaymentintentstatus`
        ) ||
        request.url.includes(
          `${environment.onyxDocDocumentUrl}/Documents/decodeurlhash/hash/sharedDocument?documentHash=`
        ) ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/Documents/uploaddocument` ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/Recipients/createrecipients` ||
        request.url.includes(
          `${environment.onyxDocDocumentUrl}/Recipients/getrecipientsbydocumentid`
        ) ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Users/getuserverificationstatus`
        ) ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/Documents/senddocumenttosignatories` ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/Documents/updatecomponent` ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/Documents/signandacceptdocument` ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/RecipientActions/rejectdocument` ||
        request.url.includes(
          `${environment.onyxDocFormBuilderUrl}/Controls/getactivecontrols`
        ) ||
        request.url ===
          `${environment.onyxDocDocumentUrl}/DocumentFeedback/createfeedback` ||
        // request.url.includes(
        //   `${environment.onyxDocSubscriptionUrl}/SubscriptionPayment/makesubscriptionpayment`
        // ) ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Utility/getcountrydetails` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Utility/getextendedcountrydetails` ||
        request.url ===
          `${environment.onyxDocAuthUrl}/Subscribers/sendverificationemail` ||
        request.url.includes(
          `${environment.onyxDocAuthUrl}/Users/getapiuserbyid`
        ) ||
        request.url.includes(`${environment.onyxCore}`) ||
        request.url.includes(`${environment.onyxDocReminderUrl}`)
      ) {
        if (localStorage.getItem('OnyxDoc_auth') !== null) {
          const OnyxDocAuthData: {
            expiryDate: number;
            bearer_token: string;
          } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

          headers = headers.set(
            'Authorization',
            `Bearer ${OnyxDocAuthData.bearer_token}`
          );

          const modifiedRequest = request.clone({
            // headers: request.headers.set(
            //   'Authorization',
            //   `Bearer ${OnyxDocAuthData.bearer_token}`
            // ),
            headers: headers,
            // body: {
            //   data: this.helperService.AES_Encryption_Decryption(
            //     'encrypt',
            //     JSON.stringify(request.body),
            //   ),
            // },
          });

          return next.handle(modifiedRequest);
        } else {
          return next.handle(request); //Tentative
        }
      } else if (
        ((request.url !==
          `${environment.onyxDocAuthUrl}/Authentication/login` ||
          request.url !== `${environment.onyxDocAuthUrl}/Subscribers/signup`) &&
          request.url.includes(environment.onyxDocAuthUrl)) ||
        request.url.includes(environment.onyxDocSubscriptionUrl) ||
        request.url.includes(environment.onyxDocAlternateDocumentUrl) ||
        request.url.includes(environment.onyxDocDocumentUrl) ||
        request.url.includes(environment.onyxDocFormBuilderUrl) ||
        request.url.includes(environment.onyxDocProcessWorkflowUrl) ||
        request.url.includes(environment.onyxDocAudit) ||
        request.url.includes(environment.onyxDocProcessUrl) ||
        request.url.includes(environment.onyxDocAPIUrl)
      ) {
        if (localStorage.getItem('OnyxDoc') !== null) {
          const OnyxDocData: {
            accessToken: string;
            userToken: string;
            exp: number;
          } = JSON.parse(localStorage.getItem('OnyxDoc')!);

          headers = headers.set(
            'Authorization',
            `Bearer ${OnyxDocData.accessToken}`
          );
          const modifiedRequest = request.clone({
            // headers: request.headers.set(
            //   'Authorization',
            //   `Bearer ${OnyxDocData.accessToken}`
            // ),
            headers: headers,
          });

          return next.handle(modifiedRequest);
        } else {
          return next.handle(request);
        }
      } else {
        return next.handle(request);
      }
    }
  }
}
