<div [id]="id" [formGroup]="form" class="flwmn-date-control">
  <div #dateWrapper class="flwmn-date-wrapper">
    <input
      #dateInput
      matInput
      [matDatepicker]="date"
      placeholder="dd/mm/yyyy"
      [readonly]="actorMenu === true ? true : false"
      [formControlName]="control.name"
      [ngClass]="{ 'no-date-external': editorMode === 'external' }"
      [ngStyle]="{ cursor: actorMenu === true ? 'not-allowed' : '' }"
      maxlength="10"
    />

    <div class="flwmn-date-control-calendar-container">
      <mat-datepicker-toggle
        matSuffix
        [for]="date"
        [disabled]="actorMenu === false ? false : true"
      >
        <img
          matDatepickerToggleIcon
          src="/assets/icons/calendar.svg"
          alt="Calendar Icon"
          class="calendar"
        />
      </mat-datepicker-toggle>
      <mat-datepicker #date></mat-datepicker>
    </div>
  </div>
</div>
