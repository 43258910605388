import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, map, catchError, share, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import * as fromApp from '../app/app.reducer';
import * as ProductConfigurationActions from './product-configuration.actions';
import { HelperService } from '../../services/helper.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class ProductConfigurationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(
      ProductConfigurationActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetProducts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Product/products/`,
            {
              ...productConfigurationData.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveProducts({
                  payload: {
                    products: resData.entity.product,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                return ProductConfigurationActions.SaveProducts({
                  payload: {
                    products: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetActiveProducts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Product/activeproducts/`,
            {
              ...productConfigurationData.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                if (productConfigurationData.forSelectField) {
                  return ProductConfigurationActions.SaveActiveProductsForSelectField(
                    {
                      payload: {
                        products: resData.entity.product,
                        totalCount: resData.entity.count,
                      },
                    }
                  );
                } else {
                  return ProductConfigurationActions.SaveActiveProducts({
                    payload: {
                      products: resData.entity.product,
                      totalCount: resData.entity.count,
                    },
                  });
                }
              } else {
                return ProductConfigurationActions.SaveActiveProducts({
                  payload: {
                    products: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Active Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getInactiveProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetInactiveProducts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Product/inactiveproducts/`,
            {
              ...productConfigurationData.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveInactiveProducts({
                  payload: {
                    products: resData.entity.product,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                return ProductConfigurationActions.SaveInactiveProducts({
                  payload: {
                    products: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Inactive Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriberActiveProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetSubscriberActiveProducts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .get<any>(
            `${environment.onyxDocSubscriptionUrl}/Product/getsubscriberactiveproducts/${authState.user.SubscriberId}/${authState.user.UserId}/${productConfigurationData.payload.skip}/${productConfigurationData.payload.take}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveSubscriberActiveProducts(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                return ProductConfigurationActions.SaveSubscriberActiveProducts(
                  {
                    payload: null,
                  }
                );
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Subscriber Active Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getProductByGuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetProductByGuid),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .get<any>(
            `${environment.onyxDocProductUrl}/Product/productbyguid/${authState.user.SubscriberId}/${authState.user.UserId}/${productConfigurationData.payload.guid}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              this.store.dispatch(
                ProductConfigurationActions.IsFetchingProductById({
                  payload: false,
                })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveProduct({
                  payload: {
                    product: resData.entity,
                  },
                });
              } else {
                return ProductConfigurationActions.SaveProduct({
                  payload: {
                    product: null,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Product By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProductConfigurationActions.CreateProduct),
        withLatestFrom(this.store.select('auth')),
        switchMap(([productConfigurationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocProductUrl}/Product/create`,
              {
                ownerId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                ...productConfigurationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProductConfigurationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Create Product Was Successful',
                  });

                  return true;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Failed To Create Product',
                  });

                  return false;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Product Configuration][CatchError] Failed To Create Product ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProductConfigurationActions.UpdateProduct),
        withLatestFrom(this.store.select('auth')),
        switchMap(([productConfigurationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocProductUrl}/Product/update`,
              {
                ownerId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                ...productConfigurationData.payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProductConfigurationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Update Product Was Successful',
                  });
                  return true;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Failed To Update Product',
                  });

                  return false;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Product Configuration][CatchError] Failed To Update Product ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateProductStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.UpdateProductStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocSubscriptionUrl}/Product/updateproductstatus`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...productConfigurationData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  ProductConfigurationActions.GetProducts({
                    payload: {
                      skip: productConfigurationData.paginationPayload!.skip,
                      take: productConfigurationData.paginationPayload!.take,
                    },
                  })
                );

                return {
                  type: '[Product Configuration] Update Product Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Product Configuration] Failed To Update Product',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Update Product ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProductConfigurationActions.ActivateProduct),
        withLatestFrom(this.store.select('auth')),
        switchMap(([productConfigurationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocProductUrl}/Product/activate`,
              {
                ownerId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                guId: productConfigurationData.payload.guId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProductConfigurationActions.IsLoading({ payload: false })
                );

                if (productConfigurationData.payload.id) {
                  this.store.dispatch(
                    ProductConfigurationActions.GetProductByGuid({
                      payload: {
                        guid: productConfigurationData.payload.guId,
                      },
                    })
                  );
                }

                if (resData.succeeded === true) {
                  if (
                    productConfigurationData.paginationPayload
                      ?.moduleCurrentTab === 1
                  ) {
                    this.store.dispatch(
                      ProductConfigurationActions.GetActiveProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  } else if (
                    productConfigurationData.paginationPayload
                      ?.moduleCurrentTab === 2
                  ) {
                    this.store.dispatch(
                      ProductConfigurationActions.GetInactiveProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  } else {
                    this.store.dispatch(
                      ProductConfigurationActions.GetProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  }

                  this.store.dispatch({
                    type: '[Product Configuration] Activate Product Was Successful',
                  });

                  return of();
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Failed To Activate Product',
                  });

                  return of();
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Product Configuration][CatchError] Failed To Activate Product ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deactivateProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProductConfigurationActions.DeactivateProduct),
        withLatestFrom(this.store.select('auth')),
        switchMap(([productConfigurationData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocProductUrl}/Product/deactivate`,
              {
                ownerId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                actorEmail: authState.user.Email,
                productGuId: productConfigurationData.payload.productGuId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProductConfigurationActions.IsLoading({ payload: false })
                );

                if (productConfigurationData.payload.id) {
                  this.store.dispatch(
                    ProductConfigurationActions.GetProductByGuid({
                      payload: {
                        guid: productConfigurationData.payload.productGuId,
                      },
                    })
                  );
                }

                if (resData.succeeded === true) {
                  if (
                    productConfigurationData.paginationPayload
                      ?.moduleCurrentTab === 1
                  ) {
                    this.store.dispatch(
                      ProductConfigurationActions.GetActiveProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  } else if (
                    productConfigurationData.paginationPayload
                      ?.moduleCurrentTab === 2
                  ) {
                    this.store.dispatch(
                      ProductConfigurationActions.GetInactiveProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  } else {
                    this.store.dispatch(
                      ProductConfigurationActions.GetProducts({
                        payload: {
                          skip: productConfigurationData.paginationPayload!
                            .skip,
                          take: productConfigurationData.paginationPayload!
                            .take,
                        },
                      })
                    );
                  }

                  this.store.dispatch({
                    type: '[Product Configuration] Deactivate Product Was Successful',
                  });

                  return of();
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Product Configuration] Failed To Deactivate Product',
                  });

                  return of();
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Product Configuration][CatchError] Failed To Deactivate Product ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getProductMicroService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetProductMicroService),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .get<any>(
            `${environment.onyxCore}/ProductMicroService/getall/${authState.user.SubscriberId}/${authState.user.UserId}/${productConfigurationData.skip}/${productConfigurationData.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveProductMicroService({
                  payload: {
                    microService: resData.entity.productMicroService,
                  },
                });
              } else {
                return ProductConfigurationActions.SaveProductMicroService({
                  payload: {
                    microService: [],
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Products Microservice ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveResources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductConfigurationActions.GetActiveResources),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getactiveresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              skip: productConfigurationData.skip,
              take: productConfigurationData.take,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProductConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProductConfigurationActions.SaveActiveResources({
                  payload: {
                    resources: resData.entity.resource,
                  },
                });
              } else {
                return ProductConfigurationActions.SaveActiveResources({
                  payload: {
                    resources: [],
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Product Configuration][CatchError] Failed To Get Active Resources ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
