import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromRecipientSignature from './recipient-signature.reducer';

const getRecipientSignatureState = (state: fromApp.AppState) =>
  state.recipientSignature;

export const getRecipientSignaturesIsLoading = createSelector(
  getRecipientSignatureState,
  (state: fromRecipientSignature.recipientReducerState) => state.isLoading
);

export const getRecipientActiveSignatures = createSelector(
  getRecipientSignatureState,
  (state: fromRecipientSignature.recipientReducerState) =>
    state.allRecipientActiveSignature
);

export const getRecipientSignatures = createSelector(
  getRecipientSignatureState,
  (state: fromRecipientSignature.recipientReducerState) =>
    state.allRecipientSignature
);

export const getRecipientDefaultSignature = createSelector(
  getRecipientSignatureState,
  (state: fromRecipientSignature.recipientReducerState) =>
    state.defaultRecipientSignature
);
