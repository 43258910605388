import { Action, createReducer, on } from '@ngrx/store';
import * as DefaultRoleActions from './default-role.actions';

export interface State {
  isLoading: boolean;
  defaultRoles: any[] | null;
}

export const initialState: State = {
  isLoading: false,
  defaultRoles: null,
};

const defaultRoleReducerInternal = createReducer(
  initialState,
  on(DefaultRoleActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(DefaultRoleActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(DefaultRoleActions.SaveDefaultRoles, (state, { payload }) => ({
    ...state,
    defaultRoles: payload,
  }))
);

export function defaultRoleReducer(state: State | undefined, action: Action) {
  return defaultRoleReducerInternal(state, action);
}
