import { createAction, props } from '@ngrx/store';
import { twoStepVerificationReducerState } from './two-step-verification.reducer';

export const ResetStore = createAction('[User] Reset Store');

export const IsLoading = createAction(
  '[Two Step Verification] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GenerateOtpUrl = createAction(
  '[Two Step Verification]  Generate Otp Url'
);

export const SaveOtpUrl = createAction(
  '[Two Step Verification]  Save Otp Url',
  props<{
    payload: twoStepVerificationReducerState['otpUrl'];
  }>()
);

export const VerifyOtpPin = createAction(
  '[Two Step Verification]  Verify Otp Pin',
  props<{
    payload: {
      totp: string;
    };
  }>()
);

export const VerifyEmailAddress = createAction(
  '[Two Step Verification]  Verify Email Address',
  props<{
    payload: {
      password: string;
    };
  }>()
);

export const GetUserByEmail = createAction(
  '[Two Step Verification]  Get User By Email',
  props<{
    payload: {
      email: string;
      userId: string | null;
    };
  }>()
);

export const SaveUserByEmail = createAction(
  '[Two Step Verification]  Save User By Email',
  props<{
    payload: twoStepVerificationReducerState['userEmail'];
  }>()
);

export const ToggleTwoFactorAuthenticator = createAction(
  '[Two Step Verification] Toggle Two Factor Authenticator',
  props<{
    payload: {
      isTwoFactorEnabled: boolean;
    };
  }>()
);

export const SendOtpToEmail = createAction(
  '[Two Step Verification]  Send Otp To Email'
);

// export const SendOtpToEmail = createAction(
//   '[Two Step Verification]  Send Otp To Email',
//   props<{
//     payload: {
//       email: string;
//     };
//   }>()
// );

// export const DeleteAuthenticatorSetup = createAction(
//   '[Two Step Verification]  Delete Authenticator Setup'
// // );

export const DeleteAuthenticatorSetup = createAction(
  '[Two Step Verification]  Delete Authenticator Setup',
  props<{
    payload: {
      twoFAVerificationType: number;
    };
  }>()
);
