// Application Management
export const ResetStore = createAction('[Application Management] Reset Store');

export const IsLoading = createAction(
  '[Application Management] Is Loading',
  props<{
    payload: boolean;
  }>()
);
export const IsFetching = createAction(
  '[Application Management] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

import { createAction, props } from '@ngrx/store';

export const CreateExternalApplication = createAction(
  '[Application Management] Create External Application',
  props<{
    payload: {
      applicationName: string;
      authenticationType: number;
    };
  }>()
);

export const CreateEncryptionKey = createAction(
  '[Application Management] Create Encryption Key',
  props<{
    payload: {
      // apiClientId: string;
      appName: string;
      appId: string;
      encryptionKey: string;
      token: string;
    };
  }>()
);

export const ConfigureWebhookUrl = createAction(
  '[Application Management] Configure Webhook Url',
  props<{
    payload: {
      appId: string;
      appName: string;
      apiClientId: string;
      webHookUrl: string;
      token: string;
    };
  }>()
);

export const DeactivateEncryptionKey = createAction(
  '[Application Management] Deactivate Encryption Key',
  props<{
    payload: {
      apiClientId: string;
      appName: string;
      token: string;
      appId: string;
      referenceNumber: string;
    };
  }>()
);

export const UpdateExternalApplication = createAction(
  '[Application Management] Update External Application',
  props<{
    payload: {
      applicationName: string;
      applicationId: number;
    };
  }>()
);

export const GetExternalApplicationById = createAction(
  '[Application Management] Get External Application By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetActiveEncryptionKey = createAction(
  '[Application Management] Get Active Encryption Key',
  props<{
    payload: {
      appId: string;
    };
  }>()
);

export const GetWebhookUrl = createAction(
  '[Application Management] Get Webhook Url',
  props<{
    payload: {
      appId: string;
      apiClientId: string;
    };
  }>()
);

export const GetEncryptionKeyHistory = createAction(
  '[Application Management] Get Encryption Key History',
  props<{
    payload: {
      skip: number;
      take: number;
      appId: string;
    };
  }>()
);

export const GetExternalApplicationByAppId = createAction(
  '[Application Management] Get External Application By App Id',
  props<{
    payload: {
      appId: number;
    };
  }>()
);

export const GetAllExternalApplications = createAction(
  '[Application Management] Get All External Applications'
);

export const SaveAllExternalApplications = createAction(
  '[Application Management] Save All External Applications',
  props<{
    payload: any[];
  }>()
);

export const SaveExternalApplicationById = createAction(
  '[Application Management] Save External Application By Id',
  props<{
    payload: any;
  }>()
);

export const SaveActiveEncryptionKey = createAction(
  '[Application Management] Save Active Encryption Key',
  props<{
    payload: any;
  }>()
);

export const SaveWebhookUrl = createAction(
  '[Application Management] Save Webhook Url ',
  props<{
    payload: any;
  }>()
);

export const SaveEncryptionKeyHistory = createAction(
  '[Application Management] Save Encryption Key History',
  props<{
    payload: any;
  }>()
);

export const ActivateExternalApplication = createAction(
  '[Application Management] Activate External Application',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const DeactivateExternalApplication = createAction(
  '[Application Management] Deactivate External Application',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const ValidateExternalApplication = createAction(
  '[Application Management] Validate External Application',
  props<{
    payload: {
      applicationName: string;
      token: string;
      appId: number;
    };
  }>()
);

export const PushAppToProduction = createAction(
  '[Application Management] Push App To Production',
  props<{
    payload: {
      appId: number;
    };
  }>()
);
