import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[randomColor]',
})
export class RandomColorDirective implements AfterContentInit {
  @Input() color: boolean = true;
  @Input() backgroundColor: boolean = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterContentInit(): void {
    const backgroundColor = this.randomColor();
    const textColor = this.chooseTextColor(backgroundColor);

    if (backgroundColor) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'background-color',
        backgroundColor
      );
    }

    if (this.color) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'color', textColor);
    }
  }

  randomColor() {
    const value =
      '#' + // start with a leading hash
      Math.random() // generates random number
        .toString(16) // changes that number to base 16 as a string
        .substring(2, 8); // gets 6 characters and excludes the leading "0."

    return value;
  }

  hexToRgb(hex: string) {
    // Remove the hash if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return [r, g, b];
  }

  rgbToLuminance(r: number, g: number, b: number) {
    // Normalize RGB values
    const [R, G, B] = [r, g, b].map(function (val) {
      val /= 255;
      return val <= 0.03928
        ? val / 12.92
        : Math.pow((val + 0.055) / 1.055, 2.4);
    });

    // Calculate luminance
    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
  }

  getContrastRatio(luminance1: number, luminance2: number) {
    const light = Math.max(luminance1, luminance2);
    const dark = Math.min(luminance1, luminance2);
    return (light + 0.05) / (dark + 0.05);
  }

  chooseTextColor(backgroundColor: string) {
    // Convert hex to RGB
    const [r, g, b] = this.hexToRgb(backgroundColor);

    // Calculate background luminance
    const bgLuminance = this.rgbToLuminance(r, g, b);

    // Luminance for white and black
    const whiteLuminance = this.rgbToLuminance(255, 255, 255);
    const blackLuminance = this.rgbToLuminance(0, 0, 0);

    // Calculate contrast ratios
    const contrastWithWhite = this.getContrastRatio(
      bgLuminance,
      whiteLuminance
    );
    const contrastWithBlack = this.getContrastRatio(
      bgLuminance,
      blackLuminance
    );

    // Return the color with better contrast
    return contrastWithWhite > contrastWithBlack ? '#ffffff' : '#000000';
  }
}
