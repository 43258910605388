import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as ProcessWorkflowActions from './process-workflow.actions';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class ProcessWorkflowEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(ProcessWorkflowActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getProcesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetProcesses),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/Processes/getprocesses/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveProcesses({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ProcessWorkflowActions.SaveProcesses({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Processes ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getPublishedProcessesByGroupdId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetPublishedProcessesByGroupId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/Processes/getpublishedprocessesbygroupid/${authState.user.SubscriberId}/${authState.user.UserId}/${processData.payload.processGroupId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SavePublishedProcessesByGroupId({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ProcessWorkflowActions.SavePublishedProcessesByGroupId({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Published Processes By Group Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  uploadForms$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.UploadForms),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/Forms/uploadforms`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                forms: [...processData.payload],
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed To Upload Form. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Upload Forms ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteForm$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteForm),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/Forms/deleteform`,
              {
                subscriberId: authState.user.SubscriberId,
                id: processData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed To Delete Form. Please try again.',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Form ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createProcess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.CreateProcess),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/Processes/create`,
              {
                ...processData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Process Workflow] Create Process Was Succesful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Create Process',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Create Process ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateProcess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.UpdateProcess),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/Processes/update`,
              {
                ...processData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Process Workflow] Update Process Was Succesful',
                  });

                  if (processData?.optionalPayload?.instance === 'normal') {
                    const notification: Notification = {
                      state: 'success',
                      message: resData.message || resData.messages[0],
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    this.store.dispatch(
                      ProcessWorkflowActions.GetProcessById({
                        payload: { id: processData.payload.id },
                      })
                    );
                  } else if (
                    processData?.optionalPayload?.instance ===
                    'finalize process'
                  ) {
                    this.store.dispatch(
                      ProcessWorkflowActions.IsLoading({ payload: true })
                    );

                    this.store.dispatch(
                      ProcessWorkflowActions.PublishProcess({
                        payload: {
                          id: processData?.optionalPayload
                            ?.publishProcessPayload?.id,
                          processFormIdToPublish:
                            processData?.optionalPayload?.publishProcessPayload
                              ?.processFormIdToPublish,
                          processWorkflowIdToPublish:
                            processData?.optionalPayload?.publishProcessPayload
                              ?.processWorkflowIdToPublish,
                        },
                      })
                    );
                  }

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Update Process',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Update Process ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getProcessById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetProcessById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/Processes/getprocessbyid/${authState.user.SubscriberId}/${processData.payload.id}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveProcessData({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ProcessWorkflowActions.SaveProcessData({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Process By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getProcessFormById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetProcessFormById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessForms/getprocessformbyid/${authState.user.SubscriberId}/${processData.payload.id}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveProcessForms({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ProcessWorkflowActions.SaveProcessForms({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Process Form By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getProcessGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetProcessGroups),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/getprocessgroups/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveProcessGroups({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  ProcessWorkflowActions.SaveProcessGroups({
                    payload: [],
                  })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Get Process Groups',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Process Groups ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveProcessGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetActiveProcessGroups),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/getactiveprocessgroups/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveActiveProcessGroups({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  ProcessWorkflowActions.SaveActiveProcessGroups({
                    payload: [],
                  })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Get Active Process Groups',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Active Process Groups ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createProcessGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.CreateProcessGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/create`,
            {
              ...processData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ProcessWorkflowActions.GetProcessGroups({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Process Workflow] Create Process Group Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Create Process Group',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Create Process Group ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateProcessGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.UpdateProcessGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/update`,
            {
              ...processData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ProcessWorkflowActions.GetProcessGroups({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Process Workflow] Update Process Group Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Update Process Group',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Update Process Group ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  archiveProcessGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.ArchiveProcessGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/archiveprocessgroup`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...processData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ProcessWorkflowActions.GetProcessGroups({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Process Workflow] Archive Process Group Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Archive Process Group',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Archive Process Group ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unarchiveProcessGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.UnarchiveProcessGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/ProcessGroups/unarchiveprocessgroup`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...processData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.dialog.closeAll();

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ProcessWorkflowActions.GetProcessGroups({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Process Workflow] Unarchive Process Group Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Unarchive Process Group',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Unarchive Process Group ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteProcessInitiator$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteProcessInitiator),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/ProcessInitiators/deleteprocessinitiator`,
              {
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
                processId: processData.payload.processId,
                id: processData.payload.id,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Delete Process Initiator Was Succesful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Delete Process Initiator',
                  });
                }

                return {
                  resData: resData,
                  processRequestInitator:
                    processData.payload.processRequestInitator,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Process Initiator ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getWorkflowLevelsByWorkflowId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.GetWorkflowLevelsByWorkflowId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/WorkflowLevels/getworkflowlevelsbyworkflow/${authState.user.SubscriberId}/${authState.user.UserId}/${processData.payload.workflowId}`
          )
          .pipe(
            map((resData) => {
              // this.store.dispatch(
              //   GeneralActions.IsLoading({ payload: false })
              // );

              if (resData.succeeded === true) {
                return ProcessWorkflowActions.SaveWorkflowLevelsByProcessWorkflowId(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  ProcessWorkflowActions.SaveWorkflowLevelsByProcessWorkflowId({
                    payload: [],
                  })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Get Workflow Levels By ProcessWorkflow Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Get Workflow Levels By ProcessWorkflow Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  publishProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProcessWorkflowActions.PublishProcess),
      withLatestFrom(this.store.select('auth')),
      switchMap(([processData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocProcessWorkflowUrl}/Processes/publishprocess`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...processData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ProcessWorkflowActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/process-configuration'], {
                  state: { bypassGuard: true },
                });

                return {
                  type: '[Process Workflow] Publish Process Was Sucessful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Process Workflow] Failed To Publish Process',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Process Workflow][CatchError] Failed To Publish Process ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteWorkflowPhase$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteWorkflowPhase),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/WorkflowPhases/deleteworkflowphase`,
              {
                subscriberId: authState.user.SubscriberId,
                workflowId: processData.payload.workflowId,
                id: processData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.store.dispatch(
                  //   ProcessWorkflowActions.GetProcessById({
                  //     payload: { id: processData.payload.id },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Process Workflow] Delete Workflow Phase Was Sucessful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Delete Workflow Phase',
                  });
                }

                return {
                  resData: resData,
                  workflowPhaseIndex: processData.payload.workflowPhaseIndex,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Workflow Phase ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteWorkflowProxy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteWorkflowProxy),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/WorkflowProxies/deleteworkflowproxy`,
              {
                subscriberId: authState.user.SubscriberId,
                workflowPhaseId: processData.payload.workflowPhaseId,
                id: processData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.store.dispatch(
                  //   ProcessWorkflowActions.GetProcessById({
                  //     payload: { id: processData.payload.id },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Process Workflow] Delete Workflow Proxy Was Sucessful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Delete Workflow Proxy',
                  });
                }

                return {
                  resData: resData,
                  workflowProxy: processData.payload.workflowProxy,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Workflow Proxy ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteWorkflowLevel$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteWorkflowLevel),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/WorkflowLevels/deleteworkflowlevel`,
              {
                subscriberId: authState.user.SubscriberId,
                workflowPhaseId: processData.payload.workflowPhaseId,
                id: processData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.store.dispatch(
                  //   ProcessWorkflowActions.GetProcessById({
                  //     payload: { id: processData.payload.id },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Process Workflow] Delete Workflow Level Was Sucessful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Delete Workflow Level',
                  });
                }

                return {
                  resData: resData,
                  workflowLevelIndex: processData.payload.workflowLevelIndex,
                  userCategory: processData.payload.userCategory,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Workflow Level ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteFormComponent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProcessWorkflowActions.DeleteFormComponent),
        withLatestFrom(this.store.select('auth')),
        switchMap(([processData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocProcessWorkflowUrl}/FormComponents/deleteformcomponent`,
              {
                subscriberId: authState.user.SubscriberId,
                processFormId: processData.payload.processFormId,
                id: processData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ProcessWorkflowActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // this.store.dispatch(
                  //   ProcessWorkflowActions.GetProcessById({
                  //     payload: { id: processData.payload.id },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Process Workflow] Delete Form Component Was Sucessful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Process Workflow] Failed To Delete Form Component',
                  });
                }

                return {
                  resData: resData,
                  component: processData.payload.component,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Process Workflow][CatchError] Failed To Delete Form Component ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
