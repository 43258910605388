import { createReducer, on, Action } from '@ngrx/store';
import * as TransactionActions from './transaction.actions';

export interface transactionReducerState {
  isLoading: boolean;
  allTransactions: { transaction: any[]; totalCount: number } | null;
  allProducts: {
    products: any[];
    totalCount: number;
  } | null;
  activeProducts: {
    products: any[];
    totalCount: number;
  } | null;
  allSubscriptionPlan: any[];
  activeSubscribers: { subscriberList: any[]; totalCount: number } | null;
  transactions: any;
}

const initialState: transactionReducerState = {
  isLoading: false,
  allTransactions: null,
  allSubscriptionPlan: [],
  transactions: null,
  allProducts: null,
  activeProducts: null,
  activeSubscribers: null,
};

const transactionReducerInternal = createReducer(
  initialState,
  on(TransactionActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(TransactionActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  // on(TransactionActions.SaveAllTransactions, (state, { payload }) => ({
  //   ...state,
  //   allTransactions: payload,
  // })),

  on(TransactionActions.SaveAllTransactions, (state, { payload }) => ({
    ...state,
    allTransactions: payload,
  })),

  on(TransactionActions.SaveAllSubscriptionPlan, (state, { payload }) => ({
    ...state,
    allSubscriptionPlan: payload,
  })),

  on(TransactionActions.SaveTransactionById, (state, { payload }) => ({
    ...state,
    transactions: payload,
  })),

  on(TransactionActions.SaveTransactionProducts, (state, { payload }) => ({
    ...state,
    allProducts: payload,
  })),

  on(
    TransactionActions.SaveTransactionActiveProducts,
    (state, { payload }) => ({
      ...state,
      activeProducts: payload,
    })
  ),

  on(TransactionActions.SaveActiveSubscriber, (state, { payload }) => ({
    ...state,
    activeSubscribers: payload,
  }))
);

export const transactionReducer = (
  state: transactionReducerState | undefined,
  action: Action
) => {
  return transactionReducerInternal(state, action);
};
