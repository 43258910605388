<mat-dialog-content>
  <section id="third-party-error">
    <svg
      class="img-fluid image"
      height="32"
      style="overflow: visible; enable-background: new 0 0 32 32"
      viewBox="0 0 32 32"
      width="32"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g id="Error_1_">
          <g id="Error">
            <circle cx="16" cy="16" id="BG" r="16" style="fill: #d72828" />
            <path
              d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
              id="Exclamatory_x5F_Sign"
              style="fill: #e6e6e6"
            />
          </g>
        </g>
      </g>
    </svg>

    <h1 class="flwmn-heading-3 fw-700 mb-3">Ooops!!!</h1>

    <p class="flwmn-para-1 text-center">
      It seems you do not have an email attached to your {{ data }} account.
      Kindly, enter in your email and password instead.
    </p>

    <button
      matRipple
      class="btn flwmn-btn-primary ok-btn my-3"
      (click)="closeDialog()"
    >
      Ok
    </button>
  </section>
</mat-dialog-content>
