import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { View } from '../../interfaces';

@Component({
  selector: 'app-view-mode',
  templateUrl: './view-mode.component.html',
  styleUrls: ['./view-mode.component.scss'],
})
export class ViewModeComponent implements OnInit {
  @Input() views: View[] = [];
  @Input() activeView: string;

  @Output() viewChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.activeView = this.views[0]?.name;
  }

  setActiveView(view: string): void {
    this.activeView = view;
    this.viewChange.emit(view);
  }
}
