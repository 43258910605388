<div id="user-initial">
  <div class="initials-container" randomColor [backgroundColor]="true">
    <ng-container
      *ngIf="
        (user?.profilePicture && user?.profilePicture !== '') ||
          (user?.licenseUserProfilePicture &&
            user?.licenseUserProfilePicture !== '');
        else noProfilePicture
      "
    >
      <img
        class="img-fluid user-initials-img"
        [src]="user?.profilePicture ?? user?.licenseUserProfilePicture"
        alt=""
      />
    </ng-container>

    <ng-template #noProfilePicture>
      <p class="initials-text">
        {{
          user?.firstName ??
            user?.licenseUserFirstName + ' ' + user?.lastName ??
            user?.licenseUserLastName | nameInitials
        }}
      </p>
    </ng-template>
  </div>

  <div class="initials-name">
    <p class="flwmn-para-1 fw-500 mb-0">
      {{ user?.firstName ?? user?.licenseUserFirstName }}
      {{ user?.lastName ?? user?.licenseUserLastName }}
    </p>

    <p class="flwmn-caption flwmn-text-neutral mb-0" *ngIf="showEmail">
      {{ user?.email ?? user?.licenseUserEmail }}
    </p>
  </div>
</div>
