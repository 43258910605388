// Documentation
import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Documentation] Reset Store');

export const IsLoading = createAction(
  '[Documentation] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingSidebarItems = createAction(
  '[Documentation] Is Loading Sidebar Items',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingCurrentPage = createAction(
  '[Documentation] Is Loading Current Page',
  props<{
    payload: boolean;
  }>()
);

export const IsSidebarToggled = createAction(
  '[Documentation] Is Sidebar Toggled',
  props<{
    payload: boolean;
  }>()
);

export const GetSidebarItems = createAction(
  '[Documentation] Get Sidebar Items'
);

export const GetCurrentPage = createAction('[Documentation] Get Current Page');

export const SaveSidebarItems = createAction(
  '[Documentation] Save Sidebar Items',
  props<{
    payload: any[];
  }>()
);

export const SaveCurrentPage = createAction(
  '[Documentation] Save Current Page',
  props<{
    payload: any;
  }>()
);
