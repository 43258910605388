import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from 'src/app/@core/services/helper.service';
import { ThemeService } from 'src/app/@core/services/theme.service';
import { regexValidator } from 'src/app/@core/utils/helpers';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  isLoading!: Observable<boolean>;
  year!: number;
  resetPasswordForm!: FormGroup;
  show: boolean = false;
  show2: boolean = false;
  email!: string;
  token!: string;

  // Password validations
  uppercasePattern = new RegExp('^(?=.*?[A-Z])');
  lowercasePattern = new RegExp('(?=.*?[a-z])');
  numberPattern = new RegExp('(?=.*?[0-9])');
  specialCharPattern = new RegExp('(?=.*?[#?!@$%^&*-])');

  activatedRouteSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    public dialog: MatDialog,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.year = new Date().getFullYear();

    this.createLoginForm();

    this.listenToActivatedRouteSubscription();
  }

  createLoginForm() {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: [
          null,
          [
            Validators.required,
            regexValidator(new RegExp('^(?=.*?[A-Z])'), {
              uppercaseLetter: true,
            }),
            regexValidator(new RegExp('(?=.*?[a-z])'), {
              lowercaseLetter: true,
            }),
            regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
            regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
              specialCharacter: true,
            }),
            regexValidator(new RegExp('.{8,}$'), { minimum: true }),
            // Validators.pattern(
            //   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
            // ),
          ],
        ],
        // newPassword: [
        //   null,
        //   [
        //     Validators.required,
        //     Validators.pattern(
        //       '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        //     ),
        //   ],
        // ],
        confirmNewPassword: [null, Validators.required],
      },
      {
        validator: this.checkIfMatchingPasswords(
          'newPassword',
          'confirmNewPassword'
        ),
      }
    );
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  get resetPasswordFormControls() {
    return this.resetPasswordForm.controls;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls.newPassword.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls['newPassword'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls['newPassword'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls['newPassword'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls['newPassword'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'newPassword' &&
      this.resetPasswordFormControls['newPassword'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    } else if (
      instance === 'confirmNewPassword' &&
      this.resetPasswordFormControls.confirmNewPassword.hasError(
        'notEquivalent'
      )
    ) {
      return 'Sorry, this does not match your new password';
    } else {
      return;
    }
  }

  listenToActivatedRouteSubscription() {
    this.activatedRouteSub = this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params.email) {
          this.email = params.email;
        }

        if (params.token) {
          this.token = params.token;
        }
      }
    );
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.resetPassword.bind(this)
    );
  }

  resetPassword() {
    this.store.dispatch(
      AuthActions.ResetPassword({
        payload: {
          email: this.email,
          newPassword: this.resetPasswordForm.value.newPassword,
          token: this.token,
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe();
    }
  }
}
