import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AddCategoryDialogComponent } from 'src/app/@components/webapp/processes-next/category/add-category-dialog/add-category-dialog.component';
import { View } from 'src/app/@core/interfaces';
import { FLWMN_SHEET_DATA, FlwmnSheetRef } from 'src/app/@core/shared/sheet';

@Component({
  selector: 'app-send-request-dialog',
  templateUrl: './send-request-dialog.component.html',
  styleUrls: ['./send-request-dialog.component.scss'],
})
export class SendRequestDialogComponent implements OnInit {
  views: View[] = [];
  activeView: 'Parameters' | 'Authorization' | 'Headers' | 'Body' =
    'Parameters';

  isLoading: boolean = false;
  response: any;

  @ViewChild('dismiss') dismiss: ElementRef;

  constructor(
    @Inject(FLWMN_SHEET_DATA) public data: any,
    private sheetRef: FlwmnSheetRef<AddCategoryDialogComponent>
  ) {}

  ngOnInit(): void {
    this.views = [
      { id: 1, name: 'Parameters' },
      { id: 2, name: 'Authorization' },
      { id: 3, name: 'Headers' },
      { id: 4, name: 'Body' },
    ];
  }

  onViewChange(view: string) {
    if (!view) return;

    this.activeView = view as
      | 'Parameters'
      | 'Authorization'
      | 'Headers'
      | 'Body';
  }

  onCreateFlow() {
    this.dismiss.nativeElement.click();
  }

  test() {
    this.isLoading = true;
    this.response = `
    <!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel=”utf-8” />
       
    </head>
    </html>
      `;

    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  closeSheet() {
    this.sheetRef.close('Yay!');
  }
}
