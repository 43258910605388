import { createReducer, on, Action } from '@ngrx/store';
import * as ProcessWorkflowActions from './process-workflow.actions';

export interface State {
  // uploadedFormData: any;
  isLoading: boolean;
  allProcessConfigurations: any;
  allPublishedProcessConfigurationsByGroupId: any;
  activeProcessGroups: any;
  processGroups: any;
  processData: any;
  processForms: any[] | null;
  workflowLevelsByProcessId: any;
}

const initialState: State = {
  // uploadedFormData: null,
  isLoading: false,
  allProcessConfigurations: null,
  allPublishedProcessConfigurationsByGroupId: null,
  activeProcessGroups: null,
  processGroups: null,
  processData: null,
  processForms: null,
  workflowLevelsByProcessId: null,
};

const processWorkflowReducerInternal = createReducer(
  initialState,
  on(ProcessWorkflowActions.ResetStore, (state) => ({
    ...initialState,
  })),
  // on(ProcessWorkflowActions.SaveUploadedFormData, (state, { payload }) => ({
  //   ...state,
  //   uploadedFormData: payload,
  // })),
  on(ProcessWorkflowActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(ProcessWorkflowActions.SaveProcesses, (state, { payload }) => ({
    ...state,
    allProcessConfigurations: payload,
  })),
  on(
    ProcessWorkflowActions.SavePublishedProcessesByGroupId,
    (state, { payload }) => ({
      ...state,
      allPublishedProcessConfigurationsByGroupId: payload,
    })
  ),
  on(ProcessWorkflowActions.SaveProcessGroups, (state, { payload }) => ({
    ...state,
    processGroups: payload,
  })),
  on(ProcessWorkflowActions.SaveActiveProcessGroups, (state, { payload }) => ({
    ...state,
    activeProcessGroups: payload,
  })),
  on(ProcessWorkflowActions.SaveProcessData, (state, { payload }) => ({
    ...state,
    processData: payload,
  })),
  on(ProcessWorkflowActions.SaveProcessForms, (state, { payload }) => ({
    ...state,
    processForms: payload,
  })),
  on(
    ProcessWorkflowActions.SaveWorkflowLevelsByProcessWorkflowId,
    (state, { payload }) => ({
      ...state,
      workflowLevelsByProcessId: payload,
    })
  )
);

export function processWorkflowReducer(
  state: State | undefined,
  action: Action
) {
  return processWorkflowReducerInternal(state, action);
}
