import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInitialsComponent } from './user-initials.component';
import { PipesModule } from '../../pipes/pipes.module';
import { RandomColorModule } from 'src/app/@directives/random-color/random-color.module';
import { NameInitialsModule } from '../../pipes/name-initials/name-initials.module';

@NgModule({
  declarations: [UserInitialsComponent],
  imports: [CommonModule, PipesModule, RandomColorModule, NameInitialsModule],
  exports: [UserInitialsComponent],
})
export class UserInitialsModule {}
