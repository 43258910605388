import { UploadMethodEnum } from '../enums/drive.enum';

export const InitialFilterDTO = {
  type: {
    name: '',
    value: '',
  },
  user: {
    name: '',
    value: '',
  },
  created: {
    name: '',
    value: null,
  },
  modified: {
    name: '',
    value: null,
  },
  sort: {
    name: '',
    value: '',
  },

  search: {
    name: '',
    value: '',
  },
};

export const DateFilterItems = [
  'All Time',
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'This year',
  'Last year',
  'Set a range',
];

export const UploadMethods = [
  {
    id: UploadMethodEnum.Desktop,
    heading: 'Drag and drop your files here',
    paragraph: 'Supported files: PDF, Word, JPG, PNG',
    buttonText: 'Select file',
  },
  {
    id: UploadMethodEnum.Google,
    heading: 'Upload a file from Google Drive',
    paragraph: 'You just need to login to your Google account',
    buttonText: 'Login to Google Drive',
  },
  {
    id: UploadMethodEnum.OneDrive,
    heading: 'Upload a file from One Drive',
    paragraph: 'You just need to login to your One Drive account',
    buttonText: 'Login to One Drive',
  },
  {
    id: UploadMethodEnum.DropBox,
    heading: 'Upload a file from Dropbox',
    paragraph: 'You just need to login to your Dropbox account',
    buttonText: 'Login to Dropbox',
  },
];

export const MyDriveGuid: string = '00000000-0000-0000-0000-000000000000';
