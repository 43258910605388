import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-carousel',
  templateUrl: './auth-carousel.component.html',
  styleUrls: ['./auth-carousel.component.scss'],
})
export class AuthCarouselComponent implements OnInit, OnDestroy {
  currentIndex = 0;
  disableTransition = false;

  items = [
    {
      image: 'assets/images/auth/carousel/flowz-info.png',
      caption: 'Connect. Automate. Accelerate.',
      productName: 'Flowmono Flowz',
      description:
        'Your all-in-one solution for seamless digital workflows, document management, and e-signing – all in one place.',
    },
    {
      image: 'assets/images/auth/carousel/sign-info.png',
      caption: 'Sign and send contracts at anytime',
      productName: 'Flowmono Sign',
      description:
        'Flowmono lets you sign, send, and track documents with ease securely.',
    },
    {
      image: 'assets/images/auth/carousel/sla-info.png',
      caption: 'Track tasks. Escalate with ease.',
      productName: 'Flowmono SLA',
      description:
        'Flowmono SLA moves tasks forward with timely escalation process.',
    },
    {
      image: 'assets/images/auth/carousel/drive-info.png',
      caption: 'Store. Manage. Access Files Easily.',
      productName: 'Flowmono Drive',
      description:
        'With Flowmono Drive, your documents are always secure and just a click away.',
    },
    {
      image: 'assets/images/auth/carousel/phoenix-info.png',
      caption: 'Design, personalize, and link forms.',
      productName: 'Flowmono Phoenix Builder',
      description:
        'Design seamless forms that bring efficiency to your business process.',
    },
  ];

  intervalId: any;

  constructor() {}

  ngOnInit() {
    this.startCarousel();
  }

  // Move to the previous slide
  prevSlide() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.items.length - 1;
    } else {
      this.currentIndex--;
    }
  }

  // Move to the next slide
  nextSlide() {
    this.disableTransition = false;

    if (this.currentIndex === this.items.length - 1) {
      this.disableTransition = true;
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
  }

  pauseCarousel() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  startCarousel() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 2200);
    }
  }

  getTransform(index: number) {
    const isActive = this.currentIndex === index;
    if (isActive) {
      // Normal display without shrinking
      return 'scale(1) translateX(0)';
    } else {
      // Shrink out effect
      return 'scale(0.8) translateX(' + -100 * this.currentIndex + '%)';
    }
  }

  // Go to a specific slide
  goToSlide(index: number) {
    this.currentIndex = index;
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
