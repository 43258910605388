import { createReducer, on, Action } from '@ngrx/store';
import * as APIRepositoryActions from './api-repository.action';
import {
  ApiCollection,
  APIEndpoint,
} from 'src/app/@core/interfaces/repository/api-repository.interface';

export interface State {
  isLoading: boolean;
  isFetching: boolean;
  apiCollections: ApiCollection[] | null;
  apiCollection: any;
  endpointsByCollection: APIEndpoint[] | null;
}

const initialState: State = {
  isLoading: false,
  isFetching: false,
  apiCollections: null,
  apiCollection: null,
  endpointsByCollection: null,
};

const apiRepositoryReducerInternal = createReducer(
  initialState,
  on(APIRepositoryActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(APIRepositoryActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(APIRepositoryActions.IsFetching, (state, { payload }) => ({
    ...state,
    isFetching: payload,
  })),

  on(APIRepositoryActions.SaveAPICollection, (state, { payload }) => ({
    ...state,
    apiCollection: payload,
  })),

  on(APIRepositoryActions.SaveAPICollections, (state, { payload }) => ({
    ...state,
    apiCollections: payload,
  })),

  on(
    APIRepositoryActions.SaveEndpointsByCollectionId,
    (state, { payload }) => ({
      ...state,
      endpointsByCollection: payload,
    })
  )
);

export function apiRepositoryReducer(state: State | undefined, action: Action) {
  return apiRepositoryReducerInternal(state, action);
}
