<section id="add-signature" (window:resize)="onWindowResize($event)">
  <div class="add-signature-header">
    <h6 class="flwmn-heading-6 fw-700 mb-0">Add Signature</h6>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="input-style1 mb-1">
        <label class="input-label">Full Name</label>

        <mat-form-field appearance="outline" [style.width.%]="100">
          <input
            matInput
            type="text"
            [value]="data.fullName || ''"
            (input)="onFullNameChange($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="input-style1 mb-1">
        <label class="input-label">Initials</label>

        <mat-form-field appearance="outline" [style.width.%]="100">
          <input
            matInput
            type="text"
            [value]="data.initials || ''"
            (input)="onInitialChange($event)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="main-container">
    <div class="signature-object-menu">
      <div
        matRipple
        class="signature-btn"
        [class.signature-active]="currentTab === 0"
        (click)="onSignatureClicked()"
      >
        <span>✍🏾</span>
        <span>Signature</span>
      </div>

      <!-- <div
        matRipple
        class="signature-btn"
        [class.signature-active]="currentTab === 1"
        (click)="onInitialClicked()"
      >
        <span>🔠</span>
        <span>Initials </span>
      </div> -->

      <div class="left-over"></div>
    </div>

    <div class="signature-tool-content">
      <div class="signature-tool">
        <div
          matTooltip="Type"
          [class.signature-active-tool]="option === 'type'"
          (click)="onToolOptionClicked('type')"
        >
          <div class="signature-tool-item" matRipple>
            <app-svg
              [src]="'/assets/icons/editor/control-icons/text.svg'"
              [svgPathStroke]="option === 'type' ? '#4e33ff' : '#222222'"
            ></app-svg>
          </div>
        </div>

        <div
          matTooltip="Draw"
          [class.signature-active-tool]="option === 'draw'"
          (click)="onToolOptionClicked('draw')"
        >
          <div class="signature-tool-item" matRipple>
            <app-svg
              [src]="'/assets/icons/draw.svg'"
              [svgPathStroke]="option === 'draw' ? '#4e33ff' : '#222222'"
            ></app-svg>
          </div>
        </div>

        <div
          matTooltip="Upload"
          [class.signature-active-tool]="option === 'upload'"
          (click)="onToolOptionClicked('upload')"
        >
          <div class="signature-tool-item" matRipple>
            <app-svg
              [src]="'/assets/icons/upload.svg'"
              [svgPathStroke]="option === 'upload' ? '#4e33ff' : '#222222'"
            ></app-svg>
          </div>
        </div>
      </div>

      <div class="signature-content">
        <ng-container *ngIf="currentTab === 0">
          <ng-container *ngIf="option === 'type'">
            <mat-radio-group class="signature-content-item">
              <ng-container *ngIf="signatureNameTypeList.length < 0">
                <div class="uploading-loader-container">
                  <app-button-loader></app-button-loader>
                </div>
              </ng-container>

              <ng-container *ngIf="signatureNameTypeList.length > 0">
                <ng-container
                  *ngFor="let item of signatureNameTypeList; let last = last"
                >
                  <div class="px-4 py-1 line-item">
                    <mat-radio-button
                      (change)="onNameTypeSelected($event)"
                      [value]="item.src"
                    >
                      <img [src]="item.src" />
                      <!-- <span class="list-svg"> -->
                      <!-- <app-svg
                        class="mt-2"
                        [src]="item.src"
                        [svgWidth]="'100%'"
                        [svgHeight]="'100%'"
                      ></app-svg> -->
                      <!-- </span> -->
                    </mat-radio-button>
                  </div>
                  <!-- <div *ngIf="!last" class="line-item"></div> -->
                </ng-container>
              </ng-container>
            </mat-radio-group>
          </ng-container>

          <ng-container *ngIf="option === 'draw'">
            <span class="delete-btn" (click)="clearSignature()">
              <app-svg
                class="p-2"
                matRipple
                src="assets/icons/delete.svg"
                svgPathStroke="var(--flwmn-accent-color)"
              ></app-svg
            ></span>

            <div #mediaContainer class="draw gap-3">
              <canvas id="sig-canvas">
                Your browser doesn't support this feature. please use a
                different browser. Ex: Google Chrome
              </canvas>
            </div>
          </ng-container>

          <ng-container *ngIf="option === 'upload'">
            <ng-container *ngIf="!logoPreview">
              <div
                matRipple
                dragAndDrop
                class="drag-and-drop-border"
                (click)="documentUpload.click()"
                (fileDropped)="onDropDocument($event)"
              >
                <app-svg
                  src="assets/icons/form-builder/fmbr-controls/upload.svg"
                  class="mb-2"
                >
                </app-svg>

                <p class="flwmn-caption flwmn-text-primary mb-0 fw-600">
                  Upload signature or drag file here
                </p>

                <p class="flwmn-footer flwmn-text-neutral mb-0">
                  Maximum upload size is 5MB <br />
                  Supports: JPG, PNG, SVG
                </p>

                <input
                  #documentUpload
                  type="file"
                  accept="image/*"
                  name=""
                  class="d-none"
                  (change)="onUploadDocument($event)"
                />
              </div>
            </ng-container>

            <ng-container *ngIf="logoPreview">
              <div class="wrapper">
                <div class="logo-container">
                  <img [src]="logoPreview" alt="" class="logo" />
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="currentTab === 1">
          <mat-radio-group class="signature-content-item">
            <ng-container
              *ngFor="let item of signatureInitialTypeList; let last = last"
            >
              <div class="px-4 py-1 line-item">
                <mat-radio-button
                  (change)="onInitialTypeSelected($event)"
                  [value]="item.src"
                >
                  <img [src]="item.src" />
                  <!-- <app-svg
                    [src]="item.src"
                    [svgWidth]="'100%'"
                    [svgHeight]="'100%'"
                  ></app-svg> -->
                </mat-radio-button>
              </div>

              <!-- <div *ngIf="!last" class="line-item"></div> -->
            </ng-container>
          </mat-radio-group>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between px-2">
    <div *ngIf="option !== 'saved'" class="theme-container">
      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme1"
        class="theme theme1"
        [ngClass]="{ selected: selectedTheme === 'theme1' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme2"
        class="theme theme2"
        [ngClass]="{ selected: selectedTheme === 'theme2' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme3"
        class="theme theme3"
        [ngClass]="{ selected: selectedTheme === 'theme3' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme4"
        class="theme theme4"
        [ngClass]="{ selected: selectedTheme === 'theme4' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme5"
        class="theme theme5"
        [ngClass]="{ selected: selectedTheme === 'theme5' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme6"
        class="theme theme6"
        [ngClass]="{ selected: selectedTheme === 'theme6' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme7"
        class="theme theme7"
        [ngClass]="{ selected: selectedTheme === 'theme7' }"
      ></div>

      <div
        matRipple
        (click)="onSelectTheme($event)"
        data-theme="theme8"
        class="theme theme8"
        [ngClass]="{ selected: selectedTheme === 'theme8' }"
      ></div>
    </div>

    <ng-container *ngIf="isEditorOrSelfSign() && this.option !== 'saved'">
      <mat-checkbox
        [(ngModel)]="checked"
        color="primary"
        class="recipient-checkbox"
      >
        Save Signature
      </mat-checkbox>
    </ng-container>

    <!-- <ng-container
      *ngIf="
        this.editorMode === this.editorModeEnum.Internal &&
        this.option !== 'saved'
      "
    >
      <mat-checkbox
        [(ngModel)]="defaultChecked"
        color="primary"
        class="recipient-checkbox"
      >
        Make Default
      </mat-checkbox>
    </ng-container> -->
  </div>

  <div class="d-flex justify-content-between mx-1">
    <button
      matRipple
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      id="sig-clearBtn"
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button
      matRipple
      class="btn flwmn-btn-primary flwmn-btn-md"
      id="sig-submitBtn"
      (click)="onDoneSignaturePad()"
    >
      <!-- <ng-container *ngIf="currentTab === 1 || this.data.email"
        >Done</ng-container
      >

      <ng-container
        *ngIf="
          currentTab !== 1 &&
          (this.data.recipientEmail || this.signType === 'self')
        "
        >Use</ng-container
      > -->
      Done
    </button>
  </div>
  <canvas id="textCanvas" class="d-none" height="250"></canvas>

  <canvas id="markedCanvas" class="d-none" height="400"></canvas>
</section>
