import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromUserRole from './user-role.reducer';

export const getUserRoleState = (state: fromApp.AppState) => state.userRole;

export const getUserRoleIsLoading = createSelector(
  getUserRoleState,
  (state: fromUserRole.userRoleReducerState) => state.isLoading
);

export const getAllUserRoles = createSelector(
  getUserRoleState,
  (state: fromUserRole.userRoleReducerState) => state.roles
);

export const getActiveUserRoles = createSelector(
  getUserRoleState,
  (state: fromUserRole.userRoleReducerState) => state.activeRoles
);

export const getInactiveUserRoles = createSelector(
  getUserRoleState,
  (state: fromUserRole.userRoleReducerState) => state.inactiveRoles
);

export const getPermissionsBySubscriptionPlanIdAndRoleAccessLevel =
  createSelector(
    getUserRoleState,
    (state: fromUserRole.userRoleReducerState) =>
      state.permissionsBySubscriptionPlanIdAndRoleAccessLevel
  );
