import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../stores/app/app.reducer';
import * as AuthActions from '../stores/auth/auth.actions';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Notification } from '../interfaces';
import { NotificationService } from './notification.service';
import { Subscription, filter } from 'rxjs';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  RedirectRequest,
} from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  idleState = 'Not started.';
  hasRefreshTokenBeenCalled: boolean = false;
  hasSessionLogoutBeenCalled: boolean = false;

  // private tokenExpirationTimer: any;
  private subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private idle: Idle,
    private notificationService: NotificationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    // sets an idle timeout of 1 second.
    this.idle.setIdle(1);

    // sets a timeout period of 3600 seconds. after 3601 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(3600);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscription.add(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = `Timer = ${countdown}`;

        const OnyxDocData = JSON.parse(localStorage.getItem('OnyxDoc')!);
        const OnyxDocAuthData = JSON.parse(
          localStorage.getItem('OnyxDoc_auth')!
        );

        const onyxDocSessionExpiryDateTime = OnyxDocData?.exp;
        const onyxDocAuthSessionExpiryDateTime = OnyxDocAuthData?.expiryDate;

        const currentDateTime = new Date().getTime();

        const onyxDocTimeLeft =
          (onyxDocSessionExpiryDateTime - currentDateTime) / 60000; //Value in minutes from milliseconds

        const onyxDocAuthTimeLeft =
          (onyxDocAuthSessionExpiryDateTime - currentDateTime) / 60000; //Value in minutes from milliseconds

        //If time runs out i.e timeLeft <= 0, logout user
        if (
          (onyxDocTimeLeft <= 0 || onyxDocAuthTimeLeft <= 0) &&
          this.hasSessionLogoutBeenCalled === false
        ) {
          this.sessionLogout();
        }
      })
    );

    this.subscription.add(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = `You're now active`;

        const OnyxDocData = JSON.parse(localStorage.getItem('OnyxDoc')!);
        const OnyxDocAuthData = JSON.parse(
          localStorage.getItem('OnyxDoc_auth')!
        );

        const onyxDocSessionExpiryDateTime = OnyxDocData?.exp;
        const onyxDocAuthSessionExpiryDateTime = OnyxDocAuthData?.expiryDate;

        const refreshToken = OnyxDocData?.refreshToken;

        const currentDateTime = new Date().getTime();

        const onyxDocTimeLeft =
          (onyxDocSessionExpiryDateTime - currentDateTime) / 60000; //Value in minutes from milliseconds

        const onyxDocAuthTimeLeft =
          (onyxDocAuthSessionExpiryDateTime - currentDateTime) / 60000; //Value in minutes from milliseconds

        if (
          ((onyxDocTimeLeft <= 5 /**5 minutes**/ && onyxDocTimeLeft > 0) ||
            (onyxDocAuthTimeLeft <= 5 /**5 minutes**/ &&
              onyxDocAuthTimeLeft > 0)) &&
          this.hasRefreshTokenBeenCalled === false
        ) {
          this.store.dispatch(
            AuthActions.RefreshToken({
              payload: {
                refreshToken: refreshToken,
              },
            })
          );

          this.hasRefreshTokenBeenCalled = true;
        }

        if (
          (onyxDocTimeLeft <= 0 || onyxDocAuthTimeLeft <= 0) &&
          this.hasSessionLogoutBeenCalled === false
        ) {
          this.sessionLogout();
        }
      })
    );

    this.subscription.add(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';

        /** As a last resort, if "this.idle.setTimeout(3600);" timesout
         *  and the both bearer tokens are still active,
         *  that means 3600 seconds (1 hour) has passed. So log the user out */
        this.sessionLogout();
      })
    );
  }

  autoLogout() {
    const notification: Notification = {
      state: 'error',
      message: `Your session has expired. Logging you out now.`,
    };

    this.notificationService.openNotification(
      notification,
      'flwmn-notification-error'
    );

    setTimeout(() => {
      this.store.dispatch(AuthActions.Logout({ payload: {} }));
    }, 3000);
  }

  sessionLogout() {
    /** Only auto logout when in the APP **/
    if (location.pathname.includes('/app')) {
      this.autoLogout();

      this.hasSessionLogoutBeenCalled = true;
    }
  }

  startActivityMonitor() {
    this.idle.watch();
  }

  stopActivityMonitor() {
    if (this.idle) {
      this.idle.stop();
      // this.idle.onTimeout.unsubscribe();
    }
  }

  initializeLoginWithAzureAD() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }

    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   if (this.msalGuardConfig.authRequest) {
    //     this.msalService
    //       .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //       .subscribe((response: AuthenticationResult) => {
    //         this.msalService.instance.setActiveAccount(response.account);
    //       });
    //   } else {
    //     this.msalService
    //       .loginPopup()
    //       .subscribe((response: AuthenticationResult) => {
    //         this.msalService.instance.setActiveAccount(response.account);
    //       });
    //   }
    // } else {
    //   if (this.msalGuardConfig.authRequest) {
    //     this.msalService.loginRedirect({
    //       ...this.msalGuardConfig.authRequest,
    //     } as RedirectRequest);
    //   } else {
    //     this.msalService.loginRedirect();
    //   }
    // }
  }

  listenToAzureADLoginSuccess() {
    this.subscription.add(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          )
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;

          this.msalService.instance.setActiveAccount(payload.account);
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
