import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseIterable',
})
export class ReverseIterablePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value.slice().reverse();
    }

    return value;
  }
}
