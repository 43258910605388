import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-scan-qr-code',
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss'],
})
export class ScanQrCodeComponent {
  constructor(public dialogRef: MatDialogRef<ScanQrCodeComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
