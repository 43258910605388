import { createReducer, on, Action } from '@ngrx/store';
import * as ConditionActions from './condition.actions';
import { Entity, Condition } from '../../interfaces';

export interface State {
  isLoading: boolean;
  allowModalClick: boolean;
  conditions: Entity<Condition[]> | null;
  condition: Entity<Condition> | null;
  conditionString: { id: string; queryString: string } | null;
}

const initialState: State = {
  isLoading: false,
  allowModalClick: true,
  conditions: null,
  condition: null,
  conditionString: null,
};

const conditionReducerInternal = createReducer(
  initialState,
  on(ConditionActions.ResetStore, () => ({
    ...initialState,
  })),
  on(ConditionActions.IsLoading, (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    };
  }),
  on(ConditionActions.AllowModalClick, (state, { payload }) => ({
    ...state,
    allowModalClick: payload,
  })),
  on(ConditionActions.CreateCondition, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ConditionActions.StringifyCondition, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ConditionActions.SaveConditions, (state, { payload }) => ({
    ...state,
    isLoading: false,
    conditions: payload,
  })),
  on(ConditionActions.SaveConditionById, (state, { payload }) => ({
    ...state,
    isLoading: false,
    condition: payload,
  })),
  on(ConditionActions.SaveStringifyCondition, (state, { payload }) => ({
    ...state,
    isLoading: false,
    conditionString: payload,
  })),
  on(ConditionActions.ResetStringifyCondition, (state, { payload }) => ({
    ...state,
    isLoading: false,
    conditionString: payload,
  })),
  on(ConditionActions.ResetConditionById, (state, { payload }) => ({
    ...state,
    isLoading: false,
    condition: payload,
  }))
);

export const conditionBuilderReducer = (
  state: State | undefined,
  action: Action
) => {
  return conditionReducerInternal(state, action);
};
