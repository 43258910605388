import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Inbox] Reset Store');

export const IsLoading = createAction(
  '[Inbox] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllInboxes = createAction('[Inbox] Get All Inboxes');

export const SaveAllInboxes = createAction(
  '[Inbox] Save All Inbox By Controller Name',
  props<{
    payload: any;
  }>()
);

export const GetInboxById = createAction(
  '[Inbox] Get  Inbox by ID',
  props<{
    payload: {
      id: number;
      documentId: number;
    };
  }>()
);

export const SaveInboxById = createAction(
  '[Inbox] Save Inbox By ID',
  props<{
    payload: any;
  }>()
);

export const DownloadDocuments = createAction(
  '[Inbox] Download Documents',
  props<{
    payload: {
      signingRequestId: number;
      documentIds: number[] | null;
    };
  }>()
);
