import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlwmnSheetRef<T = any, R = any> {
  /** The instance of component opened into the sheet. */
  componentInstance!: T;

  /** Subject for notifying the user that the sheet has finished opening. */
  private readonly _afterOpened = new Subject<void>();

  /** Subject for notifying the user that the sheet has finished closing. */
  private readonly _afterClosed = new Subject<R | undefined>();

  /** Subject for notifying the user that the sheet has started closing. */
  private readonly _beforeClosed = new Subject<R | undefined>();

  constructor(private overlayRef: OverlayRef) {}

  /**
   * Open the sheet.
   */
  open() {
    this._afterOpened.next();
    this._afterOpened.complete();
  }

  /**
   * Close the sheet.
   * @param sheetResult Optional result to return to the sheet opener.
   */
  close(sheetResult?: R) {
    this._beforeClosed.next(sheetResult);
    this._beforeClosed.complete();

    const timeout = setTimeout(() => {
      this.overlayRef.dispose();

      this._afterClosed.next(sheetResult);
      this._afterClosed.complete();

      clearTimeout(timeout);
    }, 300);
  }

  /**
   * Gets an observable that is notified when the sheet is finished opening.
   */
  afterOpened(): Observable<void> {
    return this._afterOpened;
  }

  /**
   * Gets an observable that is notified when the sheet is finished closing.
   */
  afterClosed(): Observable<R | undefined> {
    return this._afterClosed;
  }

  /**
   * Gets an observable that is notified when the sheet has started closing.
   */
  beforeClosed(): Observable<R | undefined> {
    return this._beforeClosed;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this.overlayRef.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this.overlayRef.keydownEvents();
  }
}
