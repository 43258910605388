import { createReducer, on, Action } from '@ngrx/store';
import * as DocumentActions from './drive.actions';
import {
  CommentDetails,
  FileProgressItem,
} from '../../interfaces/drive.interface';

export interface State {
  isLoading: boolean;
  isDataLoading: boolean;
  isInformationLoading: boolean;
  isFetching: boolean;
  isScrollFetching: boolean;
  isMovingDriveItem: boolean;
  isMoveModalLoadingData: boolean;
  isStorageLoading: boolean;
  isShareLoading: boolean;
  driveItems: any[];
  activities: any[];
  details: any;
  singleClickCurrentItem: any | null;
  doubleClickCurrentItem: any | null;
  actionSuccess: { action: string | number; uniqueId: number } | null;
  modulePagination: { skip: number; take: number };
  openedModule:
    | 'my-drive'
    | 'recent-files'
    | 'recycle-bin'
    | 'shared-with-others'
    | 'shared-with-me'
    | 'secured-folder';
  currentlyOpenedFolderGuid: string;
  driveUploadProgress: number;
  currentFilter: 'File' | 'Folder';
  driveFilesUploadProgressItems: FileProgressItem[];
  checkedIntoSecureFolder: boolean;
  SelectedItems: any[];
  commentReplies: { [commentId: string]: CommentDetails[] };
}

const initialState: State = {
  isLoading: false,
  isDataLoading: false,
  isInformationLoading: false,
  isFetching: false,
  isScrollFetching: false,
  isMovingDriveItem: false,
  isMoveModalLoadingData: false,
  isStorageLoading: false,
  isShareLoading: false,
  driveItems: [],
  activities: [],
  details: null,
  singleClickCurrentItem: { myDrive: true },
  doubleClickCurrentItem: null,
  actionSuccess: null,
  modulePagination: { skip: 0, take: 20 },
  openedModule: 'my-drive',
  currentlyOpenedFolderGuid: '',
  driveUploadProgress: 0,
  currentFilter: 'File',
  driveFilesUploadProgressItems: [],
  checkedIntoSecureFolder: false,
  SelectedItems: [],
  commentReplies: {},
};

const driveReducerInternal = createReducer(
  initialState,
  on(DocumentActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(DocumentActions.SetModulePagination, (state, { payload }) => ({
    ...state,
    modulePagination: payload,
  })),

  on(DocumentActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(DocumentActions.SharingLoading, (state, { payload }) => ({
    ...state,
    isShareLoading: payload,
  })),

  on(DocumentActions.IsScrollFetching, (state, { payload }) => ({
    ...state,
    isScrollFetching: payload,
  })),

  on(DocumentActions.IsDataLoading, (state, { payload }) => ({
    ...state,
    isDataLoading: payload,
  })),

  on(DocumentActions.MovingDriveItem, (state, { payload }) => ({
    ...state,
    isMovingDriveItem: payload,
  })),

  on(DocumentActions.IsMoveModalLoadingData, (state, { payload }) => ({
    ...state,
    isMoveModalLoadingData: payload,
  })),

  on(DocumentActions.IsLoadingInformation, (state, { payload }) => ({
    ...state,
    isInformationLoading: payload,
  })),

  on(DocumentActions.IsStorageLoading, (state, { payload }) => ({
    ...state,
    isStorageLoading: payload,
  })),

  on(DocumentActions.CheckedIntoSecureFolder, (state, { payload }) => ({
    ...state,
    checkedIntoSecureFolder: payload,
  })),

  on(DocumentActions.ActionSuccess, (state, { payload }) => ({
    ...state,
    actionSuccess: payload,
  })),

  on(DocumentActions.SaveFolderContent, (state, { payload }) => ({
    ...state,
    activeFolderContent: payload,
  })),
  on(DocumentActions.SaveCommentOnDocument, (state, { payload }) => ({
    ...state,
    commentOnDocument: payload,
  })),
  on(DocumentActions.SaveAddressBookBySubscriberId, (state, { payload }) => ({
    ...state,
    allSubscribers: payload,
  })),
  on(DocumentActions.SaveAllCompanyDetails, (state, { payload }) => ({
    ...state,
    allCompanyDetails: payload,
  })),

  //  Drive

  on(DocumentActions.SetCurrentModuleViewed, (state, { payload }) => ({
    ...state,
    openedModule: payload,
  })),

  on(DocumentActions.IsFetching, (state, { payload }) => ({
    ...state,
    isFetching: payload,
  })),

  on(DocumentActions.SaveDriveItems, (state, { payload }) => {
    return {
      ...state,
      driveItems: payload,
    };
  }),

  on(DocumentActions.SaveDriveActivities, (state, { payload }) => {
    return {
      ...state,
      activities: payload,
    };
  }),

  on(DocumentActions.SaveDriveDetails, (state, { payload }) => {
    return {
      ...state,
      details: payload,
    };
  }),

  on(DocumentActions.SaveSingleClickCurrentItem, (state, { payload }) => {
    return {
      ...state,
      singleClickCurrentItem: payload,
    };
  }),

  on(DocumentActions.SaveCtrlClickedItems, (state, { payload }) => {
    return {
      ...state,
      SelectedItems: payload,
    };
  }),

  on(DocumentActions.AddOrRemoveSelectedItem, (state, { payload }) => {
    let tempList: any[] = [...state.SelectedItems];

    if (tempList.length) {
      const index = tempList.findIndex((item) => item.guId === payload.guId);

      if (index > -1) {
        tempList = [...tempList.slice(0, index), ...tempList.slice(index + 1)];
      } else {
        tempList = [...tempList, payload];
      }
    } else {
      tempList = [...tempList, payload];
    }

    return {
      ...state,
      SelectedItems: tempList,
    };
  }),

  on(DocumentActions.SaveDoubleClickCurrentItem, (state, { payload }) => {
    return {
      ...state,
      doubleClickCurrentItem: payload,
    };
  }),
  on(DocumentActions.TrackCurrentlyOpenedFolderGuid, (state, { payload }) => ({
    ...state,
    currentlyOpenedFolderGuid: payload,
  })),
  on(DocumentActions.UploadDriveProgress, (state, { payload }) => ({
    ...state,
    driveUploadProgress: payload,
  })),

  on(DocumentActions.SetCurrentFilter, (state, { payload }) => ({
    ...state,
    currentFilter: payload,
  })),

  on(DocumentActions.AddFileUploadProgressItem, (state, { payload }) => ({
    ...state,
    driveFilesUploadProgressItems: [
      ...state.driveFilesUploadProgressItems,
      payload,
    ],
  })),

  on(
    DocumentActions.UpdateFileUploadProgressItemProgress,
    (state, { payload }) => {
      const updatedItem: FileProgressItem[] =
        state.driveFilesUploadProgressItems.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              progress: payload.progress,
            };
          }
          return item;
        });

      return {
        ...state,
        driveFilesUploadProgressItems: updatedItem,
      };
    }
  ),

  on(
    DocumentActions.UpdateFileUploadProgressItemUploadState,
    (state, { payload }) => {
      const updatedItem: FileProgressItem[] =
        state.driveFilesUploadProgressItems.map((item) => {
          if (item.id === payload.id) {
            let setItem: FileProgressItem = {
              ...item,
              progress: payload.progress,
              uploadState: payload.uploadState,
            };

            // set Message
            if (payload.uploadState === 'Success') {
              setItem = {
                ...setItem,
                message: `uploaded successfully.`,
              };
            } else if (payload.uploadState === 'Failed') {
              setItem = {
                ...setItem,
                message: `${
                  payload.message ? payload.message : 'Failed to upload file'
                }`,
              };
            }

            return setItem;
          }

          return item;
        });

      return {
        ...state,
        driveFilesUploadProgressItems: updatedItem,
      };
    }
  ),

  on(DocumentActions.ClearFileUploadProgress, (state) => ({
    ...state,
    driveFilesUploadProgressItems: [],
  })),

  on(DocumentActions.RemoveUploadProgress, (state, { payload }) => ({
    ...state,
    driveFilesUploadProgressItems: state.driveFilesUploadProgressItems.filter(
      (item) => item.id !== payload.id
    ),
  })),

  on(
    DocumentActions.GetDriveCommentByIdSuccess,
    (state, { commentGuId, replies }) => {
      const newCommentReplies = {
        ...state.commentReplies,
        [commentGuId]: replies,
      };

      return {
        ...state,
        commentReplies: newCommentReplies,
      };
    }
  ),

  on(DocumentActions.ResetCommentByIdStore, (state) => {
    return {
      ...state,
      commentReplies: {},
    };
  })
);

export function driveReducer(state: State | undefined, action: Action) {
  return driveReducerInternal(state, action);
}
