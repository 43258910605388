import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ControlMode, EditorMode } from 'src/app/@core/types';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;
  @Input() hoverState: Observable<'mouseover' | 'mouseout'>;
  @Input() actorMenu: boolean = false;

  @ViewChild('dateWrapper', { static: false })
  dateWrapper: ElementRef;

  @ViewChild('dateInput', { static: false })
  dateInput: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.hoverState.subscribe((event: any) => {
      if (event === 'mouseover') {
        this.renderer.addClass(
          this.dateWrapper.nativeElement,
          'show-flwmn-date-control-calendar'
        );
      } else if (event === 'mouseout') {
        if (
          (this.dateWrapper.nativeElement as HTMLElement).classList.contains(
            'show-flwmn-date-control-calendar'
          )
        ) {
          this.renderer.removeClass(
            this.dateWrapper.nativeElement,
            'show-flwmn-date-control-calendar'
          );
        }
      }
    });
  }
}
