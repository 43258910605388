<div
  class=""
  [formGroup]="form"
  style="width: inherit; height: inherit"
  (click)="stopPropagation($event)"
>
  <div
    #controlOverlay
    *ngIf="controlIsActive === false"
    class="flwmn-control-overlay"
    (click)="onClickControlOverlay($event)"
  ></div>

  <ng-template #viewContainerRef> </ng-template>

  <ng-container *ngIf="showControlMenuBar === true">
    <app-control-menu-bar
      class="app-control-menu-bar"
      [control]="control"
      [form]="form"
      [showActors]="signType === editorSignTypeEnum.Multiple ? true : false"
      (selectedActorEmitter)="receiveActor($event)"
      (selectedTextSize)="initiateSetTextSize($event)"
      (selectedTextWeight)="initiateSetTextWeight($event)"
      (selectedTextAlign)="initiateSetTextAlign($event)"
      (selectedTextColor)="initiateSetTextColor($event)"
      (duplicate)="initiateDuplicateControl($event)"
      (remove)="initiateRemoveControl()"
      (editIcon)="onEditIcon($event)"
      (repeatAllPages)="addInitialsOnAllPage($event)"
    ></app-control-menu-bar>
  </ng-container>

  <ng-container *ngIf="controlMode !== controlModeEnum.External">
    <div
      #topLeftResizeHandle
      [hidden]="controlIsActive === false"
      class="resize-handle top-left"
    ></div>
    <div
      #topRightResizeHandle
      [hidden]="controlIsActive === false"
      class="resize-handle top-right"
    ></div>
    <div
      #bottomLeftResizeHandle
      [hidden]="controlIsActive === false"
      class="resize-handle bottom-left"
    ></div>
    <div
      #bottomRightResizeHandle
      [hidden]="controlIsActive === false"
      class="resize-handle bottom-right"
    ></div>
  </ng-container>
</div>
