import { createReducer, on, Action } from '@ngrx/store';
import * as EditorActions from './editor.actions';

export interface State {
  isLoading: boolean;
  documentsBySigningRequestId: any[] | null;
  controlActors: {
    actorListName: string;
    actorsData:
      | {
          actorId: number;
          actorFirstName: string;
          actorLastName: string;
          actorEmail: string;
          profilePicture: string | null;
        }[];
  } | null;
  /********************************************/
  document: {
    documentId: number;
    file: string | null;
  } | null;
  recipients: any[] | null;
  user: any;
  allCommentsByDocumentId: any[] | null;
  allControlConfig: any;
  allActiveControls: any[] | null;
  saveAsTemplateData: any;
  allPageControlItemProperties: any[] | null;
  controlById: any;

  allDocumentCategory: any[] | null;
  allDocumentPriority: any[] | null;
  signingRequestValiditySetting: any | null;
  signingRequestReminderSetting: any | null;
  savedSignature: any | null;
  saveDocumentHash: any | null;

  internalUser: any | null;

  externalUser: any | null;
  selectedRecipient: {
    actorId: number;
    actorFirstName: string;
    actorLastName: string;
    actorEmail: string;
  } | null;
}

const initialState: State = {
  isLoading: false,
  documentsBySigningRequestId: null,
  controlActors: null,
  /********************************************/
  document: null,
  recipients: null,
  user: null,
  allCommentsByDocumentId: null,
  allControlConfig: null,
  allActiveControls: null,
  saveAsTemplateData: null,
  allPageControlItemProperties: null,
  controlById: null,

  allDocumentCategory: null,
  allDocumentPriority: null,
  signingRequestValiditySetting: null,
  signingRequestReminderSetting: null,
  savedSignature: null,
  saveDocumentHash: null,

  internalUser: null,
  externalUser: null,
  selectedRecipient: null,
};

const editorReducerInternal = createReducer(
  initialState,
  on(EditorActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(EditorActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(EditorActions.SaveDocumentsBySigningRequestId, (state, { payload }) => ({
    ...state,
    documentsBySigningRequestId: payload,
  })),
  on(EditorActions.SaveControlActors, (state, { payload }) => ({
    ...state,
    controlActors: payload,
  })),
  /*************************************************************************/
  on(EditorActions.SaveDocument, (state, { payload }) => ({
    ...state,
    document: payload,
  })),
  on(EditorActions.SaveRecipients, (state, { payload }) => ({
    ...state,
    recipients: payload,
  })),
  on(EditorActions.ClearDocumentAndRecipients, (state) => ({
    ...state,
    document: null,
    recipients: null,
  })),
  on(EditorActions.SaveUser, (state, { payload }) => ({
    ...state,
    user: payload,
  })),
  on(EditorActions.SaveCommentsByDocumentId, (state, { payload }) => ({
    ...state,
    allCommentsByDocumentId: payload,
  })),
  on(EditorActions.SaveControls, (state, { payload }) => ({
    ...state,
    allControlConfig: payload,
  })),
  on(EditorActions.SaveActiveControls, (state, { payload }) => ({
    ...state,
    allActiveControls: payload,
  })),
  on(EditorActions.StoreDataForSaveAsTemplate, (state, { payload }) => ({
    ...state,
    saveAsTemplateData: payload,
  })),
  on(
    EditorActions.SavePageControlItemPropertiesByPageControlItem,
    (state, { payload }) => ({
      ...state,
      allPageControlItemProperties: payload,
    })
  ),
  on(EditorActions.SaveControlById, (state, { payload }) => ({
    ...state,
    controlById: payload,
  })),

  on(EditorActions.SaveAllCategoryDocument, (state, { payload }) => ({
    ...state,
    allDocumentCategory: payload,
  })),

  on(EditorActions.SaveSigningRequestValiditySetting, (state, { payload }) => ({
    ...state,
    signingRequestValiditySetting: payload,
  })),

  on(EditorActions.SaveSigningRequestReminderSetting, (state, { payload }) => ({
    ...state,
    signingRequestReminderSetting: payload,
  })),

  on(EditorActions.SaveExternalUserByEmail, (state, { payload }) => ({
    ...state,
    savedSignature: payload,
  })),

  on(EditorActions.SaveDecodeUrlHash, (state, { payload }) => ({
    ...state,
    saveDocumentHash: payload,
  })),

  on(EditorActions.SaveInternalUser, (state, { payload }) => ({
    ...state,
    internalUser: payload,
  })),

  on(EditorActions.SaveExternalUser, (state, { payload }) => ({
    ...state,
    externalUser: payload,
  })),

  on(EditorActions.SaveSelectedRecipient, (state, { payload }) => ({
    ...state,
    selectedRecipient: payload,
  }))
);

export function editorReducer(state: State | undefined, action: Action) {
  return editorReducerInternal(state, action);
}
