import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DefaultControlTypeStyles } from 'src/app/@core/constants';
import { ControlModeEnum, ControlTypeEnum } from 'src/app/@core/enums';
import { ControlMode, EditorMode } from 'src/app/@core/types';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements AfterViewInit, OnDestroy {
  baseScrollHeight: any;
  allControlTypes = ControlTypeEnum;

  private subscription: Subscription = new Subscription();

  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;
  @Input() hoverState: Observable<'mouseover' | 'mouseout'>;
  @Input() actorMenu: boolean = false;
  @Input() controlResize: any;

  @Output() textWidth = new EventEmitter<number>();
  @Output() textHeight = new EventEmitter<number>();

  @ViewChild('inputWrapper', { static: false })
  inputWrapper: ElementRef;

  @ViewChild('customTextArea', { static: false })
  customTextArea: ElementRef;

  @ViewChild('textarea', { static: false })
  textarea: ElementRef;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setRowsCols();

    this.setMaxLength();

    this.listenToControlResizeSub();

    this.calculateTextControlWidth();

    // const timeout = setTimeout(() => {
    //   this.calculateTextControlWidth();

    //   clearTimeout(timeout);
    // });
  }

  getPlaceholder() {
    switch (this.control.type) {
      case this.allControlTypes.text:
        return 'Text Field';
        break;

      case this.allControlTypes.name:
        return 'Name Field';
        break;

      case this.allControlTypes.email:
        return 'Email Field';
        break;

      case this.allControlTypes.jobTitle:
        return 'Job Title Field';
        break;

      default:
        return 'Text Field';
        break;
    }
  }

  listenToControlResizeSub() {
    this.subscription.add(
      this.controlResize.subscribe((el: any) => {
        this.setRowsCols();

        this.setMaxLength();

        this.calculateTextControlHeight();

        // this.removeExcessText();
      })
    );
  }

  // getBaseScrollHeight() {
  //   const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
  //   const savedValue = textarea.value;
  //   textarea.value = '';
  //   this.baseScrollHeight = textarea.scrollHeight;
  //   textarea.value = savedValue;
  // }

  // onExpandableTextareaInput() {
  //   const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
  //   const minRows = 1;
  //   textarea.rows = minRows;
  //   this.getBaseScrollHeight();
  //   textarea.rows =
  //     minRows + Math.ceil((textarea.scrollHeight - this.baseScrollHeight) / 16);
  // }

  // autoExpand() {
  //   const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
  //   // Reset field height
  //   textarea.style.height = 'inherit';

  //   // Get the computed styles for the element
  //   const computed = window.getComputedStyle(textarea);

  //   // Calculate the height
  //   const height =
  //     parseInt(computed.getPropertyValue('border-top-width'), 10) +
  //     parseInt(computed.getPropertyValue('padding-top'), 10) +
  //     textarea.scrollHeight +
  //     parseInt(computed.getPropertyValue('padding-bottom'), 10) +
  //     parseInt(computed.getPropertyValue('border-bottom-width'), 10);

  //   textarea.style.height = height + 'px';
  // }

  onTextInput() {
    this.setRowsCols();

    this.setMaxLength();

    this.calculateTextControlHeight();

    // this.removeExcessText();
  }

  setRowsCols() {
    // Get the textarea element
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;

    // Get the font size of the textarea
    const fontSize = parseFloat(window.getComputedStyle(textarea).fontSize);

    // Calculate the height and width of a single line of text
    const lineHeight = fontSize * 1.5;
    const charWidth = fontSize * 0.6;

    // Calculate the number of rows and columns that fit in the textarea
    const rows = Math.floor(textarea.clientHeight / lineHeight);
    const cols = Math.floor(textarea.clientWidth / charWidth);

    // Set the rows and cols attributes of the textarea
    textarea.rows = rows;
    textarea.cols = cols;
  }

  setMaxLength() {
    if (this.controlMode === ControlModeEnum.External) {
      // Get the textarea element
      const textarea = this.textarea.nativeElement as HTMLTextAreaElement;

      // Calculate the width and height of a single character in the textarea
      const charWidth = textarea.clientWidth / textarea.cols;
      const charHeight = textarea.clientHeight / textarea.rows;

      // Calculate the maximum number of characters that can fit in the textarea
      const maxCharsWidth = Math.round(textarea.clientWidth / charWidth);
      const maxCharsHeight = Math.round(textarea.clientHeight / charHeight);
      const maxLength = maxCharsWidth * maxCharsHeight;

      // Set the maxLength property of the textarea
      textarea.maxLength = maxLength;

      // // Get the font size of the textarea
      // const fontSize = parseFloat(getComputedStyle(textarea).fontSize);

      // // Create a temporary span element to calculate the width of one character
      // const span = document.createElement('span');
      // span.style.fontSize = fontSize + 'px';
      // span.innerText = 'X';

      // // Calculate the width of one character in the textarea
      // // const charWidth = span.offsetWidth;

      // // Calculate the maximum number of characters that can fit in one row of the textarea
      // const maxCols = textarea.cols;
      // // const maxWidthChars = Math.floor((textarea.offsetWidth - 2) / charWidth); // Subtract 2 for border width

      // // Calculate the maximum number of characters that can fit in the textarea
      // const maxLength = textarea.rows * maxCols;

      // // Set the maxLength attribute of the textarea element
      // textarea.maxLength = maxLength;
    }
  }

  calculateTextControlHeight() {
    if (this.controlMode !== ControlModeEnum.External) {
      // Get the textarea element
      const textarea = this.textarea.nativeElement as HTMLTextAreaElement;

      this.textHeight.emit(textarea.scrollHeight);
    }
  }

  removeExcessText() {
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;

    if (textarea.value.length > textarea.maxLength) {
      textarea.value = textarea.value.slice(
        0,
        -(textarea.value.length - textarea.maxLength)
      );
    }
  }

  calculateTextControlWidth() {
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
    const fontSize = parseFloat(window.getComputedStyle(textarea).fontSize);
    const fontWeight = parseFloat(window.getComputedStyle(textarea).fontWeight);
    const padding =
      parseFloat(window.getComputedStyle(textarea).paddingLeft) +
      parseFloat(window.getComputedStyle(textarea).paddingRight);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    context.font = `${fontWeight} ${fontSize}px Helvetica`;

    if (
      textarea.value &&
      textarea.value !== null &&
      textarea.value !== undefined &&
      textarea.value !== ' ' &&
      textarea.value !== '  '
    ) {
      this.textWidth.emit(context.measureText(textarea.value).width + padding);
    } else {
      this.textWidth.emit(
        Number(
          (
            DefaultControlTypeStyles[ControlTypeEnum.text].width as string
          ).replaceAll('px', '')
        )
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
