import { createAction, props } from '@ngrx/store';
import { Subscriber } from '../../interfaces/subscriber.interface';
import { State } from './subscribers.reducer';

export const ResetStore = createAction('[Subscribers] Reset Store');

export const IsLoading = createAction(
  '[Subscribers] Is Loading',
  props<{
    payload: boolean;
  }>()
);

// export const GetAllSubscribers = createAction(
//   '[Subscribers] Get All Subscribers',
//   props<{
//     payload: {
//       skip: number;
//       take: number;
//     };
//   }>()
// );

export const GetAllSubscribers = createAction(
  '[Subscribers] Get All Subscribers',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const SaveAllSubscribers = createAction(
  '[Subscribers] Save All Subscribers',
  props<{
    payload: State['subscribers'];
  }>()
);

export const GetActiveSubscribers = createAction(
  '[Subscribers] Get Active Subscribers',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const SaveActiveSubscribers = createAction(
  '[Subscribers] Save All Active Subscribers',
  props<{
    payload: State['activeSubscribers'];
  }>()
);

export const GetInactiveSubscribers = createAction(
  '[Subscribers] Get Inactive Subscribers',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const SaveInactiveSubscribers = createAction(
  '[Subscribers] Save All Active Subscribers',
  props<{
    payload: State['inactiveSubscribers'];
  }>()
);

export const ActivateSubscriber = createAction(
  '[Subscribers] Activate Subscriber',
  props<{
    payload: {
      id?: number;
    };
    instance: 'allSubscribers' | 'activeSubscribers' | 'inactiveSubscribers';
  }>()
);

export const DeactivateSubscriber = createAction(
  '[Subscribers] Deactivate Subscriber',
  props<{
    payload: {
      id: number;
    };
    instance: 'allSubscribers' | 'activeSubscribers' | 'inactiveSubscribers';
  }>()
);

export const UpdateSubscriber = createAction(
  '[Subscribers] Update Subscriber',
  props<{
    payload: {
      name: string;
      phoneNumber: string;
      contactEmail: string;
      staffSize: string;
      industry: string;
      address: string;
      city: string;
      state: string;
      country: string;
      logo: string;
      latitude: string;
      longitude: string;
      referrer: string;
      rcNumber: string;
      companyDocument: string;
      subscriptionPurpose: string;
      subscriberType: number;
      subscriberAccessLevel: number;
      allowExternalRecipientsAssignControls: boolean;
      overrideExternalRecipientControlsSetting: boolean;
      allowUsersOutsideDomainToJoinOrganization: boolean;
      allowTimeStampOnSignatureControl: boolean;
    };
  }>()
);

export const GetSubscriberById = createAction(
  '[Subscribers] Get Subscriber By Id',
  props<{
    payload: {
      id: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const GetPaymentChannel = createAction(
  '[Subscribers] Get Payment Channel'
);

// export const SaveAllSubscribers = createAction(
//   '[Subscribers] Save All Subscribers',
//   props<{
//     payload: {
//       data: any;
//       count: number;
//     };
//   }>()
// );

export const SaveAllPaymentChannel = createAction(
  '[Subscribers] Save All Payment Channel',
  props<{
    payload: any;
  }>()
);

export const createSubscribers = createAction(
  '[Subscribers] Create Subscribers',
  props<{
    payload: Subscriber;
  }>()
);

export const UploadSubscriberReceipt = createAction(
  '[Subscribers] Upload Subscriber Receipt',
  props<{
    payload: {
      subscriberId: number;
      subscriptionNo: string;
      paymentReceipt: string;
      paymentChannelId: number;
      currencyCode: number;
      userId: string;
    };
  }>()
);

export const EditSubscribers = createAction(
  '[Subscribers] Edit Subscribers (Company)',
  props<{
    payload: {
      name: string;
      contactEmail: string;
      phoneNumber: string;
      staffSize: string;
      industry: string;
      address: string;
      city: string;
      state: string;
      country: string;
      latitude: string;
      longitude: string;
      referrer: string;
      subscriptionPurpose: string;
      subscriberType: number;
      subscriberAccessLevel: number;
      subscriberId: number;
      userId: string;
      isSubscriberNewlyCreated?: boolean;
    };
  }>()
);

export const saveSubcribersResponse = createAction(
  '[Subscribers] Save Subscribers response',
  props<{
    payload: any;
  }>()
);
