import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromEditor from './editor.reducer';

const getEditorState = (state: fromApp.AppState) => state.editor;

export const getEditorIsLoading = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.isLoading
);

export const getDocumentsBySigningRequestId = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.documentsBySigningRequestId
);

export const getControlActors = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.controlActors
);

/*********************************************************************/

// export const getDocument = createSelector(
//   getEditorState,
//   (state: fromEditor.State) => state.document
// );

export const getRecipients = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.recipients
);

export const getUser = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.user
);

export const getAllCommentsByDocumentId = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.allCommentsByDocumentId
);

// Control config

export const getAllControlConfig = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.allControlConfig
);

export const getAllActiveControls = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.allActiveControls
);

export const getSaveAsTemplateData = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.saveAsTemplateData
);

export const getAllPageControlItemProperties = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.allPageControlItemProperties
);

export const getControlByid = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.controlById
);

export const getAllDocumentCategory = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.allDocumentCategory
);

export const getSigningRequestValiditySetting = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.signingRequestValiditySetting
);

export const getSigningRequestReminderSetting = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.signingRequestReminderSetting
);

export const getSavedDecodeHash = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.saveDocumentHash
);

export const getInternalUserByEmail = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.internalUser
);

export const getExternalUserByEmail = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.externalUser
);

export const getSelectedRecipient = createSelector(
  getEditorState,
  (state: fromEditor.State) => state.selectedRecipient
);
