import { createReducer, on, Action } from '@ngrx/store';
import * as SigningRequestActions from './signing-request.actions';

export interface signingRequestReducerState {
  isLoading: boolean;
  signingRequestById: any;
  signingRequestActivity: any | null;
  auditTrailDownload: any | null;
}

const initialState: signingRequestReducerState = {
  isLoading: false,
  signingRequestById: undefined,
  signingRequestActivity: null,
  auditTrailDownload: null,
};

const signingRequestReducerInternal = createReducer(
  initialState,
  on(SigningRequestActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(SigningRequestActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(SigningRequestActions.SaveSigningRequestById, (state, { payload }) => ({
    ...state,
    signingRequestById: payload,
  })),

  on(
    SigningRequestActions.SaveSigningRequestUserActivity,
    (state, { payload }) => ({
      ...state,
      signingRequestActivity: payload,
    })
  ),
  on(
    SigningRequestActions.SaveSigningRequestAuditTrailDownload,
    (state, { payload }) => ({
      ...state,
      auditTrailDownload: payload,
    })
  )
);

export function signingRequestReducer(
  state: signingRequestReducerState | undefined,
  action: Action
) {
  return signingRequestReducerInternal(state, action);
}
