import { createReducer, on, Action } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';

export interface State {
  isLoading: boolean;
  dashboardData: any;
  coopDashboardData: any;
  individualDashboardData: any;
  recentDocumentsData: any;
  activities: any;
  documentLineChartData: any;
  documentDonutChartData: any;
  signingRequestDetails: any;
  dashboardMetric: any;
}

const initialState: State = {
  isLoading: false,
  dashboardData: null,
  coopDashboardData: null,
  individualDashboardData: null,
  recentDocumentsData: null,
  activities: null,
  documentLineChartData: null,
  documentDonutChartData: null,
  signingRequestDetails: null,
  dashboardMetric: null,
};

const dashboardReducerInternal = createReducer(
  initialState,
  on(DashboardActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(DashboardActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(DashboardActions.SaveDocumentDonutChartData, (state, { payload }) => ({
    ...state,
    documentDonutChartData: payload,
  })),
  on(DashboardActions.SaveDocumentLineChartData, (state, { payload }) => ({
    ...state,
    documentLineChartData: payload,
  })),
  on(DashboardActions.SaveActivities, (state, { payload }) => ({
    ...state,
    activities: payload,
  })),
  on(DashboardActions.SaveRecentDocuments, (state, { payload }) => ({
    ...state,
    recentDocumentsData: payload,
  })),
  on(DashboardActions.SaveIndividualDashboardData, (state, { payload }) => ({
    ...state,
    individualDashboardData: payload,
  })),

  on(DashboardActions.SaveCoopDashboard, (state, { payload }) => ({
    ...state,
    coopDashboardData: payload,
  })),
  on(DashboardActions.SaveDashboard, (state, { payload }) => ({
    ...state,
    dashboardData: payload,
  })),
  on(DashboardActions.SaveSigningRequestDetails, (state, { payload }) => ({
    ...state,
    signingRequestDetails: payload,
  })),
  on(DashboardActions.SaveDashboardMetric, (state, { payload }) => ({
    ...state,
    dashboardMetric: payload,
  }))
);

export const dashboardReducer = (state: State | undefined, action: Action) => {
  return dashboardReducerInternal(state, action);
};
