import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as AuthSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import { Subscription, filter } from 'rxjs';
import { Notification } from './@core/interfaces/index';
import { SEOService } from 'src/app/@core/services/seo.service';
import {
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { NotificationService } from './@core/services/notification.service';
import { PwaUpdateService } from './@core/services/pwa-update.service';
import { HelperService } from './@core/services/helper.service';
import { Meta } from '@angular/platform-browser';

import { InactivityService } from './@core/services/inactivity.service';
// import { Detector } from 'detector-js';
// import { UAParser } from 'ua-parser-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadedTheme: string | null = null;
  newLoadedTheme: string | null = null;
  loadingRouteConfig: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private seoService: SEOService,
    private notificationService: NotificationService,
    private router: Router,
    public pwaUpdateService: PwaUpdateService,
    private helperService: HelperService,
    private meta: Meta,
    private inactivityService: InactivityService
  ) {
    // this.subscription.add(
    //   this.inactivityService.inactivityDetected.subscribe(() => {
    //     this.listenIfUserIsLoggedIn();
    //   })
    // );
  }

  ngOnInit(): void {
    this.bugFixForOnyxDocAuthExpiryTime();

    localStorage.setItem('counterStatus', 'true');
    this.store.dispatch(AuthActions.InitializeApp_DeveloperToken());
    this.store.dispatch(AuthActions.InitializeApp_AccessToken());
    this.store.dispatch(AuthActions.AutoLogin());
    this.store.dispatch(GeneralActions.GetIpAddressDetails());

    this.helperService.checkExpiryStatusOfNg2Idle();

    this.manageRouterEvents();

    this.listenToOfflineOnlineState();

    this.listenToLazyLoadedModules();

    this.setContentSecurityPolicy();

    this.listenToGetSubscriber();

    this.getIpAddressDetails();
  }

  listenIfUserIsLoggedIn() {
    if (localStorage.getItem('OnyxDoc')) {
      this.onLogOut();
    }
  }

  bugFixForOnyxDocAuthExpiryTime() {
    /**
     * This should be removed latest by end of May 2024
     * ***********************************************
     * If the time NOW + 1hr is less than
     * the expiry time of the OnyxDoc_auth token, remove it.
     */

    const onyxDocAuthData: {
      bearer_token: string;
      expiryDate: number;
    } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

    const conditionDateTime: number = new Date().getTime() + 3600000;

    if (onyxDocAuthData) {
      if (conditionDateTime < onyxDocAuthData.expiryDate) {
        localStorage.removeItem('OnyxDoc_auth');
      }
    }
  }

  manageRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.seoService.manageAllPageTitlesAndDescription();

          this.seoService.setCanonicalLinkURL();
        })
    );
  }

  onClickPwaUpdateBanner() {
    this.pwaUpdateService.hasUserGivenUpdateConsent.next(true);
  }

  listenToOfflineOnlineState() {
    window.addEventListener('online', () => {
      const notification: Notification = {
        state: 'success',
        title: 'System Notification',
        message: "You're back online",
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-success'
      );
    });

    window.addEventListener('offline', () => {
      const notification: Notification = {
        state: 'warning',
        title: 'System Notification',
        message: "You're offline",
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    });
  }

  listenToLazyLoadedModules() {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        // window.scrollTo(0, 0);

        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }
      })
    );
  }

  listenToGetSubscriber() {
    this.subscription.add(
      this.store.select(AuthSelectors.getUser).subscribe((res) => {
        if (res && res.Subscriber) {
          const sessionTimeout = res.Subscriber.sessionTimeout;
          if (sessionTimeout > 0) {
            this.inactivityService.inactivityTimeLimit = sessionTimeout * 60000;
          }
          this.listenForInactivity();
        }
      })
    );
  }

  listenForInactivity() {
    this.subscription.add(
      this.inactivityService.inactivityDetected.subscribe(() => {
        this.listenIfUserIsLoggedIn();
      })
    );
  }

  getIpAddressDetails() {
    this.store.dispatch(GeneralActions.GetIpAddressDetails());
  }

  // listenToNotification() {
  //   this.notificationSub = this.store
  //     .pipe(select(generalSelectors.getNotification))
  //     .subscribe((notification) => {
  //       if (notification !== null) {
  //         if (notification.isSuccess === true) {
  //           this.openSnackBar(notification, 'notification-success');
  //         } else if (notification.isSuccess === false) {
  //           this.openSnackBar(notification, 'notification-danger');
  //         }
  //       }
  //     });
  // }

  // openSnackBar(message: Notification, notificationClass: string) {
  //   const snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
  //     data: message,
  //     duration: 15000,
  //     panelClass: [notificationClass],
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //   });

  //   snackBarRef.afterDismissed().subscribe(() => {
  //     this.store.dispatch(GeneralActions.ClearNotification());
  //   });
  // }

  // setContentSecurityPolicy() {
  //   this.meta.addTag({
  //     'http-equiv': 'Content-Security-Policy',
  //     content: `default-src 'self';
  //     connect-src 'self'
  //     ${environment.onyxDocDocumentUrl.split('/api')[0]}
  //    ${environment.onyxDocReminderUrl.split('/api')[0]}
  //     ${environment.onyxDocAuthUrl.split('/api')[0]}
  //    ${environment.onyxCore.split('/api')[0]}
  //     ${environment.onyxDocSubscriptionUrl.split('/api')[0]}
  //     ${environment.onyxDocFormBuilderUrl.split('/api')[0]}
  //      ${environment.onyxDocPhoenixFormBuilderUrl.split('/api')[0]}
  //     ${environment.onyxSLA}
  //    ${environment.onyxDocProcessUrl.split('/api')[0]}
  //     ${environment.onyxDocProcessWorkflowUrl.split('/api')[0]}
  //    ${environment.onyxDocConditionBuilderUrl.split('/api')[0]}
  //      ${environment.onyxDocAPIUrl.split('/api')[0]}
  //     ${environment.onyxDocProductUrl.split('/api')[0]}
  //     ${environment.onyxDocDriveUrl.split('/api')[0]}
  //     https://api.iplocation.net https://px.ads.linkedin.com
  //     https://google.com https://maps.googleapis.com
  //     https://firebase.googleapis.com https://www.googletagmanager.com
  //     https://www.googletagmanager.com https://apis.google.com`,
  //   });
  // }

  setContentSecurityPolicy() {
    this.meta.addTag({
      'http-equiv': 'Content-Security-Policy',
      content: ` script-src *
      'unsafe-inline' 'unsafe-eval'; style-src *
     'unsafe-inline';   img-src 'self' data: https:;
      
     `,
    });
  }

  // setContentSecurityPolicy() {
  //   const nonce = environment.AES_SecretKey;
  //   this.meta.addTag({
  //     'http-equiv': 'Content-Security-Policy',
  //     content: `default-src 'self'; script-src 'self' 'nonce-${nonce}
  //     'unsafe-inline' 'unsafe-eval'; style-src 'self'
  //     'unsafe-inline'; img-src 'self' data: https:;
  //     connect-src 'self'
  //     ${environment.onyxDocDocumentUrl.split('/api')[0]}
  //    ${environment.onyxDocReminderUrl.split('/api')[0]}
  //     ${environment.onyxDocAuthUrl.split('/api')[0]}
  //    ${environment.onyxCore.split('/api')[0]}
  //     ${environment.onyxDocSubscriptionUrl.split('/api')[0]}
  //     ${environment.onyxDocFormBuilderUrl.split('/api')[0]}
  //      ${environment.onyxDocPhoenixFormBuilderUrl.split('/api')[0]}
  //     ${environment.onyxSLA}
  //    ${environment.onyxDocProcessUrl.split('/api')[0]}
  //     ${environment.onyxDocProcessWorkflowUrl.split('/api')[0]}
  //    ${environment.onyxDocConditionBuilderUrl.split('/api')[0]}
  //      ${environment.onyxDocAPIUrl.split('/api')[0]}
  //     ${environment.onyxDocProductUrl.split('/api')[0]}
  //     ${environment.onyxDocDriveUrl.split('/api')[0]}
  //     https://api.iplocation.net https://px.ads.linkedin.com
  //     https://google.com https://maps.googleapis.com
  //     https://firebase.googleapis.com https://www.googletagmanager.com
  //     https://www.googletagmanager.com https://apis.google.com`,
  //   });
  // }

  onLogOut() {
    const notification: Notification = {
      state: 'success',
      message: 'Logging you out.',
    };

    this.notificationService.openNotification(
      notification,
      'flwmn-notification-success'
    );

    this.store.dispatch(AuthActions.Logout({ payload: {} }));
  }
}
