import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as TwoStepVerificationActions from './two-step-verification.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
// import { USE_DEVELOPER_TOKEN } from '../../interceptors/app.interceptor.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class TwoStepVerificationEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    // private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      TwoStepVerificationActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  generateOtpLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.GenerateOtpUrl),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/generatetotpurl`,
            {
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return TwoStepVerificationActions.SaveOtpUrl({
                  payload: resData.messages[0],
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Failed To Generate Otp Url',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError] Failed To Generate Otp Url  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  verifyOtpLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.VerifyOtpPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/verifyauthenticatorsetup`,
            {
              userId: authState.user.UserId,
              ...twoStepData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  TwoStepVerificationActions.GetUserByEmail({
                    payload: {
                      email: authState.user.Email,
                      userId: authState.user.UserId,
                    },
                  })
                );

                return {
                  type: '[Two Step Verification] Otp Verified Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Failed To Verify Otp ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError] Failed To Verify Otp  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.VerifyEmailAddress),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/verifyemailaddress`,
            {
              userId: authState.user.UserId,
              ...twoStepData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Two Step Verification] Email Address Verified',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Email Address Not Verified ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError] Email Address Not Verified  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUserByEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TwoStepVerificationActions.GetUserByEmail),
        withLatestFrom(this.store.select('auth')),
        switchMap(([twoStepData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Users/getbyemail/${twoStepData.payload.email}/${twoStepData.payload.userId}`,
              // {
              //   context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              // },

              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  TwoStepVerificationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Two Step Verification] Get User By Email Was Successful',
                  });

                  this.store.dispatch(
                    TwoStepVerificationActions.SaveUserByEmail({
                      payload: resData.entity,
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Two Step Verification] Failed To Get User By Email',
                  });
                }

                return {
                  resData: resData,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Two Step Verification][CatchError] Failed To Get User By Email ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  onToggleTwoFactorAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.ToggleTwoFactorAuthenticator),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/updatetwofactorauthentication`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...twoStepData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Two Step Verification] Toggle Two Factor Authenticator Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Toggle Two Factor Authenticator Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError]  Toggle Two Factor Authenticator Failed`
              );
            })
          );
      })
    )
  );

  onSendOtpToEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.SendOtpToEmail),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/sendotptoemail`,
            {
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Two Step Verification] Otp Sent To Email Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Failed To Send Otp To Email ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError]  Failed To Send Otp To Email`
              );
            })
          );
      })
    )
  );

  onDeleteAuthenticatorSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwoStepVerificationActions.DeleteAuthenticatorSetup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([twoStepData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/deleteauthenticatorsetup`,
            {
              userId: authState.user.UserId,
              twoFAVerificationType: twoStepData.payload.twoFAVerificationType,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TwoStepVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                window.location.reload();

                return {
                  type: '[Two Step Verification] Authenticator Setup Deleted Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Two Step Verification] Failed To Delete Authenticator Setup',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Two Step Verification][CatchError] Failed To Delete Authenticator Setup`
              );
            })
          );
      })
    )
  );
}
