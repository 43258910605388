import * as fromApp from '../app/app.reducer';
import * as userGroupActions from 'src/app/@core/stores/user-group/user-group.actions';
import { environment } from 'src/environments/environment';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';
import { Notification } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UserGroupEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private dialog: MatDialog
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(userGroupActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getUsersUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetUnits),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Units/getallunitswithnumberofusers/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}`,
            {
              params: { searchValue: userGroupData.payload.searchValue || '' },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveGetUnits({
                  payload: resData.entity.units,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Role] Get All Roles Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role For User Group][CatchError] Failed To Get Active User Roles ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUsersDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetDepartment),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Department/getalldepartmentwithnumberofusers/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}`,
            {
              params: { searchValue: userGroupData.payload.searchValue || '' },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveGetDepartment({
                  payload: resData.entity.departments,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Department] Get All Department Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Department For User Group][CatchError] Failed To Get Departments ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllDepartmentsWithNumbersOfUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetAllDepartmentsWithNumbersOfUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Department/getalldepartmentwithnumberofusers/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}`,
            {
              params: { searchValue: userGroupData.payload.searchValue || '' },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveAllDepartmentsWithNumbersOfUsers({
                  payload: resData.entity.departments,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Department] Get All Department With Numbers of Members Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Department For User Group][CatchError] Failed To Get All Department With Numbers of Members ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetDivisions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Division/getalldivisionwithnumberofusers/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}`,

            {
              params: { searchValue: userGroupData.payload.searchValue || '' },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveDivisions({
                  payload: resData.entity.divisions,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Divisions] Get All Divisions Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Division For User Group][CatchError] Failed To Get Divisions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getRolesWithNumbersOfMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetRolesWithNumbersOfUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getallwithnumberofusers/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}/${authState?.user?.SubscriberId}`,

            {
              params: { searchValue: userGroupData.payload.searchValue || '' },
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveRolesWithNumbersOfUsers({
                  payload: resData.entity.roles,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Divisions] Get All Divisions Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Division For User Group][CatchError] Failed To Get Divisions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  saveUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.SaveUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/create`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userGroupData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'User group created successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User Group] User Group Created Successful',
                });

                this.dialog.closeAll();

                this.store.dispatch(
                  userGroupActions.GetCreatedUserGroup({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                return {
                  type: '[User Group]  User Group Was Created Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User Group] Failed To Create User Group' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group][CatchError] Failed To Create User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.UpdateUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/update`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userGroupData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'User group updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User Group] User Group updated Successful',
                });

                this.dialog.closeAll();

                this.store.dispatch(
                  userGroupActions.GetCreatedUserGroup({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                return {
                  type: '[User Group]  User Group Was updated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User Group] Failed To Create User Group' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group][CatchError] Failed To Create User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getCreatedUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.GetCreatedUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/UserGroup/${authState?.user?.SubscriberId}/${authState?.user?.UserId}/${userGroupData.payload.skip}/${userGroupData.payload.take}`,

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.createdUserGroupIsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveCreatedUserGroup({
                  payload: resData.entity.userGroups,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group Creation] Get All updated User Group Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group Creation][CatchError] Failed To Get All Created User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  activateUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.activateUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/activate`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userGroupData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.createdUserGroupIsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User Group] Activate User group Was Successful',
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group ] Activate User Group Failed',
                };
              }

              return resData;
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group Creation][CatchError] Failed to Activate Created User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateUserGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.deactivateUserGroup),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/deactivate`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userGroupData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.createdUserGroupIsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User Group] Deactivate User group Was Successful',
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group ] Deactivate User Group Failed',
                };
              }

              return resData;
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group Creation][CatchError] Failed to Deactivate Created User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUserGroupById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGroupActions.getUserGroupById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userGroupData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserGroup/activate`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userGroupData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                userGroupActions.createdUserGroupIsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return userGroupActions.SaveGetUserGroupById({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Group ] Activate User Group Failed',
                };
              }

              // return resData;
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Group Creation][CatchError] Failed to Activate Created User Group ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
