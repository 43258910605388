import { createAction, props } from '@ngrx/store';
import { PaymentIntegration } from '../../interfaces';

export const ResetStore = createAction('[Payment] Reset Store');

export const IsLoading = createAction(
  '[Payment Integration] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllPaymentIntegrations = createAction(
  '[Payment Integration] Get All Payment Integrations'
);

export const SavePaymentIntegrations = createAction(
  '[Payment Integration] Save Payment Integrations',
  props<{
    payload: any;
  }>()
);

export const CreatePaymentIntegration = createAction(
  '[Payment Integration] Create Payment Integration ',
  props<{
    payload: PaymentIntegration;
  }>()
);

export const EditPaymentIntegration = createAction(
  '[Payment Integration] Edit Payment Integration ',
  props<{
    payload: PaymentIntegration;
  }>()
);

export const ActivatePaymentIntegrationStatus = createAction(
  '[Payment Integration] Activate Payment Integration Status',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const DeactivatePaymentIntegrationStatus = createAction(
  '[Payment Integration] Deactivate Payment Integration Status',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetActivePaymentChannels = createAction(
  '[Payment] Get Active Payment Channels',
  props<{
    payload?: {
      userId: string;
      subscriberId: number;
    };
  }>()
);

export const GetPaymentHistory = createAction('[Payment] Get Payment History');

export const SavePaymentHistory = createAction(
  '[Payment] Save Payment History',
  props<{
    payload: any;
  }>()
);

export const SaveActivePaymentChannels = createAction(
  '[Payment] Save Active Payment Channels',
  props<{
    payload: any[];
  }>()
);

export const AddCard = createAction(
  '[Payment] Save Active Payment Channels',
  props<{
    payload?: {
      currencyCode: number;
    };
  }>()
);

export const InitiatePaymentWithPaystack = createAction(
  '[Payment] Initiate Payment With Paystack',
  props<{
    payload: {
      // paymentChannelId: number;
      // subscriptionNo: string;
      // currencyCode: number;
      // callBackUrl: string;
      // userId: string;
      // subscriberId: number;
      subscriberId: number;
      paymentChannelId: number;
      subscriptionNo: string;
      currencyCode: number;
      userId: string;
      autoRenew: boolean;
    };
  }>()
);

export const SavePaystackLink = createAction(
  '[Payment] Save Paystack Link',
  props<{
    payload: any;
  }>()
);

export const VerifyPaystackPayment = createAction(
  '[Payment] Verify Paystack Payment',
  props<{
    payload: {
      referenceNo: string | any;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const InitiatePaymentWithFlutterwave = createAction(
  '[Payment] Initiate Payment With Flutterwave',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
      amount: number;
      currencyCode: number;
      customer: {
        email: string;
        phoneNumber: string;
        name: string;
      };
      paymentChannelId: number;
      subscriptionNo: string;
      autoRenew: boolean;
    };
  }>()
);

export const SaveFlutterwaveLink = createAction(
  '[Payment] Save Flutterwave Link',
  props<{
    payload: any;
  }>()
);

export const VerifyFlutterwavePayment = createAction(
  '[Payment] Verify Flutterwave Payment',
  props<{
    payload: {
      referenceNo: string | any;
      transactionId: string;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const InitiatePaymentWithStripe = createAction(
  '[Payment] Initiate Payment With Stripe',
  props<{
    payload: {
      caLLBackUrl: string;
      currencyCode: number;
      subscriptionNo: string;
      loggedInDevice: number;
      userId: string;
      subscriberId: number;
    };
  }>()
);

export const VerifyStripePayment = createAction(
  '[Payment] Verify Stripe Payment',
  props<{
    payload: {
      applicationType: number;
      hash: string;
    };
  }>()
);

export const MakeSubscriptionPayment = createAction(
  '[Payment] Make Subscription Payment',
  props<{
    payload: {
      amount: number;
      autoRenew: boolean;
      subscriptionId: number;
      storagePlanSubscriptionId: number;
      customer: {
        email: string;
        phoneNumber: string;
        name: string;
      };
      paymentChannelId: number;
      subscriptionNo: string;
      currencyCode: string;
      subscriptionActivity: number;
      subscriberId?: number;
      userId?: string;
      isSubscriberNewlyCreated?: boolean;
    };
  }>()
);
