import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EditorMode, ControlMode } from 'src/app/@core/types';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;

  constructor() {}
}
