import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Default Role] Reset Store');

export const IsLoading = createAction(
  '[Default Role] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetDefaultRoles = createAction(
  '[Default Role] Get Default Roles',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveDefaultRoles = createAction(
  '[Default Role] Save Default Roles',
  props<{
    payload: any;
  }>()
);

export const GetDefaultRoleById = createAction(
  '[Default Role] Get Default Role By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const CreateDefaultRole = createAction(
  '[Default Role] Create Default Role',
  props<{
    payload: {
      roleName: number;
      subscriberType: number;
      roleAccessLevel: number;
      subscriptionPlanId: number;
      subscriptionPlan: string;
      rolePermissions: {
        id: number;
        featureId: number;
        featureName: string;
        parentFeatureId: number;
        parentFeatureName: string;
        isChecked: boolean;
        allowFreeTrial: boolean;
        requiresSubscription: boolean;
      }[];
    };
  }>()
);

export const EditDefaultRole = createAction(
  '[Default Role] Edit Default Role',
  props<{
    payload: {
      id: number;
      roleName: number;
      subscriberType: number;
      roleAccessLevel: number;
      subscriptionPlanId: number;
      subscriptionPlan: string;
      rolePermissions: {
        id: number;
        featureId: number;
        featureName: string;
        parentFeatureId: number;
        parentFeatureName: string;
        isChecked: boolean;
        allowFreeTrial: boolean;
        requiresSubscription: boolean;
      }[];
    };
  }>()
);

export const DeleteDefaultRole = createAction(
  '[Default Role] Delete Default Role',
  props<{
    payload: {
      id: number;
    };
    additionalPayload?: {
      skip?: number;
      take?: number;
    };
  }>()
);
