import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import { HelperService } from 'src/app/@core/services/helper.service';
import { ThemeService } from 'src/app/@core/services/theme.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit, OnDestroy {
  year!: number;

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.year = new Date().getFullYear();

    this.listenToActivatedRouteSubscription();
  }

  listenToActivatedRouteSubscription() {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params.email && params.token) {
          this.store.dispatch(AuthActions.IsLoading({ payload: true }));

          this.helperService.manageDeveloperTokenAndCallFunction(
            this.confirmEmail.bind(this, params)
          );
        }
      })
    );
  }

  confirmEmail(params: any) {
    this.store.dispatch(
      AuthActions.ConfirmEmail({
        payload: {
          email: params.email,
          token: params.token,
        },
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
