<section id="send-request-dialog">
  <div class="send-request-header">
    <div matRipple class="close-icon-container" (click)="closeSheet()">
      <app-svg
        matRipple
        class="flwmn-pointer"
        src="assets/icons/flowz/tasks/close-drawer.svg"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></app-svg>
    </div>
  </div>

  <div class="send-request-body">
    <div class="request-wrapper">
      <div class="head">
        <app-svg
          matRipple
          class="flwmn-pointer"
          src="assets/icons/flowz/tasks/http.svg"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></app-svg>

        <p class="flwmn-para-1 mb-0 flwmn-text-neutral">
          https://restcountries.eu/rest/v2/all
        </p>
      </div>

      <div class="url">
        <div class="input-style1 input-no-error input-fit-content">
          <mat-form-field appearance="outline" [style.width.%]="100">
            <mat-select placeholder="Request Method">
              <mat-option
                *ngFor="let request of ['GET', 'POST', 'PUT']"
                [value]="request"
              >
                {{ request }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-flwmn-search
          class="flex-grow-1"
          placeholder="Enter URL"
        ></app-flwmn-search>

        <button
          matRipple
          class="btn flwmn-btn-primary-outline"
          (click)="test()"
        >
          Send
        </button>

        <button matRipple class="btn flwmn-btn-primary" (click)="test()">
          Save
        </button>
      </div>

      <div class="view-mode">
        <app-view-mode
          [views]="views"
          [activeView]="activeView"
          (viewChange)="onViewChange($event)"
        ></app-view-mode>

        <div
          class="input-style1 input-no-error input-fit-content"
          *ngIf="activeView === 'Body'"
        >
          <mat-form-field appearance="outline" [style.width.%]="100">
            <mat-select placeholder="Type" value="Text">
              <mat-option
                *ngFor="let request of ['Text', 'JSON', 'XML']"
                [value]="request"
              >
                {{ request }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="view">
        <ng-container *ngIf="activeView === 'Parameters'">
          <app-request-parameters></app-request-parameters>
        </ng-container>

        <ng-container *ngIf="activeView === 'Authorization'">
          <app-request-authorization></app-request-authorization>
        </ng-container>

        <ng-container *ngIf="activeView === 'Headers'">
          <app-request-headers></app-request-headers>
        </ng-container>

        <ng-container *ngIf="activeView === 'Body'">
          <app-request-body></app-request-body>
        </ng-container>
      </div>
    </div>

    <div class="response-wrapper">
      <div *ngIf="isLoading" class="lazy-loading-module-loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <app-response
        [isLoading]="isLoading"
        [response]="response"
      ></app-response>
    </div>
  </div>
</section>
