import { Pipe, PipeTransform } from '@angular/core';

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
];

@Pipe({
  name: 'stringifyNumber',
})
export class StringifyNumberPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (value < 20) return special[value];
    if (value % 10 === 0) return deca[Math.floor(value / 10) - 2] + 'ieth';
    return deca[Math.floor(value / 10) - 2] + 'y-' + special[value % 10];
  }
}
