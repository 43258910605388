import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Product Configuration] Reset Store');

export const IsLoading = createAction(
  '[Product Configuration] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetchingProductById = createAction(
  '[Product Configuration] Is Fetching Product By Id',
  props<{
    payload: boolean;
  }>()
);

export const GetProducts = createAction(
  '[Product Configuration] Get Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const GetActiveProducts = createAction(
  '[Product Configuration] Get All Active Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
    forSelectField?: boolean;
  }>()
);

export const SaveActiveProducts = createAction(
  '[Product Configuration] Save Active Products',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const SaveActiveProductsForSelectField = createAction(
  '[Product Configuration] Save Active Products For SelectField',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const GetInactiveProducts = createAction(
  '[Product Configuration] Get All Inactive Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveInactiveProducts = createAction(
  '[Product Configuration] Save Inactive Products',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const GetSubscriberActiveProducts = createAction(
  '[Product Configuration] Get Subscriber Active Products',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetProductByGuid = createAction(
  '[Product Configuration]',
  props<{
    payload: {
      guid: string;
    };
  }>()
);

export const SaveProduct = createAction(
  '[Product Configuration] Save Product',
  props<{
    payload: {
      product: any;
    };
  }>()
);

export const SaveSubscriberActiveProducts = createAction(
  '[Product Configuration] Save Subscriber Active Products',
  props<{
    payload: any;
  }>()
);

export const SaveProducts = createAction(
  '[Product Configuration] Save Products',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const CreateProduct = createAction(
  '[Product Configuration] Create Product',
  props<{
    payload: {
      name: string;
      unitOfMeasure: number;
      allowMultipleRunningPlans: boolean;
      microServiceKey: string;
      microServiceUrl: string;
      resourceGuIds: string[];
      otherProductGuIds: string[];
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateProduct = createAction(
  '[Product Configuration] Update Product',
  props<{
    payload: {
      productGuId: string;
      name: string;
      unitOfMeasure: number;
      allowMultipleRunningPlans: boolean;
      microServiceKey: string;
      microServiceUrl: string;
      resourceGuIds: string[];
      otherProductGuIds: string[];
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateProductStatus = createAction(
  '[Product Configuration] Update Product Status',
  props<{
    payload: {
      id: number;
      status: 1 | 3;
    };
    paginationPayload?: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateProduct = createAction(
  '[Product Configuration] Activate Product',
  props<{
    payload: {
      guId: string;
      id?: number;
    };
    paginationPayload?: {
      moduleCurrentTab?: number;
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateProduct = createAction(
  '[Product Configuration] Deactivate Product',
  props<{
    payload: {
      productGuId: string;
      id?: number;
    };
    paginationPayload?: {
      moduleCurrentTab?: number;
      skip: number;
      take: number;
    };
  }>()
);

export const GetProductMicroService = createAction(
  '[Product Configuration] Get Product Micro Service',
  props<{
    skip: number;
    take: number;
  }>()
);

export const SaveProductMicroService = createAction(
  '[Product Configuration] Save Micro Service',
  props<{
    payload: {
      microService: any[];
    };
  }>()
);

export const GetActiveResources = createAction(
  '[Product Configuration] Get Active Resources',
  props<{
    skip: number;
    take: number;
  }>()
);

export const SaveActiveResources = createAction(
  '[Product Configuration] Save Active Resources',
  props<{
    payload: {
      resources: any[];
    };
  }>()
);
