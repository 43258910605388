import { Action, createReducer, on } from '@ngrx/store';
import * as UserRoleActions from './user-role.actions';

export interface userRoleReducerState {
  isLoading: boolean;
  roles: { count: number; roles: any[] } | null;
  activeRoles: { count: number; roles: any[] } | null;
  inactiveRoles: { count: number; roles: any[] } | null;
  permissionsBySubscriptionPlanIdAndRoleAccessLevel: any[] | null;
}

export const initialState: userRoleReducerState = {
  isLoading: false,
  roles: null,
  activeRoles: null,
  inactiveRoles: null,
  permissionsBySubscriptionPlanIdAndRoleAccessLevel: null,
};

const userRoleReducerInternal = createReducer(
  initialState,
  on(UserRoleActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(UserRoleActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(UserRoleActions.SaveAllUserRoles, (state, { payload }) => ({
    ...state,
    roles: payload,
  })),
  on(UserRoleActions.SaveActiveUserRoles, (state, { payload }) => ({
    ...state,
    activeRoles: payload,
  })),
  on(UserRoleActions.SaveInactiveUserRoles, (state, { payload }) => ({
    ...state,
    inactiveRoles: payload,
  })),
  on(
    UserRoleActions.SavePermissionsBySubscriptionPlanIdAndRoleAccessLevel,
    (state, { payload }) => ({
      ...state,
      permissionsBySubscriptionPlanIdAndRoleAccessLevel: payload,
    })
  )
);

export function userRoleReducer(
  state: userRoleReducerState | undefined,
  action: Action
) {
  return userRoleReducerInternal(state, action);
}
