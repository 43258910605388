import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, share } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import * as FeatureActions from './feature.actions';
import { Notification } from '../../interfaces/notification.interface';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class FeatureEffects {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private http: HttpClient,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getFeaturesAsCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.GetFeaturesAsCategory),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Features/getall/${authState.user.UserId}/${authState.user.SubscriberId}/${userData.payload.skip}/${userData.payload.take}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded) {
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Feature] Failed To Get Feature As Category',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Feature][CatchError] Failed To Fet Feature As Category ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.GetAllFeatures),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Features/getall/${authState.user.UserId}/${authState.user.SubscriberId}/${userData.payload.skip}/${userData.payload.take}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                this.dialog.closeAll();

                this.store.dispatch(
                  FeatureActions.SaveAllFeatures({
                    payload: resData.entity,
                  })
                );

                return { type: '[Feature] Get All Features Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  FeatureActions.SaveAllFeatures({
                    payload: [],
                  })
                );

                return { type: '[Feature] Failed To Get All Features' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Get All Features ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.CreateFeature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Features/create`, {
            userId: authState.user.UserId,
            subscriberId: authState.user.SubscriberId,
            createdBy: authState.user.Email,
            ...userData.payload,
          })
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FeatureActions.IsLoading({
                  payload: false,
                })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: `Feature created successfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  FeatureActions.GetAllFeatures({
                    payload: {
                      skip: 0,
                      take: 0,
                    },
                  })
                );

                return { type: '[Feature] Create Feature Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Feature] Failed To Create Feature' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Create Feature ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  editFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.EditFeature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Features/update/${userData.payload.featureId}`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              createdBy: authState.user.Email,
              ...userData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  FeatureActions.GetAllFeatures({
                    payload: { skip: 0, take: 0 },
                  })
                );

                return { type: '[Feature] Edit Feature Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Feature] Failed To Edit Feature' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Edit Feature ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  // changeFeatureStatus$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(FeatureActions.ChangeFeatureStatus),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([userData, authState]) => {
  //       return this.http
  //         .post(`${environment.onyxDocAuthUrl}/Features/changefeaturestatus`, {
  //           subscriberId: authState.user.SubscriberId,
  //           userId: authState.user.UserId,
  //           ...userData.payload,
  //         })
  //         .pipe(
  //           map((resData: any) => {
  //             this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

  //             if (resData.succeeded) {
  //               const notification: Notification = {
  //                 state: 'success',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openSnackBar(
  //                 notification,
  //                 'flwmn-notification-success'
  //               );

  //               this.store.dispatch(
  //                 FeatureActions.GetAllFeatures({
  //                   payload: { skip: 0, take: 0 },
  //                 })
  //               );

  //               return {
  //                 type: '[Feature] Change Feature Status Was Successful',
  //               };
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: resData.message || resData.messages[0],
  //               };

  //               this.notificationService.openSnackBar(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );

  //               this.store.dispatch(
  //                 FeatureActions.GetAllFeatures({
  //                   payload: { skip: 0, take: 0 },
  //                 })
  //               );

  //               return { type: '[Feature] Failed To Change Feature Status' };
  //             }
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Feature][CatchError] Failed To Change Feature Status ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   )
  // );

  activateFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.ActivateFeature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Features/activatefeature`, {
            subscriberId: authState.user.SubscriberId,
            userId: authState.user.UserId,
            ...userData.payload,
          })
          .pipe(
            map((resData: any) => {
              this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  FeatureActions.GetAllFeatures({
                    payload: { skip: 0, take: 0 },
                  })
                );

                this.dialog.closeAll();

                return { type: '[Feature] Activate Feature Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Feature] Failed To Activate Feature' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Activate Feature ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.DeactivateFeature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Features/deactivatefeature`, {
            subscriberId: authState.user.SubscriberId,
            userId: authState.user.UserId,
            ...userData.payload,
          })
          .pipe(
            map((resData: any) => {
              this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  FeatureActions.GetAllFeatures({
                    payload: { skip: 0, take: 0 },
                  })
                );

                this.dialog.closeAll();

                return { type: '[Feature] Deactivate Feature Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Feature] Failed To Deactivate Feature' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Deactivate Feature ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.DeleteFeature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Features/deletefeature`, {
            subscriberId: authState.user.SubscriberId,
            userId: authState.user.UserId,
            ...userData.payload,
          })
          .pipe(
            map((resData: any) => {
              this.store.dispatch(FeatureActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  FeatureActions.GetAllFeatures({
                    payload: { skip: 0, take: 0 },
                  })
                );

                this.dialog.closeAll();

                return { type: '[Feature] Delete Feature Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Feature] Failed To Delete Feature' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Feature][CatchError] Failed To Delete Feature ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
