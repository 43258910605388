import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromPayment from './payment.reducer';

const getPaymentState = (state: fromApp.AppState) => state.payment;

export const getPaymentIsLoading = createSelector(
  getPaymentState,
  (state: fromPayment.State) => state.isLoading
);

export const getActivePaymentChannels = createSelector(
  getPaymentState,
  (state: fromPayment.State) => state.activePaymentChannels
);

export const getPaystackLink = createSelector(
  getPaymentState,
  (state: fromPayment.State) => state.paystackLink
);

export const getFlutterwaveLink = createSelector(
  getPaymentState,
  (state: fromPayment.State) => state.flutterwaveLink
);

export const GetPaymentHistory = createSelector(
  getPaymentState,
  (state: fromPayment.State) => state.paymentHistory
);
