// API Repository
export const ResetStore = createAction('[API Repository] Reset Store');

export const IsLoading = createAction(
  '[API Repository] Is Loading',
  props<{
    payload: boolean;
  }>()
);
export const IsFetching = createAction(
  '[API Repository] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

import { createAction, props } from '@ngrx/store';
import { APIEndpoint } from 'src/app/@core/interfaces/repository/api-repository.interface';

export const CreateAPICollection = createAction(
  '[API Repository] Create API Collection',
  props<{
    payload: {
      guId: string;
      name: string;
      description: string;
      tags: {
        key: string;
        value: string;
      };
      authorization: {
        name: string;
        description: string;
        key: string;
        value: string;
        authType: number;
        addTo: number;
      };
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const UpdateAPICollection = createAction(
  '[API Repository] Update API Collection',
  props<{
    payload: {
      guId: string;
      name: string;
      description: string;
      tags: {
        key: string;
        value: string;
      };
      authorization: {
        name: string;
        description: string;
        key: string;
        value: string;
        authType: number;
        addTo: number;
      };
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const GetAPICollection = createAction(
  '[API Repository] Get API Collection',
  props<{
    payload: {
      id: string;
      objectGuid: string;
    };
  }>()
);

export const SaveAPICollection = createAction(
  '[API Repository] Save API Collection',
  props<{
    payload: any;
  }>()
);

export const GetAPICollections = createAction(
  '[API Repository] Get API Collections'
);

export const SaveAPICollections = createAction(
  '[API Repository] Save API Collections',
  props<{
    payload: any;
  }>()
);

export const ActivateAPICollection = createAction(
  '[API Repository] Activate API Collection',
  props<{
    payload: {
      guId: string;
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const DeactivateAPICollection = createAction(
  '[API Repository] Deactivate API Collection',
  props<{
    payload: {
      guId: string;
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const DeleteAPICollection = createAction(
  '[API Repository] Delete API Collection',
  props<{
    payload: {
      guId: string;
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const GetEndpointsByCollectionId = createAction(
  '[API Repository] Get Endpoints By Collection Id',
  props<{
    payload: {
      collectionId: string;
    };
  }>()
);

export const SaveEndpointsByCollectionId = createAction(
  '[API Repository] Save Endpoints By Collection Id',
  props<{
    payload: any;
  }>()
);

export const CreateEndpoint = createAction(
  '[API Repository] Create Endpoint',
  props<{
    payload: Partial<APIEndpoint>;
  }>()
);

export const UpdateEndpoint = createAction(
  '[API Repository] Update Endpoint',
  props<{
    payload: Partial<APIEndpoint>;
  }>()
);

export const ActivateEndpoint = createAction(
  '[API Repository] Activate Endpoint',
  props<{
    payload: {
      guId: string;
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const DeactivateEndpoint = createAction(
  '[API Repository] Deactivate Endpoint',
  props<{
    payload: {
      guId: string;
      objectGuid: string;
      objectName: string;
      actorName: string;
    };
  }>()
);

export const DeleteEndpoint = createAction(
  '[API Repository] Delete Endpoint',
  props<{
    payload: {
      guId: string;
      endpointCollectionGuId: string;
    };
  }>()
);
