import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  hubConnection: signalR.HubConnection;
  constructor(store: Store<fromApp.AppState>) {
    this.hubConnection =
      this.hubConnection ||
      new signalR.HubConnectionBuilder()
        .withUrl(
          `${environment.onyxDocPhoenixFormBuilderUrl.replace(
            '/api',
            ''
          )}/projecthub`,
          {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            withCredentials: true,
            accessTokenFactory: () => {
              const OnyxDocData: {
                accessToken: string;
                userToken: string;
                exp: number;
              } = JSON.parse(localStorage.getItem('OnyxDoc')!);
              return `Bearer ${OnyxDocData?.accessToken}`;
            },
          }
        )
        .build();
    this.hubConnection
      .start()
      .then(() => {
        // console.log('started');
      })
      .catch((err) => {
        // console.log(err);
      });
  }
}
