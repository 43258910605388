import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as ReleaseNoteActions from '../release-note/release-note.actions';
import { Store } from '@ngrx/store';
// import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Injectable()
export class ReleaseNoteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(ReleaseNoteActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.GetAllReleaseNotes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        this.store.dispatch(ReleaseNoteActions.IsFetching({ payload: true }));
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getall`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...releaseNoteData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ReleaseNoteActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  ReleaseNoteActions.IsLoading({ payload: false })
                );

                return ReleaseNoteActions.SaveAllReleaseNotes({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ReleaseNoteActions.SaveAllReleaseNotes({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Get All Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  editReleaseNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReleaseNoteActions.UpdateReleaseNote),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/update/${releaseNoteData.payload.releaseNoteId}`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...releaseNoteData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    resData.message ||
                    resData.messages[0] ||
                    'Release note was updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/account-settings/release-notes']);

                this.store.dispatch({
                  type: '[Release Notes] Release Notes updated Successfully',
                });
                return ReleaseNoteActions.GetAllReleaseNotes({
                  payload: {
                    userId: authState.user.UserId,
                    subscriberId: authState.user.SubscriberId,
                    skip: 0,
                    take: 0,
                    searchValue: '',
                    filterValue: [],
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Subscribers] Failed To Update Release Note' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Update Release note ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getReleaseNoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReleaseNoteActions.GetReleaseNoteById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getreleasenotebyid/${releaseNoteData.payload.id}`,
            {}
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );
              return ReleaseNoteActions.SaveSingleReleaseNoteById({
                payload: resData.entity,
              });
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscribers][CatchError] Failed To Update Release note ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAllPublishedReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.GetAllPublishedNotes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        this.store.dispatch(ReleaseNoteActions.IsFetching({ payload: true }));
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getallpublishedreleasenotes`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...releaseNoteData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              // this.store.dispatch(
              //   ReleaseNoteActions.IsFetching({ payload: false })
              // );

              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ReleaseNoteActions.SaveAllPublishedReleaseNotes({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ReleaseNoteActions.SaveAllReleaseNotes({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Get All Published Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllPublishedWebsiteReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.GetAllPublisedWebsiteReleaseNotes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        this.store.dispatch(ReleaseNoteActions.IsFetching({ payload: true }));
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getallpublishedreleasenotes`,
            {
              skip: 0,
              take: 0,
              searchValue: releaseNoteData.payload.searchValue,
              filterValue: releaseNoteData.payload.filterValue,
            },
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ReleaseNoteActions.IsFetching({ payload: false })
              );

              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ReleaseNoteActions.SaveAllWebsiteReleaseNote({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ReleaseNoteActions.SaveAllWebsiteReleaseNote({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Get All Website Published Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteReleaseNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReleaseNoteActions.DeleteReleaseNote),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/deletereleasenote`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              releaseNoteId: releaseNoteData.payload.releaseNoteId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                if (releaseNoteData.payload.type === 'hidden') {
                  return ReleaseNoteActions.GetAllHiddenNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                } else {
                  return ReleaseNoteActions.GetAllSavedNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                }
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Release Note] Failed To Delete Release Note',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Release Note][CatchError] Failed To Delete Release Note ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAllSavedReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.GetAllSavedNotes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        this.store.dispatch(ReleaseNoteActions.IsFetching({ payload: true }));
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getalldraftsreleasenotes`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              skip: 0,
              take: 10,
              searchValue: releaseNoteData.payload.searchValue,
              filterValue: releaseNoteData.payload.filterValue,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ReleaseNoteActions.IsFetching({ payload: false })
              );

              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ReleaseNoteActions.SaveAllDraftReleaseNotes({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ReleaseNoteActions.SaveAllDraftReleaseNotes({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Get All Saved Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllHiddenReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.GetAllHiddenNotes),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        this.store.dispatch(ReleaseNoteActions.IsFetching({ payload: true }));
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/getallhiddenreleasenotes`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              skip: 0,
              take: 10,
              searchValue: releaseNoteData.payload.searchValue,
              filterValue: releaseNoteData.payload.filterValue,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ReleaseNoteActions.IsFetching({ payload: false })
              );

              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ReleaseNoteActions.SaveAllHiddenReleaseNotes({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ReleaseNoteActions.SaveAllHiddenReleaseNotes({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Get All Hidden Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.CreateReleaseNote),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/create`,
            {
              ...releaseNoteData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Release Note created successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/account-settings/release-notes']);

                this.store.dispatch({
                  type: '[Release Notes] Create Release Notes Was Successful',
                });
                return ReleaseNoteActions.GetAllReleaseNotes({
                  payload: {
                    userId: authState.user.UserId,
                    subscriberId: authState.user.SubscriberId,
                    skip: 0,
                    take: 0,
                    searchValue: '',
                    filterValue: [],
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Release Notes] Failed To Create Release Notes',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To Create Release Notes ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  hideAndUnhideReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.HideAndUnhideReleaseNote),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/hidereleasenote`,
            {
              ...releaseNoteData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Release Note Hidden successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Release Notes] Release Notes Was hidden Successfully',
                });

                if (releaseNoteData.payload.isHidden === true) {
                  return ReleaseNoteActions.GetAllReleaseNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                } else {
                  return ReleaseNoteActions.GetAllHiddenNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 0,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                }
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Release Notes] Failed To hide Release Note',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To hide Release Note ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  hideSavedReleaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNoteActions.HideSavedReleaseNote),
      withLatestFrom(this.store.select('auth')),
      switchMap(([releaseNoteData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/ReleaseNotes/hidereleasenote`,
            {
              ...releaseNoteData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ReleaseNoteActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Release Note Hidden successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[Release Notes] Release Notes Was hidden Successfully',
                });

                if (releaseNoteData.payload.isHidden === true) {
                  return ReleaseNoteActions.GetAllPublishedNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                } else {
                  return ReleaseNoteActions.GetAllHiddenNotes({
                    payload: {
                      userId: authState.user.UserId,
                      subscriberId: authState.user.SubscriberId,
                      skip: 0,
                      take: 0,
                      searchValue: '',
                      filterValue: [],
                    },
                  });
                }
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Release Notes] Failed To hide Release Note',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Release Notes][CatchError] Failed To hide Release Note ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
