import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimeout: any;
  public inactivityTimeLimit = 600000; // 10 minute of inactivity
  public inactivityDetected = new Subject<void>();

  constructor(private ngZone: NgZone) {
    this.initializeInactivityTimer();
    this.addEventListeners();
  }

  private initializeInactivityTimer() {
    this.resetInactivityTimer();
  }

  private resetInactivityTimer() {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }

    this.inactivityTimeout = setTimeout(() => {
      this.inactivityDetected.next();
    }, this.inactivityTimeLimit);
  }

  private addEventListeners() {
    this.ngZone.runOutsideAngular(() => {
      ['mousemove', 'keydown', 'scroll', 'click'].forEach((event: any) => {
        window.addEventListener(event, () => this.resetInactivityTimer());
      });
    });
  }
}
