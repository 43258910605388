import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[User License] Reset Store');

export const IsLoading = createAction(
  '[User License] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateUserLicenses = createAction(
  '[User License] Create User Licenses',
  props<{
    payload: {
      subscriptionId: number;
      userLicenses: [
        {
          subscriptionId: number;
          licenseUserId: string;
          isDeleted: boolean;
          id: number;
          name: string;
          description: string;
        }
      ];
      activeProductId?: number;
    };
  }>()
);

export const DeleteUserLicense = createAction(
  '[User License] Create User License',
  props<{
    payload: {
      id: number;
      actorEmail: string;
      activeProductId?: number;
    };
  }>()
);

export const CreateBulkUserLicense = createAction(
  '[User License] Create Bulk User License',
  props<{
    payload: {
      subscriptionId: number;
      csvFile: string;
      subscriberId?: number;
      ownerId?: string;
      actorEmail?: string;
    };
  }>()
);
