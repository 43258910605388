import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
})
export class ButtonLoaderComponent {
  @Input() primaryColor: boolean = false;
  constructor() {}
}
