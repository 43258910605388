import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-authorization',
  templateUrl: './request-authorization.component.html',
  styleUrls: ['./request-authorization.component.scss'],
})
export class RequestAuthorizationComponent implements OnInit {
  authorizationType: string = 'No Auth';

  constructor() {}

  ngOnInit(): void {
    console;
  }
}
