import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[External Verification] Reset Store');

export const IsLoading = createAction(
  '[External Verification] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const VerifyUser = createAction(
  '[External Verification] Verify User ',
  props<{
    payload: {
      signingRequestId: number;
      verificationCode: string;
      subscriberId: number;
    };
  }>()
);

export const SaveVerifiedUser = createAction(
  '[External Verification] Save Verify User Data',
  props<{
    payload: any;
  }>()
);

export const GetAuditTrailDownload = createAction(
  '[External Verification Audit Trail] Get Audit Trail',
  props<{
    payload: {
      signingRequestId: number;
      subscriberId: number;
    };
  }>()
);

export const SaveAuditTrailDownload = createAction(
  '[External Verification Audit Trail] Save  Audit Trail',
  props<{
    payload: any;
  }>()
);
