<section id="scan-qr-code">
  <div class="d-flex flex-column gap-2">
    <div class="qr-code-header">
      <h6 class="flwmn-heading-6 fw-700 mb-0">Draw with your mobile device</h6>

      <div matRipple class="close-icon-container" (click)="closeDialog()">
        <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
      </div>
    </div>

    <p class="mb-0 flwmn-para-1">
      Scan this QR code to draw your signature with a mobile device.
    </p>
  </div>

  <div class="qr-code-body">
    <app-svg src="assets/icons/bar-code.svg"></app-svg>
  </div>
</section>
