<section id="manage-signature">
  <div class="manage-signature-header">
    <h6
      [class.opacity-0]="isEditorOrSelfSign()"
      class="flwmn-heading-6 fw-700 mb-0"
    >
      Manage Signature
    </h6>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <div class="manage-signature-header">
    <h6 class="flwmn-para-2 fw-400 mb-0">All signatures</h6>

    <button
      matRipple
      class="btn flwmn-btn-primary-outline add-new-document-btn flwmn-btn-with-icon flwmn-para-2"
      (click)="onAddSignature()"
    >
      <span class="img-fluid">
        <app-svg
          src="assets/icons/add.svg"
          svgPathStroke="var(--flwmn-primary-color)"
        ></app-svg>
      </span>
      Add new signature
    </button>
  </div>

  <div class="signature-list">
    <!-- <ng-container *ngIf="(loading$ | async) === false"> -->
    <ng-container
      *ngFor="let item of signatureList; let index = index; let last = last"
    >
      <div class="signature-list-item">
        <div class="signature-item-container">
          <!-- <p class="signature-item mb-0">K.O</p> -->
          <!-- <ng-container *ngFor="let item of signatureList">
        <div class="signature-item-container">
          <div class="signature-item mb-0">
            <img [src]="item.signature" />
          </div>
        </div>
      </ng-container> -->
          <div
            matRipple
            [class.selected]="
              isEditorOrSelfSign() &&
              selectedSignature !== null &&
              selectedSignature.index === index
            "
            class="signature-item mb-0 flwmn-pointer"
            (click)="onSignatureClicked(item.signature, index)"
          >
            <img [src]="item.signature" />
          </div>
        </div>

        <div
          *ngIf="this.router.url.includes('/profile')"
          class="d-flex gap-2 align-items-center"
        >
          <img
            *ngIf="item.isDefaultSignature"
            src="assets/icons/pin.png"
            alt=""
          />

          <p
            matRipple
            *ngIf="item.status === 1 && isBigScreen(item)"
            class="default-item p-2"
            [class.visible]="item.isDefaultSignature"
            (click)="onMakeDefault(item)"
          >
            Set as default
          </p>

          <button
            matRipple
            [disabled]="item.status === 2"
            class="btn flwmn-btn-primary-outline edit-btn"
            (click)="onEditSignature(item)"
          >
            Edit
          </button>

          <!-- <button
            matRipple
            class="btn trash-btn"
            (click)="onDeactivateSignature(item)"
          >
            <span class="img-fluid">
              <app-svg
                src="assets/icons/delete.svg"
                svgPathStroke="var(--flwmn-accent-color)"
              ></app-svg>
            </span>
          </button> -->
          <mat-slide-toggle
            (change)="onSwitchSignature($event, item)"
            [checked]="item.status === 1"
            [disabled]="item.isDefaultSignature"
          ></mat-slide-toggle>
        </div>
      </div>

      <div *ngIf="!last" class="signature-line"></div>
    </ng-container>
    <!-- </ng-container> -->

    <ng-container *ngIf="(loading$ | async) === true">
      <div class="uploading-loader-container">
        <app-button-loader></app-button-loader>
      </div>
    </ng-container>

    <ng-container
      *ngIf="(loading$ | async) === false && signatureListTemp.length < 1"
    >
      <div class="d-flex align-items-center justify-content-center">
        You do not have a signature saved !
      </div>
    </ng-container>
  </div>

  <div class="d-flex justify-content-between">
    <button
      matRipple
      class="btn flwmn-btn-primary-outline border-cancel"
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button (click)="closeDialog()" matRipple class="btn flwmn-btn-primary">
      Done
    </button>
  </div>
</section>
