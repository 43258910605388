import { createReducer, on, Action } from '@ngrx/store';
import * as ExternalVerificationActions from './external-verification.actions';

export interface State {
  isLoading: boolean;
  verificationData: any | null;
  auditTrailDownload: any | null;
}

const initialState: State = {
  isLoading: false,
  verificationData: null,
  auditTrailDownload: null,
};

const externalVerificationReducerInternal = createReducer(
  initialState,
  on(ExternalVerificationActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(ExternalVerificationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(ExternalVerificationActions.SaveVerifiedUser, (state, { payload }) => ({
    ...state,
    verificationData: payload,
  })),

  on(
    ExternalVerificationActions.SaveAuditTrailDownload,
    (state, { payload }) => ({
      ...state,
      auditTrailDownload: payload,
    })
  )
);

export function externalVerificationReducer(
  state: State | undefined,
  action: Action
) {
  return externalVerificationReducerInternal(state, action);
}
