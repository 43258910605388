import { createReducer, on, Action } from '@ngrx/store';
import * as DocumentActions from './document.actions';
import {
  DocumentItem,
  DocumentItemMap,
} from '../../interfaces/document.interface';

export interface State {
  isLoading: boolean;
  isFetching: boolean;
  items: DocumentItem[];
  items_map: DocumentItemMap;
  currentItemIndex: string | null;
  currentItem: any | null;
  rootFolders: any[] | null;
  isSidebarToggled: boolean;
  allFolders: any[] | null;
  allDocuments: any[] | null;
  activeFolderContent: any[] | null;
  commentOnDocument: any;
  allSubscribers: any;
  allCompanyDetails: any;
  allUserAndSubscribersContact: any;
  allFoldersJSON: any[] | null;
  actionSuccess: { action: string | number; uniqueId: number } | null;
}

const initialState: State = {
  isLoading: false,
  isFetching: false,
  items: [],
  items_map: {} as DocumentItemMap,
  currentItemIndex: null,
  currentItem: null,
  rootFolders: null,
  isSidebarToggled: false,
  allFolders: null,
  allDocuments: null,
  activeFolderContent: null,
  commentOnDocument: null,
  allSubscribers: null,
  allCompanyDetails: null,
  allUserAndSubscribersContact: null,
  allFoldersJSON: null,
  actionSuccess: null,
};

const documentReducerInternal = createReducer(
  initialState,
  on(DocumentActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(DocumentActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(DocumentActions.IsSidebarToggled, (state, { payload }) => ({
    ...state,
    isSidebarToggled: payload,
  })),

  on(DocumentActions.SaveAllFolders, (state, { payload }) => ({
    ...state,
    allFolders: payload,
  })),

  on(DocumentActions.ActionSuccess, (state, { payload }) => ({
    ...state,
    actionSuccess: payload,
  })),

  on(DocumentActions.SaveAllFoldersJSON, (state, { payload }) => ({
    ...state,
    allFoldersJSON: payload,
  })),

  on(DocumentActions.SaveAllDocuments, (state, { payload }) => ({
    ...state,
    allDocuments: payload,
  })),
  on(DocumentActions.SaveFolderContent, (state, { payload }) => ({
    ...state,
    activeFolderContent: payload,
  })),
  on(DocumentActions.SaveCommentOnDocument, (state, { payload }) => ({
    ...state,
    commentOnDocument: payload,
  })),
  on(DocumentActions.SaveAddressBookBySubscriberId, (state, { payload }) => ({
    ...state,
    allSubscribers: payload,
  })),
  on(DocumentActions.SaveAllCompanyDetails, (state, { payload }) => ({
    ...state,
    allCompanyDetails: payload,
  })),
  on(
    DocumentActions.SaveAllUserAndSubscribersContact,
    (state, { payload }) => ({
      ...state,
      allUserAndSubscribersContact: payload,
    })
  ),
  //  Document Viewer

  on(DocumentActions.IsFetching, (state, { payload }) => ({
    ...state,
    isFetching: payload,
  })),

  on(DocumentActions.SaveFolders, (state, { payload }) => ({
    ...state,
    items: payload,
  })),

  on(DocumentActions.SaveRootFolders, (state, { payload }) => ({
    ...state,
    rootFolders: payload,
  })),

  on(DocumentActions.ToggleItemCaret, (state, { payload }) => {
    const map: any = { ...state.items_map };

    map[payload.itemId] = {
      ...map[payload.itemId],
      item: {
        ...map[payload.itemId]['item'],
        openState: !map[payload.itemId]['item'].openState,
      },
    };

    return {
      ...state,
      items_map: map,
    };
  }),

  on(DocumentActions.ToggleItemLoader, (state, { payload }) => {
    const map: any = { ...state.items_map };

    map[payload.itemId] = {
      ...map[payload.itemId],
      item: {
        ...map[payload.itemId]['item'],
        loadingChildren: payload.isLoading,
      },
    };

    return {
      ...state,
      items_map: map,
    };
  }),

  on(DocumentActions.SetDocumentItemMap, (state, { payload }) => {
    // TODO: Check that this copies the map as intended and doesn't mess up the state store
    const map: DocumentItemMap = { ...state.items_map };

    map[payload.itemId] = payload.itemToMap;

    return {
      ...state,
      items_map: map,
    };
  }),

  on(DocumentActions.SaveCurrentlyActiveIndex, (state, { payload }) => ({
    ...state,
    currentItemIndex: payload,
  })),

  on(DocumentActions.SaveChildrenItemsInParent, (state, { payload }) => {
    const map: DocumentItemMap = { ...state.items_map };
    map[payload.parentId] = {
      ...map[payload.parentId],
      item: {
        ...map[payload.parentId]['item'],
        items: payload.children,
      },
    };
    return {
      ...state,
      items_map: map,
    };
  }),

  on(DocumentActions.SaveCurrentItem, (state, { payload }) => {
    return {
      ...state,
      currentItem: payload,
    };
  })
);

export function documentReducer(state: State | undefined, action: Action) {
  return documentReducerInternal(state, action);
}
