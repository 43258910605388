import { ComponentType } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FlwmnSheetRef } from './sheet.ref';
import { FLWMN_SHEET_DATA } from './sheet.token';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('left', style({ transform: 'translateX(0%)' })),
      state('right', style({ transform: 'translateX(0%)' })),
      state('top', style({ transform: 'translateY(0%)' })),
      state('bottom', style({ transform: 'translateY(0%)' })),
      transition('* => left', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out'),
      ]),
      transition('* => right', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out'),
      ]),
      transition('* => top', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in-out'),
      ]),
      transition('* => bottom', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in-out'),
      ]),
      transition('left => *', [
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
      transition('right => *', [
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
      transition('top => *', [
        animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' })),
      ]),
      transition('bottom => *', [
        animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class SheetComponent implements AfterViewInit {
  private pendingComponent: ComponentType<any> | null = null;

  @Output() closeAnimationDone = new EventEmitter<void>();

  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  public viewContainerRef!: ViewContainerRef;

  @HostBinding('class') class = 'flwmn-sheet-container';
  @HostBinding('@slideInOut') slideInOutState: string | undefined = undefined;

  @HostListener('@slideInOut.done', ['$event']) slideInOutDone(
    event: AnimationEvent
  ) {
    if (event.toState === null) {
      this.closeAnimationDone.emit();
    }
  }

  constructor(
    @Inject(FLWMN_SHEET_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private sheetRef: FlwmnSheetRef<any>
  ) {}

  ngAfterViewInit() {
    if (this.pendingComponent) {
      this.loadComponent(this.pendingComponent);
      this.pendingComponent = null;
    }

    // Notify that the sheet is opened after the component is loaded
    this.sheetRef.open();
  }

  loadComponent<T>(component: ComponentType<T>) {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();

      const componentRef = this.viewContainerRef.createComponent(component);

      this.sheetRef.componentInstance = componentRef.instance;
    } else {
      this.pendingComponent = component;
    }

    this.changeDetectorRef.detectChanges();
  }
}
