import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import * as fromApp from '../app/app.reducer';
import * as ResourceConfigurationActions from './resource-configuration.actions';
import { catchError, map, share, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class ResourceConfigurationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(
      ResourceConfigurationActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getActiveResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.GetActiveResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getactiveresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ResourceConfigurationActions.SaveActiveResource({
                  payload: {
                    resource: resData.entity.resource,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ResourceConfigurationActions.SaveActiveResource({
                  payload: {
                    resource: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Get Active Resource  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getInactiveResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.GetInactiveResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getinactiveresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              ...resourceConfigurationData.payload,
              actorEmail: authState.user.Email,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ResourceConfigurationActions.SaveInactiveResource({
                  payload: {
                    resource: resData.entity.resource,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ResourceConfigurationActions.SaveInactiveResource({
                  payload: {
                    resource: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Get Inactive Resource  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getPendingResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.GetPendingResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getpendingresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ResourceConfigurationActions.SavePendingResource({
                  payload: {
                    resource: resData.entity.resource,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ResourceConfigurationActions.SavePendingResource({
                  payload: {
                    resource: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Get Pending Resource  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getRejectedResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.GetRejectedResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getrejectedresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ResourceConfigurationActions.SaveRejectedResource({
                  payload: {
                    resource: resData.entity.resource,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ResourceConfigurationActions.SaveRejectedResource({
                  payload: {
                    resource: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Get Rejected Resource  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getApprovedResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.GetApprovedResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/getapprovedresources`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ResourceConfigurationActions.SaveApprovedResource({
                  payload: {
                    resource: resData.entity.resource,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return ResourceConfigurationActions.SaveApprovedResource({
                  payload: {
                    resource: [],
                    totalCount: 0,
                  },
                });
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Get Approved Resource  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getResourceById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResourceConfigurationActions.GetResourceById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([resourceConfigurationData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocProductUrl}/Resource/getresourcebyid/${authState.user.SubscriberId}/${authState.user.UserId}/${resourceConfigurationData?.payload?.guId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  ResourceConfigurationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  // this.store.dispatch({
                  //   type: '[Resource Configuration] Get Resource By Id Was Successful',
                  // });
                  return ResourceConfigurationActions.SaveAllRequestById({
                    payload: resData.data,
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.store.dispatch({
                  //   type: '[Resource Configuration] Failed To Get Resource By Id',
                  // });
                }

                return {
                  type: '[Resource Configuration] Failed To Get Resource By Id',
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Resource Configuration][CatchError] Failed To Get Resource By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  createResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.CreateResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/create`,
            {
              resourceGuId: authState.user.resourceGuId,
              ...resourceConfigurationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Resource Was Created Successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                if (resourceConfigurationData.instance === 0) {
                  this.store.dispatch(
                    ResourceConfigurationActions.GetActiveResource({
                      payload: {
                        searchValue: null,
                        skip: 0,
                        take: 10,
                        filterValue: [],
                      },
                    })
                  );
                } else if (resourceConfigurationData.instance === 1) {
                  this.store.dispatch(
                    ResourceConfigurationActions.GetInactiveResource({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );
                }

                return {
                  type: '[Resource Configuration] Create Resource Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration] Failed To Create Resource',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Create Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.UpdateResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/update`,
            {
              ...resourceConfigurationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ResourceConfigurationActions.GetActiveResource({
                    payload: {
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  })
                );

                return {
                  type: '[Resource Configuration] Update Resource Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration] Failed To Update Resource',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Update Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  activateResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.ActivateResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/activate`,
            {
              guId: resourceConfigurationData.payload.guId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ResourceConfigurationActions.GetInactiveResource({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                return {
                  type: '[Resource Configuration] Resource Was Activated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration] Failed to Activate Resource ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Activate Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.DeactivateResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/deactivate`,
            {
              resourceGuId: resourceConfigurationData.payload.resourceGuId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Resource was deactivated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ResourceConfigurationActions.GetActiveResource({
                    payload: {
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [],
                    },
                  })
                );

                return {
                  type: '[Resource Configuration]Resource Deactivated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration] Failed to Deactivate Resource ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Deactivate Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  acceptResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.AcceptResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/approve`,
            {
              resourceGuId: resourceConfigurationData.payload.resourceGuId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...resourceConfigurationData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  ResourceConfigurationActions.IsLoading({ payload: false })
                );

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ResourceConfigurationActions.GetPendingResource({
                    payload: {
                      skip: 0,
                      take: 10,
                      searchValue: '',
                      filterValue: [''],
                    },
                  })
                );

                // return resData;
                return {
                  type: '[Resource Configuration] Resource Was  Accepted Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration]Failed To Accept Resource ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Accept Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  rejectResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceConfigurationActions.RejectResource),
      withLatestFrom(this.store.select('auth')),
      switchMap(([resourceConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Resource/reject`,
            {
              resourceGuId: resourceConfigurationData.payload.resourceGuId,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                ResourceConfigurationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Resource was rejected successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Resource Configuration] Resource was Rejected Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Resource Configuration]Failed To Reject Resource ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Resource Configuration][CatchError] Failed To Reject Resource ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllMicroService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResourceConfigurationActions.GetAllMicroService),
        withLatestFrom(this.store.select('auth')),
        switchMap(([resourceConfigurationData, authState]) => {
          return this.http
            .get(
              `${environment.onyxCore}/ProductMicroService/getall/${authState.user.SubscriberId}/${authState.user.UserId}/${resourceConfigurationData.payload.skip}/${resourceConfigurationData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    ResourceConfigurationActions.SaveAllMicroService({
                      payload: resData.entity.productMicroService,
                    })
                  );

                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     'Calendars loaded successfully' || response.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Resource Configuration] Get All Microservice Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Resource Configuration][CatchError] Failed To Get All MicroService ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllTargetObject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResourceConfigurationActions.GetAllTargetObject),
        withLatestFrom(this.store.select('auth')),
        switchMap(([resourceConfigurationData, authState]) => {
          return this.http
            .get(
              `${environment.onyxCore}/ProductMicroService/domainobjects/${authState.user.SubscriberId}/${authState.user.UserId}/${resourceConfigurationData.payload.productMicroServiceId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    ResourceConfigurationActions.SaveAllTargetObject({
                      payload: resData.entity,
                    })
                  );

                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     'Calendars loaded successfully' || response.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Resource Configuration] Failed To Get All Target Objects',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Resource Configuration][CatchError] Failed To Get All Target Objects ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllTargetObjectFields$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResourceConfigurationActions.GetAllTargetObjectFields),
        withLatestFrom(this.store.select('auth')),
        switchMap(([resourceConfigurationData, authState]) => {
          return this.http
            .get(
              `${environment.onyxCore}/ProductMicroService/domainobjectsfields/${authState.user.SubscriberId}/${authState.user.UserId}/${resourceConfigurationData.payload.productMicroServiceId}/${resourceConfigurationData.payload.objectName}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    ResourceConfigurationActions.SaveAllTargetObjectFields({
                      payload: resData.entity,
                    })
                  );

                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     'Calendars loaded successfully' || response.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Resource Configuration] Failed To Get All Target Objects Fields',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Resource Configuration][CatchError] Failed To Get All Target Objects Fields ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );
}
