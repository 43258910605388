<!-- <section id="linkedin-response">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <div class="linkedin-response-image-container">
              <img
                src="/assets/images/auth/welcome-onboard.svg"
                alt="Verify Email"
              />
            </div>

            <h5 class="flwmn-heading-5 fw-700 header-text">
              Verifying LinkedIn Login Details
            </h5>

            <p class="flwmn-caption subheader-text">
              Please wait while we verify your LinkedIn Login Details. This may
              take a few seconds
            </p>
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section> -->

<section id="linkedin-sign-in">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <div class="content-section-verify">
          <div class="form-section">
            <div class="device-verification-image-container">
              <img
                src="/assets/images/auth/welcome-onboard.svg"
                alt="Device Verification"
              />
            </div>

            <h5 class="flwmn-heading-4 fw-600 header-text">
              Verifying LinkedIn Login Details
            </h5>

            <p class="flwmn-para-2 subheader-text"></p>

            <p class="flwmn-caption subheader-text">
              Please wait while we verify your LinkedIn Login Details. This may
              take a few seconds.
            </p>
          </div>
        </div>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
