import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';

import * as DocumentationActions from './documentation.action';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { environment } from 'src/environments/environment';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class DocumentationEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(DocumentationActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getSidebarItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentationActions.GetSidebarItems),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documentations/getdocumentation`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentationActions.IsLoadingSidebarItems({ payload: false })
              );
              if (resData.succeeded === true) {
                if (resData.entity.length > 0) {
                  this.store.dispatch(
                    DocumentationActions.SaveCurrentPage({
                      payload: resData.entity[0]?.page,
                    })
                  );
                }

                return DocumentationActions.SaveSidebarItems({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DocumentationActions.SaveSidebarItems({
                    payload: [],
                  })
                );

                return {
                  type: '[Documentation] Failed To Get Sidebar Items',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DocumentationActions.IsLoadingSidebarItems({ payload: false })
              );
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get Sidebar Items ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
