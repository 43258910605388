import { createAction, props } from '@ngrx/store';
import { Notification } from '../../interfaces/index';

export const ResetStore = createAction('[General] Reset Store');

export const IsLoading = createAction(
  '[General] Is Loading',
  props<{
    payload: boolean;
  }>()
);

//TODO (SetNotification) This needs to be deleted
export const SetNotification = createAction(
  '[General] Set Notification',
  props<{
    payload: Notification;
  }>()
);

//TODO (ClearNotification) This needs to be deleted
export const ClearNotification = createAction('[General] Clear Notification');

export const GetIpAddressDetails = createAction(
  '[General] Get Ip Address Details'
);

export const SaveIpAddressDetails = createAction(
  '[General] Save Ip Address Details',
  props<{
    payload: any;
  }>()
);

// export const GetCountryFromIpAddress = createAction(
//   '[General] Get Country From Ip Address'
// );

// export const SaveCountryFromIpAddress = createAction(
//   '[General] Save Country from Ip Address',
//   props<{
//     payload: string;
//   }>()
// );

export const GetCountries = createAction('[General] Get Countries');

export const SaveCountries = createAction(
  '[General] Save Countries',
  props<{
    payload: string[] | null;
  }>()
);

export const GetStates = createAction(
  '[General] Get States',
  props<{
    payload: string | null;
  }>()
);

export const SaveStates = createAction(
  '[General] Save States',
  props<{
    payload:
      | {
          name: string;
          state_code: string;
        }[]
      | null;
  }>()
);

export const GetCities = createAction(
  '[General] Get Cities',
  props<{
    payload: { country: string; state: string };
  }>()
);

export const SaveCities = createAction(
  '[General] Save Cities',
  props<{
    payload: string[] | null;
  }>()
);

export const GetCountryCodes = createAction('[General] Get Country Codes');

export const SaveCountryCodes = createAction(
  '[General] Save Country Codes',
  props<{
    payload: any[] | null;
  }>()
);

export const IsSubscriptionExpirationNotification = createAction(
  '[General] Is Subscription Expriation Notification',
  props<{
    payload: boolean;
  }>()
);

export const CreateCustomerEnquiry = createAction(
  '[General] Create Customer Enquiry',
  props<{
    payload: {
      fullName: string;
      email: string;
      countryCode: string;
      phoneLine: string;
      businessSize: number;
      message: string;
    };
  }>()
);

export const CreateFlowzJoinWaitlist = createAction(
  '[General] Create Flowz Waitlist',
  props<{
    payload: {
      fullName: string;
      email: string;
      countryCode: string;
      flowmonoWebsiteType: number;
      phoneLine: string;
    };
  }>()
);

export const CreateCustomerSupportRequest = createAction(
  '[General] Create Customer Support Request',
  props<{
    payload: {
      fullName: string;
      email: string;
      countryCode: string;
      phoneLine: string;
      companyName: string;
      businessSize: number;
      typeofAccount: number;
      message: string;
    };
  }>()
);

export const CreateBecomeApartnerRequest = createAction(
  '[General] Create Become A Partner Request',
  props<{
    payload: {
      fullName: string;
      email: string;
      contactNumber: string;
      country: string;
      city: string;
      company: number;
    };
  }>()
);
