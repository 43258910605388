import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AddSignatureComponent } from '../add-signature/add-signature.component';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';

import * as recipientSignatureSelectors from 'src/app/@core/stores/recipient-signature/recipient-signature.selectors';
import * as recipientSignatureActions from 'src/app/@core/stores/recipient-signature/recipient-signature.actions';
import { RecipientSignatureEffects } from 'src/app/@core/stores/recipient-signature/recipient-signature.effects';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-signature',
  templateUrl: './manage-signature.component.html',
  styleUrls: ['./manage-signature.component.scss'],
})
export class ManageSignatureComponent implements OnInit, OnDestroy {
  signatureCount: number = 0;
  signatureList: any[] | null = null;
  signatureListTemp: any[] = [];
  skip: number = 0;
  selectedSignature: any | null = null;
  loading$: Observable<boolean>;
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: { fullName: string; initials: string };
      email: string;
      subscriberId: number;
      isEditor: boolean;
    },
    public dialogRef: MatDialogRef<ManageSignatureComponent>,
    private dialog: MatDialog,
    public store: Store<fromApp.AppState>,
    private recipientEffects: RecipientSignatureEffects,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(
      recipientSignatureSelectors.getRecipientSignaturesIsLoading
    );

    this.store.dispatch(recipientSignatureActions.IsLoading({ payload: true }));

    this.getRecipientSignatures();

    this.listenToGetRecipientSignatures();

    this.listenToActivateSignatures();

    this.listenToDeactivateSignatures();

    this.listenToMakeSignatureDefault();
  }

  getRecipientSignatures() {
    // this.store.dispatch(recipientSignatureActions.IsLoading({ payload: true }));

    this.store.dispatch(
      recipientSignatureActions.GetRecipientSignatures({
        payload: {
          email: this.data.email,
          skip: this.skip,
          take: 10,
          subscriberId: this.data.subscriberId,
        },
      })
    );
  }

  listenToGetRecipientSignatures() {
    this.subscription.add(
      this.store
        .pipe(select(recipientSignatureSelectors.getRecipientSignatures))
        .subscribe((res: any) => {
          if (res !== null) {
            if (res.recipientSignatures && res.recipientSignatures.length > 0) {
              this.signatureCount = res.count;
              // this.signatureList = res.recipientSignatures;
              this.signatureListTemp.push(...res.recipientSignatures);

              if (this.signatureListTemp.length < this.signatureCount) {
                this.skip = this.skip + 10;
                this.store.dispatch(
                  recipientSignatureActions.IsLoading({ payload: true })
                );
                this.getRecipientSignatures();
              } else if (
                this.signatureListTemp.length === this.signatureCount
              ) {
                this.signatureList = this.signatureListTemp;
              }
            }
          }
        })
    );
  }

  onActivateSignature(item: any) {
    if (item !== undefined || item !== null) {
      // this.store.dispatch(
      //   recipientSignatureActions.IsLoading({ payload: true })
      // );

      this.signatureList = this.signatureList!.map((signature: any) => {
        if (signature.id === item.id) {
          return { ...signature, status: 1 };
        } else {
          return { ...signature };
        }
      });

      this.store.dispatch(
        recipientSignatureActions.ActivateRecipientSignature({
          payload: {
            signatureGuId: item.guId,
            isFlowmonoUser: item.isFlowmonoUser,
            subscriberId: this.data.subscriberId,
          },
        })
      );
    }
  }

  listenToActivateSignatures() {
    this.subscription.add(
      this.recipientEffects.activateRecipientSignature$.subscribe(
        (resData: any) => {
          if (resData && resData.succeeded) {
            const notification: Notification = {
              state: 'success',
              message: resData.message,
            };

            this.notificationService.openNotification(
              notification,
              'flwmn-notification-success'
            );
          }

          this.resetSignatureList();
          this.skip = 0;
          this.signatureListTemp = [];
          this.getRecipientSignatures();
        }
      )
    );
  }

  onDeactivateSignature(item: any) {
    if (item !== undefined || item !== null) {
      this.signatureList = this.signatureList!.map((signature: any) => {
        if (signature.id === item.id) {
          return { ...signature, status: 2 };
        } else {
          return { ...signature };
        }
      });
      // this.store.dispatch(
      //   recipientSignatureActions.IsLoading({ payload: true })
      // );

      this.store.dispatch(
        recipientSignatureActions.DeactivateRecipientSignature({
          payload: {
            signatureGuId: item.guId,
            isFlowmonoUser: item.isFlowmonoUser,
            subscriberId: this.data.subscriberId,
          },
        })
      );
    }
  }

  listenToDeactivateSignatures() {
    this.subscription.add(
      this.recipientEffects.deactivateRecipientSignature$.subscribe(
        (resData: any) => {
          if (resData && resData.succeeded) {
            const notification: Notification = {
              state: 'success',
              message: resData.message,
            };

            this.notificationService.openNotification(
              notification,
              'flwmn-notification-success'
            );
          }

          this.resetSignatureList();
          this.skip = 0;
          this.signatureListTemp = [];
          this.getRecipientSignatures();
        }
      )
    );
  }

  onMakeDefault(item: any) {
    this.signatureList = this.signatureList!.map((signature: any) => {
      if (signature.id === item.id) {
        return { ...signature, isDefaultSignature: true };
      } else {
        return { ...signature, isDefaultSignature: false };
      }
    });

    if (item !== undefined || item !== null) {
      // this.store.dispatch(
      //   recipientSignatureActions.IsLoading({ payload: true })
      // );

      this.store.dispatch(
        recipientSignatureActions.SetRecipientSignatureToDefault({
          payload: {
            signatureGuId: item.guId,
            email: item.email,
            subscriberId: this.data.subscriberId,
          },
        })
      );
    }
  }

  listenToMakeSignatureDefault() {
    this.subscription.add(
      this.recipientEffects.setRecipientSignatureToDefault$.subscribe(
        (resData: any) => {
          if (resData && resData.succeeded === true) {
            const notification: Notification = {
              state: 'success',
              message: resData.message,
            };

            this.notificationService.openNotification(
              notification,
              'flwmn-notification-success'
            );
          }

          this.store.dispatch(
            recipientSignatureActions.GetRecipientDefaultSignature({
              payload: {
                email: resData.entity.email,
              },
            })
          );

          this.resetSignatureList();
          this.skip = 0;
          this.signatureListTemp = [];
          this.getRecipientSignatures();
        }
      )
    );
  }

  onSwitchSignature(event: any, item: any) {
    switch (event.checked) {
      case true:
        this.onActivateSignature(item);
        break;

      case false:
        this.onDeactivateSignature(item);
        break;
    }
  }

  onEditSignature(item: any) {
    this.onAddSignature(item);
  }

  onAddSignature(signatureItem?: any | null) {
    const signature = signatureItem ? signatureItem : null;
    this.dialogRef.close({ openAddModal: true, signatureItem: signature });
  }

  addSignature(signatureItem: any | null = null) {
    // this.closeDialog();

    const dialogRef = this.dialog.open(AddSignatureComponent, {
      data: {
        showSavedSignature: false,
        fullName: this.data.user.fullName,
        initials: this.data.user.initials,
        email: this.data.email,
        signatureItem: signatureItem,
        currentTab: 0,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          // this.currentSignature = result;
        }
      })
    );
  }

  onSignatureClicked(signature: string, index: number) {
    this.selectedSignature = { signature, index };
  }

  isEditorOrSelfSign(): boolean {
    const signType = this.route.snapshot.queryParams.signType;
    if ((signType && signType === 'self') || this.data.isEditor) {
      return true;
    }
    return false;
  }

  resetSignatureList() {
    this.store.dispatch(
      recipientSignatureActions.SaveRecipientSignatures({ payload: null })
    );
  }

  closeDialog(signature?: any) {
    this.resetSignatureList();
    this.dialogRef.close({ signature: this.selectedSignature?.signature });
  }

  isBigScreen(item: any): boolean {
    if (window.innerWidth > 576 || item.isDefaultSignature === true) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.resetSignatureList();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
