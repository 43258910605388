export enum ViewType {
  TABLE = 'table',
  GRID = 'grid',
}

export enum DocumentSidebarSVG {
  DOCUMENT = 1,
  FOLDER = 2,
  ARCHIVED = 3,
  BIN = 4,
  SHARED = 5,
  ORGANIZATION = 6,
  DRAFT = 7,
  SENT = 8,
  RECEIVED = 9,
  COMPLETED = 10,
  EXPIRED = 11,
  RECALLED = 12,
  INVALID = 13,
  SECURE = 14,
}

export enum DocumentType {
  Document = 1,
  Template = 2,
}

export enum DocumentStatus {
  Draft = 1,
  Sent = 2,
  AwaitingRecipientAction = 3,
  Completed = 4,
  Rejected = 5,
  Expired = 6,
  Recalled = 7,
  Locked = 8,
  Invalid = 9,
  Received = 10,
  Signing_Request = 11,
}

export enum FileStatus {
  Created = 1,
  Archived = 2,
  Deleted = 3,
  Opened = 5,
  Editing = 6,
  Expired = 7,
  Secure = 8,
}
