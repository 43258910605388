import { createReducer, on, Action } from '@ngrx/store';
import * as SubscriptionActions from './subscription.actions';

export interface State {
  isLoading: boolean;
  isFetching: boolean;
  subscriptionById: any;
  subscriptionEndDateById: any;
  subscriptionsByPlan: any;
  activeSubscriptionsByPlan: any;
  subscriptions: any;
  activeSubscriptions: any;
  allActiveSubscriptions: any;
  subscriptionStatus: any;
  activeSubscriptionBySubscriberId: any;
  allSubscriptions: any;
  subscriptionsByProduct: any;
  activeSubscriptionsByProduct: any;
  cards: any[] | null;
}

const initialState: State = {
  isLoading: false,
  isFetching: false,
  subscriptionById: null,
  subscriptionEndDateById: null,
  subscriptionsByPlan: null,
  activeSubscriptionsByPlan: null,
  subscriptions: null,
  activeSubscriptions: null,
  allActiveSubscriptions: null,
  subscriptionStatus: null,
  activeSubscriptionBySubscriberId: null,
  allSubscriptions: null,
  subscriptionsByProduct: null,
  activeSubscriptionsByProduct: null,
  cards: null,
};

const subscriptionReducerInternal = createReducer(
  initialState,
  on(SubscriptionActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(SubscriptionActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(SubscriptionActions.IsFetching, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(SubscriptionActions.SaveSubscriptionById, (state, { payload }) => {
    return {
      ...state,
      subscriptionById: payload,
    };
  }),

  on(SubscriptionActions.SaveSubscriptionEndDateById, (state, { payload }) => {
    return {
      ...state,
      subscriptionEndDateById: payload,
    };
  }),

  on(SubscriptionActions.SaveSubscriptionsByPlan, (state, { payload }) => ({
    ...state,
    subscriptionsByPlan: payload,
  })),

  on(
    SubscriptionActions.SaveActiveSubscriptionsByPlan,
    (state, { payload }) => ({
      ...state,
      activeSubscriptionsByPlan: payload,
    })
  ),

  on(SubscriptionActions.SaveSubscriptions, (state, { payload }) => ({
    ...state,
    subscriptions: payload,
  })),

  on(SubscriptionActions.SaveActiveSubscriptions, (state, { payload }) => ({
    ...state,
    activeSubscriptions: payload,
  })),

  on(SubscriptionActions.SaveAllActiveSubscriptions, (state, { payload }) => ({
    ...state,
    allActiveSubscriptions: payload,
  })),

  on(SubscriptionActions.SaveSubscriptionsStatus, (state, { payload }) => ({
    ...state,
    subscriptionStatus: payload,
  })),

  on(
    SubscriptionActions.SaveActiveSubscriptionsBySubscriberId,
    (state, { payload }) => ({
      ...state,
      activeSubscriptionBySubscriberId: payload,
    })
  ),

  on(SubscriptionActions.SaveAllSubscriptions, (state, { payload }) => ({
    ...state,
    allSubscriptions: payload,
  })),

  on(SubscriptionActions.SaveSubscriptionsByProduct, (state, { payload }) => ({
    ...state,
    subscriptionsByProduct: payload,
  })),

  on(
    SubscriptionActions.SaveActiveSubscriptionsByProduct,
    (state, { payload }) => ({
      ...state,
      activeSubscriptionsByProduct: payload,
    })
  ),

  on(SubscriptionActions.SaveCard, (state, { payload }) => ({
    ...state,
    cards: payload,
  }))
);

export function subscriptionReducer(state: State | undefined, action: Action) {
  return subscriptionReducerInternal(state, action);
}
