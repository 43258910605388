import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { HelperService } from 'src/app/@core/services/helper.service';
import { regexValidator } from 'src/app/@core/utils/helpers';
import { ThemeService } from 'src/app/@core/services/theme.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, OnDestroy {
  isLoading!: Observable<boolean>;
  year!: number;
  inviteForm!: FormGroup;
  activatedRouteSub: Subscription;
  showPassword: boolean = false;
  email: string;

  constructor(
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.year = new Date().getFullYear();

    this.listenToActivatedRouteSubscription();

    this.createInviteForm();
  }

  listenToActivatedRouteSubscription() {
    this.activatedRouteSub = this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params.email) {
          this.email = params.email;
        }
      }
    );
  }

  createInviteForm() {
    this.inviteForm = this.fb.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      phone_number: [
        null,
        [Validators.required, Validators.pattern('^[+0-9]{7,15}$')],
      ],
      password: [
        null,
        [
          Validators.required,
          regexValidator(new RegExp('^(?=.*?[A-Z])'), {
            uppercaseLetter: true,
          }),
          regexValidator(new RegExp('(?=.*?[a-z])'), { lowercaseLetter: true }),
          regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
          regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
            specialCharacter: true,
          }),
          regexValidator(new RegExp('.{8,}$'), { minimum: true }),
          // Validators.pattern(
          //   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
          // ),
        ],
      ],
    });
  }

  get inviteFormControls() {
    return this.inviteForm.controls;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'firstname' &&
      this.inviteFormControls.firstname.hasError('required')
    ) {
      return 'Please enter your firstname';
    } else if (
      instance === 'lastname' &&
      this.inviteFormControls.lastname.hasError('required')
    ) {
      return 'Please enter your lastname';
    } else if (
      instance === 'phone_number' &&
      this.inviteFormControls.phone_number.hasError('required')
    ) {
      return 'Please enter your phone number';
    } else if (
      instance === 'phone_number' &&
      this.inviteFormControls.phone_number.hasError('pattern')
    ) {
      return 'Incorrect phone number format';
    } else if (
      instance === 'password' &&
      this.inviteFormControls.password.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'password' &&
      this.inviteFormControls['password'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'password' &&
      this.inviteFormControls['password'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'password' &&
      this.inviteFormControls['password'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'password' &&
      this.inviteFormControls['password'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'password' &&
      this.inviteFormControls['password'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    }
    // else if (
    //   instance === 'password' &&
    //   this.inviteFormControls.password.hasError('pattern')
    // ) {
    //   return 'Your password must have at least 1 uppercase, digit (0-9), special character, and a minimum of 8 characters.';
    // }
    else {
      return;
    }
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.registerWithInvite.bind(this)
    );
  }

  registerWithInvite() {
    this.store.dispatch(
      AuthActions.RegisterWithInvite({
        payload: {
          email: this.email,
          password: this.inviteForm.value.password,
          firstName: this.inviteForm.value.firstname,
          lastName: this.inviteForm.value.lastname,
          phoneNumber: this.inviteForm.value.phone_number,
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe();
    }
  }
}
