import { createReducer, on, Action } from '@ngrx/store';
import * as SigningRequestAuditActions from './signing-request-audit.actions';

export interface State {
  isLoading: boolean;
  categories: any[] | null;
  signingRequestAuditById: any;
}

const initialState: State = {
  isLoading: false,
  categories: null,
  signingRequestAuditById: undefined,
};

const signingRequestAuditReducerInternal = createReducer(
  initialState,
  on(SigningRequestAuditActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(SigningRequestAuditActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(
    SigningRequestAuditActions.SaveSigningRequestAuditById,
    (state, { payload }) => ({
      ...state,
      signingRequestAuditById: payload,
    })
  ),

  on(
    SigningRequestAuditActions.SaveSigningRequestAudit,
    (state, { payload }) => ({
      ...state,
      categories: payload,
    })
  )
);

export function signingRequestAuditReducer(
  state: State | undefined,
  action: Action
) {
  return signingRequestAuditReducerInternal(state, action);
}
