export const INDUSTRIES: string[] = [
  'Agriculture',
  'Aerospace',
  'Construction',
  'Education',
  'Energy',
  'Entertainment',
  'Fashion',
  'Food Processing',
  'Finance',
  'Healthcare',
  'Hospitality',
  'Manufacturing',
  'Media',
  'Mining',
  'Oil & Gas',
  'Supply Chain',
  'Inventory Management',
  'Technology',
  'Telecommunication',
  'Transport',
  'Others',
];

export const REFERRER_SOURCES: { source: string; enum: number }[] = [
  { source: 'Search Engines', enum: 1 },
  { source: 'Social Media', enum: 2 },
  { source: 'Recommendations', enum: 3 },
  { source: 'Blogs And Articles', enum: 4 },
  { source: 'Others', enum: 5 },
];

export const JOB_TITLES: any[] = [
  'Accountant',
  'Artist',
  'Consultant',
  'Dentist',
  'Economist',
  'Electrician',
  'Engineer',
  'Fashion Designer',
  'Finance Analyst',
  'HR Professional',
  'Lawyer',
  'Legal Counsel',
  'Marketer',
  'Medical Doctor',
  'Nurse',
  'Nutritionist',
  'Pharmacist',
  'Product Designer',
  'Sales Professional',
  'Software Developer / Engineer',
  'Teacher',
  'Others',
];

export const PRODUCT_INTEREST: any[] = [
  {
    name: 'E-Signing',
    value: 1,
  },
  {
    name: 'Document Manager',
    value: 2,
  },
  {
    name: 'Process Builder',
    value: 3,
  },
  {
    name: 'Invoicing',
    value: 4,
  },
  {
    name: 'Contract Management',
    value: 5,
  },
  {
    name: 'Vendor Management (Self-Service Portal)',
    value: 6,
  },
  {
    name: 'Expense Management',
    value: 7,
  },
  {
    name: 'Accounting',
    value: 8,
  },
  {
    name: 'Asset Register',
    value: 9,
  },
  {
    name: 'Inventory Management',
    value: 10,
  },
  {
    name: 'Fleet Management',
    value: 11,
  },
  {
    name: 'Project Management',
    value: 12,
  },
  {
    name: 'HR Management (Recruitment, Onboarding, Leave Management & Appraisal)',
    value: 13,
  },
  {
    name: 'Payroll Management',
    value: 14,
  },
  {
    name: 'Customer Support',
    value: 15,
  },
  {
    name: 'Systems Integration',
    value: 16,
  },
  {
    name: 'Customization',
    value: 17,
  },
  {
    name: 'Others',
    value: 18,
  },
];

export const ContactUsTypeOfAccount = [
  {
    name: 'Individual',
    value: 1,
  },
  {
    name: 'Corporate',
    value: 2,
  },
];

export const ContactUsBusinessSize = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '2-10',
    value: 2,
  },
  {
    name: '11-50',
    value: 3,
  },
  {
    name: '51-100',
    value: 4,
  },
  {
    name: '101-500',
    value: 5,
  },
  {
    name: '501-1000',
    value: 6,
  },
  {
    name: '1000+',
    value: 7,
  },
];
