import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as UtilitiesActions from 'src/app/@core/stores/utilities/utilities.actions';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import { GenericResponse } from '../../interfaces';

@Injectable()
export class UtilitiesEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,

    private store: Store<fromApp.AppState>
  ) {}

  getAllCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetAllCurrencies),

      switchMap(() => {
        return this.http
          .get(`${environment.onyxDocSubscriptionUrl}/Utility/getcurrencies`)
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                return UtilitiesActions.SaveAllCurrencies({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );
                return { type: '[Utilities] Failed To Get All Currencies' };
              }
            }),
            catchError((errorRes) =>
              of({ type: '[Utilities] Failed To Get All Currencies' })
            )
          );
      })
    )
  );

  getAllTransactionRateTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetTransactionRateTypes),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocSubscriptionUrl}/Utility/gettransactionratetypes`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                return UtilitiesActions.SaveTransactionRateTypes({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );
                return {
                  type: '[Utilities] Failed To Get All Transaction Rate Types',
                };
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] Failed To Get All Transaction Rate Types',
              })
            )
          );
      })
    )
  );

  getSubscriptionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetSubscriptionTypes),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocSubscriptionUrl}/Utility/getsubscriptiontypes`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                return UtilitiesActions.SaveSubscriptionTypes({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );
                return {
                  type: '[Utilities] Failed To Get All Subscription Types',
                };
              }
            }),
            catchError((errorRes) =>
              of({ type: '[Utilities] Failed To Get All Subscription Types' })
            )
          );
      })
    )
  );

  getSubscriptionFrequency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetSubscriptionFrequency),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocSubscriptionUrl}/Utility/getsubscriptionfrequencies`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                return UtilitiesActions.SaveSubscriptionFrequency({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );
                return {
                  type: '[Utilities] Failed To Get All Subscription Frequency',
                };
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] Failed To Get All Subscription Frequency',
              })
            )
          );
      })
    )
  );

  getStorageSizeTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetStorageSizeTypes),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocSubscriptionUrl}/Utility/getstoragesizetypes`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded) {
                return UtilitiesActions.SaveStorageSizeTypes({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );
                return {
                  type: '[Utilities] Failed To Get Storage Size Types',
                };
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] Failed To Get All Subscription Frequency',
              })
            )
          );
      })
    )
  );

  // get utilies
  getControlType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetControlType),
        switchMap(() => {
          return this.http
            .get(`${environment.onyxDocFormBuilderUrl}/Utility/getcontroltypes`)
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveControlType({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Editor] failed to get control type',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed To Get all Control Type',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getBlockControl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetBlockControl),
        switchMap(() => {
          return this.http
            .get(
              `${environment.onyxDocFormBuilderUrl}/Utility/getblockcontrols`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveBlockControl({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Editor] failed to get block controls',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities]  Failed to get all block controls',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getFieldControl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetFieldControl),
        switchMap(() => {
          return this.http
            .get(
              `${environment.onyxDocFormBuilderUrl}/Utility/getfieldcontrols`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveFieldControl({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Utilities] Failed to get field controls',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed to get field controls',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getControlPropertyType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetControlPropertyType),
        switchMap(() => {
          return this.http
            .get(
              `${environment.onyxDocFormBuilderUrl}/Utility/getcontrolpropertytypes`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveControlPropertyType({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Utilities] Failed to get field controls',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed to get field controls',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getControlPropertyValueType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetControlPropertyValueType),
        switchMap(() => {
          return this.http
            .get(
              `${environment.onyxDocFormBuilderUrl}/Utility/getcontrolpropertyvaluetypes`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveControlPropertyValueType({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Utilities] Failed to get control property value type',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed to get control property value type',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getDefaultRoles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetDefaultRoles),
        switchMap(() => {
          return this.http
            .get(`${environment.onyxDocAuthUrl}/Utility/getdefaultroles`)
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveDefaultRoles({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Utilities] Failed to get defaukt roles',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed to get default roles',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getAccessLevels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilitiesActions.GetAccessLevels),
        switchMap(() => {
          return this.http
            .get(`${environment.onyxDocAuthUrl}/Utility/getaccesslevels`)
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  return UtilitiesActions.SaveAccessLevels({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch({
                    type: '[Utilities] Failed to get access levels',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes) =>
                of({
                  type: '[Utilities] Failed to get access levels',
                })
              )
            );
        })
        // share()
      )

    // { dispatch: false }
  );

  getWorkflowUserTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetWorkflowUserTypes),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocProcessWorkflowUrl}/Utility/getworkflowusertypes`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                return UtilitiesActions.SaveWorkflowUserTypes({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  UtilitiesActions.SaveWorkflowUserTypes({
                    payload: [],
                  })
                );

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      state: 'error',
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Utilities] Failed To Get Workflow User Types',
                };
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] [CatchError] Failed To Get Workflow User Types',
              })
            )
          );
      })
    )
  );

  getWorkflowActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetWorkflowActions),
      switchMap(() => {
        return this.http
          .get(
            `${environment.onyxDocProcessWorkflowUrl}/Utility/getworkflowactions`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                return UtilitiesActions.SaveWorkflowActions({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  UtilitiesActions.SaveWorkflowActions({
                    payload: [],
                  })
                );

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      state: 'error',
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Utilities] Failed To Get Workflow Actions',
                };
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] [CatchError] Failed To Get Workflow Actions',
              })
            )
          );
      })
    )
  );

  getBusinessObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetBusinessObjects),
      switchMap(({ payload }) => {
        const baseUrl = payload || environment.onyxDocDocumentUrl;

        return this.http
          .get<GenericResponse>(`${baseUrl}/Utility/getbusinessobjects`)
          .pipe(
            map((resData) => {
              this.store.dispatch(
                UtilitiesActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UtilitiesActions.SaveBusinessObjects({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      state: 'error',
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return UtilitiesActions.SaveBusinessObjects({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] [CatchError] Failed To Get Business Objects',
              })
            )
          );
      })
    )
  );

  getBusinessObjectAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilitiesActions.GetBusinessObjectAttributes),
      concatMap(({ payload }) => {
        const baseUrl = payload?.baseUrl || environment.onyxDocDocumentUrl;

        return this.http
          .get<GenericResponse>(
            `${baseUrl}/Utility/getbusinessobjectsattributes/${payload.businessObject}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                UtilitiesActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UtilitiesActions.SaveBusinessObjectAttributes({
                  payload: {
                    conditionId: payload.conditionId,
                    data: resData.entity,
                  },
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      state: 'error',
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return UtilitiesActions.SaveBusinessObjectAttributes({
                  payload: null,
                });
              }
            }),
            catchError((errorRes) =>
              of({
                type: '[Utilities] [CatchError] Failed To Get Business Object Attributes',
              })
            )
          );
      })
    )
  );
}
