import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Developer Dashboard] Reset Store');

//************************ Developer Developer Dashboard**************************************//

export const IsLoadingResponseCallChartData = createAction(
  '[Developer Dashboard] Is Loading Response Call Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingRequestFailureChartData = createAction(
  '[Developer Dashboard] Is Loading Request Failure Chart Data',
  props<{
    payload: boolean;
  }>()
);
export const IsLoadingSuccessRateChartData = createAction(
  '[Developer Dashboard] Is Loading Success Rate Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingSigningRequestChartData = createAction(
  '[Developer Dashboard] Is Loading Signing Request Chart Data',
  props<{
    payload: boolean;
  }>()
);
export const IsLoadingTotalApiSuccessChartData = createAction(
  '[Developer Dashboard] Is Loading Total Api Success Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingUsageGrowthChartData = createAction(
  '[Developer Dashboard] Is Loading Usage Growth Chart Data',
  props<{
    payload: boolean;
  }>()
);

export const GetResponseCallChartData = createAction(
  '[Developer Dashboard] Get Response Call Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveResponseCallChartData = createAction(
  '[Developer Dashboard] Save Response Call Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetRequestFailureChartData = createAction(
  '[Developer Dashboard] Get Request Failure Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveRequestFailureChartData = createAction(
  '[Developer Dashboard] Save Request Failure Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetSuccessRateChartData = createAction(
  '[Developer Dashboard] Get Success Rate Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveSuccessRateChartData = createAction(
  '[Developer Dashboard] Save Success Rate Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestChartData = createAction(
  '[Developer Dashboard] Get Signing Request Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveSigningRequestChartData = createAction(
  '[Developer Dashboard] Save Signing Request Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetTotalApiSuccessChartData = createAction(
  '[Developer Dashboard] Get Total Api Success Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveTotalApiSuccessChartData = createAction(
  '[Developer Dashboard] Save Total Api Success Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetUsageGrowthChartData = createAction(
  '[Developer Dashboard] Get Usage Growth Chart Data',
  props<{
    payload: {
      startDate: string;
      endDate: string;
      environmentVariableType: number | null;
      appId: number | null;
    };
  }>()
);

export const SaveUsageGrowthChartData = createAction(
  '[Developer Dashboard] Save Usage Growth Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetExternalApplicationsBySubscriberId = createAction(
  '[Developer Dashboard] Get All External Applications By Subscriber Id'
);
