<section id="invite-users">
  <div class="invite-users-header">
    <h6 class="flwmn-heading-6 fw-700 mb-0">Invite users</h6>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <mat-dialog-content>
    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
      <mat-tab label="By email address">
        <div class="mt-3">
          <form [formGroup]="inviteUserByEmailForm" (ngSubmit)="submitInvite()">
            <div formArrayName="invites">
              <div
                *ngFor="
                  let item of inviteUserByEmailFormArray.controls;
                  let i = index
                "
              >
                <div [formGroupName]="i">
                  <div class="invitee-form-row by-email">
                    <div class="input-style1">
                      <label class="input-label">Email</label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <input type="text" matInput formControlName="email" />

                        <mat-error
                          *ngIf="
                            inviteUserByEmailFormArray.controls[i].get('email')!
                              .errors?.required
                          "
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          Please enter invitee's email
                        </mat-error>

                        <mat-error
                          *ngIf="
                            inviteUserByEmailFormArray.controls[i].get('email')!
                              .errors?.email
                          "
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          Sorry, this is not a valid email
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="input-style1">
                      <label class="input-label">Role</label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-select formControlName="role">
                          <ng-container
                            *ngIf="
                              allRoles !== null && allRoles?.length !== 0;
                              else noRolesByEmail
                            "
                          >
                            <ng-container *ngFor="let role of allRoles">
                              <mat-option [value]="role.id">
                                {{ role?.name }}
                              </mat-option>
                            </ng-container>
                          </ng-container>

                          <ng-template #noRolesByEmail>
                            <ng-container *ngIf="allRoles === null">
                              <mat-option disabled>
                                Loading roles...</mat-option
                              >
                            </ng-container>

                            <ng-container *ngIf="allRoles?.length === 0">
                              <mat-option disabled>
                                No role is available</mat-option
                              >
                            </ng-container>
                          </ng-template>
                        </mat-select>

                        <mat-error
                          *ngIf="
                            inviteUserByEmailFormArray.controls[i].get('role')!
                              .errors?.required
                          "
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          Required
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="">
                      <div
                        matRipple
                        class="remove-invitee-icon-container no-select"
                        (click)="removeInvitee(i)"
                      >
                        <app-svg src="assets/icons/delete.svg"></app-svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex align-items-center justify-content-between gap-2 flex-wrap mt-3"
            >
              <div
                matRipple
                (click)="addInvitee()"
                class="add-invitee-container"
              >
                <svg
                  class="add-invitee-icon"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 8H12.5"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 12V4"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p class="flwmn-caption add-invitee-text">Add invitee</p>
              </div>

              <button
                matRipple
                class="btn flwmn-btn-primary flwmn-btn-md"
                (click)="submitInvite()"
                [disabled]="
                  inviteUserByEmailForm.invalid ||
                  (isUserLoading | async) === true ||
                  (isUserRoleLoading | async) === true
                "
              >
                Invite
                {{
                  inviteUserByEmailForm.value.invites.length > 1
                    ? 'Users'
                    : 'User'
                }}

                <ng-container
                  *ngIf="
                    (isUserLoading | async) === true ||
                    (isUserRoleLoading | async) === true
                  "
                >
                  <app-button-loader></app-button-loader>
                </ng-container>
              </button>
            </div>
          </form>
        </div>
      </mat-tab>

      <mat-tab label="By link">
        <div class="mt-3">
          <form [formGroup]="inviteUserByLinkForm">
            <div class="invitee-form-row by-link">
              <div class="input-style1">
                <label class="input-label">Email</label>

                <mat-form-field
                  appearance="outline"
                  floatLabel="never"
                  [style.width.%]="100"
                >
                  <input
                    type="email"
                    inputmode="email"
                    matInput
                    formControlName="email"
                  />

                  <mat-error
                    *ngIf="
                      inviteUserByLinkFormControls.email.hasError('required')
                    "
                  >
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    Please enter invitee's email
                  </mat-error>

                  <mat-error
                    *ngIf="inviteUserByLinkFormControls.email.hasError('email')"
                  >
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    Sorry, this is not a valid email
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="input-style1">
                <label class="input-label">Role</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <mat-select formControlName="role">
                    <ng-container
                      *ngIf="
                        allRoles !== null && allRoles?.length !== 0;
                        else noRolesByLink
                      "
                    >
                      <ng-container *ngFor="let role of allRoles">
                        <mat-option [value]="role.id">
                          {{ role?.name }}
                        </mat-option>
                      </ng-container>
                    </ng-container>

                    <ng-template #noRolesByLink>
                      <ng-container *ngIf="allRoles === null">
                        <mat-option disabled> Loading roles...</mat-option>
                      </ng-container>

                      <ng-container *ngIf="allRoles?.length === 0">
                        <mat-option disabled> No role is available</mat-option>
                      </ng-container>
                    </ng-template>
                  </mat-select>

                  <mat-error
                    *ngIf="
                      inviteUserByLinkFormControls.role!.hasError('required')
                    "
                  >
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    Required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div
              class="d-flex justify-content-center align-items-center justify-content-md-end align-items-md-end pt-2"
            >
              <button
                matRipple
                class="btn flwmn-btn-primary flwmn-btn-md"
                type="button"
                [disabled]="
                  inviteUserByLinkForm.invalid ||
                  (isUserLoading | async) === true ||
                  (isUserRoleLoading | async) === true
                "
                (click)="generateLink()"
              >
                Copy Link

                <ng-container
                  *ngIf="
                    (isUserLoading | async) === true ||
                    (isUserRoleLoading | async) === true
                  "
                >
                  <app-button-loader></app-button-loader>
                </ng-container>
              </button>
            </div>
          </form>
        </div>
      </mat-tab>

      <mat-tab label="Bulk Upload">
        <div class="mt-3">
          <!-- <p class="flwmn-caption fw-500">Invite multiple users from a CSV</p> -->

          <div class="upload-template">
            <p class="flwmn-caption fw-500 mb-0">
              Step 1 -
              <span
                (click)="downloadCsvTemplate()"
                class="flwmn-text-primary flwmn-pointer"
                >Download the CSV template</span
              >
            </p>

            <p class="flwmn-caption fw-500 mb-0">Step 2 - Fill the template</p>

            <p class="flwmn-caption fw-500 mb-0">
              Step 3 - Upload your CSV file here
            </p>
          </div>

          <ng-container *ngIf="!bulkUpload">
            <div
              matRipple
              dragAndDrop
              class="drag-and-drop-border"
              (click)="documentUpload.click()"
              (fileDropped)="onDropDocument($event)"
            >
              <svg
                class="drag-and-drop-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  fill="var(--flwmn-primary-color)"
                />
                <g clip-path="url(#clip0_3580_131881)">
                  <path
                    d="M17.6683 10.8021C17.3037 10.5318 16.8558 10.3333 16.3829 10.2263C16.3052 10.2089 16.2341 10.1697 16.1779 10.1133C16.1217 10.057 16.0827 9.98578 16.0654 9.90809C15.8623 9.00314 15.425 8.2271 14.7823 7.63908C14.0352 6.95444 13.0472 6.57788 12 6.57788C11.0795 6.57788 10.2292 6.86642 9.54249 7.41252C9.07708 7.78321 8.70245 8.25527 8.44718 8.79272C8.4195 8.85153 8.37845 8.90305 8.32731 8.94315C8.27617 8.98326 8.21635 9.01085 8.15264 9.02371C7.45603 9.16434 6.82712 9.46304 6.34692 9.88621C5.68389 10.4722 5.33337 11.2511 5.33337 12.1404C5.33337 13.0393 5.71072 13.8591 6.39561 14.4518C7.04978 15.0167 7.93051 15.3279 8.87504 15.3279H11.5834V11.3339L10.6279 12.2893C10.588 12.3292 10.5404 12.3606 10.4881 12.3816C10.4357 12.4025 10.3796 12.4126 10.3232 12.4112C10.2668 12.4099 10.2113 12.3971 10.16 12.3736C10.1087 12.3502 10.0627 12.3165 10.0248 12.2748C9.87374 12.1089 9.88832 11.8503 10.0469 11.6917L11.7055 10.0333C11.7836 9.95527 11.8896 9.91141 12 9.91141C12.1105 9.91141 12.2164 9.95527 12.2946 10.0333L13.9532 11.6912C14.1167 11.855 14.1261 12.1235 13.9633 12.2878C13.9247 12.3268 13.8788 12.3578 13.8281 12.379C13.7775 12.4002 13.7232 12.4111 13.6683 12.4113C13.6135 12.4114 13.5591 12.4007 13.5084 12.3798C13.4576 12.3589 13.4115 12.3281 13.3727 12.2893L12.4167 11.3339V15.3279H15.6459C16.462 15.3279 17.206 15.0987 17.7409 14.6828C18.3467 14.2115 18.6667 13.537 18.6667 12.7341C18.6667 11.9544 18.3214 11.286 17.6683 10.8021ZM11.5834 17.0055C11.5834 17.116 11.6273 17.222 11.7054 17.3001C11.7836 17.3783 11.8895 17.4222 12 17.4222C12.1105 17.4222 12.2165 17.3783 12.2947 17.3001C12.3728 17.222 12.4167 17.116 12.4167 17.0055V15.3279H11.5834V17.0055Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3580_131881">
                    <rect
                      width="13.3333"
                      height="13.3333"
                      fill="white"
                      transform="translate(5.33337 5.33331)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p class="flwmn-para-1 mb-0 upload-text">Upload CSV file.</p>

              <input
                #documentUpload
                type="file"
                accept="image/*"
                name=""
                class="d-none"
                (change)="onUploadDocument($event)"
              />
            </div>
          </ng-container>

          <ng-container *ngIf="bulkUpload">
            <div class="uploaded-file">
              <p
                class="overview-document-name file-name flwmn-para-1 fw-600 mb-0"
              >
                <span>
                  <app-svg
                    class="document-icon"
                    src="assets/icons/account-settings/team/document.svg"
                  ></app-svg
                ></span>
                {{ uploadedFileName }}
              </p>

              <div
                matRipple
                class="close-icon-container"
                (click)="onCancelFile()"
              >
                <img
                  src="/assets/icons/modal-close-icon.svg"
                  alt="modal close icon"
                />
              </div>
            </div>
          </ng-container>

          <div class="d-flex align-items-center gap-1 mt-1">
            <img src="assets/icons/info.svg" alt="Information" />

            <p class="flwmn-caption flwmn-text-neutral mb-0">
              Please ensure the file contains only Email and Role columns.
            </p>
          </div>

          <div class="cta-container">
            <button
              matRipple
              class="btn flwmn-btn-primary-outline flwmn-btn-md"
              (click)="closeDialog()"
            >
              Cancel
            </button>

            <button
              matRipple
              class="btn flwmn-btn-primary flwmn-btn-md"
              type="button"
              [disabled]="!bulkUpload"
              (click)="onUploadFile()"
            >
              Upload Bulk
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</section>
