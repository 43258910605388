import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromUser from './user.reducer';

export const getUserState = (state: fromApp.AppState) => state.user;

export const getUserIsLoading = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.isLoading
);

export const getAllUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.users
);

export const getActiveUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.activeUsers
);

export const getInactiveUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.inactiveUsers
);

export const getAllUsersWithRequest = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.usersWithRequest
);

export const getInvitedUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.invitedUsers
);

export const getRejectedUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.rejectedUsers
);

export const getLicensedUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.licensedUsers
);
export const getUnLicensedUsers = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.unlicensedUsers
);

export const getAllUserFeatures = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.features
);

export const getUserByEmail = createSelector(
  getUserState,
  (state: fromUser.userReducerState) => state.email
);
