import { createAction, props } from '@ngrx/store';

export const CreationStatus = createAction(
  '[SLA Configuration] Creation Status',
  props<{
    payload: boolean;
  }>()
);

export const IsLoading = createAction(
  '[SLA Configuration] SLA Configuration Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllProducts = createAction(
  '[SLA Configuration] Get All Products',
  props<{
    payload: {
      skip: number;
      take: number;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const SaveAllProducts = createAction(
  '[SLA Configuration] Save All Products',
  props<{
    payload: any;
  }>()
);

export const GetMicroservices = createAction(
  '[SLA Configuration] Get Microservices',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      productGuId: string;
    };
  }>()
);

export const SaveMicroservices = createAction(
  '[SLA Configuration] Save Microservices',
  props<{
    payload: any;
  }>()
);

export const GetTargetObjects = createAction(
  '[SLA Configuration] Get Target Objects',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      serviceGuId: string;
      serviceName: string;
    };
  }>()
);
export const GetCustomTargetObjects = createAction(
  '[SLA Configuration] Get Custom Target Objects',
  props<{
    payload: {
      targetObjectUrl: string;
      apiKey: string;
    };
  }>()
);

export const SaveTargetObjects = createAction(
  '[SLA Configuration] Save Target Objects',
  props<{
    payload: any;
  }>()
);
export const SaveCustomTargetObjects = createAction(
  '[SLA Configuration] Save Custom Target Objects',
  props<{
    payload: { [key: string]: any }[];
  }>()
);
export const ResetCustomTargetObjects = createAction(
  '[SLA Configuration] Reset Custom Target Objects',
  props<{
    payload: { [key: string]: any }[];
  }>()
);

export const GetAllConfigurations = createAction(
  '[SLA Configuration] Get All SLA Configurations',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      skip: number;
      take: number;
    };
  }>()
);

export const GetAllCustomConfigurations = createAction(
  '[SLA Configuration] Get All Custom SLA Configurations',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      skip: number;
      take: number;
    };
  }>()
);

export const GetConfigurationById = createAction(
  '[SLA Configuration] Get SLA Configuration By Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      id: string;
    };
  }>()
);
export const GetCustomConfigurationById = createAction(
  '[SLA Configuration] Get Custom SLA Configuration By Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      id: string;
    };
  }>()
);

export const CreateConfiguration = createAction(
  '[SLA Configuration] Create SLA Configuration',
  props<{
    payload: {
      productObjectName: string;
      productObjectGuId: string;
      targetObject: string;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const CreateCustomConfiguration = createAction(
  '[SLA Configuration] Create Custom SLA Configuration',
  props<{
    payload: {
      platformName: string;
      passKey: string;
      apiKey: string;
      targetObjectApiUrl: string;
      targetObject: string;
      isCategoryConfigured: boolean;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const UpdateConfiguration = createAction(
  '[SLA Configuration] Update SLA Configuration',
  props<{
    payload: {
      productObjectName: string;
      productObjectGuId: string;
      targetObject: string;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
      slaBuilderGuId: string;
    };
  }>()
);

export const UpdateCustomConfiguration = createAction(
  '[SLA Configuration] Update Custom SLA Configuration',
  props<{
    payload: {
      externalProductSlaBuilderGuId: string;
      platformName: string;
      passKey: string;
      apiKey: string;
      targetObjectApiUrl: string;
      targetObject: string;
      isCategoryConfigured: boolean;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const GetCurrentConfiguration = createAction(
  '[SLA Configuration] Edit Current SLA Configuration',
  props<{
    payload: string;
  }>()
);
export const GetCurrentCustomConfiguration = createAction(
  '[SLA Configuration] Edit Current Custom SLA Configuration',
  props<{
    payload: string;
  }>()
);
export const ResetCurrentConfiguration = createAction(
  '[SLA Configuration] Reset Current SLA Configuration',
  props<{
    payload: null;
  }>()
);

export const ActivateConfiguration = createAction(
  '[SLA Configuration] Activate SLA Configuration',
  props<{
    payload: {
      slaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const ActivateCustomConfiguration = createAction(
  '[SLA Configuration] Activate Custom SLA Configuration',
  props<{
    payload: {
      externalProductSlaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const DeactivateConfiguration = createAction(
  '[SLA Configuration] Deactivate SLA Configuration',
  props<{
    payload: {
      slaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const DeactivateCustomConfiguration = createAction(
  '[SLA Configuration] Deactivate Custom SLA Configuration',
  props<{
    payload: {
      externalProductSlaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const SaveAllConfigurations = createAction(
  '[SLA Configuration] Save All SLA Configurations',
  props<{
    payload: any;
  }>()
);

export const SaveAllCustomConfigurations = createAction(
  '[SLA Configuration] Save All Custom SLA Configurations',
  props<{
    payload: any;
  }>()
);
