import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';

const FLOWMONO_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [CommonModule, MatDatepickerModule, MatMomentDateModule],
  exports: [CommonModule, MatDatepickerModule, MatMomentDateModule],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    /**By default the MomentDateAdapter creates dates in your time zone specific locale.
     *  You can change the default behavior to parse dates as UTC
     *  by providing the MAT_MOMENT_DATE_ADAPTER_OPTIONS and setting it to useUtc: true.**/
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    /*************************************************************************************/

    /************************To use custom date format************************/
    { provide: MAT_DATE_FORMATS, useValue: FLOWMONO_DATE_FORMATS },
    /*************************************************************************/
  ],
})
export class GeneralDateModule {
  // static forRoot(): ModuleWithProviders<GeneralDateModule> {
  //   return {
  //     ngModule: GeneralDateModule,
  //     providers: [
  //       /**By default the MomentDateAdapter creates dates in your time zone specific locale.
  //        *  You can change the default behavior to parse dates as UTC
  //        *  by providing the MAT_MOMENT_DATE_ADAPTER_OPTIONS and setting it to useUtc: true.**/
  //       {
  //         provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  //         useValue: { useUtc: true },
  //       },
  //       /*************************************************************************************/
  //       /************************To use custom date format************************/
  //       { provide: MAT_DATE_FORMATS, useValue: FLOWMONO_DATE_FORMATS },
  //       /*************************************************************************/
  //     ],
  //   };
  // }
}
