import { createAction, props } from '@ngrx/store';
import { transactionReducerState } from './transaction.reducers';

export const ResetStore = createAction('[Transaction] Reset Store');

export const IsLoading = createAction(
  '[Transaction] Is Loading',
  props<{
    payload: boolean;
  }>()
);

// export const GetAllTransaction = createAction(
//   '[Transaction] Get All Transactions'
// );

// export const SaveAllTransactions = createAction(
//   '[Transaction] Save All Transactions',
//   props<{
//     payload: any;
//   }>()
// );

export const GetAllTransactions = createAction(
  '[Transaction] Get All Transactions',
  props<{
    payload: {
      subscriberId?: number;
      userId?: string;
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllTransactions = createAction(
  '[Transaction] Save All Transactions',
  props<{
    payload: transactionReducerState['allTransactions'];
  }>()
);

export const AddTransaction = createAction(
  '[Transaction] Add Transaction',
  props<{
    payload: {
      subscriberName: string;
      subscriberEmail: string;
      subscriptionPlanId: number;
      productId: number;
      periodType: number;
      currencyCode: 1;
      startDate: string;
      endDate: string;
      numberOfUsers: number;
      amount: number;
      receipt: string;
      mimeType: string;
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
      // startDate: '2024-06-27T14:58:11.387Z';
      // endDate: '2024-06-27T14:58:11.387Z';
    };
  }>()
);

export const UpdateTransaction = createAction(
  '[Transaction] Update Transaction',
  props<{
    payload: {
      transactionGuId?: string;
      productId: 0;
      subscriberName: string;
      subscriberEmail: string;
      subscriptionPlanId: 0;
      periodType: 1;
      currencyCode: 1;
      startDate: string;
      endDate: string;
      numberOfUsers: number;
      amount: number;
      receipt: string;
      mimeType: string;
      subscriberId?: 0;
      ownerId?: string;
      actorEmail?: string;
    };
  }>()
);

export const SaveAllProducts = createAction(
  '[Transaction] Save All Product',
  props<{
    payload: any[];
  }>()
);

export const GetAllSubscriptionPlan = createAction(
  '[Transaction] Get All Subscription Plan'
);

export const SaveAllSubscriptionPlan = createAction(
  '[Transaction] Save All Subscription Plan',
  props<{
    payload: any[];
  }>()
);

export const GetTransactionById = createAction(
  '[Transaction] Get Transaction By Id',
  props<{
    payload: {
      guId?: string;
    };
  }>()
);

export const SaveTransactionById = createAction(
  '[Transaction] Save All Transaction By Id',
  props<{
    payload: any;
  }>()
);

export const CheckTransactionEmail = createAction(
  '[Transaction] Check Transaction Email',
  props<{
    payload: {
      contactEmail: string;
    };
  }>()
);

export const GetTransactionProducts = createAction(
  '[Transaction] Get Transaction Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveTransactionProducts = createAction(
  '[Transaction] Save Transaction Products',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const GetTransactionActiveProducts = createAction(
  '[Transaction] Get All Transaction Active Products',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
    forSelectField?: boolean;
  }>()
);

export const SaveTransactionActiveProducts = createAction(
  '[Transaction] Save Transaction Active Products',
  props<{
    payload: {
      products: any[];
      totalCount: number;
    };
  }>()
);

export const GetSubscriberByEmail = createAction(
  '[Transaction] Get Subscriber By Email',
  props<{
    payload: {
      email?: string;
    };
  }>()
);

export const SaveSubscriberEmail = createAction(
  '[Transaction] Save Subscriber By Email',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriber = createAction(
  '[Transaction] Get Active Subscriber',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string;
    };
  }>()
);

export const SaveActiveSubscriber = createAction(
  '[Transaction] Save Active Subscriber',
  props<{
    payload: transactionReducerState['activeSubscribers'];
  }>()
);
