import { createAction, props } from '@ngrx/store';
import {
  CreateOrUpdateSubscriptionPlanPayload,
  SignupAndSubscribePayload,
} from '../../interfaces';

export const ResetStore = createAction('[Subscription Plan] Reset Store');

export const IsLoading = createAction(
  '[Subscription Plan] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetching = createAction(
  '[Subscription Plan] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

export const GetAllSubscriptionPlans = createAction(
  '[Subscription Plan] Get All Subscription Plans'
);

export const SaveAllSubscriptionPlans = createAction(
  '[Subscription Plan] Save All Subscription Plans',
  props<{
    payload: any;
  }>()
);

export const GetAllSubscriptionPlanPricings = createAction(
  '[Subscription Plan] Get All Subscription Plan Pricings'
);

export const SaveAllSubscriptionPlanPricings = createAction(
  '[Subscription Plan] Save All Subscription Plan Pricings',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriptionPlansForWebsite = createAction(
  '[Subscription Plan] Get Active Subscription Plans For Website'
);

export const SaveActiveSubscriptionPlansForWebsite = createAction(
  '[Subscription Plan] Save Active Subscription Plans For Website',
  props<{
    payload: any;
  }>()
);

export const GetActiveSubscriptionPlans = createAction(
  '[Subscription Plan] Get Active Subscription Plans'
);

export const SaveActiveSubscriptionPlans = createAction(
  '[Subscription Plan] Save Active Subscription Plans',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionPlanByIdForWebsite = createAction(
  '[Subscription Plan] Get Subscription Plan By Id For Website',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetSubscriptionPlanById = createAction(
  '[Subscription Plan] Get Subscription Plan By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const ActivateSubscriptionPlan = createAction(
  '[Subscription Plan] Activate A Subscription Plan',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const DeactivateSubscriptionPlan = createAction(
  '[Subscription Plan] Deactivate A Subscription Plan',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const GetAllSubscriptionFeatures = createAction(
  '[Subscription Plan] Get All Subscription Features'
);

export const SignupAndSubscribe = createAction(
  '[Subscription Plan] Signup And Subscribe',
  props<{
    payload: SignupAndSubscribePayload;
  }>()
);

export const CreateSubscripitonPlan = createAction(
  '[Subscription Plan] Create Subscription Plan',
  props<{
    payload: Omit<CreateOrUpdateSubscriptionPlanPayload, 'id'>;
  }>()
);

export const UpdateSubscripitonPlan = createAction(
  '[Subscription Plan] Update Subscription Plan',
  props<{
    payload: CreateOrUpdateSubscriptionPlanPayload;
  }>()
);

export const GetActiveCurrencies = createAction(
  '[Subscription Plan] Get Active Currencies'
);

export const SaveActiveCurrencies = createAction(
  '[Subscription Plan] Save Active Currencies',
  props<{
    payload: any;
  }>()
);

export const DeleteSubscriptionPricing = createAction(
  '[Subscription Plan] Delete Subscription Pricing',
  props<{
    payload: {
      subscriptionPlanId: number;
      id: number;
    };
    additionalPayload: {
      instance: 'monthly' | 'yearly';
      index: number;
    };
  }>()
);

export const GetSubscriptionPlan = createAction(
  '[Subscription Plan] Get Subscription plans'
);

export const SaveAllSubscriptionPlan = createAction(
  '[Subscription Plan] Save All Subscription plan',
  props<{
    payload: any;
  }>()
);

export const GetCurrentSubscriptionPlan = createAction(
  '[Subscription Plan] Get Current Subscription plan'
);

export const SaveCurrentSubscriptionPlan = createAction(
  '[Subscription Plan] Save Current Subscription plan',
  props<{
    payload: any;
  }>()
);

export const GetPreviousSubscriptionPlan = createAction(
  '[Subscription Plan] Get Previous Subscription plans'
);

export const SavePreviousSubscriptionPlan = createAction(
  '[Subscription Plan] Save Previous Subscription plan',
  props<{
    payload: any;
  }>()
);

export const GetSubscriptionPlanHistory = createAction(
  '[Subscription Plan] Get Subscription plan history'
);

export const SaveSubscriptionPlanHistory = createAction(
  '[Subscription Plan] Save Subscription plan history',
  props<{
    payload: any;
  }>()
);

/***************************************************************************/
export const GetSubscriptionPlanFeaturesBySubscriptionPlanId = createAction(
  '[Subscription Plan] Get Subscription Plan Features By Subscription Plan Id',
  props<{
    payload: {
      subscriptionPlanId: number;
    };
  }>()
);

export const SaveSubscriptionPlanFeaturesBySubscriptionPlanId = createAction(
  '[Subscription Plan] Save Subscription Plan Features By Subscription Plan Id',
  props<{
    payload: any;
  }>()
);

// export const GetSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel =
//   createAction(
//     '[Subscription Plan] Get Subscription Plan Features By Subscription Plan Id And Role Access Level',
//     props<{
//       payload: {
//         roleAccessLevel: number;
//         subscriptionPlanId: number;
//         skip: number;
//         take: number;
//       };
//     }>()
//   );

// export const SaveSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel =
//   createAction(
//     '[Subscription Plan] Save Subscription Plan Features By Subscription Plan Id And Role Access Level',
//     props<{
//       payload: any;
//     }>()
//   );
/***************************************************************************/

export const GetActiveSubscriptionPlanForCorporateUser = createAction(
  '[Subscription Plan] Get Subscription Active Subscription Plan Features For Corporate'
);

export const SaveActiveSubscriptionPlanForCorporateUser = createAction(
  '[Subscription Plan] Save Subscription Active Subscription Plan Features For Corporate',
  props<{
    payload: any;
  }>()
);

export const CancelSubscriptionAutoRenewal = createAction(
  '[Subscription Plan] Cancel Subscription Auto Renewal',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const ChangeSubscription = createAction(
  '[Subscription Plan] Change Subscription',
  props<{
    payload: {
      // activeSubscriptionId: number;
      // newSubscriptionPlanId: number;
      // subscriberName: string;
      // newSubscriptionPlanPricingId: number;
      // initialSubscriptionPlanId: number;
      // subscriptionType: number;
      // currencyId: number;
      // currencyCode: string;
      systemSettingId: number;
      subscriptionPlanId: number;
      currencyId: number;
      currencyCode: string;
      subscriptionPlanPricingId: number;
      subscriptionId: number;
      paymentChannelId: number;
      subscriptionFrequency: number;
      numberOfMonthsOrYears: number;
      numberOfUsers: number;
      amount: number;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const RenewSubscription = createAction(
  '[Subscription Plan] Renew Subscription',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
      subscriptionFrequency: number;
      noOfMonthsOrYears: number;
      noOfUsers: number;
    };
  }>()
);

export const GetAuditParameter = createAction(
  '[Subscription Plan] Get Audit Parameter'
);

export const GetSubscriptionById = createAction(
  '[Subscription Plan] Get Subscription By ID',
  props<{
    payload: {
      id: number;
      // subscriberId: number;
      // userId: string;
    };
  }>()
);

export const SaveSubscriptionById = createAction(
  '[Subscription Plan] Get Subscription By ID',
  props<{
    payload: any;
  }>()
);

export const SaveSubscriptionPlanById = createAction(
  '[Subscription Plan] Save Subscription Plan By ID',
  props<{
    payload: any;
  }>()
);

export const CheckExpirationPeriod = createAction(
  '[Subscription Plan] Check Expiration Period ',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
    };
  }>()
);

// Storage
export const GetAllStoragePlan = createAction(
  '[Subscription Plan] Get All Storage Plan'
  // props<{
  //   payload: {
  //     id: number;
  //     // subscriberId: number;
  //     // userId: string;
  //   };
  // }>()
);

export const GetActiveStoragePlanById = createAction(
  '[Subscription Plan] Get Active Storage Plan by Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const CreateStoragePlan = createAction(
  '[Subscription Plan] Create Storage Plan',
  props<{
    payload: {
      subscriberId: number;
      storageSize: number;
      storageSizeType: number;
      currencyCode: number;
      amount: number;
      status: number;
      userId: string;
    };
  }>()
);

export const UpdateStoragePlan = createAction(
  '[Subscription Plan] Update Storage Plan',
  props<{
    payload: {
      id: number;
      subscriberId: number;
      storageSize: number;
      storageSizeType: number;
      currencyCode: number;
      amount: number;
      status: number;
      userId: string;
    };
  }>()
);

export const ActivateStoragePlan = createAction(
  '[Subscription Plan] Activate Storage Plan',
  props<{
    payload: {
      id: number;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const DeactivateStoragePlan = createAction(
  '[Subscription Plan] Deactivate Storage Plan',
  props<{
    payload: {
      id: number;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const PurchaseStoragePlan = createAction(
  '[Subscription Plan] Purchase Storage Plan',
  props<{
    payload: {
      storagePlanId: number;
      currencyId: number;
      currencyCode: number;
      paymentChannelId: number;
      subscriptionFrequency: number;
      amount: number;
      subscriberId: number;
      userId: string;
    };
  }>()
);
