import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  Input,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { DowngradeWarningComponent } from 'src/app/@components/webapp/account-settings/billing/dialogs/downgrade-warning/downgrade-warning.component';

@Component({
  selector: 'app-setup-users',
  templateUrl: './setup-users.component.html',
  styleUrls: ['./setup-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupUsersComponent implements OnInit {
  isLoading: Observable<boolean> = of(false);
  filteredUsers: any[] = [];
  selectedUsers: any[] = [];

  @Input() users: any = [
    { id: 1, name: 'User 1' },
    { id: 2, name: 'User 2' },
    { id: 3, name: 'User 3' },
    { id: 4, name: 'User 4' },
    { id: 5, name: 'User 5' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<DowngradeWarningComponent>
  ) {}

  ngOnInit(): void {
    console;
  }

  closeDialog = () => this.dialogRef.close();

  onFilterUsers(event: KeyboardEvent | any) {
    const inputValue = event.target.value.toLowerCase();

    const result = this.users?.filter((user: any) => {
      return (
        user?.email?.toLowerCase().includes(inputValue) ||
        user?.firstName?.toLowerCase().includes(inputValue) ||
        user?.lastName?.toLowerCase().includes(inputValue)
      );
    });

    this.filteredUsers = result;
  }

  isUserSelected(user: any) {
    return this.selectedUsers.includes(user);
  }

  trackById(index: number, user: any): number {
    return user.id;
  }

  onSelectUser(user: any, $event: MatCheckboxChange) {
    if ($event.checked) {
      this.selectedUsers.push(user);
    } else {
      const index = this.selectedUsers.findIndex((u) => u.id === user.id);
      if (index >= 0) {
        this.selectedUsers.splice(index, 1);
      }
    }
  }
}
