<div [id]="id" [formGroup]="form" class="flwmn-checkbox-control">
  <div
    matRipple
    class="flwmn-checkbox-container"
    (click)="hiddenCheckbox.click()"
  >
    <img
      *ngIf="
        form.controls[control.name]?.value === true ||
        form.controls[control.name]?.value === 'true'
      "
      class="checkmark-icon"
      src="assets/icons/editor/control-icons/checkmark.png"
      alt=""
    />

    <input
      #hiddenCheckbox
      class="d-none"
      type="checkbox"
      [formControlName]="control.name"
    />
  </div>
</div>
