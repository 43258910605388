import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as UserActions from '../user/user.actions';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import * as ContactCategoryActions from './contact-category.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class ContactCategoryEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(UserActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  createContactCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.CreateContactCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ContactCategory/createcontactcategory`,
            {
              ...contactCategoryData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ContactCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ContactCategoryActions.GetContactCategory({
                    payload: { skip: 0, take: 10 },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Contact Category] Contact category Was Created Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contact Category] Failed To Create Contact Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Contact Category][CatchError] Failed To Create Contact Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateContactCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.UpdateContactCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ContactCategory/updatecontactcategory`,
            {
              ...contactCategoryData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ContactCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ContactCategoryActions.GetContactCategory({
                    payload: { skip: 0, take: 10 },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Contact Category] Contact Category Was Updated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contact Category] Failed To Update Contact Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Contact Category][CatchError] Failed To Update Contact Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  activateContactCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.ActivateContactCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ContactCategory/activatecontactcategory`,
            {
              ...contactCategoryData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ContactCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ContactCategoryActions.GetContactCategory({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Contact Category] Contact Category Activated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contact Category] Failed To Activate Contact Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Contact Category][CatchError] Failed To Activate Contact Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deactivateContactCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.DeactivateContactCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ContactCategory/deactivatecontactcategory`,
            {
              ...contactCategoryData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ContactCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  ContactCategoryActions.GetContactCategory({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Contact Category] Contact Category Deactivated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contact Category] Failed To Deactivate Contact Category',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Contact Category][CatchError] Failed To Deactivate Contact Category ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getContactCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.GetContactCategory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return (
          this.http
            // .get<GenericResponse>(
            //   `${environment.onyxDocDocumentUrl}/ContactCategory/getcontactcategories/${authState.user?.SubscriberId}/${authState.user?.UserId}/${contactCategoryData.payload.skip}/${contactCategoryData.payload.take}`
            // )
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ContactCategory/getcontactcategories`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...contactCategoryData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ContactCategoryActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return ContactCategoryActions.SaveContactCategory({
                    payload: resData.entity,
                  });
                } else {
                  this.store.dispatch(
                    //   ContactCategoryActions.SaveCommentOnDocument({
                    ContactCategoryActions.SaveContactCategory({
                      payload: [],
                    })
                  );

                  return {
                    type: '[Contact Category] Failed To Get Contact Category',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Contact Category][CatchError] Failed To Get Contact Category ${errorRes.message}`
                );
              })
            )
        );
      })
    );
  });

  getContactCategoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactCategoryActions.GetContactCategoryById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([contactCategoryData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/ContactCategory/getcontactcategorybyid/${contactCategoryData.payload.id}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ContactCategoryActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ContactCategoryActions.SaveContactCategoryById({
                  payload: resData.entity,
                });
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                this.store.dispatch(
                  //   ContactCategoryActions.SaveCommentOnDocument({
                  ContactCategoryActions.SaveContactCategoryById({
                    payload: [],
                  })
                );

                return {
                  type: '[Contact Category] Failed To Get Contact Category By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Contact Category][CatchError] Failed To Get Contact Category By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
