<div class="button-loader" [ngClass]="{ primaryColor: primaryColor }">
  <mat-spinner diameter="16"></mat-spinner>
</div>

<!-- <div class="lds-ring">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div> -->
