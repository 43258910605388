// Checkout
export const ResetStore = createAction('[Checkout] Reset Store');

export const IsLoading = createAction(
  '[Checkout] Is Loading',
  props<{
    payload: boolean;
  }>()
);

import { createAction, props } from '@ngrx/store';
import { SignupAndSubscribePayload } from '../../interfaces/subscription.interface';

export const SignUpAndSubscribe = createAction(
  '[Checkout] Sign Up And Subscribe',
  props<{
    payload: SignupAndSubscribePayload;
  }>()
);

export const SaveSubscriber = createAction(
  '[Checkout] Save Subscriber',
  props<{
    payload: any;
  }>()
);
