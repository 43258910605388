import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ExternalVerificationActions from './external-verification.actions';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { HelperService } from '../../services/helper.service';
import { USE_DEVELOPER_TOKEN } from '../../interceptors/app.interceptor.service';
import { NotificationService } from '../../services/notification.service';

@Injectable()
export class ExternalVerificationEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    // private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      ExternalVerificationActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  verifyExternalUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExternalVerificationActions.VerifyUser),
      withLatestFrom(this.store.select('auth')),
      switchMap(([verificationData, authState]) => {
        const payload = {
          signingRequestId: verificationData.payload.signingRequestId,
          verificationCode: verificationData.payload.verificationCode,
          subscriberId: verificationData.payload.subscriberId,
        };
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/SigningRequests/verifysigningrequestaudit`,
            payload,
            {
              // params: params,
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                ExternalVerificationActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return ExternalVerificationActions.SaveVerifiedUser({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  ExternalVerificationActions.SaveVerifiedUser({
                    payload: null,
                  })
                );
                const notification: Notification = {
                  state: 'error',
                  message: resData.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );
                return {
                  type: '[External Verification] Failed to Verify User',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[External Verification] Failed to Verify User ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSigningRequestsAuditTrailDownload$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExternalVerificationActions.GetAuditTrailDownload),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/SigningRequests/downloadsigningrequestaudit/${signingRequestData.payload.signingRequestId}/${signingRequestData.payload.subscriberId}/`,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ExternalVerificationActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    ExternalVerificationActions.SaveAuditTrailDownload({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[External Verification Audit Trail] Failed To Get  External Verification Audit Trail',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[External Verification Audit Trail] Failed To Get  External Verification Audit Trail ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
