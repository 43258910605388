export interface APICall {
  apiClientId: string;
  apiResponseTime: number;
  applicationName: string;
  applicationStatus: number;
  createdBy: string;
  createdByEmail: string;
  createdDate: string;
  description: string;
  deviceId: number;
  id: number;
  ipAddress: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  noOfApiCalls: number;
  requestMethod: number;
  requestName: string;
  responseCode: number;
  responseStatusCode: number;
  status: number;
  statusDesc: string;
  subscriberId: number;
  subscriberName: string;
  userId: number;
  username: string;
}

export enum RequestMethodEnum {
  Post = 0,
  Get = 1,
  Put = 2,
  Delete = 3,
}

export const RequestMethods = {
  Post: 'Post',
  Get: 'Get',
  Put: 'Put',
  Delete: 'Delete',
};
