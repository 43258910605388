import { createAction, props } from '@ngrx/store';
import { DocumentType, SignTypeEnum } from '../../enums';
import { RepeatRequest } from '../../interfaces';
import { EditorSignType } from '../../types';

export const ResetStore = createAction('[Editor] Reset Store');

export const IsLoading = createAction(
  '[Editor] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const UploadDocuments = createAction(
  '[Editor] Upload Documents',
  props<{
    payload: {
      signingRequestId: number;
      batchDocumentUploadRequest: {
        mimeType: string;
        name: string;
        documentType: number;
        file: string;
        extension: string;
      }[];
      destinationFolderId: number;
      userId: string;
      email: string;
      subscriberId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const UploadDocumentsUsingIformFile = createAction(
  '[Editor] Upload Documents Using Iform File',
  props<{
    payload: {
      signingRequestId: number;
      files: File[];
      destinationFolderId: number;
      userId: string;
      email: string;
      subscriberId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const GetDocumentsBySigningRequestId = createAction(
  '[Editor] Get Documents By Signing Request Id',
  props<{
    payload: {
      signingRequestId: number;
      userId: string;
      subscriberId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const SaveDocumentsBySigningRequestId = createAction(
  '[Editor] Save Documents By Signing Request Id',
  props<{
    payload: any;
  }>()
);

export const UpdateDocumentData = createAction(
  '[Editor] Update Document Data',
  props<{
    payload: {
      subscriberId: number;
      signingRequestId: number;
      recipients: {
        id: number;
        email: string;
        firstName: string;
        lastName: string;
        rank: number;
        recipientCategory: number;
        hasPrivateMessage: boolean;
        messageBody: string;
      }[];
      messageRequest: {
        subject: string;
        message: string;
      };
      settingsRequest: {
        completionDate: Date;
        validationPeriod: Date;
        setPassword: boolean;
        setReminders: boolean;
        reminderSetDate: Date;
        reminderRepeatType: number;
        repeatRequest: RepeatRequest | null;
      };
      notifyRecipientInSequentialFormat: boolean;
      allowRecipientsViewDocumentActivities: boolean;
      allowRecipientsAddControls: boolean;
      allowTimeStampOnSignatureControl: boolean;
      stopAfterAnyDocumentRejection: boolean;
      categoryId: number;
      priorityType: number;
      setPassword: boolean;
      email: string;
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const SelfSignDocuments = createAction(
  '[Editor] Self Sign Documents',
  props<{
    payload: {
      signingRequestId: number;
      subscriberId: number;
      email: string;
      allDocuments: {
        documentId: number;
        controls: {
          id: number;
          recipientId: number;
          actualCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          clientCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          additionalStyles: {
            fontFamily: string;
            fontWeight: string;
            fontSize: string;
            color: string;
            textAlign: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: string[];
          validators: string[];
          rank: number;
          value: string;
          pageNumber: string;
        }[];
      }[];
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const ReplaceDocument = createAction(
  '[Editor] Replace Document',
  props<{
    payload: {
      signingRequestId: number;
      existingDocumentId: number;
      documentName: string;
      mimeType: string;
      file: string;
      extension: string;
      userId: string;
      subscriberId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const RemoveDocument = createAction(
  '[Editor] Remove Document',
  props<{
    payload: {
      signingRequestId: number;
      documentId: number;
      userId: string;
      subscriberId: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const DownloadDocuments = createAction(
  '[Editor] Download Documents',
  props<{
    payload: {
      signingRequestId: number;
      documentIds: number[];
      subscriberId: number;
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const ShareSelfSignedDocuments = createAction(
  '[Editor] Share Self Signed Documents',
  props<{
    payload: {
      signingRequestId: number;
      controlRequests: {
        documentId: number;
        controls: {
          id: number;
          recipientId: number;
          actualCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          clientCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          additionalStyles: {
            fontFamily: string;
            fontWeight: string;
            fontSize: string;
            color: string;
            textAlign: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: [string];
          validators: [string];
          rank: number;
          value: string;
          pageNumber: string;
          disabled: true;
        }[];
      }[];
      recipientEmail: string;
      allowRecipientsAddControls: boolean;
      signType: SignTypeEnum.SelfSign | SignTypeEnum.SignWithOthers;
    };

    // payload: {
    //   signingRequestId: number;
    //   documentsAndControls: {
    //     documentId: number;
    //     controls: {
    //       id: number;
    //       recipientId: number;
    //       actualCoordinate: {
    //         position: string;
    //         width: string;
    //         height: string;
    //         xPosition: string;
    //         yPosition: string;
    //       };
    //       clientCoordinate: {
    //         position: string;
    //         width: string;
    //         height: string;
    //         xPosition: string;
    //         yPosition: string;
    //       };
    //       additionalStyles: {
    //         fontFamily: string;
    //         fontWeight: string;
    //         fontSize: string;
    //         color: string;
    //         textAlign: string;
    //       };
    //       recipientEmail: string;
    //       label: string;
    //       type: number;
    //       name: string;
    //       selectOptions: string[];
    //       validators: string[];
    //       rank: number;
    //       value: string;
    //       pageNumber: string;
    //     }[];
    //   }[];
    //   // documentIds: number[];
    //   subscriberId: number;
    //   userId: string;
    // };
    // additionalPayload?: {
    //   bearerToken?: string;
    // };
  }>()
);

export const DeleteRecipient = createAction(
  '[Editor] Delete Recipient',
  props<{
    payload: {
      subscriberId: number;
      signingRequestId: number;
      recipientEmail: string;
    };
    additionalPayload: {
      bearerToken?: string;
      recipientIndex: number;
      userId: string;
    };
  }>()
);

export const SaveAsDraft = createAction(
  '[Editor] Save As Draft',
  props<{
    payload: {
      subscriberId: number;
      signingRequestId: number;
      email: string;
      documentControlsRequest: {
        documentId: number;
        controls: {
          id: number;
          recipientId: number;
          actualCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          clientCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          additionalStyles: {
            fontFamily: string;
            fontWeight: string;
            fontSize: string;
            color: string;
            textAlign: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: [string];
          validators: [string];
          rank: number;
          value: string;
          pageNumber: string;
          disabled: true;
        }[];
      }[];
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const SendToSignatories = createAction(
  '[Editor] Send To Signatories',
  props<{
    payload: {
      signingRequestId: number;
      allDocuments: {
        documentId: number;
        controls: {
          id: number;
          recipientId: number;
          actualCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          clientCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          additionalStyles: {
            fontFamily: string;
            fontWeight: string;
            fontSize: string;
            color: string;
            textAlign: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: [string];
          validators: [string];
          rank: number;
          value: string;
          pageNumber: string;
          disabled: true;
        }[];
      }[];
      subscriberId: number;
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const ResendToSignatories = createAction(
  '[Editor] Resend To Signatories',
  props<{
    payload: {
      signingRequestId: number;
      subscriberId: number;
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const GetRecipientsBySigningRequestId = createAction(
  '[Editor] Get Recipients By Signing Request Id',
  props<{
    payload: {
      signingRequestId: number;
      subscriberId: number;
      userId: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const SaveControlActors = createAction(
  '[Editor] Save Control Actors',
  props<{
    payload: {
      actorListName: string;
      actorsData:
        | {
            actorId: number;
            actorFirstName: string;
            actorLastName: string;
            actorEmail: string;
            profilePicture: string | null;
          }[];
    } | null;
  }>()
);

export const DecodeUrlHash = createAction(
  '[Editor] Decode Url Hash',
  props<{
    payload: {
      documentHash: string | null;
      isSharedDocument: boolean;
    };
  }>()
);

export const SaveDecodeUrlHash = createAction(
  '[Editor] Save Decode Url Hash',
  props<{
    payload: any;
  }>()
);

export const GetApiUserById = createAction(
  '[Editor] Get Api User By Id',
  props<{
    payload: {
      userId: string;
    };
  }>()
);

export const SignAndAccept = createAction(
  '[Editor] Sign And Accept',
  props<{
    payload: {
      subscriberId?: number;
      signingRequestId: number;
      controlRequests: {
        documentId: number;
        controls: {
          id: number;
          recipientId: number;
          actualCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          clientCoordinate: {
            position: string;
            width: string;
            height: string;
            xPosition: string;
            yPosition: string;
          };
          additionalStyles: {
            fontFamily: string;
            fontWeight: string;
            fontSize: string;
            color: string;
            textAlign: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: [string];
          validators: [string];
          rank: number;
          value: string;
          pageNumber: string;
          disabled: true;
        }[];
      }[];
      recipientEmail: string;
      allowRecipientsAddControls: boolean;
      signType: SignTypeEnum.SelfSign | SignTypeEnum.SignWithOthers;
      ipAddress: string;
      location: string;
      comment: string;
      userId?: string;
    };
  }>()
);

export const SaveSignature = createAction(
  '[Editor] Save Signature',
  props<{
    payload: {
      signature: string;
      recipientEmail: string;
      mimeType: string;
      subscriberId: number;
      userId: string | null;
    };
  }>()
);

export const GetExternalUserByEmail = createAction(
  '[Editor] Get External User By Email',

  props<{
    payload: {
      email: string;
    };
  }>()
);

export const SaveExternalUserByEmail = createAction(
  '[Editor] Save External User By Email',

  props<{
    payload: any;
  }>()
);

export const InAppUserLogin = createAction(
  '[Editor] In App User Login',
  props<{
    payload: {
      apiClientId: string;
      token: string;
      appName: string;
      appId: number;
      signType: EditorSignType;
      returnUrl: string;
      email: string;
      password: string;
    };
  }>()
);

export const RejectDocument = createAction(
  '[Editor] Reject Document',
  props<{
    payload: {
      signingRequestId: number;
      documentIds: number[];
      rejectionReason: string;
      recipientEmail: string;
      attachedFileRequest: {
        attachment: string;
        name: string;
        mimeType: string;
        extension: string;
      }[];
    };
    additionalPayload: {
      stopAfterAnyDocumentRejection: boolean;
    };
  }>()
);

export const ReassignSigningRequest = createAction(
  '[Editor] Reassign Signing Request',
  props<{
    payload: {
      encryptedString: {
        signingRequestId: number;
        reason: string;
        recipientId: number | null;
        firstName: string;
        lastName: string;
        email: string;
      };
      subscriberId: number;
      userId: string | null;
    };
  }>()
);

export const RecallDocumentsBySigningRequestId = createAction(
  '[Editor] Recall Documents By Signing Request Id',
  props<{
    payload: {
      signingRequestId: number;
      userId: string;
      subscriberId: number;
      recalledReason: string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const RenameDocument = createAction(
  '[Editor] Rename Document',
  props<{
    payload: {
      documentId: number;
      name: string;
      userId: string;
      subscriberId: number;
    };
    additionalPayload: {
      signingRequestId: number;
      bearerToken?: string;
    };
  }>()
);

export const DeleteDocumentControlsByControlId = createAction(
  '[Editor] Delete Document Controls By Control Id',
  props<{
    payload: {
      signingRequestId: number;
      subscriberId: number;
      documentControls: {
        controlId: number;
        documentId: number;
      }[];
      userId: string;
    };
    additionalPayload: {
      controlRemoveData: any;
      bearerToken?: string;
    };
  }>()
);

export const GetUserByEmail = createAction(
  '[Editor] Get User By Email',
  props<{
    payload: {
      email: string;
      userId: string | null;
    };
  }>()
);

/*******************************************************************************/

export const UploadDocument = createAction(
  '[Editor] Upload Document',
  props<{
    payload: {
      mimeType: string;
      name: string;
      destinationFolderId: number;
      documentType: DocumentType;
      file: string;
      extension: string;
      userId: string;
      email: string;
      subscriberId: string;
    };
  }>()
);

export const GetDocumentById = createAction(
  '[Editor] Get Document By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

// export const ReplaceDocument = createAction(
//   '[Document] Replace Document',
//   props<{
//     payload: {
//       existingDocumentId: number;
//       mimeType: string;
//       file: string;
//       extension: string;
//     };
//   }>()
// );

export const DownloadDocument = createAction(
  '[Editor] Download Document',
  props<{
    payload: {
      documentId: number;
      subscriberId: number;
      userId: string;
      file: string;
      extension: string;
      mimeType: string;
    };
  }>()
);

export const StoreDataForSaveAsTemplate = createAction(
  '[Editor] Store Data For Save As Template',
  props<{
    payload: {
      mimeType: string;
      name: string;
      file: string;
      extension: string;
      userId: string;
      email: string;
      subscriberId: string;
    };
  }>()
);

export const SaveDocument = createAction(
  '[Editor] Save Document',
  props<{
    payload: {
      documentId: number;
      file: string | null;
      name: string | null;
    };
  }>()
);

export const VerifyPasscode = createAction(
  '[Editor] Verify Passcode',
  props<{
    payload: {
      documentId: number;
      passCode: number;
      email: string;
    };
  }>()
);

export const GetRecipientsByDocumentId = createAction(
  '[Editor] Get Recipients By Document Id',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const SaveRecipients = createAction(
  '[Editor] Save Recipients',
  props<{
    payload: any[];
  }>()
);

export const CreateRecipients = createAction(
  '[Editor] Create Recipients',
  props<{
    payload: {
      subscriberId: number;
      recipients: {
        email: string;
        firstName: string;
        lastName: string;
        rank: number;
        recipientCategory: number;
      }[];
      userId: string;
      documentId: number;
    };
  }>()
);

export const UpdateRecipients = createAction(
  '[Editor] Update Recipients',
  props<{
    payload: {
      subscriberId: number;
      recipients: {
        id: number;
        email: string;
        firstName: string;
        lastName: string;
        rank: number;
        recipientCategory: number;
      }[];
      userId: string;
      documentId: number;
    };
  }>()
);

export const DeleteRecipientOld = createAction(
  '[Editor] Delete Recipient Old',
  props<{
    payload: {
      organizationId: number;
      recipientId: number;
      index: number;
      documentId: number;
    };
  }>()
);

export const ClearDocumentAndRecipients = createAction(
  '[Editor] Clear Document And Recipients'
);

// export const SaveAsDraft = createAction(
//   '[Editor] Save As Draft',
//   props<{
//     payload: {
//       documentId: number;
//       recipientDetails: {
//         component: {
//           id: number;
//           recipientId: number;
//           coordinate: {
//             position: string;
//             transform: string;
//             name: string;
//             width: string;
//             height: string;
//           };
//           recipientEmail: string;
//           label: string;
//           type: number;
//           name: string;
//           selectOptions: string[];
//           validators: string[];
//           value: string;
//           rank: number;
//           pageNumber: string;
//         };
//       }[];
//       pageDimensions: {
//         pageNumber: number;
//         width: string;
//         height: string;
//       }[];
//       recipients: string[];
//     };
//   }>()
// );

// export const ShareSelfSignedDocument = createAction(
//   '[Editor] Share Self Signed Document',
//   props<{
//     payload: {
//       documentId: number;
//       subscriberId: number;
//       userId: string;
//       selfSignedDocument: string;
//       mimeType: string;
//       extension: string;
//       subject: string;
//       message: string;
//       recipients: string[];
//     };
//   }>()
// );

export const SendDocumentToSignatories = createAction(
  '[Editor] Send Documents To Signatories',
  props<{
    payload: {
      documentId: number;
      subscriberId: number;
      recipientDetails: {
        component: {
          id: number;
          recipientId: number;
          coordinate: {
            position: string;
            transform: string;
          };
          recipientEmail: string;
          label: string;
          type: number;
          name: string;
          selectOptions: string[];
          validators: string[];
          value: string;
          rank: number;
        };
      }[];
      pageDimensions: { pageNumber: number; width: string; height: string }[];
      filePath: string;
      userId: string;
      message: string;
      recipients: string[];
      shouldSendPasscode: boolean;
      subject: string;
    };
  }>()
);

export const GetUserVerificationStatus = createAction(
  '[Editor] Get User Verification Status',
  props<{
    payload: {
      userId: string;
      instance:
        | 'download'
        | 'sendToDocumentSignatories'
        | 'shareDocument'
        | 'save as draft';
    };
  }>()
);

export const SaveUser = createAction(
  '[Editor] Save User',
  props<{
    payload: any;
  }>()
);

export const SaveInternalUser = createAction(
  '[Editor] Save Internal User',
  props<{
    payload: any;
  }>()
);

export const SaveExternalUser = createAction(
  '[Editor] Save External User',
  props<{
    payload: any;
  }>()
);

// export const UpdateComponents = createAction(
//   '[Editor] Update Components',
//   props<{
//     payload: {
//       subscriberId: number;
//       documentId: number;
//       email: string;
//       components: [
//         {
//           id: number;
//           recipientId: number;
//           coordinate: {
//             position: string;
//             transform: string;
//           };
//           recipientEmail: string;
//           label: string;
//           type: number;
//           name: string;
//           selectOptions: string[];
//           validators: string[];
//           value: string;
//           rank: number;
//         }
//       ];

//       /**SignAndAcceptDocument paylooa**/
//       signedFile: string | null;
//       mimeType: string | null;
//       extension: string | null;
//       recipientEmail: string;
//       signingAPIUrl: string;
//     };
//   }>()
// );

// export const SignAndAcceptDocument = createAction(
//   '[Editor] Sign And Accept Document',
//   props<{
//     payload: {
//       documentId: number;
//       signedFile: string;
//       mimeType: string;
//       // file: string;
//       extension: string;
//       recipientEmail: string;
//       signingAPIUrl: string;
//     };
//   }>()
// );

export const GetCommentsByDocumentId = createAction(
  '[Editor] Get Comments By Document Id',
  props<{
    payload: {
      subscriberId: number;
      documentId: number;
    };
  }>()
);

export const SaveCommentsByDocumentId = createAction(
  '[Editor] Save Comments By Document Id',
  props<{
    payload: any[];
  }>()
);

export const CreateComment = createAction(
  '[Editor] Create Comment',
  props<{
    payload: {
      subscriberId: number;
      subscriberName: string;
      documentId: number;
      commentType: number;
      comment: string;
      userId: string;
      coordinates: {
        position: string;
        transform: string;
        name: string;
      };
    };
  }>()
);

export const CreateControl = createAction(
  '[Editor] Create Control Configuration',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
      name: string;
      description: string;
      inputValueType: number;
      controlType: number;
      blockControlType: number;
      fieldControlType: number;
      displayName: string;
      controlTips: string;
      versionNumber: number;
      controlProperties: [
        {
          parentPropertyId: number;
          index: number;
          displayName: string;
          propertyTips: string;
          controlPropertyType: number;
          controlPropertyValueType: number;
          showInContextMenu: boolean;
          isDeleted: boolean;
          status: number;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
          controlPropertyItems: [
            {
              controlId: number;
              parentPropertyId: number;
              controlPropertyId: number;
              index: number;
              value: string;
              isDefaultValue: boolean;
              isDeleted: boolean;
              status: number;
              id: number;
              subscriberId: number;
              name: string;
              description: string;
              userId: string;
            }
          ];
        }
      ];
    };
  }>()
);

export const UpdateControl = createAction(
  '[Editor] Update Control Configuration',
  props<{
    payload: {
      id: number;
      subscriberId: number;
      userId: string;
      name: string;
      description: string;
      inputValueType: number;
      controlType: number;
      blockControlType: number;
      fieldControlType: number;
      displayName: string;
      controlTips: string;
      versionNumber: number;
      controlProperties: [
        {
          parentPropertyId: number;
          index: number;
          displayName: string;
          propertyTips: string;
          controlPropertyType: number;
          controlPropertyValueType: number;
          showInContextMenu: boolean;
          isDeleted: boolean;
          status: number;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
          controlPropertyItems: [
            {
              controlId: number;
              parentPropertyId: number;
              controlPropertyId: number;
              index: number;
              value: string;
              isDefaultValue: boolean;
              isDeleted: boolean;
              status: number;
              id: number;
              subscriberId: number;
              name: string;
              description: string;
              userId: string;
            }
          ];
        }
      ];
    };
  }>()
);

export const GetControlById = createAction(
  '[Editor] Get Control By ID',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveControlById = createAction(
  '[Editor] Save Control Configuration By Id',
  props<{
    payload: any;
  }>()
);

export const GetActiveControls = createAction(
  '[Editor] Get Active Controls',
  props<{
    payload: {
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const GetControls = createAction('[Editor] Get Controls Configuration');

export const SaveControls = createAction(
  '[Editor] Save Controls Configuration',
  props<{
    payload: any[];
  }>()
);

export const SaveActiveControls = createAction(
  '[Editor] Save Active Controls',
  props<{
    payload: any[];
  }>()
);

export const CreateDocument = createAction(
  '[Editor] Create Document',
  props<{
    payload: {
      // subscriberId: number;
      name: string;
      description: string;
      displayName: string;
      watermark: string;
      versionNumber: number;
      documentType: number;
      documentShareType: number;
      documentPages: [];
      // userId: string;
    };
  }>()
);

export const CreateDocumentPage = createAction(
  '[Editor] Create Document Page',
  props<{
    payload: {
      // subscriberId: number;
      subscriberName: string;
      name: string;
      displayName: string;
      watermark: string;
      pageTips: string;
      documentId: number;
      pageIndex: number;
      pageNumber: number;
      pageLayout: number;
      pageControlItems: [];
      height: string;
      width: string;
      position: string;
      transform: string;
      headerData: string;
      footerData: string;
      // userId: string;
    };
  }>()
);

export const CreatePageControlItem = createAction(
  '[Editor] Create Page Control Item',
  props<{
    payload: {
      pageControlItem: {
        documentPageId: number;
        controlId: number;
        textValue: string;
        numberValue: number;
        floatValue: number;
        booleanValue: boolean;
        dateTimeValue: string;
        blobValue: string;
        height: string;
        width: string;
        position: string;
        transform: string;
        pageControlItemProperties: {
          controlPropertyId: number;
          pageControlItemPropertyValues: {
            value: string;
            isDeleted: boolean;
            status: number;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[];
          isDeleted: boolean;
          status: number;
          id: number;
          subscriberId: number;
          name: string;
          description: string;
          userId: string;
        }[];
      };
      dropEvent: any;
    };
  }>()
);

export const UpdatePageControlItem = createAction(
  '[Editor] Update Page Control Item',
  props<{
    payload: {
      id: number;
      documentPageId: number;
      controlId: number;
      height: string;
      width: string;
      position: string;
      transform: string;
      pageControlItemProperties:
        | {
            controlPropertyId: number;
            pageControlItemPropertyValues: {
              value: string;
              isDeleted: boolean;
              status: number;
              id: number;
              subscriberId: number;
              name: string;
              description: string;
              userId: string;
            }[];
            isDeleted: boolean;
            status: number;
            id: number;
            subscriberId: number;
            name: string;
            description: string;
            userId: string;
          }[]
        | null;
    };
  }>()
);

export const DeletePageControlItem = createAction(
  '[Editor] Delete Page Control Item',
  props<{
    payload: {
      documentPageId: number;
      controlId: number;
      id: number;
    };
  }>()
);

export const GetPageControlItemById = createAction(
  '[Editor] Get Page Control Item By Id',
  props<{
    payload: {
      pagecontrolitemid: number;
      documentId: number;
      elementRef?: any;
    };
  }>()
);

export const GetPageControlItemPropertiesByPageControlItem = createAction(
  '[Editor] Get Page Control Item Properties By Page Control Item',
  props<{
    payload: {
      pagecontrolitemid: number;
    };
  }>()
);

export const SavePageControlItemPropertiesByPageControlItem = createAction(
  '[Editor] Save Page Control Item Properties By Page Control Item',
  props<{
    payload: any;
  }>()
);

export const GetDocumentPages = createAction(
  '[Editor] Get Document Pages',
  props<{
    payload: {
      files: File[];
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const SaveReminderSetting = createAction(
  '[Reminder] Save Reminder Setting',
  props<{
    payload: {
      data: any;
      signingRequestId: number;
    };
  }>()
);

export const DeleteReminderSetting = createAction(
  '[Reminder] Delete Reminder Setting',
  props<{ payload: any }>()
);

export const SaveValiditySetting = createAction(
  '[Validity Setting] Save Validity Setting',
  props<{
    payload: {
      data: any;
      signingRequestId: number;
    };
  }>()
);

export const DeleteValiditySetting = createAction(
  '[Validity] Delete Validity Setting',
  props<{ payload: any }>()
);

export const GetAllCategoryDocument = createAction(
  '[Document ] Get All Category For Documents',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
    };
  }>()
);

export const SaveAllCategoryDocument = createAction(
  '[Document ] Save All Category For Documents',
  props<{ payload: any }>()
);

export const SaveSigningRequestOnContinue = createAction(
  '[Document ] Save Signing Request On Continue',
  props<{ payload: any }>()
);

export const GetSigningRequestValiditySettings = createAction(
  '[Setting  Validity] Get Signing Request Setting For Validity',
  props<{
    payload: {
      signingRequestId: number;
    };
  }>()
);

export const SaveSigningRequestValiditySetting = createAction(
  '[Setting Validity ] Save Signing Request Setting For Validity',
  props<{ payload: any }>()
);

export const GetSigningRequestReminderSettings = createAction(
  '[Setting Reminder] Get Signing Request Setting For Reminder',
  props<{
    payload: {
      objectName: string;
      objectId: number;
    };
  }>()
);

export const SaveSigningRequestReminderSetting = createAction(
  '[Setting Reminder] Save Signing Request Setting For Reminder',
  props<{ payload: any }>()
);

export const SaveSelectedRecipient = createAction(
  '[Selected Recipient] Save Selected Recipient (Actor)',
  props<{
    payload: {
      actorId: number;
      actorFirstName: string;
      actorLastName: string;
      actorEmail: string;
    } | null;
  }>()
);
