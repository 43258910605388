import { createReducer, on, Action } from '@ngrx/store';
import * as RecipientActions from './recipient-signature.actions';

export interface recipientReducerState {
  isLoading: boolean;
  allRecipientActiveSignature: any;
  allRecipientSignature: any;
  defaultRecipientSignature: any;
}

const initialState: recipientReducerState = {
  isLoading: false,
  allRecipientActiveSignature: null,
  allRecipientSignature: null,
  defaultRecipientSignature: null,
};

const recipientReducerInternal = createReducer(
  initialState,
  on(RecipientActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(RecipientActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(RecipientActions.SaveRecipientActiveSignatures, (state, { payload }) => ({
    ...state,
    allRecipientActiveSignature: payload,
  })),

  on(RecipientActions.SaveRecipientSignatures, (state, { payload }) => ({
    ...state,
    allRecipientSignature: payload,
  })),

  on(RecipientActions.SaveRecipientDefaultSignature, (state, { payload }) => ({
    ...state,
    defaultRecipientSignature: payload,
  }))
);

export function recipientReducer(
  state: recipientReducerState | undefined,
  action: Action
) {
  return recipientReducerInternal(state, action);
}
