<ng-container *ngIf="(isLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="invite">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <h5 class="flwmn-heading-5 fw-700 header-text">
              Sign up with invite
            </h5>

            <p class="flwmn-caption subheader-text">
              Let's know few things about you
              <span class="fw-700">({{ email }})</span> so we can make your
              onboarding seamless
            </p>

            <form [formGroup]="inviteForm" (submit)="onSubmit()">
              <div class="registration-form-row mb-1">
                <div class="input-style1 input-md">
                  <label class="input-label">First name</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input type="text" matInput formControlName="firstname" />

                    <mat-error *ngIf="inviteFormControls.firstname.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('firstname') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="input-style1 input-md">
                  <label class="input-label">Last name</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input type="text" matInput formControlName="lastname" />

                    <mat-error *ngIf="inviteFormControls.lastname.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('lastname') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="input-style1 input-md mb-1">
                <label class="input-label">Phone number</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input
                    #individualPhoneNumber
                    type="tel"
                    inputmode="tel"
                    matInput
                    formControlName="phone_number"
                    (input)="
                      inviteForm.patchValue({
                        phone_number: individualPhoneNumber.value.replaceAll(
                          ' ',
                          ''
                        )
                      })
                    "
                  />
                  <mat-error *ngIf="inviteFormControls.phone_number.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('phone_number') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-template #visibleEyeIcon>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>

              <ng-template #hiddenEyeIcon>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.31325 9.68665L1.33325 14.6666"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6668 1.33331L9.68677 6.31331"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>

              <div class="input-style1 input-md">
                <label class="input-label">Password</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input
                    matInput
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                  />

                  <span
                    matSuffix
                    class="img-fluid"
                    (click)="showPassword = !showPassword"
                  >
                    <ng-container *ngIf="showPassword">
                      <ng-container
                        *ngTemplateOutlet="visibleEyeIcon"
                      ></ng-container>
                    </ng-container>

                    <ng-container *ngIf="!showPassword">
                      <ng-container
                        *ngTemplateOutlet="hiddenEyeIcon"
                      ></ng-container>
                    </ng-container>
                  </span>

                  <mat-error *ngIf="inviteFormControls.password.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('password') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <button
                matRipple
                type="submit"
                class="btn flwmn-btn-primary flwmn-btn-md signup-btn"
                [disabled]="inviteForm.invalid"
              >
                Sign Up
              </button>
            </form>
          </div>

          <div class="illustration-section">
            <img
              src="/assets/images/auth/auth-right-illustration-2.png"
              alt="Illustration Image"
              class="illustration"
            />
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>

<!-- <section id="invite">
  <div class="container content" *ngIf="user$ | async; else loading">
    <div class="form-container container-fluid">
      <h1 class="header">Sign up with invite</h1>

      <p class="subheader">
        Let’s know few things about you so we can make your onboarding seamless
      </p>

      <p class="invitee-email" *ngIf="email">{{ email }}</p>

      <form [formGroup]="inviteForm" (submit)="onSubmit()">
        <div class="w-100">
          <div class="input-style1 mb-1">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <mat-label>First Name</mat-label>
              <input type="text" matInput formControlName="firstname" />
              <mat-error *ngIf="inviteFormControls.firstname.invalid">
                <img
                  src="/assets/icons/error.svg"
                  class="img-fluid error-icon"
                />
                {{ getErrorMessage('firstname') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-style1 mb-1">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <mat-label>Last Name</mat-label>
              <input type="text" matInput formControlName="lastname" />
              <mat-error *ngIf="inviteFormControls.lastname.invalid">
                <img
                  src="/assets/icons/error.svg"
                  alt=""
                  class="img-fluid error-icon"
                />
                {{ getErrorMessage('lastname') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-style1 mb-1">
            <div class="row g-1 g-md-2">
              <div class="col-12 col-md-3">
                <mat-form-field appearance="outline" [style.width.%]="100">
                  <mat-label>Country Code <i>(Optional)</i></mat-label>
                  <mat-select
                    panelClass="country-code"
                    formControlName="code"
                    (selectionChange)="onSelectCountryCode($event)"
                  >
                    <ng-container
                      *ngIf="
                        (allCountryCodes | async) !== null;
                        else noCountryCodes
                      "
                    >
                      <ng-container
                        *ngFor="let countryCode of allCountryCodes | async"
                      >
                        <mat-option [value]="countryCode.code">
                          <div class="country-code-container">
                            <img [src]="countryCode.flag" alt="" class="flag" />

                            <p class="country">
                              {{ countryCode.country }} <span></span>
                            </p>

                            <p class="code">({{ countryCode.code }})</p>
                          </div>
                        </mat-option>
                      </ng-container>
                    </ng-container>

                    <ng-template #noCountryCodes>
                      <mat-option disabled>Loading Country Codes...</mat-option>
                    </ng-template>
                  </mat-select>

                  <mat-error *ngIf="inviteFormControls.code.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('code') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-9">
                <mat-form-field appearance="outline" [style.width.%]="100">
                  <mat-label>Phone Number</mat-label>
                  <input
                    #phoneNumber
                    type="text"
                    matInput
                    formControlName="phone_number"
                    (input)="
                      inviteForm.patchValue({
                        phone_number: phoneNumber.value.replaceAll(' ', '')
                      })
                    "
                  />
                  <mat-error *ngIf="inviteFormControls.phone_number.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('phone_number') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="input-style1 mb-1">
            <mat-form-field appearance="outline" [style.width.%]="100">
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
              />
              <mat-error *ngIf="inviteFormControls.password.invalid">
                <img
                  src="/assets/icons/error.svg"
                  alt=""
                  class="img-fluid error-icon"
                />{{ getErrorMessage('password') }}
              </mat-error>
              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="showPassword = !showPassword"
                [attr.aria-label]="'show password'"
                [attr.aria-pressed]="showPassword"
              >
                <mat-icon color="primary">
                  {{ showPassword ? 'visibility' : 'visibility_off' }}
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="d-flex justify-content-center my-2">
            <button
              matRipple
              class="btn onyx-btn-flat onyx-btn-full w-75 continue-btn"
              (click)="onSubmit()"
              [disabled]="inviteForm.invalid || (isLoading | async) !== false"
            >
              Signup

              <ng-container *ngIf="(isLoading | async) !== false">
                <app-button-loader></app-button-loader>
              </ng-container>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-template #loading>
    <div>Loading content</div>
  </ng-template>
</section> -->
