import { createReducer, on, Action } from '@ngrx/store';
import * as DocumentActions from './documentation.action';

export interface State {
  isLoading: boolean;
  isLoadingSidebarItems: boolean;
  isLoadingCurrentPage: boolean;
  isSidebarToggled: boolean;
  sidebarItems: any[] | null;
  currentPage: any;
}

const initialState: State = {
  isLoading: false,
  isLoadingSidebarItems: false,
  isLoadingCurrentPage: false,
  isSidebarToggled: false,
  sidebarItems: null,
  currentPage: null,
};

const documentationReducerInternal = createReducer(
  initialState,
  on(DocumentActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(DocumentActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(DocumentActions.IsLoadingSidebarItems, (state, { payload }) => ({
    ...state,
    isLoadingSidebarItems: payload,
  })),
  on(DocumentActions.IsLoadingCurrentPage, (state, { payload }) => ({
    ...state,
    isLoadingCurrentPage: payload,
  })),
  on(DocumentActions.IsSidebarToggled, (state, { payload }) => {
    return {
      ...state,
      isSidebarToggled: payload,
    };
  }),
  on(DocumentActions.SaveSidebarItems, (state, { payload }) => {
    return {
      ...state,
      sidebarItems: payload,
    };
  }),
  on(DocumentActions.SaveCurrentPage, (state, { payload }) => {
    return {
      ...state,
      currentPage: payload,
    };
  })
);

export function documentationReducer(state: State | undefined, action: Action) {
  return documentationReducerInternal(state, action);
}
